
import { types } from "./actionTypes";




export const agreementDate = (values) => ({
  type: types.DATE_CAHNGE,
  values,
});




export const createAgreement = (values, callback) => ({
  type: types.SECONDARY_CREATE_AGREEMENT,
  values,
  callback,
});



export const fetchSecondaryAgreements = (values, cb, duration) => {
    
    return {
      type: types.FETCH_SECONDARY_AGREEMENTS,
      values,
      cb,
      duration,
    };
  };


export const fetchViewAgreement = (id) => ({
    type: types.VIEW_AGREEMENT,
    id,
});


export const fetchLastAgreement = (id) => ({
  type: types.SECONDARY_FETCH_LAST_AGREEMENT_VALUES,
  id,
});


export const clearLoadedValues = () => ({
    type: types.CLEAR_LOADED_VALUES,
});


export const fetchAllAgreements = (offset, limit, callback) => ({
  type: types.SECONDARY_COPY_AGREEMENT_LIST,
  offset,
  limit,
  callback,
});




export const storeAgreement = (values) => ({
  type: types.STORE_AGREEMENT,
  payload: values,
});

export const fetchSignedURL = (values, callback) => ({
  type: types.FETCH_SIGNED_URL,
  values,
  callback,
});


export const uploadAgreement = (uri, data, headers) => ({
  type: types.UPLOAD_AGREEMENT,
  uri,
  data,
  headers,
});


export const uploadImagesAws = (uri, data, headers, callback) => ({
  type: types.UPLOAD_IMAGES_AWS,
  uri,
  data,
  headers,
  callback,
});


export const fetchSecondaryInvoices = (values, callback, fromDate, endDate, state) => ({
  type: types.FETCH_SECONDARY_INVOICES,
  values,
  fromDate,
  endDate,
  state,
  callback,
});


export const acceptSecondaryInvoice = (values, callback) => ({
  type: types.ACCEPT_SECONDARY_INVOICE,
  values,
  callback,
});

export const fetchSecondaryInvoicesExcel = (values, callback, fromDate, endDate, state) => ({
  type: types.FETCH_SECONDARY_INVOICES_EXCEL,
  values,
  fromDate,
  endDate,
  state,
  callback,
});



export const approveSecondaryInvoice = (invoiceID, callback) => ({
  type: types.APPROVE_SECONDARY_INVOICE,
  invoiceID,
  callback,
});



export const rejectSecondaryInvoice = (values, callback) => ({
  type: types.REJECT_SECONDARY_INVOICE,
  values,
  callback,
});


export const fetchInvoiceById = (invId, callback) => ({
  type: types.FETCH_SECONDARY_INVOICE_BYID,
  invId,
  callback,
});


export const invoiceBillAddOrDelete = (payload, callback) => ({
  type: types.SECONDARY_INVOICE_BILL_DELETE,
  payload,
  callback,
});


export const updateInvoice = (values, callback) => ({
  type: types.UPDATE_SECONDARY_INVOICE,
  values,
  callback,
});


export const updateBill = (values, callback) => ({
  type: types.UPDATE_SECONDARY_BILL,
  values,
  callback,
});


export const fetchBills = (
  values,
  startDate,
  endDate,
  stateCode,
  callback
) => ({
  type: types.FETCH_SECONDARY_BILLS,
  values,
  startDate,
  endDate,
  stateCode,
  callback,
});



export const submitToRaisedInvoice = (payload, callback) => ({
  type: types.SUBMIT_TO_RAISE_SECONDARY_INVOICE,
  payload,
  callback,
});


export const createInvoiceForBills = (payload, callback) => {
  return {
  type: types.CREATE_SECONDARY_INVOICE_BILLS,
  payload,
  callback,
}
};