import React, { Component } from "react";
import refreshIcon from './assets/refresh_cw.svg'

import "../HorizontalLayout/header.css";
import { restrictSync, roleCheck } from "../../pages/restrict";
import { PERMS } from "../../enum/perms.enum";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { Button } from "reactstrap";
import { fetchSync } from "../../store/common/actions";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import toggle from "../../assets/images/ToggleGroup13563.png";
import VerticalMenuBar from "../VerticalMenuBar/index";
import Help from "../../pages/baxter_help/Help";
import BaxterEnv from "./BaxterEnv";
// import AppTheme from "../AppTheme";
import { changeSwitchTheme } from "../../store/layout/actions";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class HeaderNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userLangs: undefined,
      show: false,
      // client: "",
    };
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  // componentDidMount() {
  //   if (localStorage.getItem("AppType")) {
  //     this.setState({
  //       client: localStorage.getItem("AppType"),
  //     });
  //   } else if (
  //     localStorage.getItem("tenants") &&
  //     JSON.parse(localStorage.getItem("tenants"))[0].label === "Baxter"
  //   ) {
  //     this.setState({
  //       client: "Baxter",
  //     });
  //   } else {
  //     this.setState({
  //       client: "Kidneco",
  //     });
  //   }
  //   const tenantObj = JSON.parse(localStorage.getItem("tenants"));
  //   for (let i = 0; i < tenantObj.length; i++) {
  //     const element = tenantObj[i];
  //     if (localStorage.getItem("AppType")) {
  //       if (element.label === localStorage.getItem("AppType")) {
  //         localStorage.setItem("AppTenantId", element._id);
  //       }
  //     } else {
  //       // console.log(JSON.parse(localStorage.getItem("tenants"))[0]._id);
  //       localStorage.setItem(
  //         "AppTenantId",
  //         JSON.parse(localStorage.getItem("tenants"))[0]._id
  //       );
  //     }
  //   }
  // }

  toggleSynch = () => {
    //code for synchronization with Oracle
    this.setState({ isLoading: true });
    // console.log("Sync 1");
    this.props.fetchSync((res) => {
      if (res.success === true) {
        NotificationManager.success("Synced", "Success");
        this.setState({ isLoading: false });
      } else {
        NotificationManager.error("Not Synced", "failure");
        this.setState({ isLoading: false });
      }
    });
  };

  toggleSwitchArray = [
    "/secondary-add-indent",
    "/add-indent",
    "/secondary-indent-planning",
    "/indent-planning",
    "/secondary-assigned-indent",
    "/assigned-indent",
    "/secondary-freight",
    "/freight",
    "/secondary-customers",
    "/secondary-trips",
    "/trips",
    "/secondary-vehicle-placement-detail",
    "/vehicle-placement-detail",
    "/secondary-operationsDashboard",
    "/operationsDashboard",
    "/secodary-TransporterDashboard",
    "/transporterDashboard",
    "/secondary-BillingIndex",
    "/BillingIndex",
    "/SecondaryBilling/AddAgreement",
    "/Billing/AddAgreement",
    "/Billing/InvoiceList",
    "/Billing/Secondary-InvoiceList",
    "/invoiceGeneration",
    "/Secondary-invoiceGeneration"
  ];

  primarySelection = () => {
    localStorage.setItem("typeSelection", "Primary");
  };

  secondarySelection = () => {
    localStorage.setItem("typeSelection", "Secondary");
  };

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  componentDidUpdate() {
    var switchTheme = this.props.switchsTheme;
    // console.log(switchTheme, "switchTheme");
    let initTogglestorage = localStorage.getItem("Theme");
    if (
      this.props.switchsTheme === "dark__mode" ||
      initTogglestorage === "dark__mode"
    ) {
      const spanColBread = document.getElementsByClassName("breadactive");
      if (spanColBread && spanColBread.length) {
        spanColBread[0].style.color = "#FFFFFF";
      }
      const spanCol = document.getElementsByClassName("span-font-roboto");
      if (spanCol && spanCol.length) {
        spanCol[0].style.color = "#B7A9FF";
      }

      const spanlan = document.getElementsByClassName("ri-fullscreen-line");
      if (spanlan && spanlan.length) {
        spanlan[0].style.color = "#FFFFFF";
      }
    } else if (
      this.props.switchsTheme === "light__mode" ||
      initTogglestorage === "light__mode"
    ) {
      const spanColBread = document.getElementsByClassName("breadactive");
      if (spanColBread && spanColBread.length)
        spanColBread[0].style.color = "#343434";

      const spanCol = document.getElementsByClassName("span-font-roboto");
      if (spanCol && spanCol.length) spanCol[0].style.color = "#2800FC";

      const spanlan = document.getElementsByClassName("ri-fullscreen-line");
      if (spanlan && spanlan.length) spanlan[0].style.color = "#000000";
    }

    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  closeMenuBar = (closeIcon) => {
    // console.log("close", closeIcon);
    this.setState({
      show: closeIcon,
    });
  };

  // baxterSelection = () => {
  //   localStorage.setItem("AppType", "Baxter");
  //   this.setState({
  //     client: "Baxter",
  //   });
  //   if (localStorage.getItem("tenants")) {
  //     const tenantObj = JSON.parse(localStorage.getItem("tenants"));
  //     for (let i = 0; i < tenantObj.length; i++) {
  //       const element = tenantObj[i];
  //       console.log(element);
  //       if (element.label === "Baxter") {
  //         localStorage.setItem("AppTenantId", element._id);
  //       }
  //     }
  //   }
  //   window.location.reload();
  // };

  // kidnecoSelection = () => {
  //   localStorage.setItem("AppType", "Kidneco");
  //   this.setState({
  //     client: "Kidneco",
  //   });
  //   if (localStorage.getItem("tenants")) {
  //     const tenantObj = JSON.parse(localStorage.getItem("tenants"));
  //     for (let i = 0; i < tenantObj.length; i++) {
  //       const element = tenantObj[i];
  //       console.log(element);
  //       if (element.label === "Kidneco") {
  //         localStorage.setItem("AppTenantId", element._id);
  //       }
  //     }
  //   }
  //   window.location.reload();
  // };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    const path = this.props.location.pathname;
    let rotate;
    if (this.state.isLoading === true) {
      rotate = "rotate";
    }
    var switchTheme = this.props.switchsTheme;
    let initTogglestorage = localStorage.getItem("Theme");

    if (switchTheme === "dark__mode" || initTogglestorage === "dark__mode") {
      var headerDarkBgClr = "#373756";
      var darkTheme = true;
    } else if (
      switchTheme === "light__mode" ||
      initTogglestorage === "light__mode"
    ) {
      headerDarkBgClr = " ";
      darkTheme = false;
    }

    const userData = JSON.parse(window.localStorage.getItem("authUser"));
    const userType = userData?.data?.role?.label;
    return (
      <>
        {/* <Card id="page-topbar-new"> */}
        <header
          id="page-topbar-new "
          className="navbar-header-new-top shadow newheader"
          style={{
            backgroundColor: `${headerDarkBgClr}`,
          }}
        >
          <div
            className={
              this.props?.history?.location?.pathname ===
                "/ontime-delivery-report" ||
                this.props?.history?.location?.pathname === "/customers"
                ? "navbar-header-new-scroll"
                : "navbar-header-new"
            }
          // className="navbar-header-new"
          >
            <div className="d-flex">
              <div className="mobile-toggle ml-1 mr-2">
                <img
                  onClick={() => {
                    this.setState({ show: !this.state.show }, () => { });
                  }}
                  src={toggle}
                  height="15px"
                  width="18px"
                  alt=""
                ></img>
                <VerticalMenuBar
                  show={this.state.show}
                  closeMenuBar={this.closeMenuBar}
                />
              </div>
              <div className="">
                {path === "/operationsDashboard" ||
                  path === "/operationalDashboard" ||
                  path === "/secondary-operationsDashboard" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.dashboard?.DASHBOARD}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.OPERATIONS}
                    </span>
                  </>
                ) : path === "/transporterDashboard" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.dashboard?.DASHBOARD}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.planning?.TRANSPORTER}
                    </span>
                  </>
                ) : path === "/invoice-dashboard" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.dashboard?.DASHBOARD}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.INVOICE}
                    </span>
                  </>
                ) : path === "/indent-planning" ||
                  path === "/secondary-indent-planning" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                    </span>
                  </>
                ) : path === "/truckUtilizationReports" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.TRUCK_UTILIZATION
                        ? this.state.userLangs?.dashboard?.TRUCK_UTILIZATION
                        : "Truck Utilization"}
                    </span>
                  </>
                ) : path === "/logbook" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.LOGBOOK
                        ? this.state.userLangs?.dashboard?.LOGBOOK
                        : "Logbook"}
                    </span>
                  </>
                ) : path === "/add-indent" ? (
                  <>
                    <div className="mobileAddIndent">
                      <Link to="/indent-planning">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto ">
                        {this.state.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                    <div className="desktopAddIndent">
                      <Link to="/indent-planning">
                        <span
                          className="breadcrumbInactive breadcrumb-active span-font-roboto"
                          style={{ color: `${darkTheme ? "#B7A9FF" : ""}` }}
                        >
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        {this.state.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                  </>
                ) : path === "/Route-Optimization" ? (
                  <>
                    <div className="mobileAddIndent">
                      <Link to="/Route-Optimization">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto ">
                        {"Route-Optimization"}
                        {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                      </span>
                    </div>
                    <div className="desktopAddIndent">
                      <Link to="/Route-Optimization">
                        <span
                          className="breadcrumbInactive breadcrumb-active span-font-roboto"
                          style={{ color: `${darkTheme ? "#B7A9FF" : ""}` }}
                        >
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        {"Route-Optimization"}
                        {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                      </span>
                    </div>
                  </>
                ) : path === "/trips" || path === "/secondary-trips" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.trip?.TRIPS_DETAILS}
                    </span>
                  </>
                ) : path === "/tripDetails" || path === "/tripAllDetails" ? (
                  <>
                    <Link to="/trips">
                      <span
                        className="breadcrumbInactive breadcrumb-active span-font-roboto"
                        style={{ color: `${darkTheme ? "#ffffff" : ""}` }}
                      >
                        {this.state.userLangs?.trip?.TRIPS_DETAILS}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.trip?.TRIP_HISTORY}
                    </span>
                  </>
                ) : path === "/Tracking" ? (
                  <>
                    <Link to="/trips">
                      <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                        {this.state.userLangs?.trip?.TRIPS_DETAILS}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.tracking?.TRACKING}
                    </span>
                  </>
                ) : path === "/drivers" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.DRIVERS}
                    </span>
                  </>
                ) : path === "/customers" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* {this.state.userLangs?.masters?.DRIVERS} */}
                      Customers
                    </span>
                  </>
                ) : path === "/products" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* {this.state.userLangs?.masters?.DRIVERS} */}
                      Products
                    </span>
                  </>
                ) : path === "/locations" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      Locations
                      {/* {this.state.userLangs?.masters?.DRIVERS} */}
                    </span>
                  </>
                ) : path === "/vehicles" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.VEHICLES}
                    </span>
                  </>
                ) : path === "/vehicle-type" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.VEHICLE_TYPE}
                    </span>
                  </>
                ) : path === "/user" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.USERS}
                    </span>
                  </>
                ) : path === "/roles" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.ROLES}
                    </span>
                  </>
                ) : path === "/indents" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.INDENTS}
                    </span>
                  </>
                ) : path === "/hops" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.HOPS ?? "Hops"}
                    </span>
                  </>
                ) : path === "/freight" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.FREIGHT ?? "Freight"}
                    </span>
                  </>
                ) : path === "/shipments" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.SHIPMENT ?? "Shipment"}
                    </span>
                  </>
                ) : path === "/routes" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.ROUTES}
                    </span>
                  </>
                ) : path === "/invoiceGeneration" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* Invoice Genarations */}
                      {this.state.userLangs?.invoice?.INVOICE_GENERATION}
                    </span>
                  </>
                ) : path === "/Billing/InvoiceList" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* Invoice Genarations */}
                      {this.state.userLangs?.invoice?.INVOICE_LIST}
                    </span>
                  </>
                ) : path === "/BillingIndex" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.AGREEMENT}
                    </span>
                  </>
                ) : path === "/Billing/AddAgreement" ? (
                  <>
                    <Link to="/BillingIndex">
                      <span className="breadcrumbInactive breadcrumb-active span-font-roboto">
                        {this.state.userLangs?.invoice?.AGREEMENT}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.ADD_AGREEMENT}
                    </span>
                  </>
                ) : path === "/tripBills" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.INVOICE_LISTS}
                    </span>
                  </>
                ) : path === "/Reports" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.TRANSPORTER_PERFORMANCE}
                    </span>
                  </>
                ) : path === "/ontime-delivery-report" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.ON_TIME_DELIVERY_REPORT1 ??
                        "On Time Delivery Report  "}
                    </span>
                  </>
                ) : path === "/vehicle-placement" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.VEHICLE_PLACEMENT_REPORT ??
                        "Vehicle Placement Report  "}
                    </span>
                  </>
                ) : path === "/vehicle-placement-detail" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report
                        ?.VEHICLE_PLACEMENT_DETAIL_REPORT ??
                        "Vehicle Analysis Report  "}
                    </span>
                  </>
                ) : path === "/indentReport-analysis" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.LANE}
                    </span>
                  </>
                ) : path === "/statusIndent" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.STATUS}
                    </span>
                  </>
                ) : path === "/transporterIndent" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.TRANSPORTER}
                    </span>
                  </>
                ) : path === "/ControlTower" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.controlTower?.CONTROL_TOWER}
                    </span>
                  </>
                ) : path === "/profileSettings" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.PROFILE_SETTINGS}
                    </span>
                  </>
                ) : path === "/viewOrders" ||
                  path === "/viewProductByOrder" ||
                  path === "/trackOrder" ||
                  path === "/orderHistory" ||
                  path === "/ordersList" ? (
                  <>
                    <Link to="/ordersList">
                      <span className="span-font-roboto">
                        {this.state.userLangs?.orders?.ORDER ?? "Order"}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {path === "/viewProductByOrder"
                        ? this.state.userLangs?.orders?.VIEW_PRODUCT ??
                        "View Products"
                        : path === "/trackOrder"
                          ? this.state.userLangs?.orders?.TRACK_ORDER ??
                          "Track Orders"
                          : path === "/orderHistory"
                            ? this.state.userLangs?.orders?.ORDER_HISTORY ??
                            "Order History"
                            : path === "/ordersList"
                              ? this.state.userLangs?.orders?.ORDER_LIST ??
                              "Order List"
                              : this.state.userLangs?.orders?.VIEW_ORDER ??
                              "View Orders"}
                    </span>
                  </>
                ) : path === "/invoiceOrderProduct" ? (
                  <>
                    <Link to="/ordersList">
                      <span className="span-font-roboto">
                        {this.state.userLangs?.orders?.ORDER ?? "Order"}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.orders?.VIEW_INVOICE ??
                        "View Invoice"}
                    </span>
                  </>
                ) : path === "/InvoiceList" || path === "/viewInvoiceDetail" ? (
                  <>
                    <Link to="/InvoiceList">
                      <span className="span-font-roboto">
                        {this.state.userLangs?.orders?.INVOICE ?? "Invoice"}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {path === "/viewInvoiceDetail"
                        ? this.state.userLangs?.orders?.VIEW_INVOICE ??
                        "View Invoice Detail"
                        : this.state.userLangs?.orders?.INVOICE_LIST ??
                        "Invoice List"}
                    </span>
                  </>
                ) : path === "/order/products" ||
                  path === "/viewOrderProductDetail" ? (
                  <>
                    <Link to="/order/products">
                      <span className="span-font-roboto">
                        {this.state.userLangs?.orders?.PRODUCT ?? "Product"}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {path === "/viewOrderProductDetail"
                        ? this.state.userLangs?.orders?.PRODUCT_DETAIL ??
                        "Product Detail"
                        : this.state.userLangs?.orders?.PRODUCT_LIST ??
                        "Product List"}
                    </span>
                  </>
                ) : path === "/tripList" ? (
                  <>
                    <Link to="/tripList">
                      <span className="span-font-roboto">
                        {this.state.userLangs?.orders?.TRIP ?? "Trip"}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.orders?.TRIP_LIST ?? "Trip List"}
                    </span>
                  </>
                ) : path === "/trackOrders" ? (
                  userType !== "customer" ? (
                    <>
                      <Link to="/tripList">
                        <span className="span-font-roboto">
                          {this.state.userLangs?.orders?.TRIP ?? "Trip"}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        Track Order
                      </span>
                    </>
                  ) : (
                    <>
                      <Link to="/ordersList">
                        <span className="span-font-roboto">
                          {this.state.userLangs?.orders?.ORDER ?? "Order"}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        Track Order
                      </span>
                    </>
                  )
                ) : path === "/qualityCheck" ? (
                  <>
                    <Link to="/tripList">
                      <span className="span-font-roboto">
                        {this.state.userLangs?.orders?.TRIP ?? "Trip"}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      QualityCheck
                    </span>
                  </>
                ) : path === "/viewTripDetails" ? (
                  <>
                    <Link to="/tripList">
                      <span className="span-font-roboto">
                        {this.state.userLangs?.orders?.TRIP ?? "Trip"}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      View Details
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="navbar-header-new-2">
              {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
                <div className="dropdown  ml-1">
                  <div className="d-flex align-items-center justify-content-center">
                    {/* <div style={{ display: "flex", gap: "15px" }}> */}
                    <div>
                      <BaxterEnv />
                      {/* {JSON.parse(localStorage.getItem("tenants"))?.length >
                      1 && (
                      <>
                        <div className="toggle-switch-1 ">
                          <div
                            onClick={() => this.baxterSelection()}
                            className={
                              this.state.client === "Baxter"
                                ? "isActivated"
                                : ""
                            }
                          >
                            Baxter
                          </div>

                          <div
                            onClick={() => this.kidnecoSelection()}
                            className={
                              this.state.client === "Kidneco"
                                ? "isActivated"
                                : ""
                            }
                          >
                            Kidneco
                          </div>
                        </div>
                      </>
                    )} */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="dropdown  ml-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      {/* {process.env.REACT_APP_CLIENT == "3_BAXTER" ? (
                     
            ) : (
              ""
                    )} */}

                      {process.env.REACT_APP_CLIENT == "3_BAXTER" ? (
                        ""
                      ) : (
                        <>
                          {this.toggleSwitchArray.includes(
                            window.location.pathname
                          ) && (
                              <div className="toggle-switch- toggle-switch-2">
                                <Link
                                  to={
                                    (window.location.pathname ===
                                      "/secondary-add-indent" &&
                                      "/add-indent") ||
                                    (window.location.pathname ===
                                      "/secondary-indent-planning" &&
                                      "/indent-planning") ||
                                    (window.location.pathname ===
                                      "/secondary-assigned-indent" &&
                                      "/assigned-indent") ||
                                    (window.location.pathname ===
                                      "/secondary-freight" &&
                                      "/freight") ||
                                    (window.location.pathname ===
                                      "/secondary-trips" &&
                                      "/trips") ||
                                    (window.location.pathname ===
                                      "/secondary-vehicle-placement-detail" &&
                                      "/vehicle-placement-detail") ||
                                    (window.location.pathname ===
                                      "/secondary-operationsDashboard" &&
                                      "/operationsDashboard") ||
                                    (window.location.pathname ===
                                      "/secodary-TransporterDashboard" &&
                                      "/transporterDashboard") ||
                                    (
                                      window.location.pathname ===
                                      "/secondary-BillingIndex" &&
                                      "/BillingIndex"
                                    ) ||
                                    (window.location.pathname === "/SecondaryBilling/AddAgreement" && "/Billing/AddAgreement")
                                    ||
                                    (window.location.pathname === "/Billing/Secondary-InvoiceList" && "/Billing/InvoiceList")
                                    ||
                                    (window.location.pathname === "/Secondary-invoiceGeneration" && "/invoiceGeneration")
                                  }
                                >
                                  <div
                                    onClick={() => this.primarySelection()}
                                    className={
                                      localStorage.getItem("typeSelection") ===
                                        "Primary"
                                        ? "isActivated"
                                        : ""
                                    }
                                  >
                                    Primary
                                  </div>
                                </Link>
                                <Link
                                  to={
                                    (window.location.pathname === "/add-indent" &&
                                      "/secondary-add-indent") ||
                                    (window.location.pathname ===
                                      "/indent-planning" &&
                                      "/secondary-indent-planning") ||
                                    (window.location.pathname ===
                                      "/assigned-indent" &&
                                      "/secondary-assigned-indent") ||
                                    (window.location.pathname === "/freight" &&
                                      "/secondary-freight") ||
                                    (window.location.pathname === "/trips" &&
                                      "/secondary-trips") ||
                                    (window.location.pathname ===
                                      "/vehicle-placement-detail" &&
                                      "/secondary-vehicle-placement-detail") ||
                                    (window.location.pathname ===
                                      "/operationsDashboard" &&
                                      "/secondary-operationsDashboard") ||
                                    (window.location.pathname ===
                                      "/transporterDashboard" &&
                                      "/secodary-TransporterDashboard") ||
                                    (
                                      window.location.pathname ===
                                      "/BillingIndex" && "/secondary-BillingIndex"
                                    ) ||
                                    (
                                      window.location.pathname === "/Billing/AddAgreement" &&
                                      "/SecondaryBilling/AddAgreement"
                                    ) ||
                                    (window.location.pathname === "/Billing/InvoiceList" &&
                                      "/Billing/Secondary-InvoiceList")
                                    ||
                                    (window.location.pathname === "/invoiceGeneration" &&
                                      "/Secondary-invoiceGeneration"
                                    )
                                  }
                                >
                                  <div
                                    className={
                                      localStorage.getItem("typeSelection") ===
                                        "Secondary"
                                        ? "isActivated"
                                        : ""
                                    }
                                    onClick={() => this.secondarySelection()}
                                  >
                                    Secondary
                                  </div>
                                </Link>
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div
                className="d-flex mt-0 pr-2 "
                style={{
                  padding: "0px 0px",
                  alignItems: "center",
                  marginTop: "0px important",
                  paddingTop: "0px !important",
                }}
              >
                {restrictSync(PERMS.INDENT_PLANNER_READ) &&
                  (roleCheck("Admin") ||
                    roleCheck("indent_planner") ||
                    roleCheck("loading_unloading")) ? (
                  <>
                    <div className=" mt-0 pt-0 dropdown mobileSync d-lg-inline-block ml-2">

                    <div className="tooltip-container">
                        <Button
                          style={{
                            position: "relative",
                            // height: "36px",
                          }}
                          type="button"
                          color="none"
                          onClick={this.toggleSynch}
                          className="desktopDisplay"
                        >
                          {/* <span
                          style={{
                            display: "block",
                            marginTop: "-10px",
                          }}
                        >
                          <i
                            style={{
                              width: "17.11px",
                              marginTop: "0px",
                              height: "13.993px",
                              color: `${darkTheme ? "#FFFFFF" : "#000000"}`,
                              margin: "12px",
                              paddingBottom: "14px",
                            }}
                            className={`fas fa-sync ${rotate}`}
                          ></i>
                          <span
                            style={{
                              color: `${darkTheme ? "#FFFFFF" : "#343434"}`,
                            }}
                          >
                            {this.state.userLangs?.common?.ORACLE_SYNC}
                          </span>
                        </span> */}
                          <img
                            style={{
                              width: '24px', height: '24px',
                            }}
                            className={`filter-img ${rotate}`}
                            src={refreshIcon}
                            alt="exp"
                          />
                        </Button>
                        <span className="tooltip" style={{bottom:'-30px'}}>Oracle Sync</span>
                        </div>
                      <Button
                        style={{
                          position: "relative",
                          height: "40px",
                          paddingRight: "10px",
                        }}
                        type="button"
                        color="none"
                        onClick={this.toggleSynch}
                        className="mobileDisplay HoverSync"
                      >
                        {/* <span>
                          <i
                            style={{
                              // width: "10.11px",
                              // marginTop: "0px",
                              // height: "5.99px",
                              color: `${darkTheme ? "#FFFFFF" : "#000000"}`,
                              // margin: "12px",
                              // paddingBottom: "14px",
                            }}
                            className={`fas fa-sync ${rotate}`}
                          ></i>
                        </span> */}

                        <img
                          style={{
                            width: '24px', height: '24px',
                          }}
                          className={`filter-img ${rotate}`}
                          src={refreshIcon}
                          alt="exp"
                        />
                      </Button>
                      
                    </div>
                  </>
                ) : null}

                {/* <AppTheme /> */}
                {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
                  <div className="dropdown  ml-1">
                    <Help />
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="dropdown d-none d-lg-inline-block ml-1">
                  <Button
                    type="button"
                    color="none"
                    onClick={this.toggleFullscreen}
                    className="header-item noti-icon waves-effect"
                    data-toggle="fullscreen"
                  >
                    <i
                      className="ri-fullscreen-line"
                      style={{ color: "black" }}
                    ></i>
                  </Button>
                </div> */}
                <ProfileMenu darkThemeProfile={darkTheme} />
              </div>
            </div>

          </div>
        </header>
        {/* </Card> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    switchsTheme: state.Layout.switchTheme,
  };
};
export default connect(mapStateToProps, { fetchSync, changeSwitchTheme })(
  HeaderNew
);
