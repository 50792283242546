let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: "Transpoter Name",
        field: "transporterName",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: "Truck Plan Date",
        field: "truckPlanDate",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: "Plan Given Date",
        field: "planGivenDate",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: "Location From",
        field: "locationFrom",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: "STL Code",
        field: "destinationCode",
        sort: "asc",
        width: 3,
        width: 100,
      },

      {
        label: "Ship To Location",
        field: "shipToLocation",
        sort: "asc",
        // width: 4,
        width: 100,
      },

      {
        label: "Two Point Loading",
        field: "twoPointLoading",
        sort: "asc",
        // width: 2,
        width: 100,
      },
      {
        label: "Truck Type",
        field: "truckType",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: "Truck Asked",
        field: "truckAsked",
        sort: "asc",
        // width: 2,
        width: 100,
      },
      {
        label: "Truck Placed",
        field: "truckPlaced",
        sort: "asc",
        // width: 2,
        width: 100,
      },
      {
        label: "Remarks",
        field: "remark",
        sort: "asc",
        // width:3,
        width: 100,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
    columns: [
        {
          label: "Transpoter Name",
          field: "transporterName",
          sort: "asc",
          // width: 4,
          width: 180,
        },
        {
          label: "Truck Plan Date",
          field: "truckPlanDate",
          sort: "asc",
          // width: 4,
          width: 100,
        },
        {
          label: "Plan Given Date",
          field: "planGivenDate",
          sort: "asc",
          // width: 4,
          width: 100,
        },
        {
          label: "Location From",
          field: "locationFrom",
          sort: "asc",
          // width: 4,
          width: 100,
        },
        {
          label: "STL Code",
          field: "destinationCode",
          sort: "asc",
          // width: 3,
          width: 100,
        },
  
        {
          label: "Ship To Location",
          field: "shipToLocation",
          sort: "asc",
          // width: 4,
          width: 160,
        },
  
        {
          label: "Two Point Loading",
          field: "twoPointLoading",
          sort: "asc",
          // width: 2,
          width: 100,
        },
        {
          label: "Truck Type",
          field: "truckType",
          sort: "asc",
          // width: 4,
          width: 100,
        },
        {
          label: "Truck Asked",
          field: "truckAsked",
          sort: "asc",
          // width: 2,
          width: 100,
        },
        {
          label: "Truck Placed",
          field: "truckPlaced",
          sort: "asc",
          // width: 2,
          width: 100,
        },
        {
          label: "Remarks",
          field: "remark",
          sort: "asc",
          // width:3,
          width: 100,
        },
      ],
};

export const searchBar = {
  searchBarData: [
  ],
};
