import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from "../restrict";
import { Row, Col } from "reactstrap";
import { PERMS } from "../../enum/perms.enum";
//components
import QuickFilters from "./QuickFilter";
import Index from "../../components/Common/DataTableNeo/Index";
// files
import { HeaderData, searchBar, getLocalStorage } from "./RowHeader.js";
// actions
import { fetchRoutesForMaster } from "../../store/route/actions";
import { fetchHops } from "../../store/hop/actions";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offset: 0,
      filterNames: {},
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_READ);

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }
  componentDidMount = () => {
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
    this.getAllSourceHopData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }
  // getAllData = () => {
  //   this.setState({ loading: true }, () => {});
  //   let viaAr = [];
  //   if (this.state.filterNames.viaHops?.length > 0) {
  //     this.state.filterNames.viaHops.map((e) => {
  //       viaAr.push(e?.value?.id);
  //     });
  //   }
  //   this.props.fetchRoutesForMaster(
  //     {
  //       offset: offset,
  //       source: this.state.filterNames.sourceHop?.value?.id,
  //       destination: this.state.filterNames.destinationHop?.value?.id,
  //       viaHops: viaAr,
  //       routeCode: this.state.filterNames?.routeCode,
  //       name: this.state.filterNames?.name,
  //     },
  //     () => {
  //       this.setState({ loading: false });
  //     }
  //   );
  // };
  handleChangePage = (event, val) => {
    // console.log(event, val, "handlepage");
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };
  getAllSourceHopData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 30,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };
  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
        hopCode: filtersHopsData?.hopCode,
      },
      (res) => {
        this.hops = [];
        if (res?.data?.docs?.length > 0) {
          // this.hops = [this.initialVal("Location")];
          res.data.docs.forEach((ele) => {
            this.hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: {
                name:
                  ele.address.addressLine1 +
                  ele.address.city +
                  ele.address.state,
                hopCode: ele.hopCode,
                id: ele._id,
              },
            });
          });
          // this.setState({ hops: this.hops }, () => {});
               this.setState({ hops: this.hops }, () => {
                  searchBar.searchBarData.forEach((obj) => {
                    if (obj.field === "source") {
                      obj.options = this.state.hops; 
                    }
                  });
                });
               this.setState({ hops: this.hops }, () => {
                  searchBar.searchBarData.forEach((obj) => {
                    if (obj.field === "destination") {
                      obj.options = this.state.hops; 
                    }
                  });
                });
               this.setState({ hops: this.hops }, () => {
                  searchBar.searchBarData.forEach((obj) => {
                    if (obj.field === "viaHops") {
                      obj.options = this.state.hops; 
                    }
                  });
                });
        }
      }
    );
  };
  // get location name by filter
  filtersHops = (e) => {
    let pattern = /[0-9]/g;
    if (e?.length > 2) {
      let filtersHopsData = {
        offset: 0,
        limit: 30,
        q: e.match(pattern) ? "" : e,
        hopCode: e.match(pattern) ? e : "",
      };
      this.fetchHops(filtersHopsData);
    }
    if (e?.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 30,
        q: " ",
        hopCode: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };

  filterDestinationHops = (e) => {
    if (e?.length > 2) {
      let filtersHopsData = {
        offset: 0,
        limit: 30,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 30,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };
  searchValueAssignerFunction = (name, value, type) => {
    this.setState({ dataLoading: true });
    let searchValues = { [name]: value };
  
    this.setState({ searchFilters: searchValues }, () => {
      // localStorage.setItem("filters", JSON.stringify(this.state.searchFilters));
      // console.log(this.state.searchFilters, "-----Filters-");
      
      this.getAllData();  
    });
  };
   getAllData = () => {
      this.setState({ dataLoading: true }, () => {
        const searchFilters = { ...this.state.searchFilters }; 
      offset = 0;
        console.log("Calling fetchDrivers with filters:", searchFilters);
    
        this.props.fetchRoutesForMaster(
          offset, 
          searchFilters,  
          () => {
                  this.setState({ loading: false });
                }
        );
      });
    };
    reset = () => {
 
      this.props.fetchRoutesForMaster(
        0, 
        {},  
        () => {
                this.setState({ loading: false });
              }
      );
    };
  handleChangeFilters = (type) => {
    // console.log("type", type);
    this.setState(
      {
        filterNames: {
          ...this.state.filterNames,
          [type?.name]: type?.value,
        },
      },
      () => {
        // console.log(this.state?.filterNames);
        if (
          type?.name == "sourceHop" ||
          type?.name == "destinationHop" ||
          type?.name == "viaHops"
        )
          this.getAllData();
      }
    );
  };

  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getAllData();
    }
  };
  filterList = () => {
    this.getAllData();
  };
  render() {
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { routeDataForMaster } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("RoutesData"));
    const routesDataForList = navigator.onLine
      ? routeDataForMaster?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(routesDataForList)) {
      totCnt = navigator.onLine
        ? routeDataForMaster?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? routeDataForMaster?.data?.offset +
          routeDataForMaster?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      routesDataForList.forEach((ele) => {
        let hopscode = [];
        ele.viaHops.map((c) => {
          return hopscode.push(c.hopCode);
        });
        rows.push({
          routeCode: ele?.routeCode,
          name: ele?.name,
          source:
          //  ele?.sourceHop[0]?.label,
          (
            <p className=" tw-truncate comn180 tw-p-0 tw-m-0" title={ele?.sourceHop[0]?.label ? `${ele?.sourceHop[0]?.label}` : ""} >
              {ele?.sourceHop[0]?.label ? `${ele?.sourceHop[0]?.label}` : "-----"}
            </p>
          ) || "-----",
          destination: 
          // ele?.destinationHop[0]?.label,
          (
            <p className=" tw-truncate comn180 tw-p-0 tw-m-0" title={ele?.destinationHop[0]?.label ? `${ele?.destinationHop[0]?.label}` : ""} >
              {ele?.destinationHop[0]?.label ? `${ele?.destinationHop[0]?.label}` : "-----"}
            </p>
          ) || "-----",
          via: hopscode && hopscode.length ? hopscode.join(" - ") : "-----",

        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    // console.log("User row:------ ",rows);
    return (
      <React.Fragment>
        {getLocalStorage()}
        {/* <QuickFilters
          reset={this.reset}
          filterList={this.filterList}
          enterCheck={this.enterCheck}
          mode={darkTheme}
          handleChangeFilters={this.handleChangeFilters}
          filterNames={this.state.filterNames}
          hopOptions={this.state.hops}
          filtersHops={this.filtersHops}
        /> */}
        <Index
          {...this.props}
          headers={HeaderData}
          data={rows}
          hidesearchBar={true}
          searchable={true}
          searchBar={searchBar}
          fetchId={this.props.fetchId}
          getAllData={this.getAllData}
          dataLength={routeDataForMaster?.data?.length}
          rowHeight={"56px"}
          dataLoading={this.state.loading}
          reset={this.reset}
          searchValueAssignerFunction={this.searchValueAssignerFunction}

        />
        {totCnt !== 0 && !this.state.loading ? (
          <Row
            xs={12}
            style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
            className="mb-2 mt-2 paginationRow"
          >
             <Col
              className="mt-2 spanPagenationentries span-col-pagenation"
              xs={5}
              sm={5}
              md={5}
            >
              {" "}
              <span>
                Showing {fromVal} to {toVal} of {totCnt} entries
              </span>
            </Col>
            <Col xs={7} sm={7} md={7} className="span-col-pagenation">
              <div className="float-right">
                <Pagination
                  className="desktop-pagination"
                  size="medium"
                  shape="rounded"
                  page={offset / 30 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
                <Pagination
                  className="mobile-pagination"
                  size="small"
                  shape="rounded"
                  page={offset / 30 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
              </div>
            </Col>
           
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    routeDataForMaster: state?.Route?.route?.routeData,
    fetchId: state?.Route?.route?.fetchId,
  };
};

export default connect(mapStateToProps, { fetchRoutesForMaster, fetchHops })(
  Routes
);
