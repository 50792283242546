import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";

import Index from "../../components/Common/DataTableNeo/Index";
import { MoonLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import { fetchIndents } from "../../store/actions";
import { fetchHopsSourceDestination, fetchHops } from "../../store/hop/actions";
import { fetchAllVehicles } from "../../store/vehicle/actions";
import CancelIndentModal from "../CancelIndentModal";
import formatDate from "../DateFormatter";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import QuickFilters from "./quckFilters";
import { Modal, ModalBody } from "reactstrap";
import { fetchVehicleTypesList } from "../../store/actions";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import { actions } from "./actionButtons";
import ApproveModal from "../ApproveModal";
import IndentData from "../indentData";
import {
  fetchAvailDrivers,
  fetchDrivers,
  createDriver,
} from "../../store/driver/actions";
import {
  fetchCreatedIndentInfo,
  approveIndent,
  fetchIndentsExcel,
} from "../../store/indent/actions";
import {
  fetchAvailVehicles,
  fetchVehicles,
  createVehicle,
} from "../../store/vehicle/actions";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//dummy imports
import {
  HeaderData,
  searchBar,
  TransporterHeaderData,
  getLocalStorage,
} from "./RowHeaders/RowHeaders";

const indentStatusMaster = [
  { label: "Assigned", value: "Assigned" },
  { label: "Accepted", value: "Accepted" },
  { label: "Finalized", value: "Finalized" },
  { label: "AutoRejected", value: "AutoRejected" },
  { label: "Expired", value: "Expired" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Rejected", value: "Rejected" },
  { label: "Reassigned", value: "Reassigned" },
  { label: "Drafted", value: "Drafted" },
];

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};
var darkMode;
class Indent_new extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exloading: false,
      loadingExcel: false,
      dataLoading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      modalApproval: false,
      modalIndentDetails: false,
      IndentData: [],
      cancelModal: false,

      data: [],
      filters: "",
      indentData: {},
      searchKey: "",
      searchValue: "",
      disabled: true,
      searchOn: false,
      keySelected: false,
      radioFilter: "",
      indentId: "",
      driver: "",
      vehicle: "",
      onSubmitDisableVehicle: false,
      onSubmitDisableDriver: false,
      export: false,
      toBeSelectedVehicleType: null,
      transporter: "",

      offset: 0,
      limit: 200,
      filterTranspoter: "",
      allTransporter: "",

      headerData: undefined,
      active: false,
      vehicleForm: {},
      driverForm: {},
      transporterVehicleOptions: [],
      transporterDriverOptions: [],
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getAllData();
  };
  getTAllranspoterData = () => {
    this.props.fetchAllTransporterSearch(
      this.state.offset,
      10,
      this.state.filterTranspoter,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState(
          { allTransporter: transporter },
          () => {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "transporter") {
                obj.options = this.state.allTransporter;
              }
            });
          },
          () => {}
        );
      }
    );
  };
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label:
                (ele.name ? ele.name : "NA") +
                " - " +
                (ele.userId ? ele.userId : "NA") +
                (ele.email ? " - " + ele.email : " - NA"),
            });
          });
        }
        this.setState(
          {
            allTransporter: transporter,
          },
          () => {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "transporter") {
                obj.options = this.state.allTransporter;
              }
            });
          },
          () => {}
        );
      }
    );
  };
  filterDataTranspotersAndTrips = (e) => {
    this.filtersHops(e);
    this.filtersTranspoter(e);
  };
  filtersTranspoter = (e) => {
    if (e?.length > 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchAllTransporterSearch(filterData);
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchAllTransporterSearch(filterData);
    }
  };
  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        this.hops = [];
        if (res?.data?.docs?.length > 0) {
          // this.hops = [this.initialVal("Location")];
          res.data.docs.forEach((ele) => {
            this.hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: {
                name:
                  ele.address.addressLine1 +
                  ele.address.city +
                  ele.address.state,
                hopCode: ele.hopCode,
                id: ele._id,
              },
            });
          });
          this.setState({ hops: this.hops }, () => {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source" || obj.field === "destination") {
                obj.options = this.state.hops;
              }
            });
          });
        }
      }
    );
  };
  // get location name by filter
  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }

  getAllData = () => {
    this.setState({ dataLoading: true });
    this.getTAllranspoterData();
    let type = [];
    this.props.fetchVehicleTypesList(0, (res) => {
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            type.push({
              label: ele.label,
              value: {
                id: ele._id,
              },
            });
          });
        }
      }
    });

    this.setState({ vehicleTypes: type }, () => {
      searchBar.searchBarData.forEach((obj) => {
        if (obj.field === "vehicleType") {
          obj.options = this.state.vehicleTypes;
        }
      });
    });

    let filterData = localStorage.getItem("IndentFilters");
// for table --------------------api
    this.props.fetchIndents(
      (offset = 0),
      JSON.parse(filterData) || this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
    // this.props.fetchHopsSourceDestination(0, "", "", (res) => {
    //   this.hops = [];
    //   if (res?.data?.length > 0) {
    //     // this.hops = [this.initialVal("Location")];
    //     res.data.forEach((ele) => {
    //       this.hops.push({
    //         label: ele.hopCode + " (" + ele.label + ")",
    //         value: {
    //           name:
    //             ele.address.addressLine1 + ele.address.city + ele.address.state,
    //           hopCode: ele.hopCode,
    //           id: ele._id,
    //         },
    //       });
    //     });
    //     this.setState({ hops: this.hops }, () => {
    //       searchBar.searchBarData.forEach((obj) => {
    //         if (obj.field === "source" || obj.field === "destination") {
    //           obj.options = this.state.hops;
    //         }
    //       });
    //     });
    //   }
    // });
    this.props.fetchHops({ offset, limit: 10 }, (res) => {
      this.hops = [];
      if (res?.data?.docs?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.docs.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        this.setState({ hops: this.hops }, () => {
          searchBar.searchBarData.forEach((obj) => {
            if (obj.field === "source" || obj.field === "destination") {
              obj.options = this.state.hops;
            }
          });
        });
      }
    });
    searchBar.searchBarData.forEach((obj) => {
      if (obj.field === "status") {
        obj.options = indentStatusMaster;
      }
    });
  };

  excelRoutePublish = (ele) => {
    let routeString = "";

    ele.map((item, index) => {
      if (index === 0) routeString = routeString + item.label;
      else routeString = routeString + "->" + item.label;
      return true;
    });
    return routeString;
  };

  // toggleExcel = async () => {
  //   let offset = 0;
  //     this.setState({
  //       loadingExcel: true,
  //     });
  //     this.props.fetchIndentsExcel(offset, this.state.searchFilters, (res) => {
  //       this.setState(
  //         {
  //           loadingExcel: false,
  //         },
  //         () => {
  //           let indents = [];
  //           if (Array.isArray(res?.data?.docs)) {
  //             res.data.docs.forEach((ele, i) => {
  //               indents.push({
  //                 IndentId: ele?.indentId ? ele?.indentId : "---",
  //                 Source: ele?.source?.label || "---",
  //                 Destination: ele?.destination?.label || "---",
  //                 Vehicle: ele?.vehicle?.type?.label || "---",
  //                 Transporter: ele?.transporter?.name || "---",
  //                 Route: ele?.route?.via?.length
  //                   ? this.excelRoutePublish(ele?.route?.via)
  //                   : "DIRECT",
  //                 // description: ele.description,
  //                 StartTime: ele?.startTime
  //                   ? moment(ele?.startTime).format("DD-MM-YYYY h:mm A", true)
  //                   : "---",
  //                 ExpiryTime: ele?.expiryTime
  //                   ? moment(ele?.expiryTime).format("DD-MM-YYYY h:mm A", true)
  //                   : "---",
  //                 Status: ele?.status,
  //                 "Expired Comment":
  //                   ele?.status === "Expired"
  //                     ? "Auto Rejection by System due to indent Not Accepted"
  //                     : "",
  //                 " Cancelled Comment": ele?.cancellationComment,
  //               });
  //             });
  //           }
  //           if (indents?.length > 0) {
  //             this.exportToCSV([...indents], "INDENT_DATA_EXCEL");
  //           }
  //         }
  //       );
  //     });
    
  // };

  toggleExcel = async () => {
    let offset = 0;
    this.setState({ exloading: true, export: true }); 
  
    this.props.fetchIndentsExcel(offset, this.state.searchFilters, async (res) => {
      let indents = [];
      if (Array.isArray(res?.data?.docs)) {
        res.data.docs.forEach((ele) => {
          indents.push({
            IndentId: ele?.indentId || "---",
            Source: ele?.source?.label || "---",
            Destination: ele?.destination?.label || "---",
            Vehicle: ele?.vehicle?.type?.label || "---",
            Transporter: ele?.transporter?.name || "---",
            Route: ele?.route?.via?.length ? this.excelRoutePublish(ele?.route?.via) : "DIRECT",
            StartTime: ele?.startTime ? moment(ele?.startTime).format("DD-MM-YYYY h:mm A", true) : "---",
            ExpiryTime: ele?.expiryTime ? moment(ele?.expiryTime).format("DD-MM-YYYY h:mm A", true) : "---",
            Status: ele?.status || "---",
            "Expired Comment": ele?.status === "Expired" ? "Auto Rejection by System due to indent Not Accepted" : "",
            "Cancelled Comment": ele?.cancellationComment || "---",
          });
        });
      }
      
      await this.exportToCSV([...indents], "INDENT_DATA_EXCEL"); // Export to CSV
      this.setState({ exloading: false, export: false }); 
    });
  };
  
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false, exloading: false });
  };

  driverPopulate = (value) => {
    let name;
    let phone;
    if (value.name !== "") {
      name = value.name;
    } else name = "----";
    if (value.phone !== "") phone = value.phone;
    else phone = "----";
    if (name === "----" && phone === "----") return "-----";
    else return name + " (" + phone + ")";
  };

  route = (via) => {
    return (
      <div className="route-container comn150">
        {/* Show only the first label */}
        <div className="text-left d-flex align-items-center justify-content-between flex-nowrap mobileRoute">

        <span className={`tw-truncate ${via.length === 1 ? "single-label-text" : "someclass" }`}>
        {via[0]?.label}
          </span>


          {via.length > 0 && (
            <span
              className="icon-container"
            >
              <span className="total-labels">{via.length}</span>
              {/* Tooltip with remaining labels */}
              <div className="showroute">
                {via?.map((e, i) => (
                  <div key={i} className="tooltip-item">
                    <span className="timeline-dot"></span>
                    <span>{e.label}</span>
                  </div>
                ))}
              </div>
            </span>
          )}
        </div>
      </div>
    );
  };

  handleChangePage = (event, val) => {
    // let key;
    this.setState({ dataLoading: true });

    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.props.fetchIndents(offset, this.state.searchFilters, (res) => {
      this.setState({ dataLoading: false });
    });
  };

  searchValueAssignerFunction = (name, value, type) => {

    this.setState({ dataLoading: true });
    if (typeof value === "object") {
      if (type === "date") searchValues[name] = value;
      else searchValues[name] = value.id;
    } else {
      searchValues[name] = value;
    }
    this.setState({ searchFilters: searchValues }, () => {
      localStorage.setItem("IndentFilters", JSON.stringify(this.state.searchFilters));
      // console.log(this.state.searchFilters, "-----IndentFilters-");
      
        this.getAllData();
    });
  };

  reset = () => {
    this.setState(
      { searchFilters: {}, active: false, dataLoading: true },
      () => {
        searchValues = {};
        this.props.fetchIndents(0, this.state.searchFilters, (res) => {
          this.setState({ dataLoading: false });
        });
      }
    );
    localStorage.removeItem("IndentFilters");
  };

  resetSelection = () => {
    this.setState({ vehicle: "", driver: "" });
  };

  status = (newStatus, lastRejection, id) => {
    switch (newStatus) {
      case "Accepted":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound accepted-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Rejected":
        // return (
        //   <>
        //     <div id={"status-" + id}>
        //       <div className="tile-text h6 rejected-color">{status}</div>
        //     </div>
        //     {/* <UncontrolledTooltip
        //       placement="top"
        //       autohide={true}
        //       target={"status-" + id}
        //     >
        //       <div>
        //         <b>{lastRejection?.reason ? lastRejection?.reason : "N/A"}</b>
        //       </div>
        //       {lastRejection?.remark ? lastRejection?.remark : "N/A"}
        //     </UncontrolledTooltip> */}
        //   </>
        // );
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound rejected-color">{" " + newStatus}</div>
          
          </div>
        );
      case "AutoRejected":
        // return (
        //   <>
        //     <div id={"status-" + id}>
        //       <div className="tile-text h6 rejected-color">{status}</div>
        //     </div>
        //     {/* <UncontrolledTooltip
        //       placement="top"
        //       autohide={true}
        //       target={"status-" + id}
        //     >
        //       <div>
        //         <b>{lastRejection?.reason ? lastRejection?.reason : "N/A"}</b>
        //       </div>
        //       {lastRejection?.remark ? lastRejection?.remark : "N/A"}
        //     </UncontrolledTooltip> */}
        //   </>
        // );
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound rejected-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Assigned":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound assigned-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Reassigned":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound assigned-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Finalized":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound finalized-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Drafted":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound drafted-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Expired":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound expired-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Cancelled":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound cancelled-color">{" " + newStatus}</div>
          
          </div>
        );
      default:
        break;
    }
  };

  quickFilters = (filterData, dateValue) => {
    let searchFilters = this.state.searchFilters;
    if (filterData === "rejected") {
      searchFilters.status = "Rejected";
      this.setState({ active: true });
    }
    if (filterData === "date") {
      let date = [];
      date[0] =
        dateValue[0] !== null
          ? moment(dateValue[0]).format("MM-DD-YYYY")
          : null;
      date[1] =
        dateValue[1] !== null
          ? moment(dateValue[1]).format("MM-DD-YYYY")
          : null;
      searchFilters.startTime = date;
    }
    this.setState({ searchFilters: searchFilters }, () => {
      this.getAllData();
    });
  };
  toggleIndentModal = (ele) => {
    this.props.fetchCreatedIndentInfo(ele?._id, (result) => {
      this.setState({ IndentData: result.data }, () => {});
      this.setState({ modalIndentDetails: !this.state.modalIndentDetails });
    });
  };
  toggleRejectModal = (ele) => {
    this.setState(
      {
        cancelModal: !this.state.cancelModal,
        indentId: ele?._id,
        indent_id: ele?.indentId,
      },
      () => {}
    );
  };

  toggleModalApproval = (ele) => {
    this.setState({
      modalApproval: !this.state.modalApproval,
      vehicleForm: {},
      driverForm: {},
    });
    this.setState(
      {
        toBeSelectedVehicleType: {
          value: ele?.vehicle?.type._id ? ele?.vehicle?.type._id : "",
          label: ele?.vehicle?.type?.label ? ele.vehicle?.type?.label : "",
        },
      },
      () => {}
    );
    this.props.fetchDrivers(
      0,
      "",
      "",
      {
        q: "",
        transporterId: ele?.transporter?._id,
        // noLimit: true,
      },
      (res) => {
        this.setState({ transporterDriverOptions: res.data.docs });
      }
    );
    // debugger
    this.props.fetchVehicles(
      0,
      {
        transporterId: ele?.transporter?._id,
        refNo: ele?.vehicle?.refNo,
        // noLimit: true,
      },
      (res) => {
        this.setState({ transporterVehicleOptions: res.data.docs });
      }
    );

    this.setState(
      {
        indentId: ele?._id ? ele?._id : "---",
        indent_id: ele?.indentId,
        transporter: ele?.transporter?._id,
        vehicle: {
          value: ele?.vehicle?.id,
          label: ele?.vehicle?.label ? ele?.vehicle?.label : "Select",
        },
      },
      () => {}
    );
  };

  handleChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  filterVehicleList = (e) => {
    if (e.length > 2) {
      this.props.fetchVehicles(
        null,
        {
          q: e,
          regNo: e,
          transporterId: this.state.transporter,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
    if (e.length === 0) {
      this.props.fetchVehicles(
        0,
        {
          transporterId: this.state.transporter,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
  };
  filterDriverList = (e) => {
    var regexNumber = /^[0-9]+$/;
    var regexString = /^[a-zA-Z]+$/;
    if (e.length > 2) {
      if (e.match(regexNumber)) {
        this.props.fetchDrivers(offset, "", "", { phone: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
      }
      if (e.match(regexString))
        this.props.fetchDrivers(offset, "", "", { name: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
    }
    if (e.length === 0) {
      this.props.fetchDrivers(
        offset,
        "",
        "",
        {
          q: "",
          transporterId: this.state.transporter,
        },
        (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        }
      );
    }
  };
  handleSubmitSaveVehicle = () => {
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
    } = this.state?.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner ? VehicleOwner : "",
      transporter: this.state?.transporter,
      regNo: VehicleRegistrationNumber ? VehicleRegistrationNumber : "",
      type: type?.value ? type?.value : "",
    };
    this.setState({ onSubmitDisableVehicle: true });
    this.props.createVehicle(formObj, (res) => {
      if (res.success) {
        this.props.fetchVehicles(
          0,
          { transporterId: this.state.transporter },
          (res) => {
            this.setState({ transporterVehicleOptions: res.data.docs });
          }
        );
        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableVehicle: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableVehicle: false,
          vehicle: "",
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    });

    setTimeout(() => {
      let vh;
      if (this.state.vehicleForm.VehicleRegistrationNumber !== "")
        if (Array.isArray(this.state.transporterVehicleOptions)) {
          vh = this.state.transporterVehicleOptions.filter((v) => {
            return (
              v?.registration?.number ===
              this.state?.vehicleForm?.VehicleRegistrationNumber
            );
          });
          this.setState({
            vehicle: {
              value: vh[0]?._id,
              label:
                vh[0]?.registration?.number + " (" + vh[0]?.type?.label + ")",
            },
            vehicleForm: {
              VehicleOwner: "",
              VehicleRegistrationNumber: "",
              type: null,
            },
          });
        }
    }, 4000);
  };

  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name === "vehicleType") {
      vf.type = value;
    }
    if (name === "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name === "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value.toString().toUpperCase();
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      transporter: this.state.transporter,
      phone,
      licence: { number: driverLicenseNo },
    };
    this.setState({ onSubmitDisableDriver: true });
    if (Object.keys(formObj.phone).length > 0) {
      this.props.createDriver(formObj, (res) => {
        if (res.message === "Driver has been created successfully") {
          this.props.fetchDrivers(
            0,
            "",
            "",
            { q: "", transporterId: this.state.transporter },
            (res) => {
              this.setState({
                transporterDriverOptions: res.data.docs,
              });
            }
          );

          NotificationManager.success(res["message"], "Success");
          this.setState({ onSubmitDisableDriver: false });
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({
            onSubmitDisableDriver: false,
            driver: "",
            driverForm: {
              fullName: "",
              phone: "",
              driverLicenseNo: "",
              transporter: "",
            },
          });
        }
      });
    } else {
      NotificationManager.error(" Phone Field required");
    }
    setTimeout(() => {
      let dt;
      if (this.state.driverForm.phone !== "") {
        if (Array.isArray(this.state.transporterDriverOptions)) {
          dt = this.state.transporterDriverOptions.filter((v) => {
            return v.phone === this.state.driverForm.phone;
          });
        }
        this.setState({
          driver: {
            value: dt[0]?._id,
            label: dt
              ? dt[0]?.phone + " (" + (dt[0]?.name ? dt[0]?.name : "N/A") + ")"
              : this.state.driverShow.phone,
          },
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
        });
      }
    }, 4000);
  };
  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name === "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name === "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name === "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };
  onClickAddIndent = () => {
    this.props.history.push({
      pathname: "/add-indent",
    });
  };
  routeD = (via) => {
    return (
      <div style={{ display: "flex" }}>
        {via?.map((e, i) => (
          <div style={{ display: "inline-block" }}>
            <span item style={{ padding: "5px" }}>
              <i
                style={{ color: "#5664D2" }}
                className="fas fa-circle iconSize"
              ></i>
              <span className="ml-2">
                {e?.label?.slice(e?.label?.length - 4)}
              </span>
            </span>
          </div>
        ))}
      </div>
    );
  };

  draftTofinal = (ele) => {
    window.history.replaceState(null, null, "/");
    let data = ele;
    data.source = {
      label: ele.source?.label ? ele.source.label : "",
      value: { id: ele.source._id },
    };

    data.destination = {
      label: ele.destination.label ? ele.destination.label : "",
      value: { id: ele.destination._id },
    };
    data.route = ele.route
      ? {
          label: ele?.route?.via?.length ? (
            <>{this.routeD(ele?.route?.via)}</>
          ) : (
            "DIRECT"
          ),
          value: { id: ele.route._id },
        }
      : null;
    data.transporter = {
      name: ele.transporter?.name ? ele.transporter.name : "",
      value: { id: ele.transporter?._id },
    };
    data.vehicle.type = {
      label: ele.vehicle.type.label ? ele.vehicle.type.label : "",
      value: { id: ele.vehicle.type._id ? ele.vehicle.type._id : "" },
    };
    data.startTime = ele.startTime ? ele.startTime : "---";
    data.expiryTime = ele.expiryTime ? ele.expiryTime : "---";
    data.rejectionHistory = ele.rejectionHistory;
    data.comment = ele.description;
    this.props.history.push({
      pathname: "/add-indent",
      indent: data,
      mode: "edit",
      indentID: ele._id,
    });
  };
  componentDidUpdate() {
    if (
      (this.state.headerData === undefined &&
        HeaderData[0]?.label === undefined) ||
      this.state.headerData?.label !== HeaderData.label
    ) {
      this.setState({
        headerData: HeaderData,
      });
      getLocalStorage();
    }
  }
  handleResetEdit = () => {
    this.setState({
      vehicle: "",
      driver: "",
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
    });
  };
  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;

    if (this.state.headerData === undefined) {
      this.setState({
        headerData: HeaderData,
      });
    }

    // const { loading } = this.state;
    // if (loading) {
    //   return (
    //     <div className="page-content">
    //       <Col xs={12} className="loader">
    //         <MoonLoader size={45} color={"#123abc"} />
    //       </Col>
    //     </div>
    //   );
    // }
    let vehicleType = this.props.vehicleType.vehicleType;
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let LocalData = JSON.parse(localStorage.getItem("IndentData"));
    var PWATest = navigator.onLine ? this.props.indents?.data : LocalData?.data;
    let data = [];
    if (Array.isArray(PWATest?.docs)) {
      data = [];
      offset = PWATest?.offset;
      totCnt = PWATest?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = PWATest?.offset + PWATest?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      PWATest.docs.forEach((ele) => {
        data.push({
          _id: ele._id ? ele._id : undefined,
          indentId: ele.indentId ? ele.indentId : "---",
          // source: ele.source?.label || "---",
          source: (
            <p className=" ellipsis comn130" title={ele.source ? `${ele.source.label}` : ""} style={{ padding: "0px", margin: "0px" }}>
              {ele.source ? `${ele.source.label}` : "-----"}
            </p>
          ) || "-----",
          destination: (
            <p className=" ellipsis comn130" title={ele.destination ? `${ele.destination.label}` : ""} style={{ padding: "0px", margin: "0px" }}>
              {ele.destination ? `${ele.destination.label}` : "-----"}
            </p>
          ) || "-----",
          vehicleType: (
            <p className=" ellipsis comn130 m-0"
            title={` ${ele.vehicle?.type?.label || ""}`}>
            {ele.vehicle?.type?.label ?`${ele?.vehicle?.type?.label}`:"-----"}
            </p>
          ) || "-----",
          transporter: (
            <p className=" ellipsis comn140" title={ele.transporter?.name || ''} style={{ padding: "0px", margin: "0px" }}>
              {ele.transporter?.name || "-----"}
            </p>
          ) || "-----",
          route: ele?.route?.via?.length ? (
            <>{this.route(ele?.route?.via)}</>
          ) : (
            "DIRECT"
          ),
          // description: ele.description,
          startTime: ele?.startTime ? formatDate(ele?.startTime) : "---",
          expiryTime: ele?.expiryTime ? formatDate(ele?.expiryTime) : "---",
          status:
            this.status(ele?.status, ele?.lastRejection, ele?._id) || "---",
          actions: actions(ele?.status, ele, {
            routingToIndentDataModel: this.toggleIndentModal,
            routingToCancelIndentModel: this.toggleRejectModal,
            routingToProvideDetailModel: this.toggleModalApproval,
            routingToDraftToFinal: this.draftTofinal,
          }),
        });
      });
    }

    if (Array.isArray(this.state.transporterVehicleOptions)) {
      this.state.transporterVehicleOptions.forEach((ele) => {
        vehicleOptions.push({
          value: ele?._id,
          label: ele?.registration?.number + " (" + ele?.type?.label + ")",
        });
      });
    }

    if (Array.isArray(vehicleType?.data?.docs)) {
      vehicleType.data.docs.forEach((ele) => {
        vehicleTypeOptions.push({
          value: ele?._id,
          label: ele?.vehicleTypeCode,
        });
      });
    }
    if (Array.isArray(this.state.transporterDriverOptions)) {
      this.state.transporterDriverOptions.forEach((ele) => {
        driverOptions.push({
          value: ele?._id,
          label: ele?.phone + " (" + ele?.name + ")",
        });
      });
    }
    return (
      <div>
        {getLocalStorage()}
        {/* <QuickFilters
          onClickAddIndent={this.onClickAddIndent}
          quickFilters={this.quickFilters}
          reset={this.reset}
          toggleExcel={this.toggleExcel}
          export={this.state.export}
          activeState={this.state.active}
          mode={darkMode ? darkMode : ""}
        /> */}
        <Container
          fluid
          className="darkBackgroundList mobilepPadding"
          style={{
            // maxHeight: "115vh",
            overflowX: "hidden", paddingLeft: "0px", paddingRight: "0px"
          }}
        >
          {userType && userType === "transporter" ? (
            <Index
              {...this.props}
              headers={TransporterHeaderData}
              data={data}
              searchBar={searchBar}
              searchable={true}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllData}
              dataLength={this.props.indents?.data?.length}
              fetchId={this.props.fetchId}
              toggle={this.toggleIndentModal}
              dataLoading={this.state.dataLoading}
              filterDataTranspotersAndTrips={this.filterDataTranspotersAndTrips}
              onClickAddIndent={{ handler: this.onClickAddIndent, label: "Indent" }}
              toggleExcel={this.toggleExcel}
              tableType={"indenttable"}
              exportLoading={this.state.exloading} 
            />
          ) : (
            <Index
              {...this.props}
              headers={HeaderData}
              data={data}
              searchBar={searchBar}
              searchable={true}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllData}
              dataLength={this.props.indents?.data?.length}
              fetchId={this.props.fetchId}
              toggle={this.toggleIndentModal}
              dataLoading={this.state.dataLoading}
              filterDataTranspotersAndTrips={this.filterDataTranspotersAndTrips}
              onClickAddIndent={{ handler: this.onClickAddIndent, label: "Indent" }}
              toggleExcel={this.toggleExcel}
              tableType={"indenttable"}
              exportLoading={this.state.exloading}
            />
          )}
          {data.length > 0 && !this.state.dataLoading ? (
            totCnt !== 0 ? (
      //   pagination---------------------------------------------------- 
           
              <Row
                style={{
                  color: darkMode ? "#9D9D9D" : "#0C0C0C",
                  paddingTop: "150px !important",
                }}
                className="mb-2 mt-2 paginationRow  ptd10"
              >
                 <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={5}
                  md={5}
                >
                  <span className="spanPagePadding">
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
                <Col xs={7} sm={7} md={7} className="span-col-pagenation">
                  <div className="float-right">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
               
              </Row>
      //   pagination------------------------END---------------------------- 

            ) : null
          ) : (
            ""
          )}
        </Container>
        <ApproveModal
          handleResetEdit={this.handleResetEdit}
          isOpen={this.state.modalApproval}
          toggle={this.toggleModalApproval}
          onChange={this.handleChangeModal}
          options={vehicleOptions}
          optionsdriver={driverOptions}
          driver={this.state.driver}
          vehicle={this.state.vehicle}
          submit={this.handleSubmitApprove}
          disabledVehicle={this.state.onSubmitDisableVehicle}
          disabledDriver={this.state.onSubmitDisableDriver}
          indentIndex={this.state.indentId}
          indent_id={this.state.indent_id}
          filterVehicleList={this.filterVehicleList}
          filterDriverList={this.filterDriverList}
          type={vehicleTypeOptions}
          refreshData={this.getAllData}
          submitVehicleForm={this.handleSubmitSaveVehicle}
          onVehicleChange={this.onVehicleChange}
          vehicleForm={this.state.vehicleForm}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          resetSelection={this.resetSelection}
          toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
          transporter={this.state.transporter}
          indentPageRoute={true}
          mode={darkMode}
        />
        <CancelIndentModal
          isOpen={this.state.cancelModal}
          toggle={this.toggleRejectModal}
          indentIndex={this.state.indentId}
          refreshData={this.getAllData}
          IndentId={this.state.indent_id}
          mode={darkMode}
        />
        <Modal
          isOpen={this.state.modalIndentDetails}
          toggle={this.toggleIndentModal}
          size="lg"
        >
          <ModalBody
            className={`indentModal ${
              darkMode ? "darkIndentModalDetails" : ""
            }`}
          >
            <IndentData
              data={this.state.IndentData}
              formatDate={formatDate}
              routeConvert={this.route}
              header={"Indent Details"}
              closeModal={this.toggleIndentModal}
              mode={darkMode}
              indentType={"primary"}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.creatorIndents,
    fetchId: state.Indent.indents.fetchId,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    vehicleType: state.VehicleType.vehicleType,
    indentsExcel: state.Indent.indents.excelIndents,
  };
};

export default connect(mapStateToProps, {
  fetchIndents,
  fetchIndentsExcel,
  // fetchTripsExcel,
  // fetchTransporterTrips,
  // startLoading,
  // finishLoading,
  createVehicle,
  fetchAllTransporterSearch,
  fetchAvailVehicles,
  fetchVehicleTypesList,
  fetchAvailDrivers,
  fetchVehicles,
  fetchDrivers,
  approveIndent,
  fetchCreatedIndentInfo,
  fetchHopsSourceDestination,
  fetchHops,
  createDriver,
  fetchAllVehicles,
})(Indent_new);
