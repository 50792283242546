let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: userLang?.masters?.INDENT_PLANNING_CODE,
        field: "indentPlanningCode",
        sort: "asc",
        // width: 2,
        width: 100,
      },
      {
        label: userLang?.masters?.SOURCE,
        field: "source",
        sort: "asc",
        // width: 2,
        width: 180,
      },
      {
        label: userLang?.masters?.DESTINATION,
        field: "destination",
        sort: "asc",
        // width: 2,
        width: 180,
      },
      {
        label: userLang?.masters?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        // width: 2,
        width: 220,
      },
      {
        label: userLang?.masters?.VALIDITY,
        field: "validity",
        sort: "asc",
        // width: 2,
        width: 180,
      },
      {
        label: userLang?.masters?.KMS,
        field: "kms",
        sort: "asc",
        // width: 1,
        width: 60,
      },
      {
        label: userLang?.masters?.LANE_LOT,
        field: "laneLot",
        sort: "asc",
        // width: 1,
        width: 60,
      },
      {
        label: userLang?.masters?.LEAD_TIME,
        field: "leadtime",
        sort: "asc",
        // width: 1,
        width: 60,
      },
      {
        label: userLang?.masters?.LOT_NO,
        field: "lotNo",
        sort: "asc",
        // width: 1,
        width: 60,
      },
      {
        label: userLang?.masters?.TRAGET_INDENTS,
        field: "targetIndents",
        sort: "asc",
        // width: 1,
        width: 60,
      },
      {
        label: userLang?.masters?.ACTUAL_INDENTS,
        field: "actualIndents",
        sort: "asc",
        // width: 2,
        width: 60,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    {
      label: userLang?.masters?.INDENT_PLANNING_CODE,
      field: "indentPlanningCode",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.DESTINATION,
      field: "destination",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.VALIDITY,
      field: "validity",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.KMS,
      field: "kms",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.LANE_LOT,
      field: "laneLot",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.LEAD_TIME,
      field: "leadtime",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.LOT_NO,
      field: "lotNo",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.TRAGET_INDENTS,
      field: "targetIndents",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.ACTUAL_INDENTS,
      field: "actualIndents",
      sort: "asc",
      width: 2,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.masters?.INDENT_PLANNING_CODE,
      field: "indentPlanningCode",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.SOURCE,
      field: "source",
      sort: "asc",
      type: "select",
    },
    {
      label: userLang?.masters?.DESTINATION,
      field: "destination",
      sort: "asc",
      type: "select",
    },
    {
      label: userLang?.masters?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      type: "select",
    },
    {
      label: userLang?.masters?.KMS,
      field: "kms",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.LANE_LOT,
      field: "laneLot",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.LEAD_TIME,
      field: "leadtime",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.VALIDITY,
      field: "validityFromTo",
      type: "date",
    },
    {
      label: userLang?.masters?.LOT_NO,
      field: "lotNo",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.TRAGET_INDENTS,
      field: "targetIndents",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.ACTUAL_INDENTS,
      field: "actualIndents",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.ACTION,
      field: "action",
      sort: "asc",
      type: "text",
    },
  ],
};
