import React, { Component } from "react";

import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  getZoneToStateMap,
  AddZoneToStateMapping,
  deleteZoneToStateMapping,
} from "../../store/baxter_zoneToStateMap/actions";

import Pagination from "@material-ui/lab/Pagination";
import AddZonetoStateMap from "./AddZonetoStateMap";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import { actions } from "./actionButtons";
import QuickFilters from "./quckFilters";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};

class ZonteToStateMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      searchFilters: {},
      modal: false,
      viewById: {},
      editId: "",
      deleteId: "",
      form: {},
      errors: {},
    };
  }

  componentDidMount = () => {
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  formSet = () => {
    let formDataEdit = {};
    let data = this.state?.viewById?.ele;

    if (this.state.viewById?.viewMode === "edit") {
      formDataEdit["state"] = data?.state;
      formDataEdit["zone"] = data?.zone;
      formDataEdit["cost"] = data?.cost;
      formDataEdit["businessUnit"] = data?.businessUnit;
      formDataEdit["courier"] = data?.courier;
    }

    this.setState({ form: formDataEdit, editId: data?._id });
    return formDataEdit;
  };
  getAllData = () => {
    this.setState({ dataLoading: true });
    this.props.getZoneToStateMap(
      { offset: offset ? offset : 0, limit: 10 },
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }

  togglemodal = () => {
    this.setState(
      (prevState) => ({
        modal: !prevState.modal,
      }),
      () => {}
    );
  };
  handleSubmit = () => {
    this.setState({
      onSubmitDisable: true,
      dataLoading: true,
      errors: this.validateValAdd(),
    });
    const { form, formErrors } = this.state;

    let formVal = { ...form };
    formVal.cost = parseInt(formVal.cost);
    // console.log(formVal, "form");

    this.props.AddZoneToStateMapping(formVal, (res) => {
      // console.log(res, "90");
      if (
        (res["message"] &&
          res["message"] === "State - Zone Mapping Added  Successfully!") ||
        res["success"] === true
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/zoneToState");
        this.setState(
          { onSubmitDisable: false, dataLoading: false, modal: false },
          () => {
            this.reset();
            this.getAllData();
          }
        );
      } else {
        this.setState({ onSubmitDisable: false, dataLoading: false });
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  addEditZoneToStateFunc = (ele, viewMode) => {
    this.setState(
      { modal: true, viewById: { ele, viewMode }, errors: {} },
      () => {
        if (this.state?.viewById && viewMode === "edit") {
          this.formSet();
        } else {
          this.setState({
            form: {
              state: "",
              zone: "",
              cost: "",
              businessUnit: "",
              courier: "",
            },
          });
        }
      }
    );
  };

  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getAllData();
    }
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  handleSearchInput = (e, data) => {
    searchValues[e?.target?.name] = e?.target?.value;
    this.setState({ searchFilters: searchValues }, () => {});
  };

  reset = (e) => {
    offset = 0;
    searchValues = {};
    this.setState(
      {
        form: {
          state: "",
          zone: "",
          cost: "",
          businessUnit: "",
          courier: "",
        },
        searchFilters: {
          state: "",
          zone: "",
          cost: "",
          businessUnit: "",
          courier: "",
        },
        errors: {},
      },

      () => {
        this.getAllData();
      }
    );
  };

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;

    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  editDetail = (ele) => {
    this.addEditZoneToStateFunc(ele, "edit");
  };

  deleteZoneToStateMap = (ele) => {
    // console.log("delete");
    this.setState(
      { deleteId: ele?._id ? ele?._id : "", dataLoading: true },
      () => {
        if (this.state.deleteId.length) {
          let id = { _id: this.state.deleteId };
          this.props.deleteZoneToStateMapping(id, (res) => {
            if (res.success) {
              NotificationManager.success(res["message"], "Success");
              this.getAllData();
            } else {
              NotificationManager.error(res["message"], "Error");
              this.setState({ onSubmitDisable: false, dataLoading: false });
            }
          });
        }
      }
    );
  };
  validateValAdd = () => {
    const errorsAdd = {};
    let { state, businessUnit, cost, zone, courier } = this.state.form;
    // let image = this.state.urlArrayValues;

    if (!state) {
      errorsAdd.state = "State is required!";
    }
    if (!businessUnit) {
      errorsAdd.businessUnit = "Warehouse is required!";
    }
    if (!cost || !Number(cost)) {
      errorsAdd.cost = "Cost is required and it should be Number !";
    }
    if (!zone) {
      errorsAdd.zone = "Zone is required!";
    }
    if (!courier) {
      errorsAdd.courier = "Courier is required!";
    }
    return errorsAdd;
  };

  onChangeInputData = (name, value) => {
    const { form } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {});
  };
  render() {
    const { zoneToStateMapList } = this.props;

    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkTheme = mode === "dark__mode" ? true : false;

    let rows = [];
    let data = zoneToStateMapList?.data;
    let dataListArr = zoneToStateMapList?.data?.docs;

    if (Array.isArray(dataListArr)) {
      offset = data?.offset;
      totCnt = data?.length;

      toVal = offset + dataListArr?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      if (dataListArr !== undefined && dataListArr.length > 0) {
        dataListArr.forEach((ele) => {
          if (ele) {
            rows.push({
              state: ele?.state ? ele?.state : "N/A",
              zone: ele?.zone ? ele?.zone : "N/A",
              cost: ele?.cost ? ele?.cost : "N/A",
              businessUnit: ele?.businessUnit ? ele?.businessUnit : "N/A",
              courier: ele?.courier ? ele?.courier : "N/A",
              actions: actions(ele, {
                routingToEditdetail: this.editDetail,
                routingToDelete: this.deleteZoneToStateMap,
              }),
            });
          }
        });
      }
    }

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    return (
      <React.Fragment>
        <div /* className="page-content" */>
          {getLocalStorage()}
          <QuickFilters
            addEditZoneToStateFunc={this.addEditZoneToStateFunc}
            reset={this.reset}
            onInputChange={this.handleSearchInput}
            inputVal={this.state.searchFilters}
            enterCheck={this.enterCheck}
            filterList={this.getAllData}
          />

          <Container
            aria-hidden="true"
            fluid
            className={`${
              darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
            }`}
          >
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              getAllData={this.getAllData}
              rowHeight={"56px"}
              limit={data?.length}
              dataLoading={this.state.dataLoading}
            />
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 mt-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
            <AddZonetoStateMap
              modal={this.state.modal}
              toggle={this.togglemodal}
              onChangeInput={this.onChangeInputData}
              reset={this.reset}
              form={this.state.form}
              handleSubmit={this.handleSubmit}
              viewById={this.state.viewById}
              errors={this.state.errors}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchId: state?.zoneToStateMapReducer?.fetchId,
  zoneToStateMapList: state?.zoneToStateMapReducer?.getZoneToStateMap,
});

export default connect(mapStateToProps, {
  getZoneToStateMap,
  AddZoneToStateMapping,
  deleteZoneToStateMapping,
})(ZonteToStateMap);
