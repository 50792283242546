import React, { Component } from "react";
import { useState, useEffect } from "react";

import { Label, FormGroup, Input, InputGroup } from "reactstrap";
import Select, { components } from "react-select";
import { DateRangePicker,DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Selector from "../multiSelect/selector";
import { select } from "redux-saga/effects";
import SingleDatepicker from "../../SingleDatepicker/SingleDatepicker";
import Datepicker2 from "../../DatePicker/DatePicker";
import Calendar from "../assets/calendar.svg";
const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};



const MyIcon = React.forwardRef((props, ref) => {
  return (
    <span style={{ position: "absolute", top: "2px", right: "10px" }}></span>
  );
});

export const InputField = (props) => {
  // console.log(props,"selectProps1")
  const {
    controlType,
    labelStyle = {},
    labelClass = "",
    labelValue,
    isRequired,
  } = props;
  return (
    <FormGroup className="select2-container">
      <Label
        style={labelStyle}
        className={(isRequired ? "required-field " : "") + labelClass}
      >
        {labelValue}
      </Label>
      {controlType === "text" && <RenderInput {...props} />}
      {controlType === "email" && <RenderInput {...props} />}
      {controlType === "select" && <RenderSelect {...props} />}
      {controlType === "date" && <RenderDate {...props} />}
      {/* {errors[inputName] && <RenderFormError error={errors[inputName]} />} */}
    </FormGroup>
  );
};

export const RenderFormError = ({ error }) => {
  return <span className="err">{error}</span>;
};

// const RenderInput = (inputProps) => {
//   let pathName = window.location.pathname;
//   let keyFilterExists;
//   const key = `mySearchFilter_${pathName}`;
//   if (pathName != "/tripList" && pathName != "/ordersList") {
//     keyFilterExists = localStorage.getItem(key)
//       ? JSON.parse(localStorage.getItem(key))
//       : null;
//   } else {
//     keyFilterExists =
//       localStorage.getItem("tripsFilterData") && pathName === "/tripList"
//         ? JSON.parse(localStorage.getItem("tripsFilterData"))
//         : localStorage.getItem("myFilterData") && pathName === "/ordersList"
//           ? JSON.parse(localStorage.getItem("myFilterData"))
//           : "";
//   }

//   // console.log(pathName === window.location.pathname, pathName, "keyfilter1")
//   const {
//     // inputType,
//     inputName,
//     value,
//     onChangeMethod,
//     placeholderValue,
//     handleKeyPress,
//     handleBlur,
//     innerRef,
//   } = inputProps;
//   let isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
//   return (
//     <div className="RenderInput baxterCapitalize" style={{ position: "relative", display: "inline-block", width: "100%" }}>
//       <Input
//         style={{
//           height: "42px",
//           margin: "12px",
//           fontSize: "12px",
//           boxSizing: "border-box",
//           border: "1px solid #E6E6E6",
//           ...(isBaxter && { textTransform: "uppercase", fontWeight: "700" }),
//         }}
//         className="form-control input"
//         type={
//           inputName === "indentId" || inputName === "tripId" ? "number" : inputName === "email" ? "email" : "text"
//         }
//         // value={value}
//         defaultValue={
//           keyFilterExists && isBaxter
//             ? keyFilterExists[inputName]?.replace("mySearchFilter_", "") 
//             : ""
//         }
//         onChange={(event) =>
//           onChangeMethod({
//             target: {
//               name: inputName,
//               value: event.target.value,
//             },
//           })
//         }
//         placeholder={placeholderValue}
//         onKeyPress={handleKeyPress && handleKeyPress}
//         // onBlur={(e) => handleBlur && handleBlur(e, inputName)}
//         innerRef={innerRef}
//       />
//       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
//         style={{
//           position: "absolute",
//           right: "34px",
//           top: "40%",
//           transform: "translateY(-50%)",

//         }}
//       >
//         <path d="M9.3 15.6C12.7794 15.6 15.6 12.7794 15.6 9.3C15.6 5.82061 12.7794 3 9.3 3C5.82061 3 3 5.82061 3 9.3C3 12.7794 5.82061 15.6 9.3 15.6Z" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
//         <path d="M17.0004 17.0004L14.9004 14.9004" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
//       </svg>
//     </div>
//   );
// };

// CheckBox start----------------


const RenderInput = ({ inputName, placeholderValue }) => {
  const pathName = window.location.pathname;
  const key = `mySearchFilter_${pathName}`;
  const pageKey = pathName.replace(/^\//, ""); // Remove leading "/"

  // Retrieve stored values from localStorage when the component mounts
  const storedData = JSON.parse(localStorage.getItem(key)) || {};
  const pageStoredData = JSON.parse(localStorage.getItem(pageKey)) || {};
  const initialValue = storedData[inputName] || pageStoredData[inputName] || "";

  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue); // Set stored value when component mounts
  }, []);

  // Handle input change and update localStorage
  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);

    // Update both storage objects
    const updatedData = { ...storedData, [inputName]: value };
    const updatedPageData = { ...pageStoredData, [inputName]: value };

    // Save updated data to both keys
    localStorage.setItem(key, JSON.stringify(updatedData));
    localStorage.setItem(pageKey, JSON.stringify(updatedPageData));
  };

  return (
    <div className="RenderInput" style={{ position: "relative", display: "inline-block", width: "100%" }}>
      <input
        className="form-control input"
        type="text"
        value={inputValue} // Controlled input
        onChange={handleInputChange} // Handle input change
        placeholder="Search"
        style={{
          height: "42px",
          margin: "12px",
          fontSize: "12px",
          border: "1px solid #E6E6E6",
        }}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{
          position: "absolute",
          right: "34px",
          top: "40%",
          transform: "translateY(-50%)",
        }}
      >
        <path
          d="M9.3 15.6C12.7794 15.6 15.6 12.7794 15.6 9.3C15.6 5.82061 12.7794 3 9.3 3C5.82061 3 3 5.82061 3 9.3C3 12.7794 5.82061 15.6 9.3 15.6Z"
          stroke="#6D7885"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M17.0004 17.0004L14.9004 14.9004" stroke="#6D7885" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default RenderInput;




const CustomOption = (props) => {
  const { data, innerRef, innerProps, isSelected } = props;

  return (
    <div ref={innerRef} {...innerProps} className="ml-4" style={{ display: "flex", alignItems: "center" }}>
      <input type="checkbox"
        checked={isSelected}
        readOnly
        style={{ marginRight: "10px " }} />
      <span
        style={{
          marginLeft: 10,
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(25ch + 5px)'
        }}
      >
        {data.label}
      </span>
    </div>
  );
};
// CheckBox End------------------

// multi selector option----------------------------------------------
const RenderSelect = (selectProps) => {

  // console.log(selectProps,"selectProps")
  let mode = localStorage.getItem("Theme")
    ? localStorage.getItem("Theme")
    : null;
  let darkMode = mode === "dark__mode" ? true : false;
  let pathName = window.location.pathname;
  let key = `myStatusFilter_${pathName}`;
  let innerKey = `${selectProps?.inputName}_${pathName}`;
  const storedStatusObjects = JSON.parse(localStorage.getItem(key)) || {};
  // console.log(storedStatusObjects,"21")

  // const customStyles = {
  //   indicatorsContainer: (style) => {
  //     return {
  //       ...style,
  //       width: "20px",
  //       height: "22px",
  //       textAlign: "center",
  //       justifyContent: "center",
  //       borderRadius: "0px 4px 4px 0px",

  //     };
  //   },
  //   control: (style) => {
  //     return {
  //       ...style,
  //       textAlign: "center",
  //       alignSelf: "flex-end",
  //       height: "26px",
  //       minHeight: "26px",
  //       margin: "10px",
  //       marginRight: "6px",
  //       backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
  //       border: "1px solid #E6E6E6",
  //     };
  //   },
  //   input: (style, state) => {
  //     return {
  //       ...style,
  //       width: "20%",
  //       textAlign: "center",
  //       fontSize: "10",
  //       color: darkMode ? "#9d9d9d" : "#505d69",
  //     };
  //   },

  //   dropdownIndicator: (style) => {
  //     return {
  //       ...style,
  //       svg: {
  //         fill: darkMode ? "#2E2E46" : "#ffffff",
  //         marginRight: "5px",
  //         marginBottom: "3px",
  //       },
  //     };
  //   },
  //   placeholder: (style) => {
  //     return {
  //       ...style,
  //       fontSize: "12px",
  //       position: "absolute",
  //       left: "28px",
  //     };
  //   },
  //   valueContainer: (style) => {
  //     return {
  //       ...style,
  //       height: "22px",
  //       minHeight: "22px",
  //       alignContent: "center",
  //     };
  //   },
  //   container: (style) => {
  //     return {
  //       ...style,
  //       marginTop: "12px",
  //     };
  //   },
  //   menu: (style) => {
  //     return {
  //       ...style,

  //       backgroundColor: darkMode ? "#171722" : "#ffffff",
  //       color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
  //       border: darkMode
  //         ? "0.5px solid #616161"
  //         : "0.5px solid rgb(204, 204, 204)",
  //       boxShadow: darkMode
  //         ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
  //         : "0px 3px 12px rgba(0, 0, 0, 0.12)",
  //       // zIndex: "22px",
  //       // position: "",
  //       transition: "all 0.8s ease",
  //     };
  //   },
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     // console.log({ data, isDisabled, isFocused, isSelected });
  //     return {
  //       ...styles,
  //       backgroundColor: darkMode
  //         ? darkMode && isFocused
  //           ? "#1b1c31"
  //           : null
  //         : isFocused
  //         ? "#cce9ff"
  //         : "",
  //       color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
  //     };
  //   },
  // };
  const {
    value,
    inputName,
    onChangeMethod,
    onInputChange,
    optionList,
    handleScrollBottom,
    handleMenuClose,
    isLoading,
    handleKeyPress,
    innerRef,
    selectval,
  } = selectProps;
  let isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
  // console.log("optionList------------",optionList)


  const handleSelectorChange = (e) => {
    console.log("Data from Selector-----", e);  

    onChangeMethod({
      target: {
        name: inputName,
        value: e.value,
        label: e.label,
      },
    })
  };



  return (
    <>



      <Selector
        inputName={inputName}
        options={optionList}
        onChange={handleSelectorChange}
      />
    </>
  );
};


// no longer needed--------------------
const RenderDate = (inputProps) => {
  // console.log(inputProps, "renderDate");
  
  const {
    // inputType,
    inputName,
    inputValue,
    onChangeMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
    dateBg,
  } = inputProps;
  return (
    <Input
      style={{
        height: "22px",
        margin: "12px",
        fontSize: "12px",
        backgroundColor: `${dateBg}`,
      }}
      className="form-control input"
      type="date"
      value={inputValue}
      onChange={(event) =>
        onChangeMethod({
          target: {
            name: inputName,
            value: event.target.value,
          },
        })
      }
      
      placeholder={
        placeholderValue.charAt(0).toUpperCase() + placeholderValue.slice(1)
      }
      onKeyPress={handleKeyPress && handleKeyPress}
      onBlur={(e) => handleBlur && handleBlur(e, inputName)}
    />
  );
};

export const RenderNewDate = (inputProps) => {
  const { inputName, inputValue, onChangeMethod, dateBg, dateClr } = inputProps;
  const pageKey = window.location.pathname.replace(/^\//, "");

  // State for the input value
  const [dateValue, setDateValue] = useState(inputValue || "");

  // Load data from local storage on mount
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};
    if (storedData[inputName]) {
      setDateValue(storedData[inputName]);
    }
  }, [inputName, pageKey]);

  const handleInputChange = (e) => {
    const date = e.target.value;
    setDateValue(date);

    // Update local storage
    const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};
    const updatedData = { ...storedData, [inputName]: date };
    localStorage.setItem(pageKey, JSON.stringify(updatedData));

    // Call the provided onChange method if it exists
    // if (onChangeMethod) {
    //   onChangeMethod(date);
    // }
  };

  return (
    <div
      className="FieldsClass input"
      style={{
        backgroundColor: dateBg,
        color: dateClr,
        height: "42px",
        margin: "14px",
        fontSize: "12px",
        width: "95%",
      }}
    >
      <input
        style={{
          height: "42px",
          margin: "12px",
          fontSize: "12px",
          boxSizing: "border-box",
          border: "1px solid #E6E6E6",
          background: `url(${Calendar}) no-repeat right 10px center`,

        }}
        value={dateValue}
        onChange={handleInputChange}
        className="form-control input"
        type="date"
        placeholder="Select a date"
      />
    </div>
  );
};




export class RenderNewDate2 extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     fromDate: null,
  //     toDate: null,
  //   };
  // }

  // Handle Date Change
  // onChangeDatePicker = (value, label) => {
  //   // console.log(value, label);
  //   // debugger
  //   if (label === "fromDate") {
  //     this.setState({ fromDate: value }, () => {
  //       if (this.state.fromDate !== null && this.state.toDate !== null) {
  //         this.props.handleChange([this.state.fromDate, this.state.toDate]);
  //       }
  //     });
  //   }
  //   if (label === "toDate") {
  //     this.setState({ toDate: value }, () => {
  //       if (this.state.fromDate !== null && this.state.toDate !== null) {
  //         this.props.handleChange([this.state.fromDate, this.state.toDate]);
  //       }
  //     });
  //   }
  // };

  constructor(props) {
    super(props);
    const pageKey = window.location.pathname.replace(/^\//, "");
    const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};

    this.state = {
      fromDate: storedData.fromDate || null,
      toDate: storedData.toDate || null,
    };
  }

  componentDidMount() {
    const pageKey = window.location.pathname.replace(/^\//, "");
    const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};
    
    if (storedData.fromDate || storedData.toDate) {
      this.setState({
        fromDate: storedData.fromDate || null,
        toDate: storedData.toDate || null,
      });
    }
  }

  // Handle Date Change & Store in localStorage
  onChangeDatePicker = (value, label) => {
    const pageKey = window.location.pathname.replace(/^\//, "");
    const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};

    this.setState({ [label]: value }, () => {
      const updatedData = {
        ...storedData,
        [label]: value,
      };

      localStorage.setItem(pageKey, JSON.stringify(updatedData));

      // if (this.state.fromDate && this.state.toDate) {
      //   this.props.handleChange([this.state.fromDate, this.state.toDate]);
      // }
    });
  };
  render() {
    const { fromDate, toDate } = this.state;
    const { darkMode } = this.props; // Assuming darkMode is a prop

    return (
      <div className="tw-flex">
        <Datepicker2
          className="tw-w-full"
          mode={darkMode}
          value={fromDate}
          onChangeDatePicker={(value) => this.onChangeDatePicker(value, "fromDate")}
          fromDate={true}
          toDate={false}
          placeholder={"Start Date"}
          dashboard={true}
        />
        <Datepicker2
          mode={darkMode}
          value={toDate}
          onChangeDatePicker={(value) => this.onChangeDatePicker(value, "toDate")}
          fromDate={false}
          toDate={true}
          placeholder={"End Date"}
          dashboard={true}
        />
      </div>
    );
  }
}

