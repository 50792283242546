import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class DynamicTable extends Component {

  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    this.state = {
      //state is by default an object
    };
  }



  renderTableData() {
    let styletd = this.props.styles?.td;
    let styletr = this.props.styles?.tr;

    //console.log(styletd())
    let header =
      window.location.pathname.includes("/Billing/InvoiceList") ||
      window.location.pathname.includes("/generateInvoice") ||
      window.location.pathname.includes("/Billing/Secondary-InvoiceList") ||
      window.location.pathname.includes("/Secondary-invoiceGeneration") 
        ? this.props.data?.columns
        : this.props.data?.columns?.columns;
    if (this.props.data?.rows.length > 0) {
      return this.props.data?.rows?.map((s, index) => {
        // const { id, name, age, email } = s; //destructuring
        return (
          <tr key={index} style={styletr()}>
            {header?.map((key, i) => {
              if (key["field"] === "actions") {
                return true;
              } else {
                return (
                  <td
                    className={
                      s[key["field"]] < 0
                        ? "deductionRedDiv dynamictd"
                        : "dynamictd"
                    }
                    key={index + "-" + i}
                    style={styletd()}
                  >
                    {s[key["field"]]}
                  </td>
                );
              }
            })}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>No Matching Records</td>
        </tr>
      );
    }
  }
  renderTableHeader() {
    let styleth = this.props.styles?.th;

    let header =
      window.location.pathname.includes("/Billing/InvoiceList") ||
      window.location.pathname.includes("/generateInvoice") ||
      window.location.pathname.includes("/Billing/Secondary-InvoiceList") ||
      window.location.pathname.includes("/Secondary-invoiceGeneration") 
        ? this.props.data?.columns
        : this.props.data?.columns?.columns;
    return header?.map((key, index) => {
      if (key["field"] === "actions") {
        return true;
      } else {
        return (
          <th key={index} style={styleth()}>
            {key["label"]}
          </th>
        );
      }
    });
  }
  renderTableTitleHeader() {
    let header = this.props.data?.titleHeader;
    let styleth = this.props.styles?.tHeader;

    return (
      <td style={styleth()} colSpan="16">
        {header?.toUpperCase()}
      </td>
    );
  }

  render() {
   
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    let styletable = this.props.styles?.table;
    let styletitle = this.props.styles?.title;
    let styletr = this.props.styles?.tr;
    let styletd = this.props.styles?.td;

    return (
      <div>
        {this.props.data?.page === "invoice" ? (
          <span>
            <Row>

              <Col md={6}>
                <p style={styletitle()} >
                  {userLang?.common?.TRANSPORTER}:{" "}
                  {"   " + this.props.data?.titleHeader}
                
                </p>
              </Col>

              <Col md={6}>
                <p style={styletitle()}>
                  {userLang?.invoice?.TMS_INVOICE_NO}:{" "}
                  {"   " + this.props?.data?.invoice?.invoiceId}
                </p>
                <p style={styletitle()}>
                  {userLang?.invoice?.DATED} .{" "}
                  {"  " + this.props?.data?.invoice?.createdAt
                    ? moment(
                        new Date(this.props?.data?.invoice?.createdAt)
                      ).format("DD-MMM-YYYY")
                    : null}
                </p>
              </Col>

            </Row>
            <Row>
              <Col md={6}>
                {" "}
                <p style={styletitle()}>
                  {userLang?.invoice?.SUPPLIER_TAX_INVOICE_NO}:{" "}
                  {"   " + this.props?.data?.invoice?.gstNo},
                  {userLang?.invoice?.DATED} .{" "}
                  {"   " + this.props?.data?.invoice?.fromDate
                    ? moment(
                        new Date(this.props?.data?.invoice?.fromDate)
                      ).format("DD-MMM-YYYY")
                    : null}
                </p>
              </Col>
              <Col md={6}>
                {" "}
                <p style={styletitle()}>
                  {userLang?.common?.STATE}:{" "}
                  {"   " + this.props?.data?.invoice?.state?.stateCode}
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                {" "}
                <p style={styletitle()}>
                  {userLang?.invoice?.NO_OF_BILLS} :{" "}
                  {"   " + this.props?.data?.invoice?.bills?.length}
                </p>
              </Col>
            </Row>
          </span>
        ) : (
          <p style={styletitle()}>{this.props?.title}</p>
        )}

        <table style={styletable()} id="table-to-xls">
          <tbody>

            {this.props.data?.page !== "invoice" ? (
              this.props.data?.titleHeader ? (
                <tr>{this.renderTableTitleHeader()}</tr>
              ) : null
            ) : null}


            <tr style={styletr()}>{this.renderTableHeader()}</tr>

            {this.renderTableData()}
            
            {this.props.data?.rows.length > 0 ? (
              this.props.data?.finalRow ? (
                <tr>
                  {this.props.data?.columns.map((key, i) => {
                    return (
                      <td
                        className={
                          this.props.data?.finalRow[key["field"]] < 0
                            ? "deductionRedDiv dynamictd"
                            : "dynamictd"
                        }
                        key={"total" + i}
                        style={styletd()}
                      >
                        {this.props.data?.finalRow[key["field"]]}
                      </td>
                    );
                  })}
                </tr>
              ) : null
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}

export default DynamicTable;
