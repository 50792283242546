import moment from "moment";
import React from "react";

const formatDate = (date) => {
  return (
    <div className="d-flex">
      <div>{moment(date).format("DD-MM-YYYY")},</div>
      <div className="ml-1" style={{color: '#AEAEAE'}}>{moment(date).format(" h:mm A")}</div>
    </div>
  );
};
export default formatDate;
