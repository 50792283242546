import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Row, Col, Button } from "reactstrap";
import { Grid } from "@material-ui/core";
import { updateBill, fetchBills } from "../../../store/billing/actions";
import { connect } from "react-redux";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class InvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      damageCostEdit: false,
      deductionCost: 0,
      costDescription: "",
      costValue: "",
      costArray: [],
      freightCost: 0,
      freightCostEdit: true,
      defaultDamageCost: 0,
      loadingChargesEdit: false,
      loadingCharges: 0,
      unloadingChargesEdit: false,
      unloadingCharges: 0,
      multiPointPickupChargesEdit: false,
      multiPointPickupCharges: 0,
      detentionSourceChargesEdit: false,
      detentionSourceCharges: 0,
      detentionDestinationChargesEdit: false,
      detentionDestinationCharges: 0,
      lateDeliveryChargesEdit: false,
      lateDeliveryCharges: 0,
      shortageCharges: 0,
      fetched: false,
      submitDisable: true,
    };
  }

  componentDidUpdate = () => {
    // console.log(this.props.invoiceDet);
    if (this.props.invoiceDet.id !== this.state.id) {
      // console.log("CDU", this.props.invoiceDet);
      this.setState({
        damageCostEdit: false,
        deductionCost: 0,
        costDescription: "",
        costValue: "",
        costArray: [],
        freightCost: 0,
        loadingCharges: 0,
        unloadingCharges: 0,
        lateDeliveryCharges: 0,
        multiPointPickupCharges: 0,
        defaultDamageCost: 0,
        fetched: false,
      });
    }

    if (!this.state.fetched) {
      // console.log(this.props, "PROPS SAM");
      if (this.props.invoiceDet.id)
        this.setState({ id: this.props.invoiceDet.id });

      const status = this.props.invoiceDet?.revisedChargesStatus;
      const calculations = this.props.invoiceDet?.calculations;

      if (status !== undefined) {
        if (status?.isFreightCostChargesRevised) {
          this.setState({
            freightCost: calculations?.revisedFreightCostCharges,
          });
        } else {
          this.setState({
            freightCost: calculations?.freightCharges,
          });
        }
        if (status?.isDamageChargesRevised) {
          this.setState({
            deductionCost: calculations?.revisedDamageCharges,
          });
        } else {
          this.setState({
            deductionCost: calculations?.damageCharges,
          });
        }
        if (status?.isLoadingChargesRevised) {
          this.setState({
            loadingCharges: calculations?.revisedLoadingCharges,
          });
        } else {
          this.setState({
            loadingCharges: calculations?.loadingCharges,
          });
        }
        if (status?.isUnloadingChargesRevised) {
          this.setState({
            unloadingCharges: calculations?.revisedUnloadingCharges,
          });
        } else {
          this.setState({
            unloadingCharges: calculations?.unloadingCharges,
          });
        }
        if (status?.isMultipointPickupChargesRevised) {
          this.setState({
            multiPointPickupCharges:
              calculations?.revisedMultipointPickupCharges,
          });
        } else {
          this.setState({
            multiPointPickupCharges: calculations?.multipointPickupCharges,
          });
        }
        if (status?.isSourceDetentionChargesRevised) {
          this.setState({
            detentionSourceCharges: calculations?.revisedSourceDetentionCharges,
          });
        } else {
          this.setState({
            detentionSourceCharges: calculations?.sourceDetentionCharges,
          });
        }
        if (status?.isDestinationDetentionChargesRevised) {
          this.setState({
            detentionDestinationCharges:
              calculations?.revisedDestinationDetentionCharges,
          });
        } else {
          this.setState({
            detentionDestinationCharges:
              calculations?.destinationDetentionCharges,
          });
        }
        if (status?.isLateDeliveryChargeChargesRevised) {
          this.setState({
            lateDeliveryCharges: calculations?.revisedLateDeliveryChargeCharges,
          });
        } else {
          this.setState({
            lateDeliveryCharges: calculations?.lateDeliveryCharges,
          });
        }
        if (status?.isShortageChargesRevised) {
          this.setState({
            shortageCharges: calculations?.revisedShortageCharges,
          });
        } else {
          this.setState({
            shortageCharges: calculations?.shortageCharges,
          });
        }
        if (status?.isOtherDeductionsChargesRevised) {
          this.setState({
            costArray: this.props.invoiceDet.charges?.revisedOtherDeductions,
          });
        } else {
          this.setState({
            costArray: this.props.invoiceDet.charges?.otherDeduction,
          });
        }
        this.setState({
          defaultDamageCost: calculations?.damageCharges,
        });
      } else {
        if (calculations !== undefined)
          this.setState({
            freightCost: calculations?.freightCharges,
            deductionCost: calculations?.damageCharges,
            loadingCharges: calculations?.loadingCharges,
            unloadingCharges: calculations?.unloadingCharges,
            multiPointPickupCharges: calculations?.multipointPickupCharges,
            detentionSourceCharges: calculations?.sourceDetentionCharges,
            detentionDestinationCharges:
              calculations?.destinationDetentionCharges,
            lateDeliveryCharges: calculations?.lateDeliveryCharges,
            shortageCharges: calculations?.shortageCharges,
            costArray: this.props.invoiceDet.charges?.otherDeduction,
            defaultDamageCost: calculations?.damageCharges,
          });
      }

      // if (
      //   this.props.invoiceDet.revisedChargesStatus?.isFreightCostChargesRevised !==
      //   undefined
      // ) {
      //   debugger
      //   if (this.props.invoiceDet.revisedChargesStatus?.isFreightCostChargesRevised) {
      //     this.setState({
      //       freightCost:
      //         this.props.invoiceDet.calculations?.revisedFreightCostCharges,
      //     });
      //   } else {
      //     this.setState({
      //       freightCost: this.props.invoiceDet.calculations?.freightCharges,
      //     });
      //   }
      // } else {
      //   debugger
      //   this.setState({
      //     freightCost: this.props.invoiceDet.calculations?.freightCharges,
      //   });
      // }

      // if (this.props.invoiceDet.charges?.otherDeduction) {
      //   if (
      //     this.props.invoiceDet.calculations?.isotherDeductionsChargesRevised
      //   ) {
      //     this.setState({
      //       costArray: this.props.invoiceDet.charges?.revisedOtherDeductions,
      //     });
      //   } else {
      //     this.setState({
      //       costArray: this.props.invoiceDet.charges?.otherDeduction,
      //     });
      //   }
      // }

      // if (this.props.invoiceDet.calculations?.damageCharges) {
      //   this.setState({
      //     defaultDamageCost: this.props.invoiceDet.calculations?.damageCharges,
      //   });
      // }
      // if (
      //   this.props.invoiceDet.calculations?.isDamageChargesRevised !== undefined
      // ) {
      //   if (this.props.invoiceDet.calculations?.isDamageChargesRevised) {
      //     this.setState({
      //       deductionCost:
      //         this.props.invoiceDet.calculations?.revisedDamageCharges,
      //     });
      //   } else {
      //     this.setState({
      //       deductionCost: this.props.invoiceDet.calculations?.damageCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     deductionCost: this.props.invoiceDet.calculations?.damageCharges,
      //   });
      // }
      // if (
      //   this.props.invoiceDet.calculations?.isLoadingChargesRevised !==
      //   undefined
      // ) {
      //   if (this.props.invoiceDet.calculations?.isLoadingChargesRevised) {
      //     this.setState({
      //       loadingCharges:
      //         this.props.invoiceDet.calculations?.revisedLoadingCharges,
      //     });
      //   } else {
      //     this.setState({
      //       loadingCharges: this.props.invoiceDet.calculations?.loadingCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     loadingCharges: this.props.invoiceDet.calculations?.loadingCharges,
      //   });
      // }

      // if (
      //   this.props.invoiceDet.calculations?.isUnloadingChargesRevised !==
      //   undefined
      // ) {
      //   if (this.props.invoiceDet.calculations?.isUnloadingChargesRevised) {
      //     this.setState({
      //       unloadingCharges:
      //         this.props.invoiceDet.calculations?.revisedUnloadingCharges,
      //     });
      //   } else {
      //     this.setState({
      //       unloadingCharges:
      //         this.props.invoiceDet.calculations?.unloadingCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     unloadingCharges:
      //       this.props.invoiceDet.calculations?.unloadingCharges,
      //   });
      // }
      // if (
      //   this.props.invoiceDet.calculations?.isMultipointPickupChargesRevised !==
      //   undefined
      // ) {
      //   if (
      //     this.props.invoiceDet.calculations?.isMultipointPickupChargesRevised
      //   ) {
      //     this.setState({
      //       multiPointPickupCharges:
      //         this.props.invoiceDet.calculations
      //           ?.revisedMultipointPickupCharges,
      //     });
      //   } else {
      //     this.setState({
      //       multiPointPickupCharges:
      //         this.props.invoiceDet.calculations?.multipointPickupCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     multiPointPickupCharges:
      //       this.props.invoiceDet.calculations?.multipointPickupCharges,
      //   });
      // }
      // if (
      //   this.props.invoiceDet.calculations?.isSourceDetentionchargesRevised !==
      //   undefined
      // ) {
      //   if (
      //     this.props.invoiceDet.calculations?.isSourceDetentionchargesRevised
      //   ) {
      //     this.setState({
      //       detentionSourceCharges:
      //         this.props.invoiceDet.calculations?.revisedSourceDetentionCharges,
      //     });
      //   } else {
      //     this.setState({
      //       detentionSourceCharges:
      //         this.props.invoiceDet.calculations?.sourceDetentionCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     detentionSourceCharges:
      //       this.props.invoiceDet.calculations?.sourceDetentionCharges,
      //   });
      // }
      // if (
      //   this.props.invoiceDet.calculations?.isDestinationDetentionChargesRevised
      // ) {
      //   if (
      //     this.props.invoiceDet.calculations
      //       ?.isDestinationDetentionChargesRevised
      //   ) {
      //     this.setState({
      //       detentionDestinationCharges:
      //         this.props.invoiceDet.calculations
      //           ?.revisedDestinationDetentionCharges,
      //     });
      //   } else {
      //     this.setState({
      //       detentionDestinationCharges:
      //         this.props.invoiceDet.calculations?.destinationDetentionCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     detentionDestinationCharges:
      //       this.props.invoiceDet.calculations?.destinationDetentionCharges,
      //   });
      // }
      // if (
      //   this.props.invoiceDet.calculations
      //     ?.isLateDeliveryChargechargesRevised !== undefined
      // ) {
      //   if (
      //     this.props.invoiceDet.calculations?.isLateDeliveryChargechargesRevised
      //   ) {
      //     this.setState({
      //       lateDeliveryCharges:
      //         this.props.invoiceDet.calculations
      //           ?.revisedLateDeliveryChargeCharges,
      //     });
      //   } else {
      //     this.setState({
      //       lateDeliveryCharges:
      //         this.props.invoiceDet.calculations?.lateDeliveryCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     lateDeliveryCharges:
      //       this.props.invoiceDet.calculations?.lateDeliveryCharges,
      //   });
      // }
      // if (
      //   this.props.invoiceDet.calculations?.isShortageChargesRevised !==
      //   undefined
      // ) {
      //   if (this.props.invoiceDet.calculations?.isShortageChargesRevised) {
      //     this.setState({
      //       shortageCharges:
      //         this.props.invoiceDet.calculations?.revisedShortageCharges,
      //     });
      //   } else {
      //     this.setState({
      //       shortageCharges:
      //         this.props.invoiceDet.calculations?.shortageCharges,
      //     });
      //   }
      // } else {
      //   this.setState({
      //     shortageCharges: this.props.invoiceDet.calculations?.shortageCharges,
      //   });
      // }

      this.setState({ fetched: true });
    }
  };

  deductionCostHandler = (e) => {
    // console.log("cost handler called", e.target.value);
    this.setState({
      deductionCost: parseInt(e.target.value),
      deductionCostChanged: true,
    });
  };

  closeDeductionInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ damageCostEdit: false }, () => {
        // console.log(this.state.deductionCost, "COST");
      });
    }
  };

  freightCostHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({
        freightCost: e.target.value * -1,
        freightCostChanged: true,
      });
    } else {
      this.setState({ freightCost: e.target.value, freightCostChanged: true });
    }
  };

  loadingChargeHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({
        loadingCharges: e.target.value * -1,
        loadingChargesChanged: true,
      });
    } else {
      this.setState({
        loadingCharges: e.target.value,
        loadingChargesChanged: true,
      });
    }
  };
  unloadingChargeHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({
        unloadingCharges: e.target.value * -1,
        unloadingChargesChanged: true,
      });
    } else {
      this.setState({
        unloadingCharges: e.target.value,
        unloadingChargesChanged: true,
      });
    }
  };
  multiPointPickupChargesHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({
        multiPointPickupCharges: e.target.value * -1,
        multiPointPickupChargesChanged: true,
      });
    } else {
      this.setState({
        multiPointPickupCharges: e.target.value,
        multiPointPickupChargesChanged: true,
      });
    }
  };
  detentionSourceChargesHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({
        detentionSourceCharges: e.target.value * -1,
        detentionSourceChargesChanged: true,
      });
    } else {
      this.setState({
        detentionSourceCharges: e.target.value,
        detentionSourceChargesChanged: true,
      });
    }
  };
  detentionDestinationChargesHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({
        detentionDestinationCharges: e.target.value * -1,
        detentionDestinationChargesChanged: true,
      });
    } else {
      this.setState({
        detentionDestinationCharges: e.target.value,
        detentionDestinationChargesChanged: true,
      });
    }
  };
  lateDeliveryChargesHandler = (e) => {
    if (e.target.value < 0) {
      this.setState({
        lateDeliveryCharges: e.target.value * -1,
        lateDeliveryChargesChanged: true,
      });
    } else {
      this.setState({
        lateDeliveryCharges: e.target.value,
        lateDeliveryChargesChanged: true,
      });
    }
  };

  closeFreightCostInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ freightCostEdit: false });
    }
  };

  closeLoadingChargeInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ loadingChargesEdit: false });
    }
  };
  closeUnloadingChargeInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ unloadingChargesEdit: false });
    }
  };
  closeMultiPointChargeInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ multiPointPickupChargesEdit: false });
    }
  };
  closeDetentionSourceInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ detentionSourceChargesEdit: false });
    }
  };
  closeDetentionDestinationInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ detentionDestinationChargesEdit: false });
    }
  };
  closeLateDeliveryChargesInput = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({ lateDeliveryChargesEdit: false });
    }
  };

  costDescriptionHandler = (e) => {
    // console.log(e.target.value);
    this.setState({ costDescription: e.target.value }, () => {
      if (this.state.costDescription.length && this.state.costValue.length) {
        this.setState({ submitDisable: false });
      } else {
        this.setState({ submitDisable: true });
      }
    });
  };

  costValueHandler = (e) => {
    // console.log(e.target.value);
    this.setState({ costValue: e.target.value }, () => {
      if (this.state.costValue.length && this.state.costDescription.length) {
        this.setState({ submitDisable: false });
      } else {
        this.setState({ submitDisable: true });
      }
    });
  };

  expenseAddHandler = () => {
    let expArray = [];
    expArray.push({
      label: this.state.costDescription,
      charges: parseInt(this.state.costValue),
      // id : this.props.invoiceDet.id
    });
    this.setState(
      {
        costArray: this.state.costArray.concat(expArray),
        costDescription: "",
        costValue: "",
        submitDisable: true,
      },
      () => {
        // console.log("COST ARRAY", this.state.costArray);
      }
    );
  };

  resetExpenseHandler = () => {
    this.setState({ costDescription: "", costValue: "" }, () => {
      if (!this.state.costDescription.length || !this.state.costValue.length) {
        this.setState({ submitDisable: true });
      } else {
        this.setState({ submitDisable: false });
      }
    });
  };

  submitChanges = () => {
    let values = { limit: 10, offset: this.props.offset };
    let data = {
      billId: this.props.invoiceDet.id,
    };
    if (this.state.freightCost) {
      data.revisedFreightCost = parseInt(this.state.freightCost);
    }

    if (this.state.loadingCharges && this.state.loadingChargesChanged) {
      data.revisedLoading = parseInt(this.state.loadingCharges);
    }
    if (this.state.unloadingCharges && this.state.unloadingChargesChanged) {
      data.revisedUnloading = parseInt(this.state.unloadingCharges);
    }
    if (this.state.deductionCost && this.state.deductionCostChanged) {
      data.revisedDamage = this.state.deductionCost;
    }
    if (this.state.costArray && this.state.costArray?.length > 0) {
      data.revisedOtherDeductions = this.state.costArray;
    }
    if (
      this.state.lateDeliveryCharges &&
      this.state.lateDeliveryChargesChanged
    ) {
      data.revisedLateDeliveryCharge = parseInt(this.state.lateDeliveryCharges);
    }
    if (
      this.state.multiPointPickupCharges &&
      this.state.multiPointPickupChargesChanged
    ) {
      data.revisedMultipointPickup = parseInt(
        this.state.multiPointPickupCharges
      );
    }
    if (
      this.state.detentionSourceCharges &&
      this.state.detentionSourceChargesChanged
    ) {
      data.revisedSourceDetention = parseInt(this.state.detentionSourceCharges);
    }
    if (
      this.state.detentionDestinationCharges &&
      this.state.detentionDestinationChargesChanged
    ) {
      data.revisedDestinationDetention = parseInt(
        this.state.detentionDestinationCharges
      );
    }

    this.props.updateBill(data, (res) => {
      this.props.toggle();
      this.props.getAllData();
    });
  };

  render() {
    // console.log(this.props?.bills, "samreen test");
    // console.log(this.props.invoiceDet, "New");

    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    if (!this.props.isOpen) {
      if (this.state.freightCostEdit)
        this.setState({
          freightCostEdit: false,
        });
      if (this.state.damageCostEdit)
        this.setState({
          damageCostEdit: false,
        });
      if (this.state.loadingChargesEdit)
        this.setState({
          loadingChargesEdit: false,
        });
      if (this.state.unloadingChargesEdit)
        this.setState({
          unloadingChargesEdit: false,
        });
      if (this.state.lateDeliveryChargesEdit)
        this.setState({
          lateDeliveryChargesEdit: false,
        });
      if (this.state.multiPointPickupChargesEdit)
        this.setState({
          multiPointPickupChargesEdit: false,
        });
      if (this.state.detentionSourceChargesEdit)
        this.setState({
          detentionSourceChargesEdit: false,
        });
      if (this.state.detentionDestinationChargesEdit)
        this.setState({
          detentionDestinationChargesEdit: false,
        });
    }
    let {
      freightCost,
      freightCostEdit,
      loadingChargesEdit,
      loadingCharges,
      unloadingCharges,
      unloadingChargesEdit,
      multiPointPickupChargesEdit,
      multiPointPickupCharges,
      lateDeliveryCharges,
      lateDeliveryChargesEdit,
      detentionSourceCharges,
      detentionSourceChargesEdit,
      detentionDestinationCharges,
      detentionDestinationChargesEdit,
      shortageCharges,
    } = this.state;
    let { vehicle, status, startTime, distance, shipments } =
      this.props.invoiceDet;
    // console.log("invoiceDet",this.props.invoiceDet,this.props.invoiceDet?.shipments)
    let lrNumbers = [];
    if (this.props.invoiceDet?.shipments !== undefined) {
      this.props.invoiceDet.shipments.map((ele) => {
        lrNumbers.push(ele?.lrNo);
      });
    }

    // console.log("lrNumbers",lrNumbers)

    return (
      <React.Fragment>
        <MDBContainer className="invoice">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => {
              this.props.toggle();
            }}
            centered
            size="lg"
            className={`mdl ${this.props.mode ? "invoiceDarkMode" : ""}`}
          >
            <i
              className="fas fa-times closemodal2 p-3 close"
              onClick={() => {
                if (this.state.freightCostEdit)
                  this.setState({ freightCostEdit: false });
                this.props.toggle();
              }}
            ></i>

            <MDBModalHeader
              toggle={this.toggle}
              className={`${this.props.mode ? "headersModalColor" : ""}`}
            >
              {" "}
              <span className="fontRoboto light__span">
                {userLang?.invoice?.BILL_DETAILS}
              </span>
            </MDBModalHeader>
            <MDBModalBody>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.TRUCK_NO}:
                  </div>
                  <div className="colaps-field dark__span">
                    {vehicle?.regNo || "---"}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.LR_NO
                      ? userLang?.invoice?.LR_NO
                      : "LR Numbers"}
                    :
                  </div>
                  <div className="colaps-field dark__span">
                    {lrNumbers.toString()}
                    {/* {shipments[0]?.lrNo} */}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.TRUCK_TYPE}:
                  </div>
                  <div className="colaps-field dark__span">
                    {vehicle?.type?.label}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.TRIP_STATUS}:
                  </div>
                  <div className="colaps-field darkStatusCompleted">
                    {status}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DATE_OF_THE_PLACEMENT}:
                  </div>
                  <div className="colaps-field dark__span">
                    {startTime?.split("T")[0]}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.STATUS_EPOD}:
                  </div>
                  <div className="colaps-field darkStatusCompleted">
                    Completed
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.TOTAL_DISTANCE}:
                  </div>
                  <div className="colaps-field dark__span">
                    {distance ? distance : 0} KMs
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label required_field light__span">
                    Freight Cost:{" "}
                  </div>
                  <div className="colaps-label dark__span">
                    {freightCostEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          style={{ width: "50%" }}
                          onChange={this.freightCostHandler}
                          onKeyDown={this.closeFreightCostInput}
                        />
                      </>
                    ) : (
                      <>
                        {freightCost}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ freightCostEdit: true });
                          }}
                        >
                          <i className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.LOADING_CHARGES}:
                  </div>
                  <div className="colaps-label dark__span">
                    {loadingChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          min="0"
                          style={{ width: "50%" }}
                          onChange={this.loadingChargeHandler}
                          onKeyDown={this.closeLoadingChargeInput}
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + loadingCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ loadingChargesEdit: true });
                          }}
                        >
                          <i className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>

                {/* <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.MULTI_POINT_PICK_CHARGES}:
                  </div>
                  <div className="colaps-field dark__span"> */}
                   
                    {/* {multiPointPickupChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          min="0"
                          style={{ width: "50%" }}
                          onChange={this.multiPointPickupChargesHandler}
                          onKeyDown={this.closeMultiPointChargeInput}
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + multiPointPickupCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              multiPointPickupChargesEdit: true,
                            });
                          }}
                        >
                          <i
                            className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"
                            style={{
                              backgroundColor: this.props.mode
                                ? "#1b1c31"
                                : "white",
                            }}
                          ></i>
                        </span>
                      </>
                    )} */}
                  {/* </div>
                </Grid> */}


                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DETENTION_CHARGES_AT_SOURCE}:
                  </div>
                  <div className="colaps-field dark__span">
                    {/* {sourceDetentionCharges} ₹ */}
                    {detentionSourceChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          min="0"
                          style={{ width: "50%" }}
                          onChange={this.detentionSourceChargesHandler}
                          onKeyDown={this.closeDetentionSourceInput}
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + detentionSourceCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ detentionSourceChargesEdit: true });
                          }}
                        >
                          <i
                            className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"
                            style={{
                              backgroundColor: this.props.mode
                                ? "#1b1c31"
                                : "white",
                            }}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DETENTION_CHARGES_AT_DESTINATON}:
                  </div>
                  <div className="colaps-field dark__span">
                    {/* {destinationDetentionCharges} ₹ */}
                    {detentionDestinationChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          min="0"
                          style={{ width: "50%" }}
                          onChange={this.detentionDestinationChargesHandler}
                          onKeyDown={this.closeDetentionDestinationInput}
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + detentionDestinationCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              detentionDestinationChargesEdit: true,
                            });
                          }}
                        >
                          <i
                            className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"
                            style={{
                              backgroundColor: this.props.mode
                                ? "#1b1c31"
                                : "white",
                            }}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.LATE_DELIVERY_CHARGES} ({" "}
                    {userLang?.invoice?.DED}):
                  </div>
                  <div className="colaps-field dark__span">
                    <span style={{ color: "red" }}>
                      {/* {this.props.invoiceDet?.calculations?.lateDeliveryCharges}{" "}
                      ₹ */}
                      {lateDeliveryChargesEdit ? (
                        <>
                          <input
                            type="number"
                            placeholder="Cost"
                            min="0"
                            style={{ width: "50%" }}
                            onChange={this.lateDeliveryChargesHandler}
                            onKeyDown={this.closeLateDeliveryChargesInput}
                          />
                        </>
                      ) : (
                        <>
                          {"₹ " + lateDeliveryCharges}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({ lateDeliveryChargesEdit: true });
                            }}
                          >
                            <i
                              className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"
                              style={{
                                backgroundColor: this.props.mode
                                  ? "#1b1c31"
                                  : "white",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.UNLOADING_CHARGES}:
                  </div>
                  <div className="colaps-field dark__span">
                    {/* {this.props.invoiceDet?.calculations?.unloadingCharges} ₹ */}
                    {unloadingChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          min="0"
                          style={{ width: "50%" }}
                          onChange={this.unloadingChargeHandler}
                          onKeyDown={this.closeUnloadingChargeInput}
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + unloadingCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ unloadingChargesEdit: true });
                          }}
                        >
                          <i
                            className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"
                            style={{
                              backgroundColor: this.props.mode
                                ? "#1b1c31"
                                : "white",
                            }}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {" "}
                    {userLang?.invoice?.SHORTAGE} ( {userLang?.invoice?.DED}):
                  </div>
                  <div className="colaps-field dark__span">
                    <span style={{ color: "red" }}>
                      {"₹ " + shortageCharges}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="colaps-label light__span">
                    {userLang?.invoice?.DAMAGE} ( {userLang?.invoice?.DED}):{" "}
                    {this.state.damageCostEdit ? (
                      <input
                        type="number"
                        style={{ width: "50%" }}
                        onChange={this.deductionCostHandler}
                        onKeyDown={this.closeDeductionInput}
                      />
                    ) : (
                      <>
                        <span style={{ color: "red" }}>
                          {"₹ " + this.state.deductionCost}
                          {/* {" " + this.state.defaultDamageCost
                            ? this.state.defaultDamageCost + " ₹"
                            : this.state.deductionCost + "₹"} */}
                        </span>

                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ damageCostEdit: true });
                          }}
                        >
                          <i className="fa fa-edit t-edit m-2 darkInvoiceModalIcons"></i>
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}></Grid>

                <Grid item xs={12} md={3}>
                  <div className="colaps-field light__span">
                    <p>
                      {userLang?.invoice?.ORIGINAL}:
                      <span style={{ color: "red" }}>
                        {" " + this.state.defaultDamageCost} ₹
                      </span>{" "}
                    </p>
                    <p>
                      {userLang?.invoice?.REVISED}:{" "}
                      <span style={{ color: "red" }}>
                        {" " + this.state.deductionCost} ₹
                      </span>
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  style={{
                    backgroundColor: this.props.mode ? "#24243C" : "#F1F5F7",
                    marginTop: "20px",
                    padding: "21px",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div
                        className="colaps-label light__span"
                        style={{
                          left: "0%",
                        }}
                      >
                        <label className="dark__span">
                          {" "}
                          {userLang?.invoice?.OTHER_CHARGES} (
                          {userLang?.invoice?.ADD_DEDUCTION}):{" "}
                        </label>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="d-block flex-column">
                        <div
                          className="colaps-label"
                          style={{
                            left: "0%",
                          }}
                        >
                          {" "}
                          <label className="light__span">
                            {userLang?.tracking?.DESCRIPTION}
                          </label>
                        </div>
                        <div>
                          <input
                            style={{ width: "100%" }}
                            onChange={this.costDescriptionHandler}
                            value={this.state.costDescription}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="d-block flex-column ">
                        <div
                          className="colaps-label light__span"
                          style={{
                            left: "0%",
                          }}
                        >
                          <label> {userLang?.invoice?.EXPENSE_ENTRY}</label>
                        </div>
                        <div>
                          <input
                            style={{ width: "100%" }}
                            onChange={this.costValueHandler}
                            value={this.state.costValue}
                            type="number"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3} className="mobileExpenseColumn">
                      <Button
                        className="btn btn-primary btnExpense "
                        onClick={this.expenseAddHandler}
                        disabled={this.state.submitDisable}
                      >
                        {userLang?.invoice?.ADD_EXPENSE}
                      </Button>

                      <Button
                        className="btn btn-primary btnExpense ml-2"
                        onClick={this.resetExpenseHandler}
                      >
                        Reset
                      </Button>
                    </Col>

                    <Col xs={12}>
                      <div className="d-flex justify-content-around">
                        {this.state.costArray.length ? (
                          <div>
                            {this.state.costArray.map((e, index) => (
                              <Row
                                key={index}
                                className="m-1"
                                style={{ width: "300px" }}
                              >
                                <Col className="text-left font-weight-bold ml-1">
                                  {e.label}
                                </Col>
                                <Col className="float-right mr-3">
                                  {e.charges > 0 ? (
                                    <span>{` ₹ ` + e.charges}</span>
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      {` ₹ ` + e.charges}
                                    </span>
                                  )}
                                  <i
                                    className="fas fa-times closemodal2  close"
                                    onClick={() => {
                                      let deleteArray =
                                        this.state.costArray.filter(
                                          (ele) => ele != e
                                        );
                                      this.setState({
                                        costArray: deleteArray,
                                      });
                                    }}
                                  ></i>
                                </Col>
                              </Row>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="saveButton pt-3">
                    <Button
                      type="button"
                      color="primary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.submitChanges();
                      }}
                      // disabled={
                      //   !this.state.freightCost || this.state.freightCostEdit
                      // }
                    >
                      {userLang?.common?.SAVE}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}></Grid>
                <Grid item xs={6} md={6}></Grid>
              </Grid>

              {/* <div className="row">
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.TRUCK_NO}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {vehicle?.regNo || "---"}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.TRUCK_TYPE}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {vehicle?.type?.label}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.TRIP_STATUS}:
                  </div>
                  <div
                    className={`colaps-field textRole ${
                      this.props.mode ? "darkmodeStatusMode" : ""
                    }`}
                  >
                    {status}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DATE_OF_THE_PLACEMENT}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {startTime?.split("T")[0]}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.STATUS_EPOD}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "darkmodeStatusMode" : ""
                    }`}
                  >
                    Completed
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.TOTAL_DISTANCE}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {distance} KMs
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label required_field ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    Freight Cost:{" "}
                  </div>
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {freightCostEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          style={{ width: "50%" }}
                          onChange={this.freightCostHandler}
                          onKeyDown={this.closeFreightCostInput}
                          className={
                            this.props.mode ? "darkInvoiceModalCharge" : ""
                          }
                        />
                      </>
                    ) : (
                      <>
                        {freightCost}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ freightCostEdit: true });
                          }}
                        >
                          <i
                            className={`fa fa-edit t-edit m-2 ${
                              this.props.mode ? "darkInviceModalIcons" : ""
                            }`}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.LOADING_CHARGES}:
                  </div>
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {loadingChargesEdit ? (
                      <>
                        <input
                          type="number"
                          placeholder="Cost"
                          style={{ width: "50%" }}
                          onChange={this.loadingChargeHandler}
                          onKeyDown={this.closeLoadingChargeInput}
                          className={
                            this.props.mode ? "darkInvoiceModalCharge" : ""
                          }
                        />
                      </>
                    ) : (
                      <>
                        {"₹ " + loadingCharges}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ loadingChargesEdit: true });
                          }}
                        >
                          <i
                            className={`fa fa-edit t-edit m-2 ${
                              this.props.mode ? "darkInviceModalIcons" : ""
                            }`}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.MULTI_POINT_PICK_CHARGES}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {multipointPickupCharges} ₹
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DETENTION_CHARGES_AT_SOURCE}:
                  </div>
                  <div className="colaps-field">{sourceDetentionCharges} ₹</div>
                </div>
              </div>
              <div className="row">
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DETENTION_CHARGES_AT_DESTINATON}:
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    {destinationDetentionCharges} ₹
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.LATE_DELIVERY_CHARGES} ({" "}
                    {userLang?.invoice?.DED}):
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    <span style={{ color: "red" }}>
                      {this.props.invoiceDet?.calculations?.lateDeliveryCharges}{" "}
                      ₹
                    </span>
                  </div>
                </div>
                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.UNLOADING_CHARGES}:
                  </div>
                  <div className="colaps-field">
                    {this.props.invoiceDet?.calculations?.unloadingCharges} ₹
                  </div>
                </div>

                <div className="colsp">
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {" "}
                    {userLang?.invoice?.SHORTAGE} ( {userLang?.invoice?.DED}):
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    <span style={{ color: "red" }}>
                      {this.props.invoiceDet?.calculations?.shortageCharges} ₹
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ width: "20%", float: "left", marginTop: "16px" }}>
                <div>
                  <div
                    className={`colaps-label ${
                      this.props.mode ? "light__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.DAMAGE} ( {userLang?.invoice?.DED}):{" "}
                    {this.state.damageCostEdit ? (
                      <input
                        type="number"
                        style={{ width: "50%" }}
                        onChange={this.deductionCostHandler}
                        onKeyDown={this.closeDeductionInput}
                        className={
                          this.props.mode ? "darkInvoiceModalCharge" : ""
                        }
                      />
                    ) : (
                      <>
                        <span style={{ color: "red" }}>
                          {" " + this.state.defaultDamageCost
                            ? this.state.defaultDamageCost + " ₹"
                            : this.state.deductionCost + "₹"}
                        </span>

                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ damageCostEdit: true });
                          }}
                        >
                          <i
                            className={`fa fa-edit t-edit m-2 ${
                              this.props.mode ? "darkInviceModalIcons" : ""
                            }`}
                          ></i>
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className={`colaps-field ${
                      this.props.mode ? "dark__span" : ""
                    }`}
                  >
                    <p>
                      {userLang?.invoice?.ORIGINAL}:
                      <span style={{ color: "red" }}>
                        {" " + this.state.defaultDamageCost} ₹
                      </span>{" "}
                    </p>
                    <p>
                      {userLang?.invoice?.REVISED}:{" "}
                      <span style={{ color: "red" }}>
                        {" " + this.state.deductionCost
                          ? this.state.deductionCost
                          : "---"}
                      </span>
                    </p>
                  </div>
                  <div className="saveButton" style={{ marginTop: "64px" }}>
                    <Button
                      type="button"
                      color="primary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.submitChanges();
                      }}
                      disabled={
                        !this.state.freightCost || this.state.freightCostEdit
                      }
                    >
                      {userLang?.common?.SAVE}
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ width: "80%", float: "right" }}>
                <div className="col-md-9">
                  <div
                    className="row last mt-1"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div style={{ width: "20%" }}>
                      <div className="col-md-2">
                        <div
                          className={`colaps-label ${
                            this.props.mode ? "light__span" : ""
                          }`}
                        >
                          <label>
                            {" "}
                            {userLang?.invoice?.OTHER_CHARGES} (
                            {userLang?.invoice?.ADD_DEDUCTION}):{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className={`colaps-label ${
                          this.props.mode ? "light__span" : ""
                        }`}
                      >
                        {" "}
                        <label>{userLang?.tracking?.DESCRIPTION}</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className={`colaps-label ${
                          this.props.mode ? "light__span" : ""
                        }`}
                      >
                        <label> {userLang?.invoice?.EXPENSE_ENTRY}</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "80%",
                      textAlign: "end",
                      marginLeft: "126px",
                    }}
                  >
                    <div className="d-flex justify-content-around">
                      {this.state.costArray.length ? (
                        <div>
                          {this.state.costArray.map((e, index) => (
                            <Row
                              key={index}
                              className="m-1"
                              style={{ width: "300px" }}
                            >
                              <Col className="text-left font-weight-bold ml-1">
                                {e.label}
                              </Col>
                              <Col className="float-right mr-3">
                                {e.charges > 0 ? (
                                  <span>{` ₹ ` + e.charges}</span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    {` ₹ ` + e.charges}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-end">
                      <input
                        style={{ width: "25%" }}
                        onChange={this.costDescriptionHandler}
                        value={this.state.costDescription}
                        className={
                          this.props.mode ? "darkInvoiceModalCharge" : ""
                        }
                      />
                      <input
                        style={{ width: "35%" }}
                        onChange={this.costValueHandler}
                        value={this.state.costValue}
                        type="number"
                        className={
                          this.props.mode ? "darkInvoiceModalCharge" : ""
                        }
                      />
                      <button
                        style={{ height: "26px", lineHeight: "16px" }}
                        className="btn btn-primary btn-sm ml-1"
                        onClick={this.expenseAddHandler}
                      >
                        {userLang?.invoice?.ADD_EXPENSE}
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state, "Samreen");
  return {};
};

export default connect(mapStateToProps, { updateBill, fetchBills })(
  InvoiceModal
);
