import { all } from "redux-saga/effects";

//public
import accountSaga from "./auth/register/saga";
import loginSaga from "./auth/login/saga";
import forgetSaga from "./auth/forgetpwd/saga";
import TripSaga from "./trip/saga";
import SecondaryTripSaga from "./secondaryTrip/saga";
import TripCountSaga from "./dashboard/saga";
import DriverSaga from "./driver/saga";
import OrderSaga from "./order/saga";
import VehicleSaga from "./vehicle/saga";
import DealerSaga from "./dealer/saga";
import EmployeeSaga from "./employee/saga";
import HopSaga from "./hop/saga";
import SecondarySaga from "./secondaryIndentPlanning/saga";
import AcessPolicySaga from "./accessPolicy/saga";
import IndentSaga from "./indent//saga";
import BillingSaga from "./billing/saga";
import SecondaryBillingSaga from './secondaryBilling/saga'
import TransporterSaga from "./transporter/saga";
import VehicleTypeSaga from "./vehicleType/saga";
import RouteSaga from "./route/saga";
import CommonSaga from "./common/saga";
import ReportsSaga from "./Reports/saga";
import loadOptimizationSaga from "./LoadOptimization/saga";
import vehiclePlacementReportSaga from "./vehiclePlacement/saga";
import OnTimeDeliveryReportSaga from "./OntimeDeliveryReports/saga";
import vehiclePlacementDetailReportSaga from "./vehiclePlacementDetail/saga";
import FreightMasterSaga from "./freightsMaster/saga";
import ShipmentMasterSaga from "./shipmentMaster/saga";

// baxter
import customerSaga from "./baxter_customer/saga";
import locationSaga from "./baxter_location/saga";
import uploadModule from "./baxter-uploadModule/saga";
import productSaga from "./baxter_product/saga";
import orderListSaga from "./baxter-order/saga";
import invoiceListSaga from "./baxter-orderInvoice/saga";
import baxterLoadOptimizationSaga from "./baxter-loadOptimization/saga";
import BaxTripListSaga from "./baxter-trip/saga";
import baxterOperationalDashboardSaga from "./baxter-dashboard/saga";
import dispatchLeadTimeReportSaga from "./baxter-report/saga";
import LogbookDataSaga from "./baxter_logbook/saga";
import masterZoneToStateMapSaga from "./baxter_zoneToStateMap/saga";
export default function* rootSaga() {
  yield all([
    //public
    accountSaga(),
    loginSaga(),
    forgetSaga(),
    TripSaga(),
    SecondaryTripSaga(),
    TripCountSaga(),
    DriverSaga(),
    OrderSaga(),
    VehicleSaga(),
    DealerSaga(),
    EmployeeSaga(),
    HopSaga(),
    SecondarySaga(),
    AcessPolicySaga(),
    IndentSaga(),
    TransporterSaga(),
    VehicleTypeSaga(),
    RouteSaga(),
    CommonSaga(),
    BillingSaga(),
    SecondaryBillingSaga(),
    ReportsSaga(),
    loadOptimizationSaga(),
    vehiclePlacementReportSaga(),
    vehiclePlacementDetailReportSaga(),
    OnTimeDeliveryReportSaga(),
    FreightMasterSaga(),
    ShipmentMasterSaga(),
    // baxter
    locationSaga(),
    customerSaga(),
    uploadModule(),
    productSaga(),
    orderListSaga(),
    invoiceListSaga(),
    baxterLoadOptimizationSaga(),
    BaxTripListSaga(),
    baxterOperationalDashboardSaga(),
    dispatchLeadTimeReportSaga(),
    LogbookDataSaga(),
    masterZoneToStateMapSaga(),
  ]);
}
