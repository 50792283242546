import React, { Component } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  fetchAssignedIndents,
  assignIndent,
  rejectIndent,
  approveIndent,
  IndentStatusAccept,
} from "../../store/indent/actions";
import { getTransporters } from "../../store/transporter/actions";
import moment from "moment";
import {
  fetchAvailVehicles,
  fetchVehicles,
  createVehicle,
} from "../../store/vehicle/actions";
import {
  fetchAvailDrivers,
  fetchDrivers,
  createDriver,
} from "../../store/driver/actions";
import { createTrip } from "../../store/trip/actions";
import { fetchVehicleTypesList } from "../../store/vehicleType/actions";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  HeaderData,
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quckFilters";
import Pagination from "@material-ui/lab/Pagination";
import formatDate from ".././DateFormatter";
import ModalTransporter from ".././ModalTransporter";
import { Modal, ModalBody } from "reactstrap";
import { fetchHopsSourceDestination, fetchHops } from "../../store/hop/actions";
import ApproveModal from ".././ApproveModal";
import RejectModal from ".././RejectModal";
import { restrict } from ".././restrict";
import IndentData from "../indentData";
import { actions } from "./actionButtons";
import { PERMS } from "../../enum/perms.enum";

const indentStatusMaster = [
  { label: "Finalized", value: "Finalized" },
  { label: "Expired", value: "Expired" },
  { label: "Cancelled", value: "Cancelled" },
  // { label: "Rejected", value: "Rejected" },
  // { label: "Autorejected", value: "Autorejected" },
  { label: "Assigned", value: "Assigned" },
  { label: "Reassigned", value: "Reassigned" },
  { label: "Accepted", value: "Accepted" },
  { label: "Drafted", value: "Drafted" },
];

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var authUser;
var searchValues = {};

class AssignedTransporterIndent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Indent", link: "#" },
        { title: "All Indents", link: "#" },
      ],
      rows: [],
      selectedOption: "",
      driverContact: "",
      driverLicense: "",
      driver: "",
      vehicle: "",
      vehicleTypes: "",
      filters: "",
      dataLoading: false,
      modalIndentDetails: false,
      modalApproval: false,
      modalReject: false,
      indentData: {},
      searchKey: "",
      searchValue: "",
      disabled: true,
      searchOn: false,
      keySelected: false,
      radioFilter: "",
      isSubmitting: false,
      searchFilters: {},
      buttonContent: "",
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      transpoterId: "",
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
      onSubmitDisableDriver: false,
      onSubmitDisableVehicle: false,
      transporter: "",
      toBeSelectedVehicleType: null,
      limit: 500,
      transporterVehicleOptions: [],
      transporterDriverOptions: [],
    };
    this.indents = [];
    (async () => {
      let redirect = await restrict(PERMS.INDENT_TRANSPORTER_READ);

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    this.getAllData();
    this.props.fetchVehicleTypesList(data || {}, () => {});
    authUser = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ transporter: authUser.data._id });
  };

  componentDidUpdate(prevProps) {
    //   this.setState({policies:this.props.policies})
    //  this.getAllData();
  }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  filterDataTranspotersAndTrips = (e) => {
    this.filtersHops(e);
  };

  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        this.hops = [];
        if (res?.data?.docs?.length > 0) {
          // this.hops = [this.initialVal("Location")];
          res.data.docs.forEach((ele) => {
            this.hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: {
                name:
                  ele.address.addressLine1 +
                  ele.address.city +
                  ele.address.state,
                hopCode: ele.hopCode,
                id: ele._id,
              },
            });
          });
          this.setState({ hops: this.hops }, () => {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source" || obj.field === "destination") {
                obj.options = this.state.hops;
              }
            });
          });
        }
      }
    );
  };
  // get location name by filter
  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };
  getAllData = () => {
    this.setState({
      dataLoading: true,
    });
    let type = [];
    this.props.fetchVehicleTypesList(0, (res) => {
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            type.push({
              label: ele.label,
              value: {
                id: ele._id,
              },
            });
          });
        }
      }
    });

    this.setState({ vehicleTypes: type }, () => {
      searchBar.searchBarData.forEach((obj) => {
        if (obj.field === "vehicleType") {
          obj.options = this.state.vehicleTypes;
        }
      });
    });

    this.props.fetchHops({ offset, limit: 10 }, (res) => {
      this.hops = [];
      if (res?.data?.docs?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.docs.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        this.setState({ hops: this.hops }, () => {
          searchBar.searchBarData.forEach((obj) => {
            if (obj.field === "source" || obj.field === "destination") {
              obj.options = this.state.hops;
            }
          });
        });
      }
    });
    searchBar.searchBarData.forEach((obj) => {
      if (obj.field === "status") {
        obj.options = indentStatusMaster;
      }
    });

    this.props.fetchAssignedIndents(0, this.state.searchFilters, (res) => {
      this.setState({
        dataLoading: false,
      });
    });
    this.props.fetchVehicles(
      0,
      { q: "", transporterId: this.state.transpoterId },
      (res) => {
        this.setState({
          transporterVehicleOptions: res.data.docs,
        });
      }
    );
    this.props.fetchDrivers(
      0,
      "",
      "",
      { q: "", transporterId: this.state.transpoterId },
      (res) => {
        this.setState({
          transporterDriverOptions: res.data.docs,
        });
      }
    );
    // this.refineData();
  };
  // refineData = () => {
  //   this.setState({ loading: false });
  // };

  routingToCancelIndentModel = (ele) => {
    this.toggleModalReject();
    this.setState({ indentIndex: ele?._id, indent_id: ele?.indentId });
    this.getAllData();
  };
  toggleModalReject = () => {
    this.setState({
      modalReject: !this.state.modalReject,
    });
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.setState({
      dataLoading: true,
    });
    this.props.fetchAssignedIndents(offset, this.state.searchFilters, (res) => {
      this.setState({
        dataLoading: false,
      });
    });
  };

  quickFilters = (filterData, dateValue) => {
    let searchFilters = this.state.searchFilters;
    if (filterData === "rejected") {
      searchFilters.status = "Rejected";
    }
    if (filterData === "date") {
      let date = [];
      date[0] = moment(dateValue[0]).format("MM-DD-YYYY");
      date[1] = moment(dateValue[1]).format("MM-DD-YYYY");
      searchFilters.startTime = date;
    }
    this.setState({ searchFilters: searchFilters }, () => {
      this.getAllData();
    });
  };
  toggleIndentModal = (ele) => {
    this.props.fetchCreatedIndentInfo(ele?._id, (result) => {
      this.setState({ IndentData: result.data }, () => {});
      this.setState({ modalIndentDetails: !this.state.modalIndentDetails });
    });
  };
  routingToAcceptStatus = (ID) => {
    this.setState({ buttonContent: "Processing...", isSubmitting: true }, () =>
      this.acceptStatus(ID)
    );
    this.getAllData();
  };
  toggleRejectModal = () => {
    this.setState({ cancelModal: !this.state.cancelModal });
  };

  toggleModalApproval = (ele) => {
    this.setState({
      indent_id: ele?.indentId,
      modalApproval: !this.state.modalApproval,
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };

  routingToProvideDetailModel = (ele, ID) => {
    this.setState(
      {
        transpoterId: ele?.transporter?._id,
        toBeSelectedVehicleType: {
          value: ele?.vehicle?.type._id,
          label: ele?.vehicle?.type?.label,
        },
      },
      () => {
        this.props.fetchDrivers(
          0,
          "",
          "",
          { q: "", transporterId: this.state.transpoterId },
          (res) => {
            this.setState({ transporterDriverOptions: res.data.docs });
          }
        );
        // console.log(ele, "ele");
        this.props.fetchVehicles(
          0,
          { q: "", transporterId: this.state.transpoterId },
          (res) => {
            this.setState({ transporterVehicleOptions: res.data.docs });
          }
        );
      }
    );
    this.toggleModalApproval(ele);
    this.setState({ indentIndex: ID });
    // this.getAllData();
    // console.log(this.state.transpoterId, "ID")
  };
  handleChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onValueChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
    });
  };

  filterVehicleList = (e) => {
    if (e.length > 2) {
      this.props.fetchVehicles(
        null,
        { q: e, transporterId: this.state.transpoterId },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
    if (e.length === 0) {
      this.props.fetchVehicles(
        0,
        { q: "", transporterId: this.state.transpoterId },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
  };

  filterDriverList = (e) => {
    var regexNumber = /^[0-9]+$/;
    var regexString = /^[a-zA-Z]+$/;
    if (e.length > 2) {
      if (e.match(regexNumber)) {
        this.props.fetchDrivers(offset, "", "", { phone: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
      }
      if (e.match(regexString))
        this.props.fetchDrivers(offset, "", "", { name: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
    }
    if (e.length === 0) {
      this.props.fetchDrivers(
        offset,
        "",
        "",
        { q: "", transporterId: this.state.transpoterId },
        (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        }
      );
    }
  };
  acceptStatus = (ID) => {
    // return false;
    let value = {
      indentID: ID,
    };
    this.props.IndentStatusAccept({
      value,
      callback: (res) => {
        this.setState({ isSubmitting: false, buttonContent: "Accept" }, () => {
          this.getAllData();
        });
      },
    });
  };

  // route = (via) => {
  //   return via?.map((e, i) => (
  //     <div key={i}>
  //       {i ? (
  //         <div className="text-left pr-4" style={{ fontSize: "0.6em" }}>
  //           <i
  //             style={{ color: "#5664D2" }}
  //             className="fas fa-ellipsis-v ml-1"
  //           ></i>
  //         </div>
  //       ) : null}

  //       <div className="text-left d-flex align-items-center flex-nowrap">
  //         <i
  //           style={{ color: "#5664D2" }}
  //           className="fas fa-circle iconSize"
  //         ></i>
  //         <span className="ml-2">{e.label}</span>
  //       </div>
  //     </div>
  //   ));
  // };
  route = (via) => {
    return (
      <div className="route-container comn150">
        {/* Show only the first label */}
        <div className="text-left d-flex align-items-center justify-content-between flex-nowrap mobileRoute">

          <span className={`tw-truncate ${via.length === 1 ? "single-label-text" : "someclass" }`}>
            {via[0]?.label}
          </span>


          {via.length > 0 && (
            <span
              className="icon-container"
            >
              <span className="total-labels">{via.length}</span>
              {/* Tooltip with remaining labels */}
              <div className="showroute">
                {via?.map((e, i) => (
                  <div key={i} className="tooltip-item">
                    <span className="timeline-dot"></span>
                    <span>{e.label}</span>
                  </div>
                ))}
              </div>
            </span>
          )}
        </div>
      </div>
    );
  };
 status = (newStatus, lastRejection, id) => {
    switch (newStatus) {
      case "Accepted":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound accepted-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Rejected":
        
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound rejected-color">{" " + newStatus}</div>
          
          </div>
        );
      case "AutoRejected":
        
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound rejected-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Assigned":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound assigned-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Reassigned":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound assigned-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Finalized":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound finalized-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Drafted":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound drafted-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Expired":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound expired-color">{" " + newStatus}</div>
          
          </div>
        );
      case "Cancelled":
        return (
          <div className=" text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound cancelled-color">{" " + newStatus}</div>
          
          </div>
        );
      default:
        break;
    }
  };

  handleClick = (e, ele) => {
    e.preventDefault();
    if (e.target.type !== "button") {
      this.setState({ IndentData: ele }, () => {
        this.toggleIndentModal();
      });
    }
  };
  toggleIndentModal = (ele) => {
    this.setState({ IndentData: ele }, () => {
      this.setState({ modalIndentDetails: !this.state.modalIndentDetails });
    });
  };

  // searchKeySetter = (e) => {
  //   this.setState({ searchKey: e, keySelected: true }, () => {
  //     if (this.state.searchKey !== "" && this.state.searchValue !== "")
  //       this.setState({ disabled: false });
  //     if (this.state.searchValue === "") {
  //       this.setState({ disabled: true });
  //     }
  //   });
  // };

  // searchValueSetter = (e) => {
  //   this.setState({ searchValue: e.target.value, searchOn: true }, () => {
  //     if (this.state.searchKey !== "" && this.state.searchValue !== "")
  //       this.setState({ disabled: false });
  //     if (this.state.searchValue === "") {
  //       this.setState({ disabled: true });
  //     }
  //   });
  // };

  searchValueAssignerFunction = (name, value, type) => {
    if (typeof value === "object") {
      if (type === "date") searchValues[name] = value;
      else searchValues[name] = value.id;
    } else {
      searchValues[name] = value;
    }

    this.setState({ searchFilters: searchValues }, () => {
      if (type === "select" || type === "date") this.getAllData();
    });
  };

  reset = (e) => {
    this.setState(
      {
        searchKey: "",
        searchValue: "",
        searchFilters: {},
        disabled: true,
        keySelected: false,
        searchOn: false,
        radioFilter: "",
      },
      () => {
        totCnt = 0;
        totPages = 0;
        toVal = 0;
        fromVal = 0;
        offset = 0;
        searchValues = {};
        this.getAllData();
      }
    );
  };

  resetSelection = () => {
    this.setState({ vehicle: "", driver: "" });
  };

  handleRadioChange = (e) => {
    this.setState({ radioFilter: e, searchOn: !this.state.searchOn }, () => {
      this.getAllData();
    });
  };

  handleSubmitSaveVehicle = () => {
    let { VehicleOwner, VehicleRegistrationNumber, type } =
      this.state.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner,
      transporter: this.state.transporter,
      regNo: VehicleRegistrationNumber,
      type: type?.value,
    };
    this.setState({ onSubmitDisableVehicle: true });
    this.props.createVehicle(formObj, (res) => {
      if (res.success) {
        this.props.fetchVehicles(
          0,
          { q: "", transporterId: this.state.transpoterId },
          (res) => {
            this.setState({ transporterVehicleOptions: res.data.docs });
          }
        );
        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableVehicle: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableVehicle: false,
          vehicle: "",
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    });

    setTimeout(() => {
      let vh;
      if (this.state.vehicleForm.VehicleRegistrationNumber !== "") {
        if (Array.isArray(this.state.transporterVehicleOptions)) {
          vh = this.state.transporterVehicleOptions.filter((v) => {
            return (
              v.registration.number ===
              this.state.vehicleForm.VehicleRegistrationNumber
            );
          });
        }
        this.setState({
          vehicle: {
            value: vh[0]?._id,
            label: vh[0]?.registration.number + " (" + vh[0]?.type.label + ")",
          },
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    }, 4000);
  };
  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name === "vehicleType") {
      vf.type = value;
    }
    if (name === "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name === "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value.toString().toUpperCase();
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      phone,
      transporter: this.state.transporter,
      licence: { number: driverLicenseNo },
    };
    this.setState({ onSubmitDisableDriver: true });
    this.props.createDriver(formObj, (res) => {
      if (res.message === "Driver has been created successfully") {
        this.props.fetchDrivers(
          offset,
          "",
          "",
          { q: "", transporterId: this.state.transpoterId },
          (res) => {
            this.setState({
              transporterDriverOptions: res.data.docs,
            });
          }
        );

        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableDriver: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableDriver: false,
          driver: "",
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
        });
      }
    });
    setTimeout(() => {
      let dt;
      if (this.state.driverForm.phone !== "") {
        if (Array.isArray(this.state.transporterDriverOptions)) {
          dt = this.state.transporterDriverOptions.filter((v) => {
            return v.phone === this.state.driverForm.phone;
          });
        }
        this.setState({
          driver: {
            value: dt[0]?._id,
            label: dt[0]?.phone + " (" + dt[0]?.name + ")",
          },
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
        });
      }
    }, 4000);
  };
  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name === "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name === "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name === "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };
  handleResetEdit = () => {
    // console.log("Assigned Indents");
    this.setState({
      vehicle: "",
      driver: "",
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
    });
  };
  render() {
    const localStorageData = JSON.parse(
      localStorage.getItem("indentAssignData")
    );
    const indentAssignData = navigator.onLine
      ? this.props.indents?.data?.docs
      : localStorageData?.data?.docs;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    this.policy = this.props.policies;
    let indents = [];
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];
    let vehicleType = this.props.vehicleType.vehicleType;
    if (Array.isArray(indentAssignData)) {
      totCnt = navigator.onLine
        ? this.props.indents?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? this.props.indents?.data?.offset +
          this.props.indents?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      indentAssignData.forEach((ele) => {
        indents.push({
          id: ele._id,
          indentId: ele.indentId ? ele.indentId : "---",
          // source: ele.source?.label,
          source: (
            <p className=" ellipsis comn130" title={ele.source ? `${ele.source.label}` : ""} style={{ padding: "0px", margin: "0px" }}>
              {ele.source ? `${ele.source.label}` : "-----"}
            </p>
          ) || "-----",
          // destination: ele.destination?.label,
          destination: (
            <p className=" ellipsis comn130" title={ele.destination ? `${ele.destination.label}` : ""} style={{ padding: "0px", margin: "0px" }}>
              {ele.destination ? `${ele.destination.label}` : "-----"}
            </p>
          ) || "-----",
          // vehicle: ele.vehicle?.type?.label,
          // transporter: ele.transporter?.name || "---",
          vehicle: (
            <p className=" ellipsis comn130 m-0"
            title={` ${ele.vehicle?.type?.label || ""}`}>
            {ele.vehicle?.type?.label ?`${ele?.vehicle?.type?.label}`:"-----"}
            </p>
          ) || "-----",
          transporter: (
            <p className=" ellipsis comn140" title={ele.transporter?.name || ''} style={{ padding: "0px", margin: "0px" }}>
              {ele.transporter?.name || "-----"}
            </p>
          ) || "-----",
          route: ele.route?.via?.length
            ? this.route(ele?.route?.via)
            : "DIRECT",
          description: ele.description,
          startTime: ele.startTime ? formatDate(ele.startTime) : "---",
          expiryTime: ele.expiryTime ? formatDate(ele.expiryTime) : "---",
          status: ele.status ? this.status(ele.status) : "N/A",
          actions: actions(ele.status, ele._id, ele, this.state.buttonContent, {
            routingToIndentDataModel: this.toggleIndentModal,
            routingToAcceptStatus: this.routingToAcceptStatus,
            routingToCancelIndentModel: this.routingToCancelIndentModel,
            routingToProvideDetailModel: this.routingToProvideDetailModel,
          }),

          clickEvent: (e) => this.handleClick(e, ele),
        });
      });
    }
    if (Array.isArray(this.state.transporterVehicleOptions)) {
      this.state.transporterVehicleOptions.forEach((ele) => {
        vehicleOptions.push({
          value: ele?._id,
          label: ele?.registration?.number + " (" + ele?.type?.label + ")",
        });
      });
    }

    if (Array.isArray(vehicleType?.data?.docs)) {
      vehicleType.data.docs.forEach((ele) => {
        vehicleTypeOptions.push({
          value: ele._id,
          label: ele.vehicleTypeCode,
        });
      });
    }
    if (Array.isArray(this.state.transporterDriverOptions)) {
      this.state.transporterDriverOptions.forEach((ele) => {
        driverOptions.push({
          value: ele._id,
          label: ele.phone + " (" + ele.name + ")",
        });
      });
    }

    return (
      <React.Fragment>
        {getLocalStorage()}
        <ModalTransporter
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          transporter={this.state.transporter}
          onChange={this.handleChange}
          options={this.state.transportersList}
          submit={this.handleSubmitTransporter}
          disabled={this.state.onSubmitDisable}
        />
        <ApproveModal
          handleResetEdit={this.handleResetEdit}
          isOpen={this.state.modalApproval}
          toggle={this.toggleModalApproval}
          onChange={this.handleChangeModal}
          options={vehicleOptions}
          optionsdriver={driverOptions}
          driver={this.state.driver}
          vehicle={this.state.vehicle}
          submit={this.handleSubmitApprove}
          disabledVehicle={this.state.onSubmitDisableVehicle}
          disabledDriver={this.state.onSubmitDisableDriver}
          indentIndex={this.state.indentIndex}
          filterVehicleList={this.filterVehicleList}
          filterDriverList={this.filterDriverList}
          type={vehicleTypeOptions}
          refreshData={this.getAllData}
          submitVehicleForm={this.handleSubmitSaveVehicle}
          onVehicleChange={this.onVehicleChange}
          vehicleForm={this.state.vehicleForm}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          resetSelection={this.resetSelection}
          transporter={this.state.transporter}
          toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
          indentPageRoute={true}
          indent_id={this.state.indent_id}
          mode={darkMode}
        />
        <RejectModal
          isOpen={this.state.modalReject}
          toggle={this.toggleModalReject}
          onChange={this.handleChange}
          options={this.state.transportersList}
          description={this.state.description}
          submit={this.handleSubmitReject}
          disabled={this.state.onSubmitDisable}
          selectedOption={this.state.selectedOption}
          onValueChange={this.onValueChange}
          indentIndex={this.state.indentIndex}
          refreshData={this.getAllData}
          mode={darkMode}
          indent_id={this.state.indent_id}
          indentType="Secondary"
        />
        <div className="darkBackgroundList">
          <QuickFilters
            onClickAddIndent={this.onClickAddIndent}
            quickFilters={this.quickFilters}
            reset={this.reset}
            toggleExcel={this.toggleExcel}
            export={this.state.export}
          />
          <>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={HeaderData}
                data={indents}
                searchBar={searchBar}
                searchable={true}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={this.props.indents?.data?.length}
                fetchId={this.props.fetchId}
                rowHeight={"70px"}
                dataLoading={this.state.dataLoading}
                filterDataTranspotersAndTrips={
                  this.filterDataTranspotersAndTrips
                }
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={indents}
                searchBar={searchBar}
                searchable={true}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={this.props.indents?.data?.length}
                fetchId={this.props.fetchId}
                rowHeight={"70px"}
                dataLoading={this.state.dataLoading}
                filterDataTranspotersAndTrips={
                  this.filterDataTranspotersAndTrips
                }
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkMode ? "#9d9d9d" : "#0C0C0C" }}
                className="mb-2 mt-2 paginationRow"
              >
                  <Col
                  className="mt-2"
                  xs={5}
                  sm={5}
                  md={5}
                  style={{ fontSize: "13px" }}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
                <Col xs={7} sm={7} md={7} className="span-col-pagenation">
                  <div className="float-right">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
              
              </Row>
            ) : null}
          </>
        </div>
        <Modal
          isOpen={this.state.modalIndentDetails}
          toggle={this.toggleIndentModal}
          size="lg"
        >
          <Card className="px-0 mb-0">
            <CardBody className="px-0 py-0">
              <ModalBody
                className={`indentModal ${
                  darkMode ? "darkIndentModalDetails" : ""
                }`}
              >
                <IndentData
                  data={this.state.IndentData}
                  formatDate={formatDate}
                  routeConvert={this.route}
                  header={"Indent Details"}
                  closeModal={this.toggleIndentModal}
                  mode={darkMode}
                  indentType={"primary"}
                />
              </ModalBody>
            </CardBody>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.assignedIndents,
    fetchId: state.Indent.indents.fetchId,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    policies: state.AccessPolicy.accessPolicies.policies,
    vehicleType: state.VehicleType.vehicleType,
  };
};

export default connect(mapStateToProps, {
  fetchAssignedIndents,
  getTransporters,
  assignIndent,
  IndentStatusAccept,
  fetchAvailVehicles,
  fetchVehicles,
  fetchAvailDrivers,
  fetchDrivers,
  rejectIndent,
  approveIndent,
  createTrip,
  createDriver,
  createVehicle,
  fetchVehicleTypesList,
  fetchHopsSourceDestination,
  fetchHops,
})(AssignedTransporterIndent);
