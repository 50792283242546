import React, { Component } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import moment from "moment";


//api's that bring data to page
// import {
//     fetchAgreements,
//     fetchViewAgreement,
//     clearLoadedValues,
//   } from "../../store/billing/actions";

import{fetchSecondaryAgreements , fetchViewAgreement , clearLoadedValues} from '../../store/secondaryBilling/actions'



import SecondaryAgreementPfd from "./SecondaryAgreementPdf";
import actionVerticleDots from './assets/more-vertical.png'
import {
    HeaderData,
    AdminHeaderData,
    searchBar,
    getLocalStorage,
  } from "./RowHeaders/RowHeaders";

import Index from "../../components/Common/DataTableNeo/Index";
import Axios from "axios";
import QuickFilters from "./QuickFilters";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;





class SecondaryAgreementIndex extends Component{

    constructor(props){
        super(props);
        this.state = {
          loading: false,
          showpdf: false,
          breadcrumbItems: [
            { title: "SecondaryBilling", link: "#" },
            { title: "Agreements", link: "#" },
          ],
          radioFilter: "",
          searchValue: "",
          searchOn: false,
          transporter: "",
          startTime: "",
          expiryTime: "",
          agreementId: "",
          dataLoading: false,
        };
        if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
          (async () => {
            let redirect = await restrict(PERMS.INDENT_PLANNER_READ);
            // //console.log(redirect, "redirect11");
    
            if (redirect === false) {
              this.props.history.push("/operationsDashboard");
            }
          })();
        }
    }


    handleDuration = (e) => {
        // let value = e.value;
        this.setState({ radioFilter: e }, () => {
          if (this.state.searchOn !== true) this.setState({ searchOn: true });
          this.getAllData();
        });
    };


    getAllData = (e) => {
        this.setState({ dataLoading: true });
        this.props.fetchSecondaryAgreements(
          { offset, q: this.state.searchValue },
          (response) => {
            this.setState({ dataLoading: false });
          }
        );
    };


    searchValueSetter = (e) => {
        this.setState({ searchValue: e.target.value, searchOn: true }, () => {
          if (this.state.searchKey !== "" && this.state.searchValue !== "")
            this.setState({ disabled: false });
          if (this.state.searchValue === "") {
            this.setState({ disabled: true });
          }
        });
    };



    handleKeyDown = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          if (this.state.disabled === false) {
            this.getAllData();
          }
        }
      };



      searchFilter = () => {
        if (this.state.disabled === false) {
          this.getAllData();
        }
      };



      handleRadioChange = (e) => {
        this.setState({ dataLoading: true });
        this.setState({ radioFilter: e, searchOn: !this.state.searchOn }, () => {
          this.props.fetchSecondaryAgreements(
            "",
            (response) => {
              this.setState({ dataLoading: false });
            },
            e.value
          );
        });
      };


      componentDidMount = (e) => {
        this.setState({ dataLoading: true });
        this.props.clearLoadedValues();
        // const { agreements } = this.props;
        this.props.fetchSecondaryAgreements({}, (response) => {
          this.setState({ dataLoading: false });
        });
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
      };


      resize() {
        if (window.innerWidth <= 1500)
          this.setState({ zoomVal: 1 }, () => {
            document.body.style.zoom = this.state.zoomVal;
          });
        else document.body.style.zoom = 1;
      }


      reset = (e) => {
        this.setState(
          { searchValue: "", radioFilter: "", dataLoading: true },
          () => {
            offset = 0;
            this.getAllData({});
          }
        );
      };




      handlePdf = (e) => {
       // console.log(e)
        this.setState(
          {
            showpdf: !this.state.showpdf,
            transporter: e.transporter || "",
            startTime: e.startTime || "",
            expiryTime: e.expiryTime || "",
          },
          () => {
            // console.log("test", this.state.transporter);
          }
        );
      };



      closehandlePdf = () => {
        this.setState({ showpdf: !this.state.showpdf });
      };



      handleChangePage = (event, val) => {
        this.setState({ dataLoading: true });

        offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    
        this.props.fetchSecondaryAgreements(
          {
            offset: (val - 1) * process.env.REACT_APP_PERPAGE,
            q: this.state.searchValue || "",
          },
          (response) => {
            this.setState({ dataLoading: false });
          }
        );
        this.setState({ loading: true });
      };


      openPdf = async (link) => {
        try {
          const url = new URL(link);
          if (!url.protocol || !url.host) {
            throw new Error("Invalid URL");
          }
          const response = await axios({
            url,
            method: "GET",
            responseType: "blob",
            withCredentials: false,
          });
          if (response.status >= 200 && response.status < 300) {
            // console.log("Success", response);
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
          } else {
            // console.log("Error", response);
            throw new Error("Failed to fetch PDF");
          }
        } catch (error) {
          // console.log("Error", error);
          throw new Error("Failed to fetch PDF");
        }
      };



 render(){

    const localStorageData = JSON.parse(localStorage.getItem("agreementData"));
    const { agreements } = this.props;
    const agreementData = navigator.onLine
      ? agreements
      : localStorageData?.data;

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let online = navigator.onLine;

    let Aggreement = [];

    if (Array.isArray(agreementData?.docs)) {
        offset = agreementData?.offset;
        totCnt = agreementData?.length;
        fromVal = totCnt !== 0 ? offset + 1 : fromVal;
        toVal = agreementData?.offset + agreementData.docs?.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
        agreementData.docs.forEach((e) => {
          Aggreement.push({
            transporter:
              (e.transporter?.name ? e.transporter.name : "NA") +
              " - " +
              (e.transporter?.userId ? e.transporter.userId : "NA") +
              " - " +
              (e.transporter?.email ? e.transporter.email : "NA"),
            startTime: moment(e.startDate).format("DD-MMM-YYYY"),
            expiryTime: moment(e.expiryDate).format("DD-MMM-YYYY"),
            id: e._id,
            //subStatus: "",
            actions: "",
            softCopy: e.softCopy,
          });
        });
      }

      if (Aggreement !== undefined) {
        Aggreement.forEach((e) => {
          e.actions = (
            <div className="dropdown">
              <Button className="iconButtons dropbtn actionButtonsDark">
                <img
                  style={{ margin: "-4px" }}
                  src={actionVerticleDots}
                  alt="actions"
                />
              </Button>
              <div
                className="dropdown-content contentDark"
                style={{ display: "block !important" }}
              >


                <div
                  // className="action-button actionDspan"
                  className={
                    online
                      ? "action-button actionDspan"
                      : "action-button-disabled actionDspan"
                  }
                  onClick={() => {
                    this.props.fetchViewAgreement(e?.id);
                    this.handlePdf(e);
                  }}
                >
                  View Agreement
                </div>


                <div
                  className="action-button actionDspan"
                  onClick={() => {
                    this.props.fetchViewAgreement(e.id);
                   
                    this.props.history.push({
                      pathname: "/SecondaryBilling/AddAgreement",
                      state: {
                        agreementId: e.id,
                        transporter: e.transporter,
                        startTime: e.startTime || "",
                        expiryTime: e.expiryTime || "",
                        page: "view details",
                      },
                    });
                  }}
                >
                  View Details
                </div>


                <div
                  className="action-button actionDspan"
                  onClick={() => this.openPdf(e.softCopy.trim())}
                  disabled={e.softCopy ? false : true}
                >
                  Download attachment
                </div>

              </div>
            </div>
          );
        });
      }

    return (

        <React.Fragment>
        {getLocalStorage()}
        
        <SecondaryAgreementPfd
          valid={this.valid}
          validExpiry={this.validExpiry}
          isOpen={this.state.showpdf}
          closePdf={this.closehandlePdf}
          transporter={this.state.transporter}
          startTime={this.state.startTime}
          expiryTime={this.state.expiryTime}
        />
        <div>
          <QuickFilters
            SearchValue={this.state.searchValue}
            searchValueSetter={this.searchValueSetter}
            handleKeyDown={this.handleKeyDown}
            reset={this.reset}
            searchFilter={this.searchFilter}
          />
          <Container fluid className="billing-parent" style={{ maxHeight: "96vh", overflow: "auto" }}>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={HeaderData}
                data={Aggreement}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={Aggreement}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: "#0C0C0C" }}
                className="mb-2 mt-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>

    )
 }



}




const mapStateToProps = (state) => ({
    agreements: state.SecondaryBilling.SecondaryBilling.agreements,
  });
  
  export default connect(mapStateToProps, {
    fetchSecondaryAgreements,
    fetchViewAgreement,
    clearLoadedValues,
  })(SecondaryAgreementIndex);