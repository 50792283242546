let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.masters?.FREIGHT_CODE ?? "Freight Code",
        field: "freightCode",
        sort: "asc",
        width: 100,
      },

      {
        label: userLang?.masters?.COST_MAIN ?? "Cost Main",
        field: "costMain",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.SOURCE ?? "Source",
        field: "source",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.CUSTOMERS ?? "Customers",
        field: "customers",
        sort: "asc",
        width: 200,
      },
      {
        label: userLang?.masters?.TRANSPORTER ?? "Transporter",
        field: "transporter",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.VEHICLE_TYPE ?? "VehicleType",
        field: "vehicleType",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.VALIDITY ?? "Validity",
        field: "validityFromTo",
        sort: "asc",
        width: 100,
      },
    ],
  };

  CustomerHeaderData = {
    columns: [
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 200,
      },

      {
        label: "Customer Number",
        field: "customerNumber",
        sort: "asc",
        width: 100,
      },
      {
        label: "Ship To Code",
        field: "shipToCode",
        sort: "asc",
        width: 100,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 200,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 100,
      },
      {
        label: "Postal Code",
        field: "postalCode",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.masters?.FREIGHT_CODE ?? "Freight Code",
      field: "freightCode",
      sort: "asc",
      width: 100,
    },

    {
      label: userLang?.masters?.COST_MAIN ?? "Cost Main",
      field: "costMain",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.SOURCE ?? "Source",
      field: "source",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.CUSTOMERS ?? "Customers",
      field: "customers",
      sort: "asc",
      width: 200,
    },
    {
      label: userLang?.masters?.TRANSPORTER ?? "Transporter",
      field: "transporter",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.VEHICLE_TYPE ?? "VehicleType",
      field: "vehicleType",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.VALIDITY ?? "Validity",
      field: "validityFromTo",
      sort: "asc",
      width: 100,
    },
  ],
};

export var CustomerHeaderData = {
  columns: [
    {
      label: "Customer Name",
      field: "customerName",
      sort: "asc",
      width: 200,
    },

    {
      label: "Customer Number",
      field: "customerNumber",
      sort: "asc",
      width: 100,
    },
    {
      label: "Ship To Code",
      field: "shipToCode",
      sort: "asc",
      width: 100,
    },
    {
      label: "Address",
      field: "address",
      sort: "asc",
      width: 200,
    },
    {
      label: "City",
      field: "city",
      sort: "asc",
      width: 100,
    },
    {
      label: "Postal Code",
      field: "postalCode",
      sort: "asc",
      width: 100,
    },
    {
      label: "State",
      field: "state",
      sort: "asc",
      width: 100,
    },
  ],
};

export const searchBar = {
  searchBarData:[
    {
      label: userLang?.masters?.FREIGHT_CODE ?? "Freight Code",
      field: "freightCode",
      sort: "asc",
      type: "text",
    },

    {
      label: userLang?.masters?.COST_MAIN ?? "Cost Main",
      field: "costMain",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.SOURCE ?? "Source",
      field: "source",
      sort: "asc",
      type: "select",
    },
    {
      label: userLang?.masters?.CUSTOMERS ?? "Customers",
      field: "customers",
      sort: "asc",
      type: "select",
    },
    {
      label: userLang?.masters?.TRANSPORTER ?? "Transporter",
      field: "transporter",
      sort: "asc",
      type: "select",
    },
    {
      label: userLang?.masters?.VEHICLE_TYPE ?? "VehicleType",
      field: "vehicleType",
      sort: "asc",
      type: "select",
    },
    {
      label: userLang?.masters?.VALIDITY ?? "Validity",
      field: "validityFromTo",
      sort: "asc",
      type: "date",
    },
    {
      label: userLang?.masters?.ACTION ?? "action",
      field: "action",
      sort: "asc",
      type: "date",
    },
  ],
}
