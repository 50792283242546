let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));



export const getLocalStorage = () => {

  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

  AdminHeaderData = {
    columns: [
      {
        label: " ",
        field: "select",
        sort: "disabled",
        width: 200,
      },
      {
        label: userLang?.invoice?.BILL_TYPE,
        field: "bill",
        sort: "disabled",
        width: 200,
      },
      {
        label: userLang?.planning?.INDENT_ID,
        field: "indentId",
        sort: "disabled",
        width: 200,
      },
      {
        label: userLang?.trip?.TRIP_ID,
        field: "tripId",
        sort: "disabled",
        width: 200,
      },
      {
        label: userLang?.invoice?.LR_NO ?? " LR_NO",
        field: "lrNo",
        sort: "disabled",
        width: 200,
      },
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "disabled",
        width: 300,
      },
      {
        label: userLang?.common?.CUSTOMERS,
        field: "customers",
        sort: "disabled",
        width: 300,
      },
      {
        label: userLang?.invoice?.DATE,
        field: "date",
        sort: "disabled",
        width: 200,
      },
      {
        label: userLang?.masters?.REG_NO,
        field: "vehicleRegNo",
        sort: "disabled",
        width: 400,
      },
      {
        label: userLang?.invoice?.TOTAL_COST,
        field: "totalCost",
        sort: "disabled",
        width: 300,
      },

      {
        label: userLang?.invoice?.ACTION,
        field: "actions",
        sort: "disabled",
        width: 200,
      },
    ],
  };
  
};


if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}



export var AdminHeaderData = {
  columns: [
    {
      label: "Select",
      field: "select",
      sort: "disabled",
      width: 100,
    },

    {
      label: userLang?.invoice?.BILL,
      field: "bill",
      sort: "disabled",
      width: 200,
    },
    {
      label: userLang?.trip?.TRIP_ID,
      field: "tripId",
      sort: "disabled",
      width: 200,
    },
    {
      label: userLang?.planning?.INDENT_ID,
      field: "indentId",
      sort: "disabled",
      width: 200,
    },
    {
      label: userLang?.invoice?.DATE,
      field: "date",
      sort: "disabled",
      width: 200,
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "disabled",
      width: 300,
    },
    {
      label: userLang?.common?.CUSTOMERS,
      field: "customers",
      sort: "disabled",
      width: 300,
    },
    {
      label: userLang?.invoice?.TOTAL_COST,
      field: "totalCost",
      sort: "disabled",
      width: 200,
    },
    {
      label: userLang?.invoice?.ACTION,
      field: "actions",
      sort: "disabled",
      width: 100,
    },
  ],
};



export const searchBar = {
  searchBarData: [
    {
      label: userLang?.invoice?.INVOICE_ID,
      field: "invoiceID",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporterName",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.invoice?.DATE,
      field: "date",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.STATE,
      field: "state",
      sort: "asc",
      width: 2,
    },

    {
      label: userLang?.common?.STATUS,
      field: "status",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.TOTAL_COST,
      field: "total",
      sort: "asc",
      width: 3,
    },

    {
      label: userLang?.invoice?.COMMENTS_INVOICE,
      field: "comments",
      sort: "asc",
      width: 4,
    },
  ],
};

