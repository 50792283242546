import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    cursor: "pointer",  
  },
});

export default function DataTable(props) {
  const classes = useStyles();
  const rows = props.data;
  const hidden = props.hidden;

  return (
    <TableContainer className="transporterList" component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.heads.map((h, i) => {
              if (h === "disabled" || h === "inValid") {
                return true;
              } else {
                return (
                  <TableCell
                    className={
                      hidden.includes(h) ? "d-none" : "darkInvoiceModalCharge"
                    }
                    key={h + i}
                  >
                    <b> {h}</b>
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            return (
              <TableRow
                key={i}
                onClick={
                  row["disabled"] || row["inValid"]
                    ? null
                    : () => props.selected(row)
                }
                // style={{ background: "yellow" }}
                className="customDataTable_hover"
              >
                {props.heads.map((h, j) => {
                  return (
                    <TableCell
                      className={
                        hidden.includes(h)
                          ? "d-none"
                          : "darkTransporterModalAgreement"
                      }
                      key={j}
                      component="th"
                      scope="row"
                    >
                      {row[h]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
