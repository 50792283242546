import React, { Component } from "react";
import { Button, Container, Row, Col, Card, CardBody } from "reactstrap";

import { connect } from "react-redux";
import { restrict } from "../../restrict";
import { PERMS } from "../../../enum/perms.enum";
import { fetchInvoiceById } from "../../../store/billing/actions";
import InvoicePdf from "./InvoicePdf";
import { MoonLoader } from "react-spinners";


class GeneratedInvoices extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      breadcrumbItems: [
        { title: "Invoice", link: "#" },
        { title: "Invoice Generation", link: "#" },
      ],
      radioFilter: "",
      searchValue: "",
      searchOn: false,
      FromDate: "",
      ToDate: "",
      raiseinvoiceModal: false,
      showPdf: false,

      isOpen: false,
      invoiceDet: {},
    };
    this.fromRef = React.createRef();
    this.toRef = React.createRef();

    (async () => {
      let redirect = await restrict(PERMS.INDENT_TRANSPORTER_READ);
      // //console.log(redirect, "redirect11");

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      let invId = this.props.history?.location?.state?.invoiceId;
      if (invId) {
        //console.log(invId,"invoiceId")
        this.props.fetchInvoiceById(invId, (res) => {
          // console.log(res);
        });
      }
      this.setState({ loading: false });
    }, 4000);
  };

  handleDuration = (e) => {
    this.setState({ radioFilter: e }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      this.getAllData();
    });
  };

  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };
  handleRadioChange = (e) => {
    this.setState({ radioFilter: e, searchOn: !this.state.searchOn }, () => {
      // this.getAllData();
    });
  };
  validTD = (current) => {
    if (this.state.FromDate !== "") {
      let day = new Date(this.state.FromDate);
      day.setDate(day.getDate() - 1);
      return current.isAfter(day);
    } else {
      return true;
    }
  };
  validFD = (current) => {
    if (this.state.ToDate !== "") {
      let day = new Date(this.state.ToDate);
      day.setDate(day.getDate() + 1);
      return current.isBefore(day);
    } else {
      return true;
    }
  };

  handleChange = (e) => {
    let date = new Date(e.target.value);
    date.setHours(0, 0, 0, 0);
    // //console.log(date,"from,to")
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeRow = (state) => {
    //console.log('state', state);
    // this.setState({ selectedRows: state.selectedRows });
  };
  openRaiseInvoiceModal = () => {
    this.setState({ raiseinvoiceModal: !this.state.raiseinvoiceModal });
  };

  handlePdf = () => {
    ////console.log(AggreementPdf)
    this.setState({ showpdf: !this.state.showpdf });
  };

  
  closeInvoiceModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  invoiceModal = (r) => {
    let destination = r.destination;
    let id = r._id;
    let indentId = r.indentId;
    let invoice = r.invoice;
    let isOpen = r.isOpen;
    let select = r.select;
    let source = r.select;
    let totalCost = r.totalCost;
    let tripId = r.tripId;
    this.props.fetchInvoiceById(id, (res) => {
      // console.log(res);
    });
    this.setState({
      invoiceDet: {
        destination,
        id,
        indentId,
        invoice,
        calculations: r.calculations,
        isOpen,
        select,
        source,
        totalCost,
        tripId,
      },
      transporter: r.transporter,
    });
    this.closeInvoiceModal();
  };
  render() {
    const { loading } = this.state;
    const { invoice } = this.props;

    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    return (
      <React.Fragment>


        <InvoicePdf
          isOpen={this.state.isOpen}
          closePdf={this.closeInvoiceModal}
          invoice={this.props.invoice}
        ></InvoicePdf>


        {/* <div className="page-content"> */}
        <InvoicePdf isOpen={this.state.showpdf} closePdf={this.handlePdf} />


        <Row>

          <Col xs={9} className="d-flex flex-row bd-highlight mb-2 mt-2">
            <div className="invoice-button">
              <Button
                onClick={() => this.props.history.push("/invoiceGeneration")}
                className="btn btn-primary"
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </div>
          </Col>

        </Row>
        
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody className="px-3 py-4">
                  <div>
                    <Row className="justify-content-between d-flex mb-3 ml-1">
                      <div lg={2} md={2} xs={12} className="float-left">
                        <h5
                          className="mt-1"
                          style={{
                            fontWeight: "700",
                            lineHeight: "24px",
                            size: "16px",
                            color: "#252B3B",
                            font: "Poppins",
                          }}
                        >
                          Generated Invoice{" "}
                        </h5>
                      </div>

                    </Row>
                  </div>{" "}
                  <div className="container-fluid">
                    <div className="view-invoice">
                      <Row className="view-row">
                        <Col className="view-col" md="3">
                          <div className="label">Invoice Ref No:</div>
                          <div className="field">{invoice?.invoiceId}</div>
                        </Col>
                        <Col className="view-col" md="3">
                          <div className="label">Invoice Date:</div>
                          <div className="field">
                            {invoice?.createdAt
                              ? invoice.createdAt.split("T")[0]
                              : 0}
                          </div>
                        </Col>
                        <Col className="view-col" md="3">
                          <div className="label">State:</div>
                          <div className="field">
                            {invoice?.state?.stateCode}
                          </div>
                        </Col>
                        <Col className="view-col" md="3">
                          <div className="label">
                            Transporter GST Invoice No:
                          </div>
                          <div className="field">{invoice?.gstNo}</div>
                        </Col>
                      </Row>
                      <Row className="view-row">
                        {/* <Col className="view-col" md="3">
                            <div className="label">
                              Transporter Invoice Date:
                            </div>
                            <div className="field">
                              {invoice?.createdAt
                                ? invoice.createdAt.split("T")[0]
                                : 0}
                            </div>
                          </Col> */}
                        <Col className="view-col" md="3">
                          <div className="label">No Of Items:</div>
                          <div className="field">{invoice?.bills?.length} </div>
                        </Col>
                        <Col className="view-col" md="3">
                          <div className="label">Total Cost:</div>
                          <div className="field">
                            {invoice?.calculations?.netPayble || 0} ₹
                          </div>
                        </Col>
                        <Col className="view-col" md="3">
                          <div className="label">Invoice Status:</div>
                          <div
                            className="field"
                            style={{ textTransform: "capitalize" }}
                          >
                            {invoice?.status}
                          </div>
                        </Col>
                      </Row>

                      {/* <Row className="view-row">
                        <Col className="view-col" md="3">
                          <div className="label">
                            Transporter Invoice Attachment File
                          </div>
                          <div className="field">
                            <Button onClick={this.handlePdf}>View PDF</Button>
                          </div>
                        </Col>
                      </Row> */}
                    </div>
                    <div className="invoice-button">
                      <Button
                        onClick={
                          () => this.invoiceModal(this.props.invoice)
                          // this.props.history.push("/invoiceGeneration")
                        }
                        className="btn btn-primary"
                      >
                        View Details
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoice: state.Billing?.billing?.invoice,
  };
};
export default connect(mapStateToProps, { fetchInvoiceById })(
  GeneratedInvoices
);
