import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { fetchIndents } from "../../store/actions";
import { actions } from "./actionButtons";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import { fetchDrivers, fetchDriversExcel } from "../../store/driver/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";
import {
  subscribeConsent,
  unsubscribeConsent,
} from "../../store/driver/actions";
import Popup from "../../components/Common/Popup";
import DriverForm from "../Forms/DriverForm";
import QuickFilters from "./quckFilters";
import { exportToCSV } from "../common/exportToCsv";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;
// let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
class Driver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exloading: false,
      dataLoading: false,
      export: false,
      breadcrumbItems: [
        { title: "Driver", link: "#" },
        { title: "Driver Details", link: "#" },
      ],
      offset: 0,
      name: "",
      phoneNo: "",
      setOpenPopup: false,
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_READ);

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
    // this.handleInputDebounceName = debounce(this.getDriverNameFunc, 1000);
    // this.handleInputDebouncePhone = debounce(this.getDriverPhoneFunc, 1000);
  }
  // resetName = (e) => {
  //   totCnt = 0;
  //   totPages = 0;
  //   toVal = 0;
  //   fromVal = 0;
  //   offset = 0;
  //   this.setState(
  //     {
  //       name: "",
  //       loading: true,
  //     },

  //     () => {
  //       let offset = 0;
  //       this.setState({ loading: true });
  //       // this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
  //       this.getAllData();
  //     }
  //   );
  // };
  reset = () => {

    offset = 0;
  
    this.setState(
   
      () => {
        // Ensure API call happens after state update
        this.props.fetchDrivers(
          offset, 
          {}, // Pass empty object
          (response) => {
            this.setState({ dataLoading: false });
          }
        );
      }
    );
  };
  
  // resetPhone = (e) => {
  //   totCnt = 0;
  //   totPages = 0;
  //   toVal = 0;
  //   fromVal = 0;
  //   offset = 0;
  //   this.setState(
  //     {
  //       phoneNo: "",
  //       loading: true,
  //     },

  //     () => {
  //       let offset = 0;
  //       this.setState({ loading: true });
  //       this.getAllData();
  //     }
  //   );
  // };
  // getAllData = () => {
    
  //   this.setState({ dataLoading: true }, () => {});
  //   // console.log( "getAllData",this.state.name)
  //   this.props.fetchDrivers(
  //     offset,
  //     this.state.searchFilters,
  //     null,
  //     (response) => {
  //       this.setState({ dataLoading: false });
  //   }
  //   );
  // };
  getAllData = () => {
    this.setState({ dataLoading: true }, () => {
      const searchFilters = { ...this.state.searchFilters }; 
  
      // console.log("Calling fetchDrivers with filters:", searchFilters);
  
      this.props.fetchDrivers(
        offset, 
        searchFilters,  
        (response) => {
          this.setState({ dataLoading: false });
        }
      );
    });
  };
  

  // getFilterData = () => {
  //   this.setState({ dataLoading: true }, () => {});
  //   offset = 0;
  //   this.props.fetchDrivers(
  //     offset,
  //     this.state.name,
  //     this.state.phoneNo,
  //     null,
  //     () => {
  //       this.setState({ dataLoading: false });
  //     }
  //   );
  // };
  // enterCheck = (event) => {
  //   if (event.key === "Enter") {
  //     this.getFilterData();
  //   }
  // };
  // filterList = () => {
  //   this.getFilterData();
  // };
 

  searchValueAssignerFunction = (name, value, type) => {
    this.setState({ dataLoading: true });
    let searchValues = { [name]: value };
  
    this.setState({ searchFilters: searchValues }, () => {
      // localStorage.setItem("filters", JSON.stringify(this.state.searchFilters));
      // console.log(this.state.searchFilters, "-----Filters-");
      
      this.getAllData();  
    });
  };
  


  // handleDriverName = (e) => {
  //   this.setState({ name: e.target.value }, () => {});
  // };
  // handleDriverPhone = (e) => {
  //   if (rx_live.test(e.target.value)) {
  //     this.setState({ phoneNo: e.target.value }, () => {});
  //   }
  // };


 
  componentDidMount = () => {
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };

  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    // console.log(event, val, "handlepage");
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };
  consentUpdate = (ele) => {
    let offset = 0;
    if (!ele.consentProvided) {
      this.props.subscribeConsent(ele.phone, (res) => {
        if (res.success) NotificationManager.success(res["message"], "Success");
        this.setState({ dataLoading: true }, () => {});
        this.props.fetchDrivers(
          offset,
          this.state.name,
          this.state.phoneNo,
          null,
          () => {
            this.setState({ dataLoading: false }, () => {});
          }
        );
      });
    } else {
      this.props.unsubscribeConsent(ele.phone, (res) => {
        this.setState({ dataLoading: true }, () => {});
        if (res.success) NotificationManager.success(res["message"], "Success");
        this.props.fetchDrivers(
          offset,
          this.state.name,
          this.state.phoneNo,
          null,
          () => {
            this.setState({ dataLoading: false }, () => {});
          }
        );
      });
    }
  };
  addDriver = () => {
    this.setState({ setOpenPopup: true });
  };

  // exportExcelData = () => {
  //   this.setState({ dataLoading: true }, () => {});
  //   // Call the action function using await to ensure proper execution
  //   this.props.fetchDriversExcel(
  //     0,
  //     this.state.name,
  //     this.state.phoneNo,
  //     null,
  //     (res) => {
  //       this.setState({ dataLoading: false }, () => {});
  //       // this.setState({ isExportingExcel: false });
  //       // let exdata=[]
  //       let exportData = [];
  //       if (res?.data?.docs?.length > 0) {
  //         res.data.docs.map((ele) => {
  //           // console.log("ele", ele);
  //           exportData.push({
  //             "Full Name": ele?.name ? ele?.name : "N/A",
  //             "Phone": ele?.phone ? ele?.phone : "N/A",
  //             "License Number": ele.licence?.number ? ele.licence?.number : "N/A",
  //             "Transporter": ele?.transporter?.name
  //               ? ele?.transporter?.name
  //               : "N/A",
  //             // "Transporter Vendor": ele?.transporter ? ele?.transporter : "N/A",
  //             "Consent Status":ele?.driverConsentStatusFromServiceProvider ? (
  //               ele?.driverConsentStatusFromServiceProvider
  //             ) : ele?.driverConsentStatusFromCargoExchange?.length ? 
  //               ele?.driverConsentStatusFromCargoExchange :"PROCESS NOT INITIATED"
  //           });
  //         });
  //       }
  //       // console.log("export Data", exportData);
  //       if (exportData?.length > 0) {
  //         exportToCSV([...exportData], "Driver");
  //       } else {
  //         exportData.push({
  //           "Full Name": "",
  //           "Phone": "",
  //           "LicenseNumber": "",
  //           "Transporter": "",
  //           "Consent Provided": "",
  //         });
  //         exportToCSV([...exportData], "Driver");
  //       }
  //     }
  //   );
  // };

  exportExcelData = () => {
    this.setState({  dataLoading: true });
    this.setState({ exloading: true, export: true }); 

    this.props.fetchDriversExcel(0, this.state.name, this.state.phoneNo, null, (res) => {
      this.setState({ dataLoading: false });
  
      let exportData = [];
      if (Array.isArray(res?.data?.docs) && res.data.docs.length > 0) {
        exportData = res.data.docs.map((ele) => ({
          "Full Name": ele?.name || "N/A",
          "Phone": ele?.phone || "N/A",
          "License Number": ele?.licence?.number || "N/A",
          "Transporter": ele?.transporter?.name || "N/A",
          "Consent Status": ele?.driverConsentStatusFromServiceProvider || 
                            (ele?.driverConsentStatusFromCargoExchange?.length ? ele.driverConsentStatusFromCargoExchange : "PROCESS NOT INITIATED")
        }));
      }
  
      if (exportData.length === 0) {
        exportData.push({
          "Full Name": "",
          "Phone": "",
          "License Number": "",
          "Transporter": "",
          "Consent Status": "",
        });
      }
      exportToCSV(exportData, "Driver");
      // this.exportToCSV(exportData, "Driver");
  
      // Ensure loading and export states are reset after export
      this.setState({ exloading: false, export: false }); 
    });
  };

  
  
  render() {
    // console.log(this.state.dataLoading, "lloading");
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { drivers } = this.props;
    const localStorageData = JSON.parse(
      localStorage.getItem("driversListData")
    );
    
    const driverData = navigator.onLine
      ? drivers?.drivers?.data?.docs
      : localStorageData?.data?.docs;
    let data = [];
    // console.log("data",data)
    if (Array.isArray(driverData)) {
      // console.log("drivers : ", this.props.drivers.drivers.data.docs);
      totCnt = navigator.onLine
        ? drivers?.drivers?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? drivers?.drivers?.data?.offset + drivers?.drivers?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      driverData.forEach((ele) => {
        data.push({
          fullName: 
          // ele?.name ? ele?.name : "N/A",
          (
            <p className=" tw-truncate comn140 tw-p-0 tw-m-0" title={ele.name ? `${ele.name}` : ""} >
              {ele.name ? `${ele.name}` : "-----"}
            </p>
          ) || "-----",
          phone: ele?.phone ? ele?.phone : "N/A",
          driverLicenseNumber: ele.licence?.number
            ? ele.licence?.number
            : "N/A",
          transporter: ele?.transporter?.name ? ele?.transporter?.name : "N/A",
          transporterVendor: ele?.transporter ? ele?.transporter : "N/A",
          consentProvided: ele?.driverConsentStatusFromServiceProvider ? (
            ele?.driverConsentStatusFromServiceProvider
          ) : ele?.driverConsentStatusFromCargoExchange?.length ? (
            (ele?.driverConsentStatusFromCargoExchange === "ALLOWED" && (
              <span
                style={{
                  color: "green",
                }}
              >
                ALLOWED
              </span>
            )) ||
            (ele?.driverConsentStatusFromCargoExchange === "PENDING" && (
              <span
                style={{
                  color: "blue",
                }}
              >
                PENDING
              </span>
            )) ||
            (ele?.driverConsentStatusFromCargoExchange ===
              "PROCESS INITIATED" && (
              <span
                style={{
                  color: "blue",
                }}
              >
                PROCESS INITIATED
              </span>
            ))
          ) : (
            <span
              style={{
                color: "red",
              }}
            >
              PROCESS NOT INITIATED
            </span>
          ),
          consentUpdate: actions(ele, {
            consentUpdate: this.consentUpdate,
          }),
        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;


    console.log("this.state.exloading------",this.state.exloading);
    return (
      <React.Fragment>
        <>
          {getLocalStorage()}
          {/* <QuickFilters
            toggleExcel={this.exportExcelData}
            addDriverFunc={this.addDriver}
            reset={this.reset}
            handleDriverName={this.handleDriverName}
            name={this.state.name}
            // resetName={this.resetName}
            handleDriverPhone={this.handleDriverPhone}
            phoneNo={this.state.phoneNo}
            // resetPhone={this.resetPhone}
            enterCheck={this.enterCheck}
            filterList={this.filterList}
            mode={darkTheme}
          /> */}
          <Container fluid 
              style={{
                // maxHeight: "115vh",
                overflowX: "hidden", paddingLeft: "0px", paddingRight: "0px"
              }}
          >
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={data}
                searchable={true}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={drivers?.drivers?.data?.length}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
                hidesearchBar={true}
                toggleExcel={this.exportExcelData}
                exportLoading={this.state.exloading} 
                reset={this.reset}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={data}
                searchable={true}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={drivers?.drivers?.data?.length}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
                hidesearchBar={true}
                toggleExcel={this.exportExcelData}
                exportLoading={this.state.exloading} 
                reset={this.reset}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
                className="mb-2 mt-2 paginationRow"
              >
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={5}
                  md={5}
                >
                  {" "}
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
                <Col xs={7} sm={7} md={7} className="span-col-pagenation">
                  <div className="float-right">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                
              </Row>
            ) : null}
          </Container>
          <Popup
            title={userLang?.masters?.ADD_DRIVER}
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
            mode={darkTheme}
          >
            <DriverForm handleSubmit={this.handleSubmit} mode={darkTheme} />
          </Popup>
        </>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  drivers: state.Driver.drivers,
  fetchId: state.Driver.drivers.fetchId,
  policies: state.AccessPolicy.accessPolicies.policies,
  user: state.Login.user,
});

export default connect(mapStateToProps, {
  fetchIndents,
  fetchDrivers,
  fetchDriversExcel,
  subscribeConsent,
  unsubscribeConsent,
})(Driver);
