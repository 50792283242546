import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi, postApi } from "../../helpers/auth_helper";

function* workfetchSourceSecondary({ callback }) {
  try {
    // Try to call the API
    let uri = `${process.env.REACT_APP_SERVERURL}/hop/sourceList_secondary_freight`;

    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_SOURCE_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}

function* workfetchVehicleTypeSecondary({ callback }) {
  try {
    // Try to call the API
    let uri = process.env.REACT_APP_SERVERURL + `/vehicleType/vehicleTypes`;

    const response = yield call(getApi, uri);
    //console.log('FETCH_VEHICLES',response);
    //   Dispatch the action to the reducers
    callback(response);
    console.log(response);

    yield put({
      type: types.FETCH_VEHICLES_TYPE_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicleType.", error);
  }
}

function* workfetchTransporterSecondary({ source, vehicleType, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/transporter/secondary_freight_transporters?source=` +
      source +
      `&vehicleType=` +
      vehicleType;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRANSPORTER_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchCustomersSecondary({
  source,
  transporter,
  freightCode,
  vehicleType,
  callback,
  filters={},
}) {
  let filterString =
    `source=` +
    source +
    `&transporter=` +
    transporter +
    `&freightCode=` +
    freightCode +
    `&vehicleType=` +
    vehicleType;

  // let SecondaryCustomersFilters = {};
  // console.log('SecondaryCustomersFilters----',filters)
  // if (localStorage.getItem("SecondaryCustomersFilters")) {
  //   SecondaryCustomersFilters = JSON.parse(
  //     localStorage.getItem("SecondaryCustomersFilters")
  //   );
  // }

  // for (const [key, value] of Object.entries(SecondaryCustomersFilters)) {
  //   let filterKey = key;
  //   let filterValue = value;
  //   filterString = filterString + "&" + filterKey + "=" + filterValue;
  // }
  const finalFilters = {...filters };

  for (const [key, value] of Object.entries(finalFilters)) {
    filterString = `${filterString}&${key}=${value}`;
  }

  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/hop/customerList_secondary_freight?` +
      filterString;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.FETCH_CUSTOMERS_SECONDARY_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch customers.", error);
  }
}

function* workCreateIndentSecondary({ values, callback }) {
  try {
    // console.log("values", values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/indent/createSecondaryFreightIndent`;
    const response = yield call(postApi, uri, values);
    // console.log("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_INDENT_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create indent.", error);
  }
}

function* workCostDisplayFormSecondary({ values, callback }) {
  try {
    // console.log("values", values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/displayCostSecondaryFreightIndent`;
    const response = yield call(postApi, uri, values);
    // console.log("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.COST_DISPLAY_FORM_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not display cost.", error);
  }
}

function* workSetCustomerDropdown({ index }) {
  yield put({
    type: types.SET_CUSTOMER_DROPDOWN_SUCCESS,
    payload: index,
  });
}

function* workSetCustomerDropdownFalse() {
  yield put({
    type: types.SET_CUSTOMER_DROPDOWN_FALSE_SUCCESS,
  });
}

function* workSetAssignedCustomerDropdown({ index }) {
  console.log(index);
  yield put({
    type: types.SET_ASSIGNED_CUSTOMER_DROPDOWN_SUCCESS,
    payload: index,
  });
}

function* workSetAssignedCustomerDropdownFalse() {
  yield put({
    type: types.SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE_SUCCESS,
  });
}

function* workfetchIndentsSecondary({ offset, filters, callback }) {
  try {
    let filterString = "";
   

    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;

      if (filterKey === "startTime" && Array.isArray(filterValue)) {
        filterString += `&startTimeStart=${filterValue[0]}`;
      } 
      else if (filterKey === "expiryTime" && Array.isArray(filterValue)) {
        filterString += `&expiryTimeStart=${filterValue[0]}`;
      } 
      else if (filterKey !== "startTime" && filterKey !== "expiryTime") {
        filterString += `&${filterKey}=${filterValue}`;
      }
    }
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/created/fetch_secondaryIndentList?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    const response = yield call(getApi, uri);
    // callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_INDENTS_SECONDARY_SUCCESS,
      payload: response,
    });
    callback(response);
    localStorage.setItem("SecondaryIndentData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchCustomersOptionsSecondary({ callback }) {
  try {
    // Try to call the API
    let uri = `${process.env.REACT_APP_SERVERURL}/customer/fetch_secondary_freight_customers`;

    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_CUSTOMERS_OPTIONS_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch customers options.", error);
  }
}

function* workfetchCustomerMasterSecondary({
  filters,
  searchFilter,
  callback,
}) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : process.env.REACT_APP_PERPAGE;
  console.log(offset);
  console.log(limit);
  let filterString = "";
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }
  try {
    // Try to call the API
    let uri =
      `${process.env.REACT_APP_SERVERURL}/customer/fetch_secondary_freight_customers?offset=` +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_CUSTOMERS_MASTER_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch customers list.", error);
  }
}

function* workfetchVehicletypeOptionsSecondary({ callback }) {
  try {
    // Try to call the API
    let uri = `${process.env.REACT_APP_SERVERURL}/vehicleType/vehicleTypes`;

    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_VEHICLETYPE_OPTIONS_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle type options.", error);
  }
}

function* workfetchTransporterOptionsSecondary({ callback }) {
  try {
    // Try to call the API
    let uri = `${process.env.REACT_APP_SERVERURL}/transporter/search`;

    const response = yield call(getApi, uri);

    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_CUSTOMERS_OPTIONS_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch transporter options.", error);
  }
}

function* workfetchAssignedIndentsSecondary({ offset, filters, callback }) {
  try {
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else if (filterKey === "expiryTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "expiryTimeStart=" +
          filterValue[0] +
          "&expiryTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      // console.log(filterString, "FILETR-SAGA");
    }
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/created/fetch_secondaryIndentList?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    console.log(uri);
    const response = yield call(getApi, uri);
    console.log(response, "here saga indent");
    // callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ASSIGNED_INDENTS_SECONDARY_SUCCESS,
      payload: response,
    });
    callback(response);
    localStorage.setItem("secondaryIndentAssignData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workSecondaryIndentStatusAccept({ value, callback }) {
  try {
    // console.log(value,"value")
    // return false;
    const uri =
      process.env.REACT_APP_SERVERURL + `/indent/acceptIndentSecondaryFreight`;
    const response = yield call(postApi, uri, value);
    const modresponse = { response: response, indentID: value.indentID };
    callback(Response);
    yield put({
      type: types.SECONDARY_INDENT_STATUS_ACCEPT_SUCCESS,
      payload: modresponse,
    });
  } catch (error) {
    console.log("Request failed! Could not accept Indent.", error);
  }
}

function* workSecondaryIndentStatusReject({ values, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/indent/rejectIndentSecondaryFreight`;
    // console.log(values);
    const response = yield call(postApi, uri, values);

    callback(response);
    yield put({
      type: types.SECONDARY_INDENT_STATUS_REJECT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log("Request failed! Could not reject Indent.", error);
  }
}

function* workfetchFreightMasterSecondary({ filters, searchFilter, callback }) {
  // console.log("filters", filters);
  // console.log("searchFilter", searchFilter);
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : process.env.REACT_APP_PERPAGE;
  let filterString = "";
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/freight/secondary_freights?offset=` +
      offset +
      "&limit=" +
      limit +
      filterString;
    const response = yield call(getApi, uri);
    console.log(uri);
    callback(response);
    // console.log(response);
    yield put({
      type: types.FREIGHTS_MASTER_SECONDARY_SUCCESS,
      payload: response,
    });
    localStorage.setItem(
      "fetchFreighMasterSecondary",
      JSON.stringify(response)
    );
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchVehiclePlacementDetailReportSecondary({ values, callback }) {
  // console.log("values",values)
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit : process.env.REACT_APP_PERPAGE;
  let transpoterId = values?.transpoterId ? values.transpoterId : "";

  let sourceHop = values?.sourceHop ? values?.sourceHop : "";
  let customerId = values?.customerId ? values?.customerId : "";

  let startDate = values?.startDate ? values?.startDate : "";
  let endDate = values?.endDate ? values?.endDate : "";

  let isExport = values?.isExport ? values?.isExport : false;

  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/fetch/placementAnalysisReportSecondaryFreight?offset=` +
      offset +
      `&limit=` +
      Number(limit) +
      `&transporterId=` +
      transpoterId +
      `&sourceHop=` +
      sourceHop +
      `&customerId=` +
      customerId +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate;
    const response = yield call(getApi, uri);
    // console.log("placementAnalysisReport",response);
    callback(response);
    yield put({
      type: types.VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY_SUCCESS,
      payload: response,
    });
    localStorage.setItem(
      "VehicleplacementDetailDataSecondary",
      JSON.stringify(response)
    );
    // console.log("new_Api",JSON.stringify(response))
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}
function* workfetchVehiclePlacementDetailReportForExcelSecondary({
  values,
  callback,
}) {
  // console.log("values",values)
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit : process.env.REACT_APP_PERPAGE;
  let transpoterId = values?.transpoterId ? values.transpoterId : "";

  let sourceHop = values?.sourceHop ? values?.sourceHop : "";
  let destinationHop = values?.destinationHop ? values?.destinationHop : "";

  let startDate = values?.startDate ? values?.startDate : "";
  let endDate = values?.endDate ? values?.endDate : "";

  let isExport = values?.isExport ? values?.isExport : false;

  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/fetch/placementAnalysisReportSecondaryFreight?offset=` +
      offset +
      `&limit=` +
      Number(limit) +
      `&transporterId=` +
      transpoterId +
      `&sourceHop=` +
      sourceHop +
      `&destinationHop=` +
      destinationHop +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate +
      `&isExport=` +
      isExport;
    const response = yield call(getApi, uri);
    // console.log("placementAnalysisReport",response);
    callback(response);
    yield put({
      type: types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY_SUCCESS,
      payload: response,
    });
    localStorage.setItem(
      "VehicleplacementDetailDataSecondary",
      JSON.stringify(response)
    );
    // console.log("new_Api",JSON.stringify(response))
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}

function* workCancelTripSecondary({ values, callback }) {
  // console.log("CANCEl---", values);
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/cancelTripSecondary`;
    const response = yield call(postApi, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CANCEL_TRIP_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not cancel trip.", error);
  }
}

export function* watchFetchSourceSecondary() {
  yield takeEvery(types.FETCH_SOURCE_SECONDARY, workfetchSourceSecondary);
}

export function* watchfetchVehicleTypeSecondary() {
  yield takeEvery(
    types.FETCH_VEHICLES_TYPE_SECONDARY,
    workfetchVehicleTypeSecondary
  );
}

export function* watchFetchTransporterSecondary() {
  yield takeEvery(
    types.FETCH_TRANSPORTER_SECONDARY,
    workfetchTransporterSecondary
  );
}

export function* watchFetchCustomersSecondary() {
  yield takeEvery(types.FETCH_CUSTOMERS_SECONDARY, workfetchCustomersSecondary);
}

export function* watchCreateIndentSecondary() {
  yield takeEvery(types.CREATE_INDENT_SECONDARY, workCreateIndentSecondary);
}

export function* watchCostDisplayFormSecondary() {
  yield takeEvery(
    types.COST_DISPLAY_FORM_SECONDARY,
    workCostDisplayFormSecondary
  );
}

export function* watchfetchIndentsSecondary() {
  yield takeEvery(types.FETCH_INDENTS_SECONDARY, workfetchIndentsSecondary);
}

export function* watchSetCustomerDropdown() {
  yield takeEvery(types.SET_CUSTOMER_DROPDOWN, workSetCustomerDropdown);
}

export function* watchSetCustomerDropdownFalse() {
  yield takeEvery(
    types.SET_CUSTOMER_DROPDOWN_FALSE,
    workSetCustomerDropdownFalse
  );
}

export function* watchSetAssignedCustomerDropdown() {
  yield takeEvery(
    types.SET_ASSIGNED_CUSTOMER_DROPDOWN,
    workSetAssignedCustomerDropdown
  );
}

export function* watchSetAssignedCustomerDropdownFalse() {
  yield takeEvery(
    types.SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE,
    workSetAssignedCustomerDropdownFalse
  );
}
export function* watchFetchCustomersOptionsSecondary() {
  yield takeEvery(
    types.FETCH_CUSTOMERS_OPTIONS_SECONDARY,
    workfetchCustomersOptionsSecondary
  );
}

export function* watchFetchCustomerMasterSecondary() {
  yield takeEvery(
    types.FETCH_CUSTOMERS_MASTER_SECONDARY,
    workfetchCustomerMasterSecondary
  );
}

export function* watchFetchVehicletypeOptionsSecondary() {
  yield takeEvery(
    types.FETCH_VEHICLETYPE_OPTIONS_SECONDARY,
    workfetchVehicletypeOptionsSecondary
  );
}

export function* watchFetchTransporterOptionsSecondary() {
  yield takeEvery(
    types.FETCH_TRANSPORTER_OPTIONS_SECONDARY,
    workfetchTransporterOptionsSecondary
  );
}

export function* watchfetchAssignedIndentsSecondary() {
  yield takeEvery(
    types.FETCH_ASSIGNED_INDENTS_SECONDARY,
    workfetchAssignedIndentsSecondary
  );
}

export function* watchSecondaryIndentStatusAccept() {
  yield takeEvery(
    types.SECONDARY_INDENT_STATUS_ACCEPT,
    workSecondaryIndentStatusAccept
  );
}
export function* watchSecondaryIndentStatusReject() {
  yield takeEvery(
    types.SECONDARY_INDENT_STATUS_REJECT,
    workSecondaryIndentStatusReject
  );
}

export function* watchfetchFreightMasterSecondary() {
  yield takeEvery(
    types.FREIGHTS_MASTER_SECONDARY,
    workfetchFreightMasterSecondary
  );
}

export function* watchfetchVehiclePlacementDetailReportSecondary() {
  yield takeEvery(
    types.VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY,
    workfetchVehiclePlacementDetailReportSecondary
  );
}
export function* watchfetchVehiclePlacementDetailReportForExcelSecondary() {
  yield takeEvery(
    types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY,
    workfetchVehiclePlacementDetailReportForExcelSecondary
  );
}

export function* watchCancelTripSecondary() {
  yield takeEvery(types.CANCEL_TRIP_SECONDARY, workCancelTripSecondary);
}

function* SecondarySaga() {
  yield all([
    fork(watchFetchSourceSecondary),
    fork(watchfetchVehicleTypeSecondary),
    fork(watchFetchTransporterSecondary),
    fork(watchFetchCustomersSecondary),
    fork(watchCreateIndentSecondary),
    fork(watchCostDisplayFormSecondary),
    fork(watchfetchIndentsSecondary),
    fork(watchSetCustomerDropdown),
    fork(watchFetchCustomersOptionsSecondary),
    fork(watchFetchVehicletypeOptionsSecondary),
    fork(watchFetchTransporterOptionsSecondary),
    fork(watchfetchAssignedIndentsSecondary),
    fork(watchSecondaryIndentStatusAccept),
    fork(watchSecondaryIndentStatusReject),
    fork(watchSetAssignedCustomerDropdown),
    fork(watchSetAssignedCustomerDropdownFalse),
    fork(watchSetCustomerDropdownFalse),
    fork(watchfetchFreightMasterSecondary),
    fork(watchFetchCustomerMasterSecondary),
    fork(watchfetchVehiclePlacementDetailReportSecondary),
    fork(watchfetchVehiclePlacementDetailReportForExcelSecondary),
    fork(watchCancelTripSecondary),
  ]);
}

export default SecondarySaga;
