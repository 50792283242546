import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from ".././restrict";
import { PERMS } from "../../enum/perms.enum";
import { freightsMaster } from "../../store/freightsMaster/actions";
import { fetchRoutesForMaster } from "../../store/route/actions";
import { fetchHops } from "../../store/hop/actions";
import { fetchVehicleTypesFilter } from "../../store/vehicleType/actions";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import Index from "../../components/Common/DataTableNeo/Index";

import moment from "moment";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickFilters from "./quckFilters";
// var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};

class FreightMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      FromDate: "",
      ToDate: "",
      // searchFilters: {
      //   freightCode: "",
      //   costMain: "",
      //   costPerPick: "",
      //   costPerDrop: "",
      //   source: "",
      //   destination: "",
      //   route: "",
      //   transporter: "",
      //   vehicleType: "",
      //   validityStartDate: "",
      //   validityEndDate: "",
      // },
      sourceDestinationHops: "",
      transporterOption: "",
      vehicleTypeOption: "",
      routeCodeOption: "",
      source: "",
      destination: "",
      transporter: "",
      vehicleType: "",
      route: "",
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);
        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }

  componentDidMount = () => {
    offset = 0;
    this.getAllRoute();
    this.getAllData();
    this.getAllTransporter();
    this.getAllsourceDest();
    this.getAllVehicleType();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }
  // handleChange = (dateValues) => {
  //   searchValues["validityStartDate"] =
  //     dateValues[0] !== null
  //       ? dateValues[0]
  //         ? moment(dateValues[0]).format("MM-DD-YYYY")
  //         : moment(dateValues).format("MM-DD-YYYY")
  //       : null;
  //   searchValues["validityEndDate"] =
  //     dateValues[1] !== null
  //       ? moment(dateValues[1]).format("MM-DD-YYYY")
  //       : null;
  //   this.setState({ searchFilters: searchValues }, () => {
  //     offset = 0;
  //     this.getAllData();
  //   });
  // };
  // handleSearchInput = (e, data) => {
  //   searchValues[e?.target?.name] = e?.target?.value;
  //   this.setState({ searchFilters: searchValues }, () => { });
  // };
  // handleSelectGroup = (e, name) => {
  //   searchValues[`${name}`] = e?.value?.id;
  //   this.setState({ searchFilters: searchValues, [`${name}`]: e }, () => {
  //     offset = 0;
  //     this.getAllData();
  //   });
  // };
searchValueAssignerFunction = (name, value, type) => {
  console.log("Fright data--------", name," -- ", value," -- ",type)
  this.setState({ dataLoading: true });

  this.setState((prevState) => {
    let searchValues = { ...prevState.searchFilters };

    if (type === "date") {
      searchValues[name] = value; // Directly assign formatted date
    } else {
      searchValues[name] = typeof value === "object" ? value.id : value;
    }

    return { searchFilters: searchValues };
  }, () => {
    this.getAllData(); // Fetch updated data
  });
};


  getAllData = () => {
    this.setState({ dataLoading: true });
    this.props.freightsMaster(
      { offset: offset, limit: 30 },
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false }, () => {
          this.getAllVehicleType();
        });
      }
    );
  };

 
  
  getAllRoute = (q = "") => {
    // console.log("routeOptArr============");
    // offset = 0;
    // this.setState({ dataLoading: true });
    this.props.fetchRoutesForMaster({ offset: offset},{ routeCode: q }, (res) => {
      this.setState({ dataLoading: false, freightMaster: res }, () => {
        if (this.state.freightMaster) {
          let routeOptArr = [];
          let routeCodeData = this.state.freightMaster?.data?.docs;
          if (routeCodeData?.length > 0) {
            let routeCode = routeCodeData.map((res) => {
              routeOptArr.push({
                value: {
                  id: res?._id,
                },
                label: `${res?.name}(${res?.routeCode})`,
              });
            });
            // console.log("routeOptArr============",routeOptArr);
            // this.setState({ routeCodeOption: routeOptArr });
            this.setState({ routeCodeOption: routeOptArr }, () => {
              searchBar.searchBarData.forEach((obj) => {
                if (obj.field === "route") {
                  obj.options = this.state.routeCodeOption; 
                }
              });
            });
          }
        }
      });
    });   
  };
  getAllsourceDest = (q = "") => {
    this.props.fetchHops(
      {
        offset: offset,
        limit: 10,
        label: q,
      },
      (res) => {
        this.hops = [];
        if (res?.data?.docs?.length > 0 && res?.data?.docs?.length <= 10) {
          // this.hops = [this.initialVal("Location")];
          res.data.docs.forEach((ele, index) => {
            this.hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: {
                name:
                  ele.address.addressLine1 +
                  ele.address.city +
                  ele.address.state,
                hopCode: ele.hopCode,
                id: ele._id,
              },
            });
          });
          // this.setState({ sourceDestinationHops: this.hops }, () => { });
          this.setState({ sourceDestinationHops: this.hops }, () => {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "destination") {
                obj.options = this.state.sourceDestinationHops; 
              }
            });
          });
          this.setState({ sourceDestinationHops: this.hops }, () => {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source") {
                obj.options = this.state.sourceDestinationHops; 
              }
            });
          });
        }
      }
    );
  };
  getAllTransporter = (q = "") => {
    offset = 0;
    this.props.fetchAllTransporterSearch(offset, 10, q, (res) => {
      let transporter = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          transporter.push({
            value: {
              id: ele._id,
            },
            label: ele.name ? ele.name : "",
          });
        });
      }

      // this.setState({ transporterOption: transporter }, () => { });
      this.setState({ transporterOption: transporter }, () => {
        searchBar.searchBarData.forEach((obj) => {
          if (obj.field === "transporter") {
            obj.options = this.state.transporterOption; 
          }
        });
      });
      });
  };

  getAllVehicleType = (label = "") => {
    let hehe = this.props?.fetchVehicleTypesFilter(0, { label }, (data) => {
      data = data?.data?.docs;
      let options = [];
      while (data?.length > 0) {
        let singleEntry = data?.shift();
        options.push({
          label: singleEntry?.label,
          value: { vehicleName: singleEntry.label, id: singleEntry._id },
          vehicleTypeCode: singleEntry?.vehicleTypeCode,
        });
      }
      // this.setState({ vehicleTypeOption: options });
      this.setState({ vehicleTypeOption: options }, () => {
        searchBar.searchBarData.forEach((obj) => {
          if (obj.field === "vehicleType") {
            obj.options = this.state.vehicleTypeOption; 
          }
        });
      });
      });
  };

  enterCheck = (event) => {
    if (event.key === "Enter") {
      offset = 0;
      this.getAllData();
    }
  };
  componentDidUpdate(prevProps) { }
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };


  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  reset = (e) => {
    offset = 0;
    searchValues = {};
    // this.setState(
    //   {
    //     searchFilters: {
    //       freightCode: "",
    //       costMain: "",
    //       costPerPick: "",
    //       costPerDrop: "",
    //       source: "",
    //       destination: "",
    //       route: "",
    //       transporter: "",
    //       vehicleType: "",
    //       validityStartDate: "",
    //       validityEndDate: "",
    //     },
    //     source: "",
    //     destination: "",
    //     transporter: "",
    //     vehicleType: "",
    //     route: "",
    //   },
    //   () => {
    //     this.getAllData();
    //   }
    // );
    this.setState({ dataLoading: true });
    this.props.freightsMaster(
      offset,
      searchValues,
      (res) => {
        this.setState({ dataLoading: false }, () => {
          this.getAllVehicleType();
        });
      }
    );
  };

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  
  render() {
    //  console.log('this.state.transporterOption;',this.state.transporterOption);
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkTheme = mode === "dark__mode" ? true : false;

    let rows = [];
    let freightsMasterList = this.props.freightsMasterList?.data;

    if (Array.isArray(freightsMasterList?.docs)) {
      totCnt = freightsMasterList?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = offset + freightsMasterList?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

      if (
        freightsMasterList !== undefined &&
        freightsMasterList?.docs?.length > 0
      ) {
        freightsMasterList.docs.forEach((ele) => {
          if (ele) {
            rows.push({
              freightCode: ele?.freightCode ? ele?.freightCode : "-----",
              costMain: ele?.cost ? ele?.cost?.main : "-----",
              costperPick: ele?.cost?.perPick ? ele?.cost?.perPick : "-----",
              costperDrop: ele?.cost?.perDrop ? ele?.cost?.perDrop : "-----",
              source:
                //  ele?.source?.label ? ele?.source?.label : "-----",
                (
                  <p className=" tw-truncate comn180 tw-p-0 tw-m-0" title={ele?.source?.label ? `${ele?.source?.label}` : ""} >
                    {ele?.source?.label ? `${ele?.source?.label}` : "-----"}
                  </p>
                ) || "-----",
              destination:
                // ele?.destination?.label? ele?.destination?.label: "-----",
                (
                  <p className=" tw-truncate comn180 tw-p-0 tw-m-0" title={ele?.destination?.label ? `${ele?.destination?.label}` : ""} >
                    {ele?.destination?.label ? `${ele?.destination?.label}` : "-----"}
                  </p>
                ) || "-----",
              vehicleType: ele?.vehicleType?.vehicleTypeCode
                ? ele?.vehicleType?.vehicleTypeCode
                : "-----",
              route: ele?.route?.name ? (
                <div>
                  {ele?.route?.name} (
                  <strong>
                    {ele?.route?.routeCode ? ele?.route?.routeCode : "-----"}
                  </strong>
                  )
                </div>
              ) : (
                <span>-----</span>
              ),
              transporter:
                // ele?.transporter && ele?.transporter !== null
                //   ? ele?.transporter?.name : "-----",
                (
                  <p className="tw-truncate comn200 tw-p-0 tw-m-0" title={ele?.transporter ? `${ele?.transporter?.name}` : ""}>
                    {
                      ele?.transporter && ele?.transporter !== null
                  ? ele?.transporter?.name : "-----"
                    }
                  </p>
                ) || "-----",
                
              validityFromTo: ele?.validity?.from ? (
                <div className="tw-flex tw-gap-2">
                  <div>{moment(ele?.validity?.from).format("DD-MMM-YYYY")}</div>
                  <div>{moment(ele?.validity?.to).format("DD-MMM-YYYY")}</div>
                </div>
              ) : (
                "-----"
              ),
            });
          }
        });
      }
    }

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    // console.log("searchBar----++++++_____",searchBar)
    return (
      <React.Fragment>
        <div /* className="page-content" */>
          {getLocalStorage()}
          {/* <QuickFilters
            reset={this.reset}
            onInputChange={this.handleSearchInput}
            handleSelectGroup={this.handleSelectGroup}
            selectectedSource={this.state.source}
            // selectectedDestination={this.state.destination}
            // selectectedTransporter={this.state.transporter}
            // selectectedVehicleType={this.state.vehicleType}
            selectectedRoute={this.state.route}
            filters={this.state.searchFilters}
            handleSubmitChange={this.handleSubmitChange}
            enterCheck={this.enterCheck}
            filterList={this.getAllData}  
            mode={darkTheme}
            sourceDest={this.state.sourceDestinationHops}
            allTransporterOption={this.state.transporterOption}
            vehicleTypeOption={this.state.vehicleTypeOption}
            routeOption={this.state.routeCodeOption}
            handleChange={this.handleChange}
            filtersHops={this.getAllsourceDest}
            getVehicleTypes={this.getAllVehicleType}
            getAllTransporter={this.getAllTransporter}
            getAllRoute={this.getAllRoute}
          /> */}

          <Container fluid 
             style={{
              // maxHeight: "115vh",
              overflowX: "hidden", paddingLeft: "0px", paddingRight: "0px"
            }}
          >
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                hidesearchBar={true}
                searchable={true}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}

              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                hidesearchBar={true}
                searchable={true}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}

              />
            )}
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 mt-2 paginationRow"
              >
                 <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={5}
                  md={5}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
                <Col xs={7} sm={7} md={7} className="span-col-pagenation">
                  <div className="float-right">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
               
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  freightsMasterList: state?.FreightsMaster?.FreightsMaster?.FreightsMasterData,
  fetchId: state?.FreightsMaster?.FreightsMaster?.fetchId,
});

export default connect(mapStateToProps, {
  freightsMaster,
  fetchRoutesForMaster,
  fetchHops,
  fetchVehicleTypesFilter,
  fetchAllTransporterSearch,
})(FreightMaster);
