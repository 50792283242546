let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: "Region",
        field: "region",
        sort: "asc",
        // width: 2,
        width: 60,
      },
      {
        label: "Stn no",
        field: "shipmentNumbers",
        sort: "asc",
        // width: 1,
        width: 60,
      },
      {
        label: "Orgn Code",
        field: "sourceOrganisationCode",
        sort: "asc",
        // width: 2,
        width: 60,
      },
      {
        label: " D Orgn Code",
        field: "destinationOrganisationCode",
        sort: "asc",
        // width: 2,
        width: 60,
      },
      {
        label: "Transpoter",
        field: "transporter",
        sort: "asc",
        // width: 4,
        width: 60,
      },
      {
        label: "Lr no",
        field: "lRNumbers",
        sort: "asc",
        // width: 2,
        width: 60,
      },
      {
        label: "Truck No",
        field: "truckNumber",
        sort: "asc",
        // width: 2,
        width: 60,
      },
      {
        label: "Dispatch Date",
        field: "dispatchDate",
        sort: "asc",
        // width: 3,
        width: 60,
      },
      {
        label: "Lead Time as Per System",
        field: "leadTime",
        sort: "asc",
        // width: 3,
        width: 60,
      },
  
      {
        label: "Days in Transit",
        field: "daysInTransit",
        sort: "asc",
        // width: 2,
        width: 60,
      },
  
      {
        label: "CFC",
        field: "cfc",
        sort: "asc",
        // width: 1,
        width: 60,
      },
      {
        label: "Reported Date",
        field: "reportedDate",
        sort: "asc",
        // width: 3,
        width: 60,
      },
      {
        label: "Reported Time",
        field: "reportedTime",
        sort: "asc",
        // width: 3,
        width: 60,
      },
      {
        label: "Unloading Date",
        field: "unloadingDate",
        sort: "asc",
        // width: 3,
        width: 60,
      },
      {
        label: "Unloading Time",
        field: "unloadingTime",
        sort: "asc",
        // width: 3,
        width: 60,
      },
      {
        label: "Transit Time",
        field: "transitTime",
        sort: "asc",
        // width: 3,
        width: 60,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    {
      label: "Region",
      field: "region",
      sort: "asc",
      // width: 2,
      width: 60,
    },
    {
      label: "Stn no",
      field: "shipmentNumbers",
      sort: "asc",
      // width: 2,
      width: 60,
    },
    {
      label: "Orgn Code",
      field: "sourceOrganisationCode",
      sort: "asc",
      // width: 2,
      width: 60,
    },
    {
      label: " D Orgn Code",
      field: "destinationOrganisationCode",
      sort: "asc",
      // width: 2,
      width: 60,
    },
    {
      label: "Transpoter",
      field: "transporter",
      sort: "asc",
      // width: 4,
      width: 60,
    },
    {
      label: "Lr no",
      field: "lRNumbers",
      sort: "asc",
      // width: 2,
      width: 60,
    },
    {
      label: "Truck No",
      field: "truckNumber",
      sort: "asc",
      // width: 2,
      width: 60,
    },
    {
      label: "Dispatch Date",
      field: "dispatchDate",
      sort: "asc",
      // width: 3,
      width: 60,
    },
    {
      label: "Lead Time as Per System",
      field: "leadTime",
      sort: "asc",
      // width: 3,
      width: 60,
    },

    {
      label: "Days in Transit",
      field: "daysInTransit",
      sort: "asc",
      // width: 2,
      width: 60,
    },

    {
      label: "CFC",
      field: "cfc",
      sort: "asc",
      // width: 1,
      width: 60,
    },
    {
      label: "Reported Date",
      field: "reportedDate",
      sort: "asc",
      // width: 3,
      width: 60,
    },
    {
      label: "Reported Time",
      field: "reportedTime",
      sort: "asc",
      // width: 3,
      width: 60,
    },
    {
      label: "Unloading Date",
      field: "unloadingDate",
      sort: "asc",
      // width: 3,
      width: 60,
    },
    {
      label: "Unloading Time",
      field: "unloadingTime",
      sort: "asc",
      // width: 3,
      width: 60,
    },
    {
      label: "Transit Time",
      field: "transitTime",
      sort: "asc",
      // width: 4,
      width: 60,
    },
  ],
};

export const searchBar = {
  searchBarData: [],
};
