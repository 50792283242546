// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi } from "../../helpers/auth_helper";
// import { getDataGroupBy } from "rsuite/esm/utils";

function* workfetchFreightMaster({
  filters,
  searchFilter,
  callback
}) {
  // console.log("filters",filters)
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : process.env.REACT_APP_PERPAGE;
  let filterString = "";
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }
  try {
    const uri = process.env.REACT_APP_SERVERURL +
      `/freight/freights?offset=` +
      offset +
      "&limit=" +
      limit +
      filterString;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.FREIGHTS_MASTER_SUCCESS,
      payload: response,
    });
    localStorage.setItem("fetchFreighMaster", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch posts.", error);
  }
}
// new one---------
// function* workfetchFreightMaster({ filters, searchFilter, callback }) {
//   let offset = filters?.offset || 0;
//   let limit = filters?.limit || process.env.REACT_APP_PERPAGE;
//   let filterString = "";
//   const processedKeys = new Set(); // To track processed keys

//   if (searchFilter) {
//     for (const [key, value] of Object.entries(searchFilter)) {
//       if (processedKeys.has(key) || value === null || value === undefined || value === "") {
//         continue;
//       }

//       if (Array.isArray(value) && key === "validityStartDate,validityEndDate" && value.length === 2) {
//         const [startDate, endDate] = value;
//         if (startDate && endDate) {
//           filterString += `&validityStartDate=${encodeURIComponent(startDate)}`;
//           filterString += `&validityEndDate=${encodeURIComponent(endDate)}`;
//           processedKeys.add("validityStartDate");
//           processedKeys.add("validityEndDate");
//         }
//       } else {
//         filterString += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
//         processedKeys.add(key);
//       }
//     }
//   }

//   try {
//     const uri = `${process.env.REACT_APP_SERVERURL}/freight/freights?offset=${offset}&limit=${limit}${filterString}`;
//     console.log('Generated URI:', uri); // Debug log
//     const response = yield call(getApi, uri);
//     callback(response);
//     yield put({
//       type: types.FREIGHTS_MASTER_SUCCESS,
//       payload: response,
//     });
//     localStorage.setItem("fetchFreighMaster", JSON.stringify(response));
//   } catch (error) {
//     console.error("Request failed! Could not fetch posts.", error);
//   }
// }
/**
 * Watchers
 */

export function* watchfetchFreightMaster() {
  yield takeEvery(
    types.FREIGHTS_MASTER,
    workfetchFreightMaster
  );
}


function* FreightMasterSaga() {
  yield all([
    fork(watchfetchFreightMaster),
  ]);
}

export default FreightMasterSaga;
