import React, { Component } from "react";
import { Button, Modal, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import TruckTypeSelect from "../../BasicComponents/truckTypeSelect";
import ChargesStartEndDate from "../../BasicComponents/ChargesStartEndDate";
import {
  NonVehiclePlacementChargesHeaders,
  getLocalStorage,
  searchBar,
} from "./DataTableHeaders";
import moment from "moment";
import Index from "../../../../components/Common/DataTableNeo/Index";
import { Card } from "@material-ui/core";


let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class NonVehiclePlacementCharges extends Component{


    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          vehicleObject: [],
          vehicleTypeLabel: "",
          vehicleType: "",
          charges: "",
          startDate: this.props.startDate,
          endDate: this.props.endDate,
          NonVehiclePlacementForm: [],
          NonVehiclePlacementFormID: [],
          flag: false,
          viewMode: false,
          DateAltered: false,
          fetchId: "",
          agreementId: "",
        };
      }



      dateResetterOnModalClose = () => {
        this.setState({ startDate: "", endDate: "" });
      };
    
      componentDidUpdate = () => {
        //console.log("vehicle")
        let arr = [];
        let arrID = [];
        let vehicleObj = [];
        if (
          (this.props.billing.copiedAgreementValues.id &&
            !this.state.NonVehiclePlacementForm.length &&
            !this.state.viewMode &&
            !this.props.billing.startDate &&
            !this.props.billing.endDate) ||
          (this.state.fetchId !== this.props.billing.fetchId &&
            this.state.agreementId !== this.props.billing.copiedAgreementValues.id)
        ) {
          this.props.billing.copiedAgreementValues.charges.vehicleNonPlacement.forEach(
            (item, index) => {
              arr.push({
                vehicleType: item.vehicleType.label,
                charges: item.charges,
                startDate: moment(item.startDate).format("l"),
                endDate: moment(item.endDate).format("l"),
              });
              arrID.push({
                vehicleType: item.vehicleType._id,
                charges: item.charges,
                startDate: item.startDate,
                endDate: item.endDate,
              });
              vehicleObj.push({
                label: item.vehicleType.label,
                value: item.vehicleType._id,
              });
            }
          );
          this.setState(
            {
              NonVehiclePlacementForm: arr,
              NonVehiclePlacementFormID: arrID,
              vehicleObject: vehicleObj,
              viewMode: true,
              agreementId: this.props.billing.copiedAgreementValues.id,
              fetchId: this.props.billing.fetchId,
            },
            () => {
              // console.log(
              //   "NonVehiclePlacementForm:=>",
              //   this.state.NonVehiclePlacementForm
              // );
              // console.log(
              //   "NonVehiclePlacementFormID:=>",
              //   this.state.NonVehiclePlacementFormID
              // );
              this.props.listFiller(
                this.state.NonVehiclePlacementForm,
                this.state.NonVehiclePlacementFormID
              );
              this.props.listShow();
            }
          );
        } else if (
          this.props.billing.startDate &&
          this.props.billing.endDate &&
          !this.state.DateAltered
        ) {
          // console.log("STATE LOGS", this.state);
          let arr = this.state.NonVehiclePlacementForm;
          let arrID = this.state.NonVehiclePlacementFormID;
          arr.forEach((item, index) => {
            item.startDate = this.props.billing.startDate.Date;
            item.endDate = this.props.billing.endDate.Date;
            // console.log("ITEM: ", index, "-", item);
          });
          arrID.forEach((item, index) => {
            item.startDate = this.props.billing.startDate.Date;
            item.endDate = this.props.billing.endDate.Date;
            // console.log("ITEMID: ", index, "-", item);
          });
          this.setState(
            {
              NonVehiclePlacementForm: arr,
              NonVehiclePlacementFormID: arrID,
              DateAltered: true,
            },
            () => {
              // console.log("ARR", this.state.NonVehiclePlacementForm);
              this.props.listFiller(
                this.state.NonVehiclePlacementForm,
                this.state.NonVehiclePlacementFormID
              );
              this.props.listShow();
            }
          );
        }
      };
    
      handleDelete = (item) => {
        // console.log(item, "ITEM");
        let modifiedArray = [];
        let modifiedIDArray = [];
        let id;
        // console.log("dlete");
        modifiedArray = this.state.NonVehiclePlacementForm.filter(
          (e) => e.vehicleType !== item.vehicleType
        );
        // console.log("vehicleObject", this.state.vehicleObject);
        this.state.vehicleObject.find((o, i) => {
          if (o.label === item.vehicleType) {
            id = this.state.vehicleObject[i].value;
            // console.log("vehicle ID", id);
          }
          return null;
        });
        modifiedIDArray = this.state.NonVehiclePlacementFormID.filter(
          (e) => e.vehicleType !== id
        );
        this.setState(
          {
            NonVehiclePlacementForm: modifiedArray,
            NonVehiclePlacementFormID: modifiedIDArray,
          },
          () => {
            this.props.listFiller(
              this.state.NonVehiclePlacementForm,
              this.state.NonVehiclePlacementFormID
            );
            // console.log("-----", this.state.NonVehiclePlacementForm);
            // console.log(this.state.NonVehiclePlacementFormID, "--------");
          }
        );
      };


      render(){
        userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
        const validationSchema = Yup.object({
          vehicleType: Yup.object().required(
            userLang?.planning?.VEHICLE_TYPE_IS_REQUIRED
          ),
          startdate: Yup.date().required(userLang?.invoice?.DATE_IS_REQUIRED),
          expirydate: Yup.date().required(userLang?.invoice?.DATE_IS_REQUIRED),
          charges: Yup.number().required(userLang?.invoice?.CHARGES_IS_REQUIRED),
        });
    
        this.state.NonVehiclePlacementForm.forEach((item) => {
          item.actions = (
            <div style={{ color: "red" }}>
              <i
                className="fas fa-trash-alt darkInvoiceModalIcons"
                style={{ cursor: "pointer" }}
                onClick={() => this.handleDelete(item)}
              ></i>
            </div>
          );
        });
   
        
        return(

            <React.Fragment>
            {getLocalStorage()}
            <Modal
              isOpen={this.props.modal}
              toggle={this.props.toggle}
              size="xl"
              className="ModalHeight"
            >
              <Row className="mt-2">
                <Col xs={8}>
                  <div className="m-3">
                    <h4 className="fontRoboto light__span">
                      {userLang?.invoice?.VEHICLE_NON_PLACEMENT_ADD_CHARGES}{" "}
                    </h4>
                  </div>
                </Col>
                <Col xs={4} className="text-right">
                  <i
                    className="fas fa-times closemodal1 pr-2 text-right close"
                    onClick={() => this.props.toggle()}
                  ></i>
                </Col>
              </Row>
              <Row style={{ padding: "0px 25px" }}>
                <Col xs={12}>
                  <Formik
                    initialValues={{
                      vehicleType: this.state.vehicleTypeLabel,
                      charges: this.state.charges,
                      startdate: this.props.startDate,
                      expirydate: this.props.endDate,
                    }}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    onSubmit={(values, { resetForm }, e) => {
                      let chargeArray = [];
                      let chargeArrayID = [];
                      this.setState(
                        {
                          vehicleObject: this.state.vehicleObject.concat(
                            values.vehicleType
                          ),
                          vehicleTypeLabel: values.vehicleType.label,
                          vehicleType: values.vehicleType.value,
                          charges: values.charges,
                          startDate: values.startdate,
                          endDate: values.expirydate,
                        },
                        () => {
                          chargeArray = {
                            vehicleType: this.state.vehicleTypeLabel,
                            charges: this.state.charges,
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                          };
                          chargeArrayID = {
                            vehicleType: this.state.vehicleType,
                            charges: this.state.charges,
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                          };
                          this.setState(
                            {
                              vehicleTypeLabel: "",
                              vehicleType: "",
                              charges: "",
                            },
                            () => {
                              this.setState(
                                {
                                  NonVehiclePlacementForm:
                                    this.state.NonVehiclePlacementForm.concat(
                                      chargeArray
                                    ),
                                  NonVehiclePlacementFormID:
                                    this.state.NonVehiclePlacementFormID.concat(
                                      chargeArrayID
                                    ),
                                },
                                () => {
                                  this.props.listFiller(
                                    this.state.NonVehiclePlacementForm,
                                    this.state.NonVehiclePlacementFormID
                                  );
                                }
                              );
                            }
                          );
                        }
                      );
                      resetForm({
                        values: {
                          vehicleType: "",
                          charges: "",
                          startdate: this.state.startDate,
                          expirydate: this.state.endDate,
                        },
                      });
                    }}
                  >
                    {(formik) => {
                      const {
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                        handleBlur,
                      } = formik;
                      return (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col xs={12} md={3} lg={2}>
                              <TruckTypeSelect
                                handleBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                val={values.vehicleType}
                                setFieldValue={setFieldValue}
                                mode={this.props.mode}
                              />
                            </Col>
                            <Col xs={12} md={5} lg={8}>
                              <ChargesStartEndDate
                                valid={this.props.valid}
                                validExpiry={this.props.validExpiry}
                                handleBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                chargeVal={values.charges}
                                startDateVal={values.startdate}
                                endDateVal={values.expirydate}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                mode={this.props.mode}
                              />
                            </Col>
                            <Col xs={6} md={2} lg={1}>
                              <Button
                                style={{
                                  width: "100%",
                                  position: "relative",
                                  top: "37%",
                                  backgroundColor: "#5E40FF",
                                }}
                                type="submit"
                                color="secondary"
                                className={`small align-middle rounded rounded-black mb-1 ${
                                  this.props.mode ? "darkAgreementAddToList" : ""
                                }`}
                              >
                                <span style={{ fontWeight: "900" }}>
                                  {userLang?.invoice?.ADD_To_LIST}
                                </span>
                              </Button>{" "}
                            </Col>
                            <Col xs={6} md={2} lg={1}>
                              <Button
                                style={{
                                  width: "100%",
                                  position: "relative",
                                  top: "37%",
                                  backgroundColor: "#0AB38C",
                                }}
                                type="button"
                                color="primary"
                                className="small align-middle rounded mb-1"
                                onClick={() => {
                                  this.props.listShow();
                                  this.props.toggle();
                                  this.dateResetterOnModalClose();
                                }}
                                disabled={!this.state.NonVehiclePlacementForm?.length}
                              >
                                <span style={{ fontWeight: "900" }}>
                                  {userLang?.invoice?.SAVE_ALL}
                                </span>
                              </Button>{" "}
                            </Col>
                          </Row>
                        </form>
                      );
                    }}
                  </Formik>
                </Col>
                <Col xs={12} className="DataTable darkDataTableCharges">
                  <Card>
                    <Index
                      headers={NonVehiclePlacementChargesHeaders}
                      data={this.state.NonVehiclePlacementForm}
                      searchBar={searchBar}
                      rowHeight={"56px"}
                      limit = {this.state.NonVehiclePlacementForm.length}
                    />
                  </Card>
                </Col>
              </Row>
            </Modal>
          </React.Fragment>
        )
      }

}

const mapStateToProps = (state) => ({
    billing: state.SecondaryBilling.SecondaryBilling,
  });
  
export default connect(mapStateToProps, {})(NonVehiclePlacementCharges);
  