import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from ".././restrict";
import { PERMS } from "../../enum/perms.enum";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import QuickFilters from "./quckFilters";
import Index from "../../components/Common/DataTableNeo/Index";
import moment from "moment";
import {
  getLocalStorage,
  AdminHeaderData,
  searchBar,
  CustomerHeaderData,
} from "./RowHeaders/RowHeaders";
import {
  freightsMasterSecondary,
  fetchSourceSecondary,
  fetchTransporterOptionsSecondary,
  fetchVehicletypeOptionsSecondary,
  fetchCustomerOptionsSecondary,
} from "../../store/secondaryIndentPlanning/actions";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};

const downArrow = require("../../../src/assets/images/downArrow.svg");

class FreightMasterSecondary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: false,
      FromDate: "",
      ToDate: "",
      // searchFilters: {
      //   freightCode: "",
      //   costMain: "",
      //   source: "",
      //   customers: "",
      //   transporter: "",
      //   vehicleType: "",
      //   validityStartDate: "",
      //   validityEndDate: "",
      // },
      sourceHops: [],
      transporterOption: [],
      vehicleTypeOption: [],
      customersOption: [],
      source: "",
      destination: "",
      customers: "",
      transporter: "",
      vehicleType: "",
      route: "",
      customerModal: false,
      customersData: [],
    };

    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);
        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }

  componentDidMount = () => {
    offset = 0;
    this.getAllData();
    this.getAllTransporter();
    this.getAllsource();
    this.getAllVehicleType();
    this.getAllCustomers();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  enterCheck = (event) => {
    if (event.key === "Enter") {
      offset = 0;
      this.getAllData();
    }
  };

  getAllsource = (q = "") => {
    this.props.fetchSourceSecondary((res) => {
      this.hops = [];
      if (res?.data?.length > 0) {
        res.data.forEach((ele, index) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        // this.setState({ sourceHops: this.hops }, () => {});
        this.setState({ sourceDestinationHops: this.hops }, () => {
          searchBar.searchBarData.forEach((obj) => {
            if (obj.field === "source") {
              obj.options = this.state.sourceDestinationHops;
            }
          });
        });
      }
    });
  };

  getAllTransporter = (q = "") => {
    this.props.fetchTransporterOptionsSecondary((res) => {
      let transporter = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          transporter.push({
            value: {
              id: ele._id,
            },
            label: ele.name ? ele.name : "",
          });
        });
      }

      // this.setState({ transporterOption: transporter }, () => { });
          this.setState({ transporterOption: transporter }, () => {
              searchBar.searchBarData.forEach((obj) => {
                if (obj.field === "transporter") {
                  obj.options = this.state.transporterOption; 
                }
              });
            });
    });
  };

  getAllVehicleType = (label = "") => {
    return this.props?.fetchVehicletypeOptionsSecondary((data) => {
      data = data?.data?.docs;
      let options = [];
      while (data?.length > 0) {
        let singleEntry = data?.shift();
        options.push({
          label: singleEntry?.label,
          value: { vehicleName: singleEntry.label, id: singleEntry._id },
          vehicleTypeCode: singleEntry?.vehicleTypeCode,
        });
      }
      // this.setState({ vehicleTypeOption: options });
          this.setState({ vehicleTypeOption: options }, () => {
              searchBar.searchBarData.forEach((obj) => {
                if (obj.field === "vehicleType") {
                  obj.options = this.state.vehicleTypeOption; 
                }
              });
            });
    });
  };

  getAllCustomers = () => {
    this.props.fetchCustomerOptionsSecondary((res) => {
      let customers = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          customers.push({
            value: {
              id: ele._id,
            },
            label: ele.customerName ? ele.customerName : "",
          });
        });
      }

      // this.setState({ customersOption: customers }, () => { });
          this.setState({ customersOption: customers }, () => {
              searchBar.searchBarData.forEach((obj) => {
                if (obj.field === "customers") {
                  obj.options = this.state.customersOption; 
                }
              });
            });
    });
  };

  componentDidUpdate(prevProps) { }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  handleSearchInput = (e, data) => {
    searchValues[e?.target?.name] = e?.target?.value;
    this.setState({ searchFilters: searchValues }, () => { });
  };
  handleSelectGroup = (e, name) => {
    searchValues[`${name}`] = e?.value?.id;
    this.setState({ searchFilters: searchValues, [`${name}`]: e }, () => {
      offset = 0;
      this.getAllData();
    });
  };

  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }

  handleChange = (dateValues) => {
    searchValues["validityStartDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("MM-DD-YYYY")
          : moment(dateValues).format("MM-DD-YYYY")
        : null;
    searchValues["validityEndDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("MM-DD-YYYY")
        : null;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      this.getAllData();
    });
  };

  // searchValueAssignerFunction = (name, value, type) => {
  //   this.setState({ dataLoading: true });
  
  //   this.setState((prevState) => {
  //     let searchValues = { ...prevState.searchFilters };
  
  //     if (type === "date") {
  //       searchValues[name] = value; // Directly assign formatted date
  //     } else {
  //       searchValues[name] = typeof value === "object" ? value.id : value;
  //     }
  
  //     return { searchFilters: searchValues };
  //   }, () => {
  //     this.getAllData(); // Fetch updated data
  //   });
  // };
  
  searchValueAssignerFunction = (name, value, type) => {
    this.setState({ dataLoading: true });
  
    this.setState((prevState) => {
      let searchValues = { ...prevState.searchFilters };
  
      if (Array.isArray(name) && Array.isArray(value) && name.length === value.length) {
        name.forEach((key, index) => {
          searchValues[key] = type === "date" ? value[index] : value[index]?.id || value[index];
        });
      } else {
        searchValues[name] = type === "date" ? value : value?.id || value;
      }
  
      return { searchFilters: searchValues };
    }, () => {
      this.getAllData(); // Fetch updated data
    });
  };
  
  getAllData = () => {
    this.setState({ dataLoading: true });
    this.props.freightsMasterSecondary(
      { offset: offset, limit: 30 },
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false }, () => { });
      }
    );
  };

  reset = (e) => {
    offset = 0;
    searchValues = {};
    // this.setState(
    //   {
    //     searchFilters: {
    //       freightCode: "",
    //       costMain: "",
    //       costPerPick: "",
    //       costPerDrop: "",
    //       source: "",
    //       customers: "",
    //       transporter: "",
    //       vehicleType: "",
    //       validityStartDate: "",
    //       validityEndDate: "",
    //     },
    //     source: "",
    //     customers: "",
    //     transporter: "",
    //     vehicleType: "",
    //   },
    //   () => {
    //     this.getAllData();
    //   }
    // );
 
    this.setState({ dataLoading: true });
    this.props.freightsMasterSecondary(
      offset,
      searchValues,
      (res) => {
        this.setState({ dataLoading: false }, () => {
          this.getAllVehicleType();
        });
      }
    );
  };

 
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  toggleCustomers = (index) => {
    let freightsMasterList =
      this.props.freightsMasterListSecondary?.docs[index].customers;
    let customersModel = [];
    freightsMasterList.forEach((e) =>
      customersModel.push({
        customerName: e?.customerName ? e?.customerName : "-----",
        customerNumber: e?.customerNumber ? e?.customerNumber : "-----",
        shipToCode: e?.shipToCode ? e?.shipToCode : "-----",
        address: e?.address
          ? `${e?.address?.address1}, ${e?.address?.address2}`
          : "-----",
        city: e?.address?.city ? e?.address?.city : "-----",
        postalCode: e?.address?.postalCode ? e?.address?.postalCode : "-----",
        state: e?.address?.state ? e?.address?.state : "-----",
      })
    );
    // console.log("customersModel",customersModel)
    this.setState({
      customerModal: !this.state.customerModal,
      customersData: customersModel,
    });
  };

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkTheme = mode === "dark__mode" ? true : false;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    let rows = [];
    let freightsMasterList = this.props.freightsMasterListSecondary;

    if (Array.isArray(freightsMasterList?.docs)) {
      totCnt = freightsMasterList?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = offset + freightsMasterList?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

      if (
        freightsMasterList !== undefined &&
        freightsMasterList?.docs?.length > 0
      ) {
        freightsMasterList.docs.forEach((ele, index) => {
          if (ele) {
            rows.push({
              freightCode: ele?.freightCode ? ele?.freightCode : "-----",
              costMain: ele?.cost ? ele?.cost?.main : "-----",
              source: ele?.source ? ele?.source : "-----",
              customers:
                (
                  <>
                    {ele?.customers.length > 1 ? (
                      <div className="route-container comn180">
                        {/* Show only the first label */}
                        <div className="text-left d-flex align-items-center justify-content-between flex-nowrap mobileRoute">

                          <div className=" w-100 tw-flex tw-justify-between">
                            <div className="tw-truncate " title="">
                              {ele.customers[0]?.customerName}
                            </div>
                            <div>
                              - {ele.customers[0]?.customerNumber} -{" "}
                              {ele.customers[0]?.shipToCode} -{" "}
                            </div>
                            <div className="tw-truncate ">
                              {ele.customers[0]?.address.city}
                            </div>
                          </div>
                          <div
                            className="icon-container"
                            onClick={() => this.toggleCustomers(index)}
                          >
                            <span className="total-labels fsize-12 tw-cursor-pointer" >{ele?.customers?.length - 1}</span>
                          </div>
                        </div>

                      </div>
                      // </div>






                    ) : (
                      "-----"
                    )}
                  </>
                ) || "-----",

              vehicleType: ele?.vehicleType?.vehicleTypeCode
                ? ele?.vehicleType?.vehicleTypeCode
                : "-----",
              transporter:
                ele?.transporter && ele?.transporter !== null
                  ? ele?.transporter?.name
                  : "-----",
              validityFromTo: ele?.validity?.from ? (
                <div className="tw-flex tw-gap-2">
                  <div>{moment(ele?.validity?.from).format("DD-MMM-YYYY")}</div>
                  <div>{moment(ele?.validity?.to).format("DD-MMM-YYYY")}</div>
                </div>
              ) : (
                "-----"
              ),
            });
          }
        });
      }
    }

    return (
      <React.Fragment>
        <div>
          <Container
            style={{
              minWidth: "100%",
            }}
          >
            {/* <div
              className={`containerWhiteDivider mt-1  mb-3 ${darkTheme ? "darkBackgroundList" : ""
                }`}
            ></div> */}

            <Modal
              isOpen={this.state.customerModal}
              toggle={() =>
                this.setState({ customerModal: !this.state.customerModal })
              }
              size="xl"
              centered
              className="CustomersModel"
            >
              <Row className="mt-2">
                <Col>
                  <ModalHeader>
                    {this.state.userLangs?.planning?.CUSTOMERS}{" "}
                  </ModalHeader>
                </Col>
                <Col className="text-right">
                  <i
                    className="fas fa-times closemodal1 pr-2 text-right"
                    onClick={() =>
                      this.setState({
                        customerModal: !this.state.customerModal,
                      })
                    }
                  ></i>
                </Col>
              </Row>

              <ModalBody className="mb-4">
                <Index
                  {...this.props}
                  headers={CustomerHeaderData}
                  data={this.state.customersData}
                // checkChanged={this.state.checkChanged}
                // getAllData={this.getAllData}
                />
              </ModalBody>
            </Modal>
          </Container>
        </div>
        <div /* className="page-content" */>
          {getLocalStorage()}
          {/* <QuickFilters
            reset={this.reset}
            onInputChange={this.handleSearchInput}
            handleSelectGroup={this.handleSelectGroup}
            selectectedSource={this.state.source}
            selectectedCustomers={this.state.customers}
            selectectedTransporter={this.state.transporter}
            selectectedVehicleType={this.state.vehicleType}
            filters={this.state.searchFilters}
            handleSubmitChange={this.handleSubmitChange}
            enterCheck={this.enterCheck}
            filterList={this.getAllData}
            mode={darkTheme}
            sourceHops={this.state.sourceHops}
            allTransporterOption={this.state.transporterOption}
            vehicleTypeOption={this.state.vehicleTypeOption}
            customersOption={this.state.customersOption}
            handleChange={this.handleChange}
            filtersHops={this.getAllsource}
            getVehicleTypes={this.getAllVehicleType}
            getAllTransporter={this.getAllTransporter}
            getAllCustomers={this.getAllCustomers}
          /> */}

          <Container fluid 
              style={{
                // maxHeight: "115vh",
                overflowX: "hidden", paddingLeft: "0px", paddingRight: "0px"
              }}
          >
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                hidesearchBar={true}
                searchable={true}
                searchBar={searchBar}
                fetchId={this.props.fetchIdMasterSecondary}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}

              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                hidesearchBar={true}
                searchable={true}
                searchBar={searchBar}
                fetchId={this.props.fetchIdMasterSecondary}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}

              />
            )}
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 mt-2 paginationRow"
              >
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={5}
                  md={5}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
                <Col xs={7} sm={7} md={7} className="span-col-pagenation">
                  <div className="float-right">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>

              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    freightsMasterListSecondary:
      state?.SecondaryIndent?.secondaryIndents?.FreightsMasterDataSecondary
        ?.data,
    fetchIdMasterSecondary:
      state?.SecondaryIndent?.secondaryIndents?.fetchIdMasterSecondary,
  };
};

export default connect(mapStateToProps, {
  freightsMasterSecondary,
  fetchSourceSecondary,
  fetchTransporterOptionsSecondary,
  fetchVehicletypeOptionsSecondary,
  fetchCustomerOptionsSecondary,
})(FreightMasterSecondary);
