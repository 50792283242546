import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getApi, postApi, putApi, deleteApi } from "../../helpers/auth_helper";
import { types } from "./actionTypes";

function* workfetchTripsSecondary({ offset, filters, hopsData, callback }) {
  try {
    // console.log("hops ",hopsData)
    // Try to call the API
    // console.log(filters, "filters");
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      // if (filterKey === "startTime") {
      //   filterString =
      //     filterString +
      //     "&" +
      //     "startTimeStart=" +
      //     filterValue[0] +
      //     "&startTimeEnd=" +
      //     filterValue[1];
      // } 
      if (filterKey === "startTime") {
        filterString += `&startTimeStart=${filterValue[0]}`;
        // filterString += `&startTimeStart=${filterValue[0]}&startTimeEnd=${filterValue[1]}`;
      } 
      else filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
    let hops = hopsData?.hops ? hopsData?.hops : "";
    let hopStatus = hopsData?.hopStatus ? hopsData?.hopStatus : "";
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/searchTripListSecondaryFreight?offset=` +
      Number(offset) +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString +
      `&hop=` +
      hops;
    // `&hopStatus=`+
    // hopStatus;
    const response = yield call(getApi, uri);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRIPS_SECONDARY_SUCCESS,
      payload: response,
    });
    callback(response);
    localStorage.setItem(
      "SecondaryTripData",
      response ? JSON.stringify(response) : null
    );
    if (filterString === "&subStatus=in_transit") {
      localStorage.setItem(
        "secondaryTripIntransitData",
        JSON.stringify(response)
      );
    }
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchTripsExcelSecondary({ offset, filters, callback }) {
  try {
    // Try to call the API
    //consolelog(filters, "filters");
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      // console.log(filterString, "FILETR-SAGA");
    }
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/searchTripListSecondaryFreight?offset=` +
      Number(offset) +
      `&limit=10000000` +
      // `&limit=` +
      // Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    const response = yield call(getApi, uri);
    callback(response);
    // callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRIPS_EXCEL_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workTripArrivedAtSourceStatusSecondary({ tripID, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/status/arrivedAtHopSecondaryFreight`;
    const response = yield call(postApi, uri, { tripID });
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not change trip status.", error);
  }
}

function* workTripMarkedAsArrivedInTransitStatusSecondary({
  tripCustomerId,
  callback,
}) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/status/arrivedAtCustomer_SecondaryFreight`;
    const response = yield call(postApi, uri, tripCustomerId);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not change trip status.", error);
  }
}

function* workSetTripCustomerDropdown({ index }) {
  console.log(index);
  yield put({
    type: types.SET_TRIP_CUSTOMER_DROPDOWN_SUCCESS,
    payload: index,
  });
}

function* workSetTripCustomerDropdownFalse() {
  yield put({
    type: types.SET_TRIP_CUSTOMER_DROPDOWN_FALSE_SUCCESS,
  });
}

function* workTripQualityCheckSecondary({ values, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/trip/qualityCheckSecondaryFreight`;
    const response = yield call(postApi, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.TRIP_QUALITY_CHECK_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workStartUnloadingSecondary({ tripID, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/status/epod_in_progress_secondary_freight`;
    const response = yield call(postApi, uri, tripID);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.START_UNLOADING_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not change trip status.", error);
  }
}

function* workstartLoadingSecondary({ tripID, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/status/loadingInProgress_SecondaryFreight`;
    const response = yield call(postApi, uri, { tripID });
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.START_LOADING_SECONDARY_SUCCESS,
      payload: { tripID },
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workSubscribeConsentSecondary({ phone, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/tracking/subscribe/${phone} `;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SUBSCRIBE_CONSENT_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workChangeShipmentverifySecondary({ verify, callback }) {
  try {
    // console.log("FORM SAGA", verify);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/shipment/verifySecondaryFreightShipments`;
    const response = yield call(postApi, uri, verify);
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.SHIPMENT_VERIFY_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not change trip status.", error);
  }
}

function* workChangeShipmentverifyInfoSecondary({ sf_number, callback }) {
  try {
    // console.log("FORM SAGA", verify);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/shipment/getShipmentIds`;
    const response = yield call(postApi, uri, sf_number);
    console.log(response);
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.SHIPMENT_VERIFY_INFO_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not change trip status.", error);
  }
}

function* workChangeAddShipmentverifySecondary({ verifyData, callback }) {
  try {
    // console.log("FORM SAGA", verify);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/shipment/addVerifiedShipments`;
    const response = yield call(postApi, uri, verifyData);
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.ADD_SHIPMENT_VERIFY_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not change trip status.", error);
  }
}

function* workfinishLoadingSecondary({ tripID, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/status/inTransitSecondaryFreight`;
    const response = yield call(postApi, uri, { tripID });
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.FINISH_LOADING_SECONDARY_SUCCESS,
      payload: { tripID },
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}

function* workTripEpodSecondary({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/epod/ItemVerification_secondary_freight`;
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.TRIP_EPOD_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workTripEpodCompleteSecondary({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/epod/add_epod_secondary_freight`;
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.TRIP_EPOD_COMPLETE_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workTripCompleteSecondary({ tripId, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/status/tripCompletedSecondaryFreight`;
    const response = yield call(postApi, uri, tripId);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.TRIP_COMPLETE_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workAcknowledgeLRSecondary({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/epod/complete_epod_secondary_freight`;
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.TRIP_COMPLETE_SECONDARY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

export function* watchFetchTripsSecondary() {
  yield takeEvery(types.FETCH_TRIPS_SECONDARY, workfetchTripsSecondary);
}

export function* watchFetchTripsExcelSecondary() {
  yield takeEvery(
    types.FETCH_TRIPS_EXCEL_SECONDARY,
    workfetchTripsExcelSecondary
  );
}

export function* watchTripArrivedAtSourceStatusSecondary() {
  yield takeEvery(
    types.CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY,
    workTripArrivedAtSourceStatusSecondary
  );
}

export function* watchTripMarkedAsArrivedInTransitStatusSecondary() {
  yield takeEvery(
    types.CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY,
    workTripMarkedAsArrivedInTransitStatusSecondary
  );
}

export function* watchSetTripCustomerDropdown() {
  yield takeEvery(
    types.SET_TRIP_CUSTOMER_DROPDOWN,
    workSetTripCustomerDropdown
  );
}

export function* watchSetTripCustomerDropdownFalse() {
  yield takeEvery(
    types.SET_TRIP_CUSTOMER_DROPDOWN_FALSE,
    workSetTripCustomerDropdownFalse
  );
}

export function* watchTripQualityCheckSecondary() {
  yield takeEvery(
    types.TRIP_QUALITY_CHECK_SECONDARY,
    workTripQualityCheckSecondary
  );
}

export function* watchStartUnloadingSecondary() {
  yield takeEvery(types.START_UNLOADING_SECONDARY, workStartUnloadingSecondary);
}

export function* watchStartLoadingSecondary() {
  yield takeEvery(types.START_LOADING_SECONDARY, workstartLoadingSecondary);
}

export function* watchFinishLoadingSecondary() {
  yield takeEvery(types.FINISH_LOADING_SECONDARY, workfinishLoadingSecondary);
}

export function* watchSubscribeConsentSecondary() {
  yield takeEvery(
    types.SUBSCRIBE_CONSENT_SECONDARY,
    workSubscribeConsentSecondary
  );
}

export function* watchShipmentVerifySecondary() {
  yield takeEvery(
    types.SHIPMENT_VERIFY_SECONDARY,
    workChangeShipmentverifySecondary
  );
}

export function* watchShipmentVerifyInfoSecondary() {
  yield takeEvery(
    types.SHIPMENT_VERIFY_INFO_SECONDARY,
    workChangeShipmentverifyInfoSecondary
  );
}

export function* watchAddShipmentVerifySecondary() {
  yield takeEvery(
    types.ADD_SHIPMENT_VERIFY_SECONDARY,
    workChangeAddShipmentverifySecondary
  );
}

export function* watchTripEpodSecondary() {
  yield takeEvery(types.TRIP_EPOD_SECONDARY, workTripEpodSecondary);
}

export function* watchTripEpodCompleteSecondary() {
  yield takeEvery(
    types.TRIP_EPOD_COMPLETE_SECONDARY,
    workTripEpodCompleteSecondary
  );
}

export function* watchTripCompleteSecondary() {
  yield takeEvery(types.TRIP_COMPLETE_SECONDARY, workTripCompleteSecondary);
}

export function* watchAcknowledgeLRSecondary() {
  yield takeEvery(types.ACKNOWLEDGE_LR_SECONDARY, workAcknowledgeLRSecondary);
}

function* SecondaryTripSaga() {
  yield all([
    fork(watchFetchTripsSecondary),
    fork(watchFetchTripsExcelSecondary),
    fork(watchTripArrivedAtSourceStatusSecondary),
    fork(watchTripMarkedAsArrivedInTransitStatusSecondary),
    fork(watchSetTripCustomerDropdown),
    fork(watchSetTripCustomerDropdownFalse),
    fork(watchTripQualityCheckSecondary),
    fork(watchStartUnloadingSecondary),
    fork(watchStartLoadingSecondary),
    fork(watchSubscribeConsentSecondary),
    fork(watchShipmentVerifySecondary),
    fork(watchShipmentVerifyInfoSecondary),
    fork(watchAddShipmentVerifySecondary),
    fork(watchFinishLoadingSecondary),
    fork(watchTripEpodSecondary),
    fork(watchTripEpodCompleteSecondary),
    fork(watchTripCompleteSecondary),
    fork(watchAcknowledgeLRSecondary),
  ]);
}

export default SecondaryTripSaga;
