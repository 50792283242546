import React, { useState, useEffect } from "react";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import calendarDarkIcon from "../../../pages/Trips/assets/calendarDark.png";
import calendarIcon from "../../../pages/Trips/assets/calendar.png";
import "../../../pages/Dashboard/OperationsDashboard/Styles/dashboard.css";

const MyIcon = React.forwardRef(({ darkMode }, ref) => (
  <img
    src={darkMode ? calendarDarkIcon : calendarIcon}
    alt="calendar icon"
    style={{ width: 20, height: 20 }}
  />
));

const SingleDatePicker = ({ value, onChange, mode, placeholder }) => {
  const [selectedDate, setSelectedDate] = useState(value || null);

  useEffect(() => {
    setSelectedDate(value || null);
  }, [value]);

  return (
    <DatePicker
      size="md"
      placeholder={placeholder || "Select Date"}
      format="dd MM yy"
      value={selectedDate}
      onChange={(date) => {
        setSelectedDate(date);
        onChange(date);
      }}
      caretAs={(props) => <MyIcon darkMode={mode} {...props} />}
    />
  );
};

export default SingleDatePicker;
