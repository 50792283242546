



export const types = {
    //new
    DATE_CAHNGE: "DATE_CHANGE",
    CLEAR_AGREEMENT_VALUES: "CLEAR_AGREEMENT_VALUES",
    //actual
    STORE_AGREEMENT: "STORE_AGREEMENT",
    STORE_AGREEMENT_SUCCESS: "STORE_AGREEMENT_SUCCESS",
    SECONDARY_CREATE_AGREEMENT: "SECONDARY_CREATE_AGREEMENT",
    SECONDARY_CREATE_AGREEMENT_SUCCESS: "SECONDARY_CREATE_AGREEMENT_SUCCESS",
    SECONDARY_FETCH_LAST_AGREEMENT_VALUES: "FETCH_LAST_AGREEMENT_VALUES",
    SECONDARY_FETCH_LAST_AGREEMENT_VALUES_SUCCESS: "FETCH_LAST_AGREEMENT_VALUES_SUCCESS",
    VIEW_AGREEMENT: "VIEW_AGREEMENT",
    VIEW_AGREEMENT_SUCCESS: "VIEW_AGREEMENT_SUCCESS",
    FETCH_SECONDARY_AGREEMENTS_SUCCESS: "FETCH_SECONDARY_AGREEMENTS_SUCCESS",
    FETCH_SECONDARY_AGREEMENTS: "FETCH_AGREEMENTS",
    FETCH_SIGNED_URL: "FETCH_SIGNED_URL",
    FETCH_SIGNED_URL_SUCCESS: "FETCH_SIGNED_URL_SUCCESS",
    UPLOAD_AGREEMENT: "UPLOAD_AGREEMENT",
    UPLOAD_AGREEMENT_SUCCESS: "UPLOAD_AGREEMENT_SUCCESS",
    UPLOAD_IMAGES_AWS: "UPLOAD_IMAGES_AWS",
    UPLOAD_IMAGES_AWS_SUCCESS: "UPLOAD_IMAGES_AWS_SUCCESS",
    FETCH_SECONDARY_INVOICES: "FETCH_SECONDARY_INVOICES",
    FETCH_SECONDARY_INVOICES_SUCCESS: "FETCH_SECONDARY_INVOICES_SUCCESS",
    FETCH_SECONDARY_INVOICES_EXCEL: "FETCH_SECONDARY_INVOICES_EXCEL",
    FETCH_SECONDARY_INVOICES_EXCEL_SUCCESS: "FETCH_SECONDARY_INVOICES_EXCEL_SUCCESS",
    CLEAR_LOADED_VALUES: "CLEAR_LOADED_VALUES",
    FETCH_SECONDARY_BILLS: "FETCH_SECONDARY_BILLS",
    FETCH_SECONDARY_BILLS_SUCCESS: "FETCH_SECONDARY_BILLS_SUCCESS",
    ACCEPT_SECONDARY_INVOICE: "ACCEPT_SECONDARY_INVOICE",
    ACCEPT_SECONDARY_INVOICE_SUCCESS: "ACCEPT_SECONDARY_INVOICE_SUCCESS",
  
    APPROVE_SECONDARY_INVOICE: "APPROVE_SECONDARY_INVOICE",
    APPROVE_SECONDARY_INVOICE_SUCCESS: "APPROVE_SECONDARY_INVOICE_SUCCESS",
    ADD_NEW_BILL: "ADD_NEW_BILL",
    ADD_NEW_BILL_SUCCESS: "ADD_NEW_BILL_SUCCESS",
  
    REJECT_SECONDARY_INVOICE: "REJECT_SECONDARY_INVOICE",
    REJECT_SECONDARY_INVOICE_SUCCESS: "REJECT_SECONDARY_INVOICE_SUCCESS",
    CREATE_INVOICE: "CREATE_INVOICE",
    CREATE_INVOICE_SUCCESS: "CREATE_INVOICE_SUCCESS",
    FETCH_SECONDARY_INVOICE_BYID: "FETCH_SECONDARY_INVOICE_BYID",
    FETCH_SECONDARY_INVOICE_BYID_SUCCESS: " FETCH_SECONDARY_INVOICE_BYID_SUCCESS",
    UPDATE_SECONDARY_BILL : "UPDATE_SECONDARY_BILL",
    UPDATE_SECONDARY_BILL_SUCCESS: "UPDATE_SECONDARY_BILL_SUCCESS",
    SECONDARY_COPY_AGREEMENT_LIST: "SECONDARY_COPY_AGREEMENT_LIST",
    CLEAR_BILLS: "CLEAR_BILLS",
    UPDATE_SECONDARY_INVOICE: "UPDATE_SECONDARY_INVOICE",
    UPDATE_SECONDARY_INVOICE_SUCCESS: "UPDATE_SECONDARY_INVOICE_SUCCESS",
    // invoice bill delete
    SECONDARY_INVOICE_BILL_DELETE: "SECONDARY_INVOICE_BILL_DELETE",
    SECONDARY_INVOICE_BILL_DELETE_SUCCESS: "SECONDARY_INVOICE_BILL_DELETE_SUCCESS",
    // invoice bills for transpoter
    CREATE_SECONDARY_INVOICE_BILLS: "CREATE_SECONDARY_INVOICE_BILLS",
    CREATE_SECONDARY_INVOICE_BILLS_SUCCESS: "CREATE_SECONDARY_INVOICE_BILLS_SUCCESS",
    SUBMIT_TO_RAISE_SECONDARY_INVOICE: "SUBMIT_TO_RAISE_SECONDARY_INVOICE",
    SUBMIT_TO_RAISE_SECONDARY_INVOICE_SUCCESS: "SUBMIT_TO_RAISE_SECONDARY_INVOICE_SUCCESS",
  };