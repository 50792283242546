import React, { Component } from "react";
import { Row, Col, Container, Input } from "reactstrap";
import { NotificationManager } from "react-notifications";
import Index from "../../components/Common/DataTableNeo/Index";
import formatDate from "../DateFormatter";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  HeaderData,
  searchBar,
  TransporterHeaderData,
  TransportersearchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders";
import {
  fetchSourceSecondary,
  fetchTransporterOptionsSecondary,
  fetchCustomerOptionsSecondary,
  fetchVehicletypeOptionsSecondary,
} from "../../store/secondaryIndentPlanning/actions";
import {
  fetchVehicles,
  fetchDrivers,
  createVehicle,
  createDriver,
} from "../../store/actions";
import {
  fetchTripsSecondary,
  fetchTripsExcelSecondary,
  change_arrivedAtSource_statusSecondary,
  startUnloadingSecondary,
  startLoadingSecondary,
  subscribeConsentSecondary,
  shipmentVerifySecondary,
  addShipmentVerifySecondary,
  finishLoadingSecondary,
  change_MarkAsArrived_InTransit_statusSecondary,
} from "../../store/secondaryTrip/actions";
import { actions } from "./actionButtons";
import ApproveModal from "../ApproveModal";
import QualityCheckButton from "./QualityCheckButton";
import {
  setTripCustomerDropDown,
  setTripCustomerDropDownFalse,
} from "../../store/secondaryTrip/actions";
import VerifyShipmentModalSecondary from "../VerifyShipmentModalSecondary";
import SecondaryTripCancelModal from "../SecondaryTripDetail/SecondaryTripCancelModal";
import PopUp from "./PopUp";

const downArrow = require("../../../src/assets/images/downArrow.svg");

const tripStatusMaster = [
  { label: "Planned", value: "planned" },
  { label: "Arrived At Source", value: "arrived_at_source" },
  { label: "Quality Check Completed", value: "quality_check_completed" },
  { label: "Loading In Progress", value: "loading_in_progress" },
  { label: "Shipment Verified", value: "shipment_verified" },
  { label: "In Transit", value: "in_transit" },
  //   { label: "Arrived At Via Point", value: "arrived_at_via" },
  { label: "EPOD In Progress", value: "epod_in_progress" },
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "rejected" },
  { label: "Cancelled", value: "cancelled" },
];

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};
var darkMode;

export class SecondaryTrips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transpoterId: "",
      dataLoading: false,
      loading: false,
      exloading: false,
      hops: [],
      customers: [],
      vehicleTypes: [],
      vehicleForm: {},
      driverForm: {},
      // searchBar: [],
      searchFilters: {},
      cancelTripDetails: {},
      showCancelTrip: false,
      VerifyShipmentModal: false,
      shipdetails: "",
      operator: "",
      verify: [],
      verifymsg: "",
      modalApproval: false,
      driver: "",
      vehicle: "",
      vehicleLabel: "",
      vehicleTypeListOptions: "",
      onSubmitDisableVehicle: false,
      onSubmitDisableDriver: false,
      tripId: "",
      tripIdEditPop: "",
      tripIdCancelPop: "",
      indentId: "",
      //mess
      // vehicleDetails: {},
      // driverDetails: {},
      transporter: "",
      export: false,

      offset: 0,
      limit: 200,
      filterTranspoter: "",
      allTransporter: "",
      allCustomers: "",
      activeTripDelayed: false,
      activeTripUntrack: false,
      activeTripPending: false,
      showTripDetailModel: false,
      transporterVehicleOptions: [],
      transporterDriverOptions: [],
      consent: "",
      showQualityCheck: false,
      TripData: "",
      //EPOD
      showEPODModal: false,
      tripDetailsForEPOD: {},
      hopStatus: [],
      missedShiped: "",
      // hopStatus:["planned","loading_in_progress","arrived_at_via","arrived_at_source"]
      customerDropDown: [],
      checkChanged: false,
      markedAsArrivedToggle: false,
      markedAsArrivedInTransitData: {},
      MarkedAsArrivedPopupPayload: {},
      startUnloadingToggle: false,
      startUnloadingInTransitData: {},
      startUnloadingPopupPayload: {},
      statusSubcribeConsent: "",
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    this.setState({ dataLoading: true });

    let searchFilters = {};
    if (this.props.location?.state?.filter !== undefined) {
      let filter = this.props.location.state.filter;

      if (
        filter === "delayed" ||
        filter === "pending" ||
        filter === "Untracked with Consent" ||
        filter === "Untracked without Consent" ||
        filter === "otherStatus"
      )
        this.quickFilters(this.props.location.state.filter);
      else if (
        filter === "completed" ||
        filter === "planned" ||
        filter === "in_transit" ||
        filter === "shipment_verified" ||
        filter === "loading_in_progress"
      ) {
        searchFilters.subStatus = filter;
        this.setState({ searchFilters: searchFilters }, () => {
          this.getAllData(true);
          this.getAllVehicleTypes();
        });
      }
    } else {
      this.getAllData(true);
      this.getAllVehicleTypes();
    }

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    document.addEventListener("mousedown", this.handleClickOutside);

    
        this.fetchHops();
        this.fetchAllTransporterSearch()
        this.getAllVehicles()
        this.fetchAllCustomersSearch()
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickOutside);
  };

  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }

  filterDataTranspotersAndTrips = (e, fieldType) => {
    if (fieldType === "transporter") {
      this.filtersTranspoter(e);
    }
    if (fieldType === "source") {
      this.filtersHops(e);
    }
    if (fieldType === "customers") {
      this.filtersCustomers(e);
    }
    if (fieldType === "vehicle") {
      this.filterVehicles(e);
    }
  };
  
  // filtersTranspoter = (e) => {
  //   if (e?.length > 2) {
  //     let filterData = {
  //       offset: 0,
  //       limit: 10,
  //       q: e,
  //     };
  //     this.fetchAllTransporterSearch(filterData);
  //   }
  //   if (e?.length === 0) {
  //     let filterData = {
  //       offset: 0,
  //       limit: 10,
  //       q: "",
  //     };
  //     if (this.state.allTransporter?.length === 0)
  //       this.fetchAllTransporterSearch(filterData);
  //   }
  // };

  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchTransporterOptionsSecondary((res) => {
      let transporter = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          transporter.push({
            value: ele._id ? ele._id : "",
            label:
              (ele.name ? ele.name : "NA") +
              " - " +
              (ele.userId ? ele.userId : "NA") +
              (ele.email ? " - " + ele.email : " - NA"),
          });
        });
      }

      this.setState(
        { allTransporter: transporter },
        () => {
          if (searchBar)
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "transporter") {
                obj.options = this.state.allTransporter;
              }
            });
          else
            TransportersearchBar.searchBarData.forEach((obj) => {
              if (obj.field === "transporter") {
                obj.options = this.state.allTransporter;
              }
            });
        },
        () => { }
      );
    });
  };

  // calling hops api
  fetchHops = (filtersHopsData, fieldType) => {
    this.props.fetchSourceSecondary((res) => {
      this.hops = [];
      if (res?.data?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.forEach((ele, index) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        this.setState({ hops: this.hops }, () => {
          if (searchBar)
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source") {
                obj.options = this.state.hops;
              }
            });
          else
            TransportersearchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source") {
                obj.options = this.state.hops;
              }
            });
        });
        // console.log("searchBar Data--------------------------:", searchBar?.searchBarData);

        //pagination fix for triplist as it has no callback as of now. Would need to be fixed later.
        if (
          this.props.trips?.data?.docs?.length <
          localStorage.getItem("secTripOffset")
        ) {
          offset = (localStorage.getItem("secTripOffset") / 10 - 1) * 10;
        }
      }
    });
  };

  // get location name by filter
  filtersHops = (e, fieldType) => {
    if (e?.length > 2) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData, fieldType, fieldType);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      if (this.state.hops?.length == 0)
        this.fetchHops(filtersHopsData, fieldType);
    }
  };

  filterVehicles = (e) => {
    if (e?.length > 2) {
      let filterData = {
        regNo: e,
        q: e,
      };
      this.getAllVehiclesFilters(filterData);
    }
    if (e?.length == 0) {
      let filterData = {
        regNo: "",
        q: "",
      };
      if (this.state.vehicleTypes?.length == 0) this.getAllVehicles(filterData);
    }
  };

  getAllVehiclesFilters = (filterData) => {
    let type = [];
    this.props.fetchVehicles(0, filterData, (res) => {
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            type.push({
              label: ele.type.label + " - " + ele.registration.number,
              value: {
                // id: ele._id,
                id: ele.registration.number,
              },
            });
          });
        }
        this.setState({ vehicleTypes: type }, () => {
          if (searchBar)
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "vehicle") {
                obj.options = this.state.vehicleTypes;
              }
            });
          else
            TransportersearchBar.searchBarData.forEach((obj) => {
              if (obj.field === "vehicle") {
                obj.options = this.state.vehicleTypes;
              }
            });
        });
      }
    });
  };

  getAllVehicles = (filterData) => {
    let type = [];
    this.props.fetchVehicles(0, filterData, (res) => {
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            type.push({
              label: ele.type.label + " - " + ele.registration.number,
              value: {
                // id: ele._id,
                id: ele.registration.number,
              },
            });
          });
        }
      }
    });
    this.setState({ vehicleTypes: type }, () => {
      if (searchBar)
        searchBar.searchBarData.forEach((obj) => {
          if (obj.field === "vehicle") {
            obj.options = this.state.vehicleTypes;
          }
        });
      else
        TransportersearchBar.searchBarData.forEach((obj) => {
          if (obj.field === "vehicle") {
            obj.options = this.state.vehicleTypes;
          }
        });
    });
  };

  filtersCustomers = (e) => {
    if (e?.length > 2) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchAllCustomersSearch(filterData);
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      if (this.state.allCustomers?.length === 0)
        this.fetchAllCustomersSearch(filterData);
    }
  };

  fetchAllCustomersSearch = (filtersData) => {
    this.props.fetchCustomerOptionsSecondary((res) => {
      let customers = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          customers.push({
            value: ele._id ? ele._id : "",
            label:
              (ele.customerName ? ele.customerName : "NA") +
              " - " +
              (ele.customerId ? ele.customerId : "NA") +
              (ele.shipToCode ? " - " + ele.shipToCode : " - NA"),
          });
        });
      }
      this.setState(
        { allCustomers: customers },
        () => {
          if (searchBar)
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "customers") {
                obj.options = this.state.allCustomers;
              }
            });
          else
            TransportersearchBar.searchBarData.forEach((obj) => {
              if (obj.field === "customers") {
                obj.options = this.state.allCustomers;
              }
            });
        },
        () => { }
      );
    });
  };

  getAllVehicleTypes = () => {
    let offset = 0;
    let typeVt = [];
    this.props.fetchVehicletypeOptionsSecondary((res) => {
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            typeVt.push({
              label: ele.label,
              value: {
                id: ele._id,
              },
            });
          });
        }
      }
    });
    this.setState({ vehicleTypeListOptions: typeVt });
  };

  quickFilters = (filterData, dateValue) => {
    this.setState({ dataLoading: true });
    let searchFilters = this.state.searchFilters;
    if (filterData === "delayed" || filterData === "Delayed") {
      searchFilters.delayedStatus = true;
      this.setState({ activeTripDelayed: true });
    }
    if (filterData === "otherStatus" || filterData === "otherStatus") {
      searchFilters.otherStatus = true;
      this.setState({ activeTripDelayed: true });
    }

    if (filterData === "pending" || filterData === "Pending") {
      searchFilters.pendingStatus = true;
      this.setState({ activeTripPending: true });
    }
    if (filterData === "pending" || filterData === "Pending") {
      searchFilters.pendingStatus = true;
      this.setState({ activeTripPending: true });
    }
    if (filterData === "Untracked Trips") {
      searchFilters.trackingStatus = false;
      searchFilters.unTrackedWithConsent = "";
      this.setState({
        consent: { label: "Untracked Trips", value: "Untracked Trips" },
      });
    }
    if (filterData === "Untracked with Consent") {
      searchFilters.trackingStatus = false;
      searchFilters.unTrackedWithConsent = true;
      this.setState({
        consent: {
          label: "Untracked with Consent",
          value: "Untracked with Consent",
        },
      });
    }
    if (filterData === "Untracked without Consent") {
      searchFilters.trackingStatus = false;
      searchFilters.unTrackedWithConsent = false;
      this.setState({
        consent: {
          label: "Untracked without Consent",
          value: "Untracked without Consent",
        },
      });
    }

    if (filterData === "date") {
      let date = [];
      date[0] =
        dateValue[0] !== null
          ? moment(dateValue[0]).format("MM-DD-YYYY")
          : null;
      date[1] =
        dateValue[1] !== null
          ? moment(dateValue[1]).format("MM-DD-YYYY")
          : null;
      searchFilters.startTime = date;
    }
    this.setState({ searchFilters: searchFilters }, () => {
      this.getAllData();
    });
  };

  getAllData = (changeState) => {
    let offset = 0;
    let filterData = localStorage.getItem("secondaryFilters");
    let offsetCount = localStorage.getItem("secTripOffset");
    let dataLength = localStorage.getItem("secTripTotCnt");
    let offsetCount1 = dataLength < offsetCount ? offset : offsetCount;
    let data = JSON.parse(window.localStorage.getItem("authUser"));
    localStorage.setItem("secTripOffset", offset);
    let hopsData = {
      hops: data?.data?.hops,
      hopStatus: this.state.hopStatus,
    };
    this.props.fetchTripsSecondary(
      offsetCount1,
      JSON.parse(filterData) || this.state.searchFilters,
      hopsData,
      (response) => {
        this.setState({ dataLoading: false });
        if (changeState) {
          this.setState({ checkChanged: !this.state.checkChanged });
        }
      }
    );
    if (searchBar)
      searchBar.searchBarData.forEach((obj) => {
        if (obj.field === "subStatus") {
          obj.options = tripStatusMaster;
        }
      });
    else
      TransportersearchBar.searchBarData.forEach((obj) => {
        if (obj.field === "subStatus") {
          obj.options = tripStatusMaster;
        }
      });
  };

  getCustomersExcel = (ele) => {
    // console.log(ele.customerDetails);

    let customersArray = [];
    customersArray = ele.customerDetails?.map((e, i) => {
      return `${e?.customerName} - ${e?.customerNumber} - ${e?.shipToCode} - ${e?.address?.city}`;
    });
    // console.log(customersArray);
    return customersArray.join(",");
  };

  toggleExcel = async () => {
    let offset = 0;
    this.setState({ exloading: true, export: true }); // Show loader immediately

    this.props.fetchTripsExcelSecondary(offset, this.state.searchFilters, async (res) => {
        // console.log(res, "tripsExcel");

        let trips = [];
        if (Array.isArray(res?.data?.docs)) {
            fromVal = totCnt !== 0 ? offset + 1 : fromVal;
            toVal = res?.data?.offset + res.data?.docs?.length;
            totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
            
            res.data.docs.forEach((ele) => {
                trips.push({
                    TripId: ele.tripId || "N/A",
                    IndentId: ele.indentIdInOrder || null,
                    Driver: ele?.driver?.name || "N/A",
                    Phone: ele?.driver?.phone || "N/A",
                    Source: ele.source ? ele.source.label : "N/A",
                    Customers: ele.customers ? this.getCustomersExcel(ele) : "N/A",
                    Vehicle: ele?.vehicle?.regNo || "",
                    VehicleType: ele?.vehicle?.type?.label || "",
                    Transporter: ele.transporter?.name || "N/A",
                    StartTime: ele.startTime ? moment(ele.startTime).format("DD-MM-YYYY h:mm A", true) : "---",
                    Status: ele.subStatus || "N/A",
                });
            });
        }
        // console.log(trips, "tripsExcel");

        await this.exportToCSV([...trips], "SECONDARY_TRIP_DATA_EXCEL");

        this.setState({ exloading: false, export: false }); // Hide loader after export
    });
};


  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false, exloading: false });
  };

  transformStatus = (status, path, customerDetails) => {
    let newStatus = status.split("_").join(" ");
    // switch (newStatus) {
    //   case "in transit":
    //     let nextHop;
    //     let epodCompletedCustomers = [];
    //     for (let i = 1; i < path.path.length; i++) {
    //       customerDetails.forEach((ele) => {
    //         if (
    //           path.path[i].customer_id === ele._id &&
    //           path.path[i].status !== "epod_completed"
    //         ) {
    //           epodCompletedCustomers.push(ele.customerNumber);
    //         }
    //       });
    //     }
    //     // path.path.forEach((ele, index) => {
    //     //   if (ele.status !== "epod_completed") {
    //     //     epodCompletedCustomers.push(ele.hopCode);
    //     //   }
    //     // });
    //     return (
    //       <div className="tile-text text-capitalize h6">
    //         <div style={{ color: "#A020F0" }}>{" " + newStatus}</div>
    //         <div
    //           style={{ color: "#05284E", fontSize: "small" }}
    //           className="dark__span"
    //         >
    //           <span></span>
    //           {epodCompletedCustomers.length > 0
    //             ? "Cutomers : " + epodCompletedCustomers.map((e) => e)
    //             : " "}
    //         </div>
    //         <div>
            
    //           <svg
    //             className="ml-1"
    //             width="20"
    //             height="20"
    //             viewBox="0 0 12 12"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <rect
    //               width="12"
    //               height="12"
    //               rx="1"
    //               fill={
    //                 nextHop?.operations?.unloading === null ||
    //                   nextHop?.operations?.unloading === false
    //                   ? "#FFBEB8"
    //                   : "#9EEAB4"
    //               }
    //             />
    //             <path
    //               d="M9 7V8.33333C9 8.51014 8.92976 8.67971 8.80474 8.80474C8.67971 8.92976 8.51014 9 8.33333 9H3.66667C3.48986 9 3.32029 8.92976 3.19526 8.80474C3.07024 8.67971 3 8.51014 3 8.33333V7"
    //               stroke={
    //                 nextHop?.operations?.unloading === null ||
    //                   nextHop?.operations?.unloading === false
    //                   ? "#AC1000"
    //                   : "#069406"
    //               }
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //             />
    //             <path
    //               d="M7.6665 4.66669L5.99984 3.00002L4.33317 4.66669"
    //               stroke={
    //                 nextHop?.operations?.unloading === null ||
    //                   nextHop?.operations?.unloading === false
    //                   ? "#AC1000"
    //                   : "#069406"
    //               }
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //             />
    //             <path
    //               d="M5.99951 3L5.99951 7"
    //               stroke={
    //                 nextHop?.operations?.unloading === null ||
    //                   nextHop?.operations?.unloading === false
    //                   ? "#AC1000"
    //                   : "#069406"
    //               }
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //             />
    //           </svg>
    //         </div>
    //       </div>
    //     );
    //   case "loading in progress":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#AC1000" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "quality check completed":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#28A34C" }}
    //       >
    //         {"quality check completed"}
    //       </div>
    //     );
    //   case "cancelled":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#f75c5c" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "planned":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#E19600" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "Planned":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#E19600" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "completed":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#006400" }}
    //       >
    //         <span>Completed</span>
    //       </div>
    //     );
    //   case "arrived at source":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#FF6F00" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "shipment verified":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#6900BB" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "epod in progress":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#6900BB" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "epod completed":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#6900BB" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   case "rejected":
    //     return (
    //       <div
    //         className="tile-text text-capitalize h6"
    //         style={{ color: "#890229" }}
    //       >
    //         {" " + newStatus}
    //       </div>
    //     );
    //   default:
    //     return <div className="tile-text">{newStatus}</div>;
    // }
    switch (newStatus) {
      case "in transit":
        let nextHop;
        path.path.forEach((ele) => {
          if (ele._id === path.next) nextHop = ele;
        });
        // console.log("chan", nextHop);
        return (
          <div className="tile-text text-capitalize h6 ">
            <div style={{ color: "#A020F0" }} className="statusRound intransit-color">{" " + newStatus}</div>
          
          </div>
        );
      case "loading in progress":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound loadingprogress-color"
            style={{ color: "#AC1000" }}
          >
            {" " + newStatus}
          </div>
        );
      case "quality check completed":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound qualitycompleted-color"
            style={{ color: "#28A34C" }}
          >
            {"quality check completed"}
          </div>
        );
      case "cancelled":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound cancelled-color"
            style={{ color: "#f75c5c" }}
          >
            {" " + newStatus}
          </div>
        );
      case "planned":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound planned-color"
            style={{ color: "#E19600" }}
          >
            {" " + newStatus}
          </div>
        );
      case "Planned":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound planned-color"
            style={{ color: "#E19600" }}
          >
            {" " + newStatus}
          </div>
        );
      case "completed":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound completed-color"
            style={{ color: "#006400" }}
          >
            <span>Completed</span>
          </div>
        );
      case "arrived at source":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound source-color"
            style={{ color: "#FF6F00" }}
          >
            {" " + newStatus}
          </div>
        );
      case "arrived at via":
        let viaHop;
        path.path.forEach((ele) => {
          if (ele._id === path.current) viaHop = ele;
        });
        return (
          <div>
            <div className="text-capitalize h6 tile-text">
              <div className="statusRound source-color" style={{ color: "#FF6F00" }}>
                {" " + newStatus}
              </div>
             
            </div>
          </div>
        );
      case "shipment verified":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound shipment-color"
            style={{ color: "#6900BB" }}
          >
            {" " + newStatus}
          </div>
        );
      case "epod in progress":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound progress-color"
            style={{ color: "#6900BB" }}
          >
            {" " + newStatus}
          </div>
        );
      case "epod completed":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound progress-color"
            style={{ color: "#6900BB" }}
          >
            {" " + newStatus}
          </div>
        );
      case "rejected":
        return (
          <div
            className="tile-text text-capitalize h6 statusRound rejected-color"
            style={{ color: "#890229" }}
          >
            {" " + newStatus}
          </div>
        );
      default:
        return <div className="tile-text">{newStatus}</div>;
    }
  };

  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true });
    // let key;
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    // if (this.state.searchKey === "") {
    //   key = "";
    // } else key = this.state.searchKey.value;
    let filterData = localStorage.getItem("secondaryFilters");
    localStorage.setItem("secTripOffset", offset);
    let data = JSON.parse(window.localStorage.getItem("authUser"));
    localStorage.setItem("secTripOffset", offset);
    let hopsData = {
      hops: data?.data?.hops,
      hopStatus: this.state.hopStatus,
    };
    this.props.fetchTripsSecondary(
      offset,
      JSON.parse(filterData) || this.state.searchFilters,
      hopsData,
      (response) => {
        this.setState({ dataLoading: false });
        localStorage.setItem("secTripOffset", 0);
      }
    );
  };

  searchValueAssignerFunction = (name, value, type) => {
    this.setState({ dataLoading: true });
    if (typeof value === "object") {
      if (type === "date") searchValues[name] = value;
      else searchValues[name] = value.id;
    } else {
      searchValues[name] = value;
    }
    this.setState({ searchFilters: searchValues }, 
      () => {
      localStorage.setItem(
        "secondaryFilters",
        JSON.stringify(this.state.searchFilters)
      );
      // if (type === "select" || type === "date")
         this.getAllData();
    });
  };

  reset = () => {
    let data = JSON.parse(window.localStorage.getItem("authUser"));
    localStorage.setItem("secTripOffset", offset);
    let hopsData = {
      hops: data?.data?.hops,
      hopStatus: this.state.hopStatus,
    };
    this.setState(
      {
        searchFilters: {},
        activeTripDelayed: false,
        activeTripUntrack: false,
        dataLoading: true,
        consent: "",
      },
      () => {
        searchValues = {};
        this.props.fetchTripsSecondary(
          0,
          this.state.searchFilters,
          hopsData,
          (response) => {
            this.setState({ dataLoading: false });
          }
        );
      }
    );
    localStorage.removeItem("secondaryFilters");
    localStorage.removeItem("secTripOffset");
  };

  driverPopulate = (value) => {
    let name;
    let phone;
    if (value.name !== "") {
      name = value.name;
    } else name = "----";
    if (value.phone !== "") phone = value.phone;
    else phone = "----";
    if (name === "----" && phone === "----") return "-----";
    // else return name + " (" + phone + ")";
    else
      return (
        // <>
        //   <div>{name}</div>
        //   <div>{" (" + phone + ")"}</div>
        // </>
        <>
        <div className="d-flex justify-content-between align-items-center  comn140" >
          <div className="vehical-sec ellipsis" title={name}>{name}</div>
          <div className="tw-flex d-flex">
            {phone !== "----" && (
              <span className="phone-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="3.5"
                    fill="#2800FC"
                    fillOpacity="0.1"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="3.5"
                    stroke="#2800FC"
                  />
                  <g clipPath="url(#clip0_9041_2700)">
                    <path
                      d="M12.1666 10.0497V11.2997C12.1671 11.4158 12.1433 11.5306 12.0968 11.6369C12.0503 11.7433 11.9821 11.8387 11.8966 11.9172C11.8111 11.9956 11.7102 12.0553 11.6002 12.0925C11.4903 12.1297 11.3738 12.1435 11.2583 12.133C9.97611 11.9937 8.74451 11.5556 7.66243 10.8539C6.65569 10.2142 5.80215 9.36062 5.16243 8.35388C4.45826 7.26688 4.02003 6.0293 3.88326 4.74138C3.87285 4.62616 3.88655 4.51003 3.92347 4.40039C3.9604 4.29075 4.01975 4.19 4.09775 4.10456C4.17575 4.01911 4.27068 3.95084 4.37651 3.9041C4.48234 3.85735 4.59674 3.83316 4.71243 3.83305H5.96243C6.16464 3.83106 6.36068 3.90266 6.514 4.03452C6.66732 4.16638 6.76746 4.34948 6.79576 4.54971C6.84852 4.94974 6.94637 5.34252 7.08743 5.72055C7.14349 5.86968 7.15562 6.03176 7.12239 6.18758C7.08916 6.3434 7.01196 6.48643 6.89993 6.59971L6.37076 7.12888C6.96391 8.17203 7.82762 9.03573 8.87076 9.62888L9.39993 9.09972C9.51322 8.98769 9.65625 8.91049 9.81206 8.87725C9.96788 8.84402 10.13 8.85615 10.2791 8.91221C10.6571 9.05328 11.0499 9.15112 11.4499 9.20388C11.6523 9.23244 11.8372 9.33438 11.9693 9.49034C12.1015 9.64629 12.1717 9.84537 12.1666 10.0497Z"
                      fill="#2800FC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_9041_2700">
                      <rect width="10" height="10" fill="white" transform="translate(3 3)" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="phone-tooltip">
                  <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.14316 7.06534V8.31534C9.14363 8.43138 9.11986 8.54624 9.07337 8.65257C9.02688 8.75889 8.9587 8.85433 8.87319 8.93278C8.78768 9.01123 8.68673 9.07096 8.57681 9.10813C8.46688 9.14531 8.3504 9.15912 8.23483 9.14867C6.95267 9.00936 5.72108 8.57123 4.63899 7.86951C3.63226 7.22978 2.77872 6.37625 2.13899 5.36951C1.43482 4.28251 0.996595 3.04492 0.859827 1.75701C0.849415 1.64178 0.863108 1.52566 0.900036 1.41602C0.936963 1.30638 0.996315 1.20563 1.07431 1.12018C1.15231 1.03474 1.24725 0.966469 1.35307 0.919724C1.4589 0.872979 1.5733 0.848782 1.68899 0.848673H2.93899C3.1412 0.846683 3.33724 0.918289 3.49056 1.05015C3.64388 1.182 3.74403 1.36511 3.77233 1.56534C3.82509 1.96537 3.92293 2.35814 4.06399 2.73617C4.12005 2.88531 4.13219 3.04739 4.09895 3.20321C4.06572 3.35903 3.98852 3.50205 3.87649 3.61534L3.34733 4.14451C3.94048 5.18765 4.80418 6.05136 5.84733 6.64451L6.37649 6.11534C6.48978 6.00331 6.63281 5.92611 6.78863 5.89288C6.94445 5.85965 7.10653 5.87178 7.25566 5.92784C7.63369 6.0689 8.02647 6.16675 8.42649 6.21951C8.6289 6.24806 8.81374 6.35001 8.94588 6.50596C9.07802 6.66192 9.14823 6.861 9.14316 7.06534Z" fill="#D9D9D9" />
                  </svg>

                  {phone}</span>
              </span>
            )}
          </div>
        </div>

      </>
      );
  };

  markerAsArrivedChangeStatus = (ele) => {
    this.props.change_arrivedAtSource_statusSecondary(ele._id, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData(true);
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  markerAsArrivedChangeStatusInTransit = () => {
    this.props.change_MarkAsArrived_InTransit_statusSecondary(
      this.state.MarkedAsArrivedPopupPayload,
      (res) => {
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
          this.getAllData(true);
          this.closePopup();
        } else {
          if (this.state.MarkedAsArrivedPopupPayload.tripId) {
            NotificationManager.error(res["message"], "Error");
          } else {
            NotificationManager.success(
              "Mark as Arrived already done",
              "Success"
            );
            this.closePopup();
          }
        }
      }
    );
  };

  routingTOTripDetails = (tripDetails) => {
    this.props.history.push({
      pathname: "/secondary-tripAllDetails",
      state: { tripDetails: tripDetails },
    });
  };

  toggleModalApproval = (eleProps) => {
    this.setState({
      modalApproval: !this.state.modalApproval,
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: this.state.vehicleLabel ? this.state.vehicleLabel : null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };

  editDetailButton = (ele) => {
    this.setState({
      tripIdEditPop: ele?.tripId ? ele?.tripId : "",
      transpoterId: ele?.transporter?._id,
    });
    this.props.fetchVehicles(
      offset,
      {
        transporterId: ele?.transporter?._id,
      },
      (res) => {
        this.setState({ transporterVehicleOptions: res.data.docs });
      }
    );
    this.props.fetchDrivers(
      offset,
      "",
      "",
      {
        q: "",
        transporterId: ele?.transporter?._id,
      },
      (res) => {
        this.setState({ transporterDriverOptions: res.data.docs });
      }
    );
    this.setState(
      {
        indentId: ele.indentId,
        transporter: ele.transporter?._id,
        tripId: ele._id,
        vehicle: {
          value: ele?.vehicle?.id,
          label: ele?.vehicle?.regNo
            ? ele?.vehicle?.regNo + " (" + ele?.vehicle?.type?.label + ")"
            : "Select",
        },
        vehicleLabel: {
          value: ele?.vehicle?.type?._id ? ele?.vehicle?.type?._id : "",
          label: ele?.vehicle?.type?.label
            ? ele?.vehicle?.type?.label
            : "Select",
        },
        driver: {
          value: ele?.driver?.id,
          label: ele?.driver?.phone
            ? ele?.driver?.phone + " (" + ele?.driver?.name + ")"
            : "Select",
        },
      },
      () => {
        this.toggleModalApproval(ele);
      }
    );
  };

  handleResetEdit = () => {
    this.setState({
      vehicle: "",
      vehicleLabel: "",
      driver: "",
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };

  handleChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  filterVehicleList = (e) => {
    if (e.length > 2) {
      this.props.fetchVehicles(
        null,
        {
          regNo: e,
          q: e,
          transporterId: this.state.transpoterId,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
    if (e.length === 0) {
      this.props.fetchVehicles(
        offset,
        {
          q: "",
          transporterId: this.state.transpoterId,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
  };

  filterDriverList = (e) => {
    var regexNumber = /^[0-9]+$/;
    var regexString = /^[a-zA-Z]+$/;
    if (e.length > 2) {
      if (e.match(regexNumber)) {
        this.props.fetchDrivers(offset, "", "", { phone: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
      }
      if (e.match(regexString))
        this.props.fetchDrivers(offset, "", "", { name: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
    }
    if (e.length === 0) {
      this.props.fetchDrivers(
        offset,
        "",
        "",
        { q: "", transporterId: this.state.transpoterId },
        (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        }
      );
    }
  };

  handleSubmitSaveVehicle = () => {
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
    } = this.state.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner,
      transporter: this.state.transporter,
      regNo: VehicleRegistrationNumber,
      type: type?.value ? type?.value : "",
    };
    this.setState({ onSubmitDisableVehicle: true });
    this.props.createVehicle(formObj, (res) => {
      if (res.success) {
        this.props.fetchVehicles(
          0,
          {
            q: "",
            transporterId: this.state.transpoterId,
          },
          (res) => {
            this.setState({ transporterVehicleOptions: res.data.docs });
          }
        );
        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableVehicle: false, vehicleLabel: "" });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableVehicle: false,
          vehicle: "",
          vehicleLabel: "",
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    });

    setTimeout(() => {
      let vh;
      if (this.state.vehicleForm.VehicleRegistrationNumber !== "") {
        if (Array.isArray(this.state.transporterVehicleOptions)) {
          vh = this.state.transporterVehicleOptions.filter((v) => {
            return (
              v.registration.number ===
              this.state.vehicleForm.VehicleRegistrationNumber
            );
          });
        }
        this.setState({
          vehicle: {
            value: vh[0]?._id,
            label:
              vh[0]?.registration?.number + " (" + vh[0]?.type?.label + ")",
          },
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    }, 4000);
  };

  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name === "vehicleType") {
      vf.type = value;
    }
    if (name === "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name === "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value.toString().toUpperCase();
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      transporter: this.state.transporter,
      phone,
      licence: { number: driverLicenseNo },
    };
    this.setState({ onSubmitDisableDriver: true });
    if (Object.keys(formObj.licence && formObj.phone).length > 0) {
      this.props.createDriver(formObj, (res) => {
        if (res.message === "Driver has been created successfully") {
          this.props.fetchDrivers(
            0,
            "",
            "",
            { q: "", transporterId: this.state.transpoterId },
            (res) => {
              this.setState({
                transporterDriverOptions: res.data.docs,
              });
            }
          );

          NotificationManager.success(res["message"], "Success");
          this.setState({ onSubmitDisableDriver: false });
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({
            onSubmitDisableDriver: false,
            driver: "",
            driverForm: {
              fullName: "",
              phone: "",
              driverLicenseNo: "",
              transporter: "",
            },
          });
        }
      });
    } else {
      NotificationManager.error(" All Field required");
    }

    setTimeout(() => {
      let dt;
      if (this.state.driverForm.phone !== "") {
        if (Array.isArray(this.state.transporterDriverOptions)) {
          dt = this.state.transporterDriverOptions.filter((v) => {
            return v.phone === this.state.driverForm.phone;
          });
        }
        this.setState({
          driver: {
            value: dt[0]?._id,
            label: dt
              ? dt[0]?.phone + " (" + (dt[0]?.name ? dt[0]?.name : "N/A") + ")"
              : this.state.driverShow.phone,
          },
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
        });
      }
    }, 4000);
  };

  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name === "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name === "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name === "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };

  resetSelection = () => {
    this.setState({ vehicle: "", driver: "", onSubmitDisable: false });
  };

  // toggleCustomerDropdown = (index) => {
  //   let customerData = this.state?.customerDropDown;
  //   customerData[index] = !this.state?.customerDropDown[index];

  //   this.setState({
  //     customerDropDown: customerData,
  //     checkChanged: !this.state.checkChanged,
  //   });
  //   this.props.setTripCustomerDropDown(index);
  // };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.props.setTripCustomerDropDownFalse();
      let customerData = this.state.customerDropDown;
      Object.entries(customerData).forEach(([key, value]) => {
        customerData[key] = false;
      });
      this.setState({
        customerDropDown: customerData,
        checkChanged: true,
      });
    }
  };

  qualityDetailButton = (ele) => {
    this.setState({
      showQualityCheck: !this.state.showQualityCheck,
      TripData: ele,
    });
  };

  toggleVerifyShipmentModal = (ele, status) => {
    this.setState({
      VerifyShipmentModal: !this.state.VerifyShipmentModal,
      shipdetails: ele,
      missedShiped: status?.missedShip,
    });
  };

  handleSubmitverify = ({ verify, callback }) => {
    this.props.addShipmentVerifySecondary(verify, (res) => {
      this.setState({
        verifymsg:
          res.message === "Not Verified Successfully"
            ? `${res.message} - Shipment number does not match with Oracle Ebiz for selected source location or truck number`
            : res.message,
      });
      if (res.success === false) {
        NotificationManager.error(res["message"], "Error");
        this.getAllData(true);
      } else {
        NotificationManager.success(res["message"], "Success");
        this.setState({ VerifyShipmentModal: false });
        this.getAllData(true);
      }
    });
  };

  verifyMsgReset = () => {
    this.setState({ verifymsg: "" });
  };

  startLoading = (ele) => {
    this.props.startLoadingSecondary(ele, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData(true);
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  verifyButton = (ele, status) => {
    // console.log(status);
    this.setState({ dataLoading: true, driver: ele?.driver });
    let phone = ele.driver?.phone;
    this.props.subscribeConsentSecondary(phone, (res) => {
      // console.log(res?.data?.data?.status);
      if (res.statusCode === 200)
        NotificationManager.success(res["message"], "Success");
      this.setState({
        statusSubcribeConsent: res?.data?.data?.status,
        dataLoading: false,
        operator: res?.data?.data?.operator || "----",
      });
    });
    this.toggleVerifyShipmentModal(ele, status);
    // console.log(this.state.statusSubcribeConsent);
  };

  startUnloading = (ele, status) => {
    // console.log("=-=-=-=-", this.state.startUnloadingPopupPayload)
    this.props.startUnloadingSecondary(
      this.state.startUnloadingPopupPayload,
      (res) => {
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
          this.closePopupStartUnloading();
          this.EPODFunction(ele);
          this.getAllData(true);
        } else {
          if (this.state.startUnloadingPopupPayload.tripID) {
            NotificationManager.error(res["message"], "Error");
          } else {
            this.EPODFunction(ele);
          }
        }
      }
    );
  };

  EPODFunction = async (ele) => {
    this.setState({ tripDetailsForEPOD: ele }, () => {
      this.props.history.push({
        pathname: "/secondary-EPODIndex",
        state: { tripDetailsForEPOD: this.state.tripDetailsForEPOD },
      });
    });
    // let flag = await this.EPODModalToggle();
    // if (flag) this.props.startUnloading("i182912i1y");
  };

  cancelTripModal = (ele) => {
    this.setState({
      showCancelTrip: !this.state.showCancelTrip,
      tripIdCancelPop: ele?.tripId ? ele?.tripId : "",
    });
  };

  cancelTripDetailSaver = (ele) => {
    this.setState({ cancelTripDetails: ele });
    this.cancelTripModal(ele);
  };

  finishLoading = (ele) => {
    this.props.finishLoadingSecondary(ele, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData(true);
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  routingTOTripTracking = (ele) => {
    this.props.history.push({
      pathname: "/secondary-Tracking",
      state: { tripId: ele._id, transporterId: ele?.transporter?._id },
    });
  };

  setMarkedAsArrivedToggle = (ele) => {
    this.setState({
      MarkedAsArrivedPopupPayload: {},
      markedAsArrivedInTransitData: ele,
      markedAsArrivedToggle: !this.state.markedAsArrivedToggle,
      checkChanged: !this.state.checkChanged,
    });
  };

  startUnloadingToggle = (ele) => {
    // console.log("=-=-=-=-=", ele)

    this.setState({
      startUnloadingPopupPayload: {
        tripID: ele?.tripId.toString(),
        customer_id: ele?.customers
      },
      startUnloadingInTransitData: ele,
      startUnloadingToggle: !this.state.startUnloadingToggle,
      checkChanged: !this.state.checkChanged,
    });
  };

  closePopup = () => {
    if (this.state.markedAsArrivedToggle) {
      this.setState({
        markedAsArrivedToggle: !this.state.markedAsArrivedToggle,
        checkChanged: !this.state.checkChanged,
      });
    }
  };

  closePopupStartUnloading = () => {
    if (this.state.startUnloadingToggle) {
      this.setState({
        startUnloadingToggle: !this.state.startUnloadingToggle,
        checkChanged: !this.state.checkChanged,
      });
    }
  };

  checkboxClickedMarkAsArrived = (index, tripId, customerId) => {
    this.setState({
      MarkedAsArrivedPopupPayload: {
        tripID: tripId,
        customer_id: customerId,
      },
    });
  };

  checkboxClickedStartUnloading = (index, tripId, customerId) => {
    this.setState({
      startUnloadingPopupPayload: {
        tripID: tripId,
        customer_id: customerId,
      },
    });
  };

  

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let data = [];
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];
    let LocalData = JSON.parse(localStorage.getItem("SecondaryTripData"));
    var PWATest = navigator.onLine ? this.props.trips?.data : LocalData?.data;
    let vehicleType = this.props.vehicleType.vehicleType;
    if (Array.isArray(PWATest?.docs)) {
      data = [];
      offset = PWATest?.offset;
      totCnt = PWATest?.length;
      localStorage.setItem("totCnt", totCnt);
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = PWATest?.offset + PWATest?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      PWATest.docs.forEach((ele, index) => {
        data.push({
          id: ele._id,
          tripId: <div className="abc">{ele.tripId ? ele.tripId : "-----"}</div>,
          indentId: ele.indentIdInOrder ? ele.indentIdInOrder : "-----",
          driver: ele.driver ? this.driverPopulate(ele.driver) : "-----",
          source:<div className="ellipsis  comn130" title={ele.source.label}>{ele.source ? ele.source.label : "-----"}</div>,


          customers: ele.customerDetails ? (
            <>
          
              <div className="route-container comn180" ref={this.wrapperRef}>
                <div className="" >
             
                  <div className=" divDropdown2">
                         {/* Show only the first customer */}
                    <div className="divDropdownText somecalsses2 ellipsis "

                    >
                      <div className="">
                        {ele?.customerDetails[0]?.customerName || "-----"}
                      </div>
                      <div>
                        - {ele?.customerDetails[0]?.customerNumber } -{" "}
                        {ele?.customerDetails[0]?.shipToCode} -{" "}
                      </div>
                      <div className="">
                        {ele?.customerDetails[0]?.address?.city}
                      </div>
                    </div>

                    {/* Show dropdown icon if more than one customer */}
                    {ele?.customerDetails.length > 0 && (
                      <span className="icon-container" >
                        <span className="total-labels">{ele?.customerDetails.length}</span>

                        {/* Tooltip with remaining customers */}
                        <div className="showroute">
                          {ele?.customerDetails.map((item, i) => (
                            <div key={i} className="tooltip-item">
                              <span className="timeline-dot"></span>
                              <span>
                                {item?.customerName} - {item?.customerNumber} -{" "}
                                {item?.shipToCode} - {item?.address?.city}
                              </span>
                            </div>
                          ))}
                        </div>
                      </span>
                    )}
                  </div>
                </div>
              </div>

            </>
          ) : (
            "-----"
          ),
          vehicle:
            (
              <>
              <div className="d-flex vehical-sec ellipsis comn150" title={`${ele.vehicle?.regNo || "-----"} ${ele.vehicle?.type?.label || "-----"}`}
              >
              <p style={{ padding: "0px", margin: "0px" }}>
                  {ele.vehicle?.regNo}
                </p>
                <p style={{ padding: "0px", margin: "0px" }}>
                  {ele.vehicle?.type?.label}
                </p>
              </div>
              </>
            ) || "-----",
          // transporter: ele.transporter?.name || "-----",
          transporter: ( <div className="vehical-sec comn150">  {ele.transporter?.name || "-----"} </div>  ),
          //   route: ele.via.length ? <>{this.route(ele.via)}</> : "DIRECT",
          description: ele.description,
          startTime: ele?.startTime ? formatDate(ele.startTime) : "-----",
          expiryTime: ele?.expiryTime ? formatDate(ele.expiryTime) : "-----",
          subStatus: this.transformStatus(
            ele?.subStatus,
            ele?.secondaryFreighttracking,
            ele.customerDetails
          ),
          actions: actions(ele, {
            routingTOTripDetails: this.routingTOTripDetails,
            cancelTripDetailSaver: this.cancelTripDetailSaver,
            routingTOTripTracking: this.routingTOTripTracking,
            startLoading: this.startLoading,
            finishLoading: this.finishLoading,
            verifyButton: this.verifyButton,
            editDetailButton: this.editDetailButton,
            markerAsArrivedChangeStatus: this.markerAsArrivedChangeStatus,
            markerAsArrivedChangeStatusInTransit:
              this.markerAsArrivedChangeStatusInTransit,
            tripDetailToggleModel: this.tripDetailToggleModel,
            skipViaApiCall: this.skipViaApiCall,
            qualityDetailButton: this.qualityDetailButton,
            EPODFunction: this.EPODFunction,
            startUnloading: this.startUnloading,
            markedAsArrivedToggle: this.state.markedAsArrivedToggle,
            setMarkedAsArrivedToggle: this.setMarkedAsArrivedToggle,
            startUnloadingToggle: this.startUnloadingToggle,
          }),
        });
      });
      if (Array.isArray(vehicleType?.data?.docs)) {
        // console.log(vehicleType.data.docs);
        vehicleType.data.docs.forEach((ele) => {
          vehicleTypeOptions.push({
            value: ele._id,
            label: ele.vehicleTypeCode,
          });
        });
      }
      if (Array.isArray(this.state.transporterVehicleOptions)) {
        this.state.transporterVehicleOptions.forEach((ele) => {
          vehicleOptions.push({
            value: ele._id,
            label: ele.registration.number + " (" + ele.type.label + ")",
          });
        });
      }
      if (Array.isArray(this.state.transporterDriverOptions)) {
        this.state.transporterDriverOptions.forEach((ele) => {
          driverOptions.push({
            value: ele._id,
            label: ele.phone + " (" + ele.name + ")",
          });
        });
      }
    }
    // console.log("searchBar-----------",searchBar)
    // this.fetchHops();

    return (
      <>
        {getLocalStorage()}
        <QualityCheckButton
          isOpen={this.state.showQualityCheck}
          toggleModel={this.qualityDetailButton}
          TripData={this.state.TripData}
          getAllData={() => {
            this.getAllData(true);
          }}
          mode={darkMode}
        />
        <ApproveModal
          handleResetEdit={this.handleResetEdit}
          isOpen={this.state.modalApproval}
          toggle={this.toggleModalApproval}
          onChange={this.handleChangeModal}
          options={vehicleOptions}
          optionsdriver={driverOptions}
          driver={this.state.driver}
          vehicle={this.state.vehicle}
          vehicleLabel={this.state.vehicleLabel}
          submit={this.handleSubmitApprove}
          disabledVehicle={this.state.onSubmitDisableVehicle}
          disabledDriver={this.state.onSubmitDisableDriver}
          indentIndex={this.state.indentId}
          filterVehicleList={this.filterVehicleList}
          filterDriverList={this.filterDriverList}
          type={vehicleTypeOptions}
          refreshData={() => {
            this.getAllData(true);
          }}
          submitVehicleForm={this.handleSubmitSaveVehicle}
          onVehicleChange={this.onVehicleChange}
          vehicleForm={this.state.vehicleForm}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          resetSelection={this.resetSelection}
          isTrip={true}
          tripIndex={this.state.tripId}
          // vehicleDetails={this.state.vehicleDetails}
          // driverDetails={this.state.driverDetails}
          toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
          transporter={this.state.transporter}
          tripIdEditPop={
            this.state.tripIdEditPop ? this.state.tripIdEditPop : ""
          }
          mode={darkMode}
        />
        <VerifyShipmentModalSecondary
          isOpen={this.state.VerifyShipmentModal}
          toggle={this.toggleVerifyShipmentModal}
          onChange={this.handleChangeModal}
          shipdetails={this.state.shipdetails ? this.state.shipdetails : {}}
          operator={this.state.operator ? this.state.operator : ""}
          statusSubcribeConsent={this.state.statusSubcribeConsent}
          // options={this.vehicleRegNoArr}
          // optionsdriver={this.driversList}
          driver={this.state.driver}
          // vehicle={this.state.vehicle}
          submit={this.handleSubmitverify}
          verify={this.state.verify}
          disabled={this.state.onSubmitDisable}
          verifymsg={this.state.verifymsg}
          mode={darkMode}
          verifyMsgReset={this.verifyMsgReset}
          missedShipment={
            this.state.missedShiped ? this.state.missedShiped : ""
          }
        />

        <PopUp
          isOpen={this.state.markedAsArrivedToggle}
          tripDataPopup={this.state.markedAsArrivedInTransitData}
          markedAsArrivedPopupPayload={this.state.MarkedAsArrivedPopupPayload}
          tripDataPopupFunction={this.markerAsArrivedChangeStatusInTransit}
          closePopup={this.closePopup}
          checkboxClicked={this.checkboxClickedMarkAsArrived}
          popupName="MarkAsArrived"
        />

        <PopUp
          isOpen={this.state.startUnloadingToggle}
          tripDataPopup={this.state.startUnloadingInTransitData}
          tripDataPopupFunction={this.startUnloading}
          closePopup={this.closePopupStartUnloading}
          checkboxClicked={this.checkboxClickedStartUnloading}
          popupName="StartUnloading"
        />

        <div>
 
          <Container
            aria-hidden="true"
            fluid
            className={`${darkMode ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
              }`}
            style={{ maxHeight: "96vh", overflowX: "hidden", paddingLeft: "0px", paddingRight: "0px" }}
          >
            {userType && userType === "transporter" ? (
              <Index
                {...this.props}
                headers={TransporterHeaderData}
                data={data}
                searchable={true}
                searchBar={searchBar}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={this.props.trips?.data?.length}
                fetchId={this.props.fetchId}
                dataLoading={this.state.dataLoading}
                filterDataTranspotersAndTrips={
                  this.filterDataTranspotersAndTrips
                }
                checkChanged={this.state.checkChanged}
                toggleExcel={this.toggleExcel}
                 exportLoading={this.state.exloading} 
                 tableType={'triptable'}
              />
            ) : (
              <Index
                {...this.props}
                headers={HeaderData}
                data={data}
                searchable={true}
                searchBar={searchBar}
                reset={this.reset}
                searchValueAssignerFunction={this.searchValueAssignerFunction}
                getAllData={this.getAllData}
                dataLength={this.props.trips?.data?.length}
                fetchId={this.props.fetchId}
                dataLoading={this.state.dataLoading}
                filterDataTranspotersAndTrips={
                  this.filterDataTranspotersAndTrips
                }
                checkChanged={this.state.checkChanged}
                toggleExcel={this.toggleExcel} //excel export
                exportLoading={this.state.exloading} //excel export
                tableType={'triptable'}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{
                  color: darkMode ? "#9D9D9D" : "#0C0C0C",
                  paddingTop: "150px !important",
                }}
                className="mb-1 mt-2 paginationRow"
              >
                  <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={5}
                  md={5}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
                <Col xs={7} sm={7} md={7} className="span-col-pagenation">
                  <div className="float-right">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 30 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
              
              </Row>
            ) : null}
          </Container>
        </div>
        <SecondaryTripCancelModal
          showCancelTrip={this.state.showCancelTrip}
          cancelTripDetails={this.state.cancelTripDetails}
          cancelTripModal={this.cancelTripModal}
          getAllData={() => {
            this.getAllData(true);
          }}
          tripIdCancelPop={
            this.state.tripIdCancelPop ? this.state.tripIdCancelPop : ""
          }
          mode={darkMode}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.SecondaryTrip.secondaryTrips.secondaryTrips,
    fetchId: state.Trip.trips.fetchId,
    vehicleType: state.VehicleType.vehicleType,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
  };
};

export default connect(mapStateToProps, {
  fetchSourceSecondary,
  fetchTransporterOptionsSecondary,
  fetchVehicletypeOptionsSecondary,
  fetchVehicles,
  fetchDrivers,
  createDriver,
  createVehicle,
  fetchCustomerOptionsSecondary,
  fetchTripsSecondary,
  fetchTripsExcelSecondary,
  setTripCustomerDropDown,
  setTripCustomerDropDownFalse,
  change_arrivedAtSource_statusSecondary,
  change_MarkAsArrived_InTransit_statusSecondary,
  startLoadingSecondary,
  startUnloadingSecondary,
  subscribeConsentSecondary,
  shipmentVerifySecondary,
  addShipmentVerifySecondary,
  finishLoadingSecondary,
})(SecondaryTrips);
