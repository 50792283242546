import { types } from "./actionTypes";

export const fetchRoutes = (offset, source, destination, callback) => ({
  type: types.FETCH_ROUTES,
  offset,
  source,
  destination,
  callback,
});
export const fetchRoutesForMaster = (offset,filters, callback) => ({
  type: types.FETCH_ROUTES_FOR_MASTER,
  offset,
  filters,
  callback,
});
