import React from "react";
import { Button } from "reactstrap";
import actionVerticleDots from "../../../../../../src/pages/Trips/assets/more-horizontal.svg";

export const actions = (actionString, ele, routingToIndentData) => {
  // console.log(actionString, "--- status");
  var darkMode = document.body.classList.contains("dark__mode");
  // console.log("darkMode",darkMode);
  switch (actionString) {
    case "Accepted":
      return (
        <div className="dropdown ">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToProvideDetailModel(ele);
              }}
            >
              Provide Details
            </div>
            {/* {darkMode?<hr className="actionLine"></hr>:""} */}
            <div
              className="action-button-cancel"
              onClick={() => {
                routingToIndentData.routingToCancelIndentModel(ele);
              }}
            >
              Cancel
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
          </div>
        </div>
      );
    case "Rejected":
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToDraftToFinal(ele);
              }}
            >
              Edit Details
            </div>
            <div
              className="action-button-cancel"
              onClick={() => {
                routingToIndentData.routingToCancelIndentModel(ele);
              }}
            >
              Cancel
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
          </div>
        </div>
      );
    case "AutoRejected":
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToDraftToFinal(ele);
              }}
            >
              Edit Details
            </div>
            <div
              className="action-button-cancel"
              onClick={() => {
                routingToIndentData.routingToCancelIndentModel(ele);
              }}
            >
              Cancel
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
          </div>
        </div>
      );
    case "Assigned":
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            {/* <div className="action-button">Edit Details</div> */}
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              {" "}
              Details
            </div>
            <div
              className="action-button-cancel"
              onClick={() => {
                routingToIndentData.routingToCancelIndentModel(ele);
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      );
    case "Reassigned":
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              {" "}
              Details
            </div>
            <div
              className="action-button-cancel"
              onClick={() => {
                routingToIndentData.routingToCancelIndentModel(ele);
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      );
    case "Finalized":
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
            {/* <div
              className="action-button-cancel"
              onClick={() => {
                routingToIndentData.routingToCancelIndentModel(ele);
              }}
            >
              Cancel
            </div> */}
          </div>
        </div>
      );
    case "Expired":
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
          </div>
        </div>
      );
    case "Cancelled":
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div className="disabled">No Options Available</div>
          </div>
        </div>
      );
  }
};
