import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import dotGroup from "./assets/dot.png";
import Vector from "./assets/Vector.png";
import dot3 from "./assets/dot3.png";
import dotLast from "./assets/dotLast.png";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class InvoiceGenerated extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;
    return (
      <>
        {/*INVOICE_GENERATED*/}
        <div>
          <Grid
            container
            // columnSpacing={3}
            style={{
              marginBottom: "-24px ",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_PUNCHED" ? (
                        <>
                          <div>{items[0]?.updatedDate}</div>
                          <div>{items[0]?.updateTime}</div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle dark__span">Order Punch</span>
              </div>

              <br></br>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            // columnSpacing={3}
            style={{
              marginBottom: "-24px ",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_RELEASED" ? (
                        <>
                          <div>{items[0]?.updatedDate}</div>
                          <div>{items[0]?.updateTime}</div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle dark__span">
                  Order Release
                </span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            // columnSpacing={3}
            style={{
              marginBottom: "-24px ",
              height: "auto",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "INVOICE_GENERATED" ? (
                        <>
                          <div>
                            {items?.length > 0 ? (
                              items[0]?.acknowledgementDateTimeObject ? (
                                <span>
                                  {moment(
                                    items[0]?.acknowledgementDateTimeObject
                                  ).format("DD-MMM-YYYY")}
                                </span>
                              ) : items[0]?.acknowledgementDate ? (
                                <span>
                                  {moment(items[0]?.acknowledgementDate).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              ) : items[0]?.updatedDate ? (
                                <span>{items[0]?.updatedDate}</span>
                              ) : (
                                <span></span>
                              )
                            ) : (
                              <span></span>
                            )}
                          </div>
                          <div>
                            {items?.length > 0 ? (
                              <span>
                                {items[0]?.acknowledgementDateTimeObject
                                  ?.length > 0
                                  ? items[0]?.acknowledgementDateTimeObject.substr(
                                      11,
                                      8
                                    )
                                  : items[0]?.updatedDateTimeObject?.length > 0
                                  ? items[0]?.updatedDateTimeObject.substr(
                                      11,
                                      8
                                    )
                                  : items[0]?.updateTime}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dot3}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle dark__span">
                  Invoice Generated
                  {`(${
                    this.props?.countInvoiceItem
                      ? this.props?.countInvoiceItem
                      : 0
                  }/${this.props.productLength})`}
                  {this.props?.finalDocumentNumber2[0] &&
                    this.props?.finalDocumentNumber2[0]?.map((item) => (
                      <>
                        <div style={{ padding: "5px" }}>
                          <span className="OrderLineHead">
                            <span>
                              <img src={Vector} />
                            </span>{" "}
                            Invoice No
                          </span>{" "}
                          :{" "}
                          <span
                            style={{
                              color: "GrayText",
                            }}
                          >
                            {item?.id}
                          </span>
                        </div>
                        <div>
                          {item?.values?.map((item) => (
                            <>
                              <div
                                style={{
                                  color: "GrayText",
                                  padding: "1%",
                                  marginLeft: "40px",
                                  fontSize: "12px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  Order Line No
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  {item?.orderLineNo}
                                </span>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ))}
                </span>
              </div>

              <br></br>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            // columnSpacing={3}
            style={{
              marginBottom: "-24px ",
              marginTop: "40px",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_DISPATCHED" ? (
                        <>
                          <div>
                            {items?.length > 0 ? (
                              items[0]?.eWayBillDate ? (
                                <span>
                                  {moment(items[0]?.eWayBillDate).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              ) : (
                                <span>{items[0]?.updatedDate}</span>
                              )
                            ) : (
                              <span></span>
                            )}
                          </div>
                          <div>
                            {items?.length > 0 ? (
                              items[0]?.eWayBillTime ? (
                                <span>{items[0]?.eWayBillTime}</span>
                              ) : (
                                <span>{items[0]?.updateTime}</span>
                              )
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dot3}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle light__span mt-4 inctiveStatus">
                  <span>
                    Order Dispatch
                    {`(${this.props?.countEway ? this.props?.countEway : 0}/ ${
                      this.props.productLength
                    })`}
                  </span>
                  {this.props?.finalEwayBillNo2[0] &&
                    this.props?.finalEwayBillNo2[0].map((item) => (
                      <>
                        <div style={{ padding: "5px" }}>
                          <span className="OrderLineHead">
                            <span>
                              <img src={Vector} />
                            </span>{" "}
                            EWay Bill No
                          </span>{" "}
                          :{" "}
                          <span
                            style={{
                              color: "GrayText",
                            }}
                          >
                            {item?.id}
                          </span>
                        </div>
                        <div>
                          {item?.values?.map((item) => (
                            <>
                              <div
                                style={{
                                  color: "GrayText",
                                  padding: "1%",
                                  marginLeft: "40px",
                                  fontSize: "12px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  Order Line No
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  {item?.orderLineNo}
                                </span>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ))}
                </span>
              </div>
              {/* <Button
                className="track-button"
                outline
                size="xs"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/trackOrders",
                    state:
                      this.props.finalEwayBillNo2[0] &&
                      this.props.finalEwayBillNo2[0]?.map((item) => {
                        return item?.id;
                      }),
                  });
                }}
              >
                Track Order
              </Button> */}
              <br></br>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            // columnSpacing={3}
            style={{
              marginBottom: "-24px ",
              marginTop: "10px",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {/* {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_DELIVERED" ? (
                        <>
                          <div>
                            {items[0]?.updatedDate}
                          </div>
                          <div>
                            {items[0]?.updateTime}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )} */}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
              className="mb-4"
            >
              <div>
                <img
                  src={dotLast}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle light__span inctiveStatus">
                  Order Delivered
                </span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}
export default connect(null)(withRouter(InvoiceGenerated));
