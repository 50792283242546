import { type } from "os";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.masters?.FREIGHT_CODE ?? "Hop Code",
        field: "hopCode",
        sort: "asc",
        width: 100,
      },

      {
        label: userLang?.masters?.LABEL ?? "Label",
        field: "label",
        sort: "asc",
        width: 100,
      },


      {
        label: userLang?.masters?.ADDRESS1 ?? "Address1",
        field: "addressLine1",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.ADDRESS2 ?? "Address2",
        field: "addressLine2",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.ADDRESS3 ?? "Address3",
        field: "addressLine3",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.common?.CITY ?? "City",
        field: "city",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.common?.STATE ?? "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.PIN_CODE ?? "PinCode",
        field: "pinCode",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.LATITUDE ?? "Latitude",
        field: "latitude",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.LONGITUDE ?? "Longitude",
        field: "longitude",
        sort: "asc",
        width: 100,
      },

      // {
      //   label: userLang?.common?.ACTIONS,
      //   field: "actions",
      //   sort: "asc",
      //   width: 100,
      // },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.masters?.FREIGHT_CODE ?? "Hop Code",
      field: "hopCode",
      sort: "asc",
      width: 100,
    },

    {
      label: userLang?.masters?.LABEL ?? "Label",
      field: "label",
      sort: "asc",
      width: 100,
    },


    {
      label: userLang?.masters?.ADDRESS1 ?? "Address1",
      field: "addressLine1",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.ADDRESS2 ?? "Address2",
      field: "addressLine2",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.ADDRESS3 ?? "Address3",
      field: "addressLine3",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.common?.CITY ?? "City",
      field: "city",
      sort: "asc",
      width: 70,
    },
    {
      label: userLang?.common?.STATE ?? "State",
      field: "state",
      sort: "asc",
      width: 70,
    },
    {
      label: userLang?.masters?.PIN_CODE ?? "PinCode",
      field: "pinCode",
      sort: "asc",
      width: 50,
    },
    {
      label: userLang?.masters?.LATITUDE ?? "Latitude",
      field: "latitude",
      sort: "asc",
      width: 60,
    },
    {
      label: userLang?.masters?.LONGITUDE ?? "Longitude",
      field: "longitude",
      sort: "asc",
      width: 60,
    },

    // {
    //   label: userLang?.common?.ACTIONS,
    //   field: "actions",
    //   sort: "asc",
    //   width: 100,
    // },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.masters?.FREIGHT_CODE ?? "Hop Code",
      field: "hopCode",
      sort: "asc",
      type: "text",
    },

    {
      label: userLang?.masters?.LABEL ?? "Label",
      field: "label",
      sort: "asc",
      type: "text",
    },


    {
      label: userLang?.masters?.ADDRESS1 ?? "Address1",
      field: "addressLine1",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.ADDRESS2 ?? "Address2",
      field: "addressLine2",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.ADDRESS3 ?? "Address3",
      field: "addressLine3",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.common?.CITY ?? "City",
      field: "city",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.common?.STATE ?? "State",
      field: "State",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.PIN_CODE ?? "PinCode",
      field: "pinCode",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.LATITUDE ?? "Latitude",
      field: "latitude",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.masters?.LONGITUDE ?? "Longitude",
      field: "longitude",
      sort: "asc",
      type: "text",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      type: "text",
    },
  ],
};
