let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        // width: 4,
        width: 300,
      },
      {
        label: userLang?.report?.TOTAL_TRIPS,
        field: "totalTrips",
        sort: "asc",
        // width: 3,
        width: 100,
      },
      {
        label: userLang?.report?.TRANSPORTER_SCORE,
        field: "transporterScore",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: userLang?.report?.VEHICLE_PLACEMENT,
        field: "vehiclePlacement",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: userLang?.report?.QUALITY_REJECTION,
        field: "qualityRejection",
        sort: "asc",
        // width: 4,
        width: 100,
      },
      {
        label: userLang?.report?.ON_TIME_DELIVERY_REPORT,
        field: "onTimeDelivery",
        sort: "asc",
        // width: 4,
        width: 100,
      },

      {
        label: userLang?.report?.DAMAGE,
        field: "damage",
        sort: "asc",
        // width: 3,
        width: 100,
      },

      {
        label: userLang?.report?.SHORTAGES,
        field: "shortages",
        sort: "asc",
        // width: 3,
        width: 100,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.report?.TOTAL_TRIPS,
      field: "totalTrips",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.report?.TRANSPORTER_SCORE,
      field: "transporterScore",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.report?.VEHICLE_PLACEMENT,
      field: "vehiclePlacement",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.report?.QUALITY_REJECTION,
      field: "qualityRejection",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.report?.ON_TIME_DELIVERY_REPORT,
      field: "onTimeDelivery",
      sort: "asc",
      width: 4,
    },

    {
      label: userLang?.report?.DAMAGE,
      field: "damage",
      sort: "asc",
      width: 3,
    },

    {
      label: userLang?.report?.SHORTAGES,
      field: "shortages",
      sort: "asc",
      width: 3,
    },
  ],
};

export const searchBar = {
  searchBarData: [
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      type: "text",
    },
    //   {
    //     label: Vehicle placement,
    //     field: "vehiclePlacement",
    //     sort: "asc",
    //     width: 3,
    //   },
    //   {
    //     label: Quality Rejection,
    //     field: "qualityRejection",
    //     sort: "asc",
    //     width: 3,
    //   },
    //   {
    //     label: On Time Delivery,
    //     field: "onTimeDelivery",
    //     sort: "asc",
    //     width: 1,
    //   },

    //   {
    //     label: Damage,
    //     field: "damage",
    //     sort: "asc",
    //     width: 1,
    //   },

    //   {
    //     label: Shortages,
    //     field: "shortages",
    //     sort: "asc",
    //     width: 3,
    //   },

    //   {
    //     label: Total Trips,
    //     field: "totalTrips",
    //     sort: "asc",
    //     width: 3,
    //   },
  ],
};
