import React from "react";
import { Button } from "reactstrap";

import actionVerticleDots from "../Trips/assets/more-horizontal.svg"; 

  export const actions = (status, ID, ele,buttonContent,routingToIndentData) => {

    switch (status) {
      case "Accepted":
        return (
          <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToProvideDetailModel(ele,ID);
              }}
            >
             Provide details
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
          </div>
        </div>
        
        );
      case "Rejected":
        return <h5 className="text-warning">Rejected</h5>;

      case "Reassigned":
      case "Assigned":
        return (
          <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToAcceptStatus(ID);
              }}
            >
               {buttonContent.length > 0
                ? buttonContent
                : "Accept"}
              
                
            </div>
            <div
              className="action-button-cancel actionDrspan"
              onClick={() => {
                routingToIndentData.routingToCancelIndentModel(ele);
              }}
            >
              Reject
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);
              }}
            >
              Details
            </div>
          </div>
        </div>
         
        );
      case "Drafted":
        return (
          <div className="dropdown">
          <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToAcceptStatus(ID);
              }}
            >
               {buttonContent.length > 0
                ? buttonContent
                : "Accept"}
                
            </div>
            
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingToIndentData.routingToIndentDataModel(ele);;
              }}
            >
              Details
            </div>
          </div>
        </div>
        );
      default: return (
        <div className="dropdown">
        <Button className="iconButtons btnfsize py-0 dropbtn actionButtonsDark">
          <img
            style={{ margin: "-4px" }}
            src={actionVerticleDots}
            alt="actions"
          />
        </Button>
        <div className="dropdown-content contentDark">
          
          <div
            className="action-button actionDspan"
            onClick={() => {
              routingToIndentData.routingToIndentDataModel(ele);
            }}
          >
            Details
          </div>
        </div>
      </div>
      
      );
    }
  };

