import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
import { Input } from "reactstrap";
import "./freightTheme.scss";
import refreshIcon from "./assets/refresh-cw.png";
import { debouncing } from "../../components/Common/utils";
import "../../pages/Billing/InvoiceNew/InvoiceList/invoiceListTheme.scss";
// import refreshIcon from "./assets/refresh-cw.png";
import Datepicker from "../../components/Common/DatePicker/DatePicker";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
import Select, { components } from "react-select";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
      dateValue: [null, null],
      showCalender: false,
      fromDate: null,
      toDate: null,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  onChangeDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };

  render() {
    let darkMode = this.props?.mode;
    // console.log(this.props.filters, "00")
    const customStylesV2 = {
      menu: ({ width, ...css }) => ({
        ...css,
        width: "100%",
        minWidth: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        zIndex: "999",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      control: (style) => {
        return {
          ...style,
          height: "28px",
          width: this.props?.form?.type?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
          backgroundColor: darkMode ? "#2E2E46" : "",
          border: darkMode ? "1.5px solid #B7A9FF" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          marginTop: "0px",
          height: "24px",
          minHeight: "24px",
          color: darkMode ? "#ffffff" : "",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
          marginTop: "3px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "4px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          height: "24px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
          marginTop: "-5px",
        };
      },
    };

    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar vehicleStyle darkBgQuickFilter">
            <>
              {/* <div className="d-flex justify-content-sm-end cardStyle"> */}
              <Row className="justify-content-space-between">
                {/* <Col xs={0} md={5}></Col> */}
                <Col xs={12} md={5} className="mobileDisplay">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Button
                        style={{
                          minWidth: "70%",
                        }}
                        className="mobile-reset reset"
                        onClick={() => this.props?.reset()}
                      >
                        <img
                          className="mobile-rest-img"
                          src={refreshIcon}
                          alt=""
                        ></img>
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={2} className="pl-0 mb-1 ">
                  <div className=" filter">
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="freightCode"
                          value={this.props.filters?.freightCode}
                          onChange={(e) => this.props.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Freight Code"
                          aria-label="Search"
                          icon={
                            <svg
                              width="14"
                              height="12"
                              viewBox="0 0 14 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.33333 1H1V8.22222H9.33333V1Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0 mb-1">
                  <div className=" filter">
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="costMain"
                          value={this.props?.filters?.costMain}
                          onChange={(e) => this.props?.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Cost Main"
                          aria-label="Search"
                          icon={
                            <svg
                              width="14"
                              height="12"
                              viewBox="0 0 14 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.33333 1H1V8.22222H9.33333V1Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0 mb-1">
                  <div className=" filter">
                    <Select
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      name="source"
                      styles={customStylesV2}
                      style={{ width: "82%" }}
                      isMulti={false}
                      onChange={(e) =>
                        this.props.handleSelectGroup(e, "source")
                      }
                      onInputChange={(e) =>
                        debouncing(() => this.props.filtersHops(e))
                      }
                      value={this.props.selectectedSource}
                      options={this.props.sourceDest}
                      onKeyDown={(event) => this.props?.enterCheck(event)}
                      id="typeVehicleList"
                      placeholder="Select Source..."
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      // classNamePrefix='select2-selection'
                    />
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0 mb-1">
                  <div className=" filter">
                    <Select
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      name="source"
                      styles={customStylesV2}
                      style={{ width: "82%" }}
                      isMulti={false}
                      onChange={(e) =>
                        this.props.handleSelectGroup(e, "destination")
                      }
                      onInputChange={(e) =>
                        debouncing(() => this.props.filtersHops(e))
                      }
                      value={this.props.selectectedDestination}
                      options={this.props.sourceDest}
                      onKeyDown={(event) => this.props?.enterCheck(event)}
                      id="typeVehicleList"
                      placeholder="Select Destination..."
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      // classNamePrefix='select2-selection'
                    />
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0 mb-1">
                  <div className=" filter">
                    <Select
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      name="route"
                      styles={customStylesV2}
                      style={{ width: "82%" }}
                      isMulti={false}
                      onChange={(e) => this.props.handleSelectGroup(e, "route")}
                      onInputChange={(e) =>
                        debouncing(() => this.props.getAllRoute(e))
                      }
                      value={this.props.selectectedRoute}
                      options={this.props.routeOption}
                      onKeyDown={(event) => this.props?.enterCheck(event)}
                      id="typeVehicleList"
                      placeholder="Select Route..."
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      // classNamePrefix='select2-selection'
                    />
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0 mb-1">
                  <div className=" filter">
                    <Select
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      name="transporter"
                      styles={customStylesV2}
                      style={{ width: "82%" }}
                      isMulti={false}
                      onChange={(e) =>
                        this.props.handleSelectGroup(e, "transporter")
                      }
                      onInputChange={(e) =>
                        debouncing(() => this.props.getAllTransporter(e))
                      }
                      value={this.props.selectectedTransporter}
                      options={this.props.allTransporterOption}
                      onKeyDown={(event) => this.props?.enterCheck(event)}
                      id="typeVehicleList"
                      placeholder="Select Transporter..."
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 13 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.7588 12.906V11.583C11.7588 10.8812 11.48 10.2082 10.9838 9.71201C10.4876 9.21579 9.81454 8.93701 9.11278 8.93701H3.82178C3.12002 8.93701 2.447 9.21579 1.95078 9.71201C1.45456 10.2082 1.17578 10.8812 1.17578 11.583V12.906"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.25879 6C7.6395 6 8.75879 4.88071 8.75879 3.5C8.75879 2.11929 7.6395 1 6.25879 1C4.87808 1 3.75879 2.11929 3.75879 3.5C3.75879 4.88071 4.87808 6 6.25879 6Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      // classNamePrefix='select2-selection'
                    />
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0 mb-1">
                  <div className=" filter">
                    <Select
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      name="vehicleType"
                      styles={customStylesV2}
                      style={{ width: "82%" }}
                      isMulti={false}
                      onChange={(e) =>
                        this.props.handleSelectGroup(e, "vehicleType")
                      }
                      onInputChange={(e) =>
                        debouncing(() => this.props.getVehicleTypes(e))
                      }
                      value={this.props.selectectedVehicleType}
                      options={this.props.vehicleTypeOption}
                      onKeyDown={(event) => this.props?.enterCheck(event)}
                      id="typeVehicleList"
                      placeholder="Select VehicleType..."
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.33333 1H1V8.22222H9.33333V1Z"
                          stroke={darkMode ? "#b7a9ff" : "#2800FC"}
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                          stroke={darkMode ? "#b7a9ff" : "#2800FC"}
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                          stroke={darkMode ? "#b7a9ff" : "#2800FC"}
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                          stroke={darkMode ? "#b7a9ff" : "#2800FC"}
                          strokeWidth="1.3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      }
                      // classNamePrefix='select2-selection'
                    />
                  </div>
                </Col>
{/* date--------------/ */}
                <Col xs={12} md={3} className="desktopDisplay">
                  <Grid item xs={12} className="datePickerGrid">
                    <div
                      className="mr-2 mt-2 date-picker-div RsuiteDateRange dashboardDateRange"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="datepicker-selector">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            placeholder={"Start Date"}
                            dashboard={true}
                          />
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            placeholder={"End Date"}
                            dashboard={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </Grid>
                </Col>
                <Col xs={12} md={1} className="desktopDisplay ml-auto">
                  <div className="d-flex justify-content-end">
                    <div className=" filter">
                      {" "}
                      <div>
                        <Button
                          style={{
                            width: "95%",
                            paddingRight: "20px",
                            marginRight: "15px",
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                dateValue: [null, null],
                                fromDate: null,
                                toDate: null,
                              },
                              () => {
                                this.props.reset();
                              }
                            );
                          }}
                          className="reset"
                        >
                          {this.state.userLangs?.common?.RESET}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
