import React from "react";
import { Button } from "reactstrap";
import actionVerticleDots from "./assets/more-vertical.png";


export const actions=(
    e,
    permissions,
    PERMS,
    documentDownloadErrorId,
    routingTOTrip
)=>{

    let online = navigator.onLine;
    // console.log(e.download, e.id, "action");
    let authUserData = JSON.parse(window.localStorage.getItem("authUser"));

    if (
        (permissions.includes(PERMS.INVOICE_SUBMITTED_UPDATE) &&
          (e.status === "submitted" || e._status === "sent_for_revision")) ||
        (authUserData?.data?.role?.label === "transporter" &&
          e.status === "drafted") ||
        (authUserData?.data?.role?.label === "transporter" &&
          e._status === "sent_for_revision")
      )
        return (
          <>
            {
              (e.actions = (
                <div className="dropdown">
                  <Button className="iconButtons dropbtn actionButtonsDark">
                    <img
                      style={{ margin: "-4px" }}
                      src={actionVerticleDots}
                      alt="actions"
                    />
                  </Button>
                  <div className="dropdown-content contentDark">
                    <div
                      className="action-button actionDspan"
                      onClick={() => routingTOTrip.invoiceEdit(e)}
                    >
                      Edit Details
                    </div>
                    {e?.download?.length ? (
                      <>
                        <div
                          className="action-button actionDspan"
                          onClick={() =>
                            routingTOTrip.invoiceAttached(e?.download, e.id)
                          }
                        >
                          Attachment
                        </div>
                        {e.id === documentDownloadErrorId ? (
                          <div className="action-button lspan actionDspan">
                            Error Downloading
                          </div>
                        ) : null}
                      </>
                    ) : (
                      ""
                      // <div className="action-button actionDspan">N/A</div>
                    )}
                    <div
                      // className='action-button actionDspan'
                      className={
                        online
                          ? "action-button actionDspan"
                          : "action-button-disabled actionDspan"
                      }
                      onClick={() => routingTOTrip.routingTOInvoiceDetails(e)}
                    >
                      View Details
                    </div>
                  </div>
                </div>
              ))
            }
          </>
        );
      else if (
        permissions.includes(PERMS.INVOICE_APPROVAL_PENDING_UPDATE) &&
        e._status === "approval_pending"
      )
        return (
          <>
            {
              (e.actions = (
                <div className="dropdown">
                  <Button className="iconButtons dropbtn actionButtonsDark">
                    <img
                      style={{ margin: "-4px" }}
                      src={actionVerticleDots}
                      alt="actions"
                    />
                  </Button>
                  <div className="dropdown-content contentDark">
                    <div
                      className="action-button actionDspan"
                      onClick={async () => routingTOTrip.invoiceAccept(e)}
                    >
                      Approve
                    </div>
                    <div
                      className="action-button"
                      onClick={() => routingTOTrip.invoiceReject(e)}
                    >
                      <span className="actionDrspan">Reject</span>
                    </div>
                    {e?.download?.length ? (
                      <>
                        <div
                          className="action-button actionDspan"
                          onClick={() =>
                            routingTOTrip.invoiceAttached(e?.download, e.id)
                          }
                        >
                          <span className="actionDspan">Attachment</span>
                        </div>
                        {e.id === documentDownloadErrorId ? (
                          <div className="action-button lspan actionDspan">
                            Error Downloading
                          </div>
                        ) : null}
                      </>
                    ) : (
                      ""
                      // <div className="action-button actionDspan">N/A</div>
                    )}
                    <div
                      // className='action-button actionDspan'
                      className={
                        online
                          ? "action-button actionDspan"
                          : "action-button-disabled actionDspan"
                      }
                      onClick={() => routingTOTrip.routingTOInvoiceDetails(e)}
                    >
                      View Details
                    </div>
                  </div>
                </div>
              ))
            }
          </>
        );
      else if (
        permissions.includes(PERMS.INVOICE_APPROVAL_PENDING_UPDATE) &&
        e.status === "submitted"
      )
        return (
          <>
            {
              (e.actions = (
                <div className="dropdown">
                  <Button className="iconButtons dropbtn actionButtonsDark">
                    <img
                      style={{ margin: "-4px" }}
                      src={actionVerticleDots}
                      alt="actions"
                    />
                  </Button>
                  <div className="dropdown-content contentDark">
                    <div className="action-button actionDspan">
                      Pending from billing Team
                    </div>
                    {e?.download?.length ? (
                      <>
                        <div
                          className="action-button actionDspan"
                          onClick={() =>
                            routingTOTrip.invoiceAttached(e?.download, e.id)
                          }
                        >
                          Attachment
                        </div>
                        {e.id === documentDownloadErrorId ? (
                          <div
                            className="action-button actionDspan"
                            style={{ color: "red" }}
                          >
                            Error Downloading
                          </div>
                        ) : null}
                      </>
                    ) : (
                      ""
                      // <div className="action-button actionDspan">N/A</div>
                    )}
                  </div>
                </div>
              ))
            }
          </>
        );
      // e.actions = <div>Pending from billing Team</div>;
      else if (e.status === "cancelled") {
        return (
          <>
            {
              (e.actions = (
                <div className="dropdown">
                  <Button
                    className="iconButtons dropbtn actionButtonsDark"
                    disabled={true}
                  >
                    <img
                      style={{ margin: "-4px" }}
                      src={actionVerticleDots}
                      alt="actions"
                    />
                  </Button>
                </div>
              ))
            }
          </>
        );
      }
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>In Cancelled state")
      else
        return (
          <>
            {
              (e.actions = (
                <div className="dropdown">
                  <Button className="iconButtons dropbtn actionButtonsDark">
                    <img
                      style={{ margin: "-4px" }}
                      src={actionVerticleDots}
                      alt="actions"
                    />
                  </Button>
                  <div className="dropdown-content contentDark">
                    {e?.download?.length ? (
                      <>
                        <div
                          className="action-button actionDspan"
                          onClick={() =>
                            routingTOTrip.invoiceAttached(e?.download, e.id)
                          }
                        >
                          Attachment
                        </div>
                        {e.id === documentDownloadErrorId ? (
                          <div
                            className="action-button actionDspan"
                            style={{ color: "red" }}
                          >
                            Error Downloading
                          </div>
                        ) : null}
                      </>
                    ) : (
                      ""
                      // <div className="action-button actionDspan">N/A</div>
                    )}
    
                    <div
                      // className='action-button actionDspan'
                      className={
                        online
                          ? "action-button actionDspan"
                          : "action-button-disabled actionDspan"
                      }
                      onClick={() => routingTOTrip.routingTOInvoiceDetails(e)}
                    >
                      View Details
                    </div>
                  </div>
                </div>
              ))
            }
          </>
        );
}