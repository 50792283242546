let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.tracking?.VEHICLE_OWNER,
        field: "vehicleOwnerName",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.REG_NO,
        field: "registrationNo",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        width: 150,
      },
      {
        label: userLang?.common?.VEHICLE_TYPE,
        field: "vehicleType",
        sort: "asc",
        width: 100,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.masters?.USERNAME,
      field: "vehicleOwnerName",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.REG_NO,
      field: "registrationNo",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 150,
    },
    {
      label: userLang?.common?.VEHICLE_TYPE,
      field: "vehicleType",
      sort: "asc",
      width: 100,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.masters?.USERNAME,
      field: "vehicleOwnerName",
      // sort: "asc",
      type: "text",
      width: 100,
    },
    {
      label: userLang?.masters?.REG_NO,
      field: "registrationNo",
      // sort: "asc",
      type: "text",
      width: 100,
    },
    {
      label: userLang?.common?.VEHICLE_TYPE,
      field: "vehicleType",
      sort: "asc",
      type: "select",
      width: 100,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      // sort: "asc",
      type: "text",
      width: 150,
    },
  ],
};
