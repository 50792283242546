import React from "react";
import { Redirect } from "react-router-dom";
import DriverNew from "../pages/MastersDriver/Driver";
import VehicleNew from "../pages/MasterVehicle/Vehicle";
import VehicleType from "../pages/MasterVehicleType/VehicleType";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import OperationsDashboard from "../pages/Dashboard/OperationsDashboard/index";
import TransporterDashboard from "../pages/Dashboard/TransporterDashboard/transporterDashboard";
// import InvoiceDashboard from "../pages/Dashboard/InvoiceDashboard";

import InvoiceDashboardCard from "../pages/Dashboard/InvoiceDashboard/InvoiceDashboardCard";
// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Utility
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Error404 from "../pages/Utility/Error404";
import Error401 from "../pages/Utility/Error401";
import Error500 from "../pages/Utility/Error500";
import ConfirmDelivery from "../pages/Utility/ConfirmDelivery";
import OrderDetail from "../pages/Utility/OrderDetail";
// import OrderDetail from "../pages/Utility/orderNew";

// Forms
import FormAdvanced from "../pages/Forms/FormAdvanced";
import TripForm from "../pages/Forms/TripForm";
import EditTripForm from "../pages/Forms/EditTripForm";
import TrackTripForm from "../pages/Forms/TrackTripForm";
import EmpForm from "../pages/Forms/EmpForm";

//Tables
import EditableTables from "../pages/Tables/EditableTables";
import Dealer from "../pages/dealer";
import Employee from "../pages/employee";
import Hop from "../pages/hop";
import Roles_New from "../pages/Roles/Roles_New";
import Order from "../pages/order";

import TripBills from "../pages/Billing/tripBills";
import CreatorIndent from "../pages/creatorIndent";
import AssignedTransporterIndent from "../pages/AssignedIndent/assignedTransporterIndent";
// import AssignedTransporterIndent from "../pages/assignedTransporterIndent";

import Transporter from "../pages/Transporter";
import Users_New from "../pages/Users/Users_New";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import DealerForm from "../pages/Forms/DealerForm";
import EditDealerForm from "../pages/Forms/EditDealerForm";
import DriverForm from "../pages/Forms/DriverForm";
import EditDriverForm from "../pages/Forms/EditDriverForm";
import VehicleForm from "../pages/Forms/VehicleForm";
import EditVehicleForm from "../pages/Forms/EditVehicleForm";
import Upload from "../pages/Forms/upload";
import HopForm from "../pages/Forms/HopForm";
import EditHopForm from "../pages/Forms/EditHopForm";
import RoleForm from "../pages/Forms/RoleForm";
import EditRoleForm from "../pages/Forms/EditRoleForm";
import IndentForm from "../pages/Forms/IndentForm";
import TransporterForm from "../pages/Forms/TransporterForm";
// import UserForm from "../pages/Forms/userForm";
// import UserForm_New from "../pages/Forms/UserForm_New";
import Tracking from "../pages/Tracking";

//Billing
// import agreementIndex from "../pages/Billing/agreementIndex";
import SecondaryAgreementIndex from "../pages/SecondaryBilling/SecondaryAgreementIndex"
import AgreementIndex_new from "../pages/Billing/AgreementIndex_new";
import addAgreementIndex from "../pages/Billing/AddAgreement/addAgreementIndex";
import SecondaryAddAgreementIndex from "../pages/SecondaryBilling/AddAgreement/SecondaryAddAgreementIndex"
import InvoiceGeneration from "../pages/Billing/InvoiceNew/InvoiceGeneration/InvoiceGeneration";
import SecondaryInvoiceGeneration from "../pages/SecondaryBilling/InvoiceNew/InvoiceGeneration/SecondaryInvoiceGeneration";
import GeneratedInvoice from "../pages/Billing/InvoiceNew/GeneratedInvoices";
import InvoiceList from "../pages/Billing/InvoiceNew/InvoiceList/InvoiceList";
import SecondaryInvoiceList from "../pages/SecondaryBilling/InvoiceNew/InvoiceList/InvoiceList"
import InvoiceEdit from "../pages/Billing/InvoiceNew/InvoiceEdit";
import SecondaryInvoiceEdit from "../pages/SecondaryBilling/InvoiceNew/InvoiceEdit";

//new
import UploadForm from "../components/Common/UploadForm";
import Reports_New from "../pages/Reports/Reports_New";
import VehiclePlacement from "../pages/VehiclePlacementReport/vehiclePlacement";
import onTimeDeliveryReport from "../pages/OntimeDeliveryReport/onTimeDeliveryReport";
import ShareOfBusiness from "../pages/ShareOfBusiness/shareOfBusiness";
import LaneIndent from "../pages/IndentReports/LaneIndentReport/LaneIndent";
import StatusIndent from "../pages/IndentReports/StatusIndentReport/StatusIndent";
import TransportIndent from "../pages/IndentReports/TransporterIndentReport/TransporterIndent";

// oracle masters
import Routes from "../pages/routes/Routes";
import IndentsForMasters from "../pages/indentsForMasters/IndentsForMasters";
import HopMaster from "../pages/MasterHops/HopMaster";
import FreightMaster from "../pages/MasterFreight/FreightMaster";
//UI Revamp
import Trip_new from "../pages/Trips/Trip_new";
import Indent_new from "../pages/Indents/Indent_new";
import SecondaryIndent from "../pages/Forms/CommonResources/Indent/SecondaryIndent/secondaryIndent";
import RouteOptimization from "../pages/RouteOptimization/Index";
import ControlTower from "../pages/ControlTower/ControlTower";
import TripDetails_new from "../pages/TripDetail/TripDetails_new";
import TripAllDetails_new from "../pages/TripDetail/TripAllDetail_new";
import TripAllDetails_newUI from "../pages/TripDetail/TripDetailNewUi";
import SecondaryTripDetailNewUi from "../pages/SecondaryTripDetail/SecondaryTripDetailNewUi";
import LoadOptimization from "../pages/LoadOptimization/LoadOptimization";

//mobile -> web features
import EPODIndex from "../pages/Trips/EPOD/EPODIndex";

//profile
import ProfileSettings from "../pages/ProfileSettings/ProfileSettings";
import vehiclePlacementDetail from "../pages/VehiclePlacementDetailAnalysisReport/vehiclePlacementDetail";

// baxter
import Customer from "../pages/baxter_customer/Customer";
import Location from "../pages/baxter_location.js/Location";
import Product from "../pages/baxter_product/Product";
import Orders from "../pages/baxter_orders/Order";
import InvoiceBaxList from "../pages/baxter_orderInvoiceList/InvoiceBaxList";
import InvoiceDetail from "../pages/baxter_orderInvoiceList/InvoiceDetail";
import OrderProduct from "../pages/baxter_orderProduct/OrderProduct";
import ProductDetail from "../pages/baxter_orderProduct/ProductDetail";
import LoadOptimizationBaxter from "../pages/baxter_optimization/LoadOptimization";
import OrderDetails from "../pages/baxter_orders/OrderDetail";
import ViewProductByOrder from "../pages/baxter_orders/ViewProductByOrder";
import OrderHistory from "../pages/baxter_orders/OrderHistoryModel";
import OrderTrackModel from "../pages/baxter_orders/OrderTrackModel";
import ProductInvoiceDetail from "../pages/baxter_orders/ProductInvoiceDetail";
import TripList from "../pages/baxter-trip/Trip";
import TripHistory from "../pages/baxter-trip/baxter-TripHistory/TripHistory";
import Questionnaire from "../pages/baxter-trip/Questionnaire";
import QualityDetail from "../pages/baxter-trip/baxter-TripHistory/QualityDetail";
import Dashboard from "../pages/baxter_dashboard/OperationsDashboard/index";
import ViewTripDetails from "../pages/baxter-trip/ViewTripDetails";
import DispatchLeadTime from "../pages/baxter-report/DispatchLeadTime";
import BifuricationRevenue from "../pages/BaxterBifuricationRevenueReport/BifuricationRevenue";
import BifuricationVolume from "../pages/BaxterBifuricationVolumeReport/BifuricationVolume";
import TruckUtilization from "../pages/BaxterTruckUtilizationReport/TruckUtilization";
import Shipments from "../pages/shipments/Shipments";
import LogBook from "../pages/baxter_logbook/LogBook";
import ZonteToStateMap from "../pages/baxter_zoneToShip/ZonteToStateMap";
import SecondaryIndentForm from "../pages/Forms/SecondaryIndentForm";
import AssignedSecondaryTransporterIndent from "../pages/AssignedSecondaryIndent/assignedSecondaryTransporterIndent";
import FreightMasterSecondary from "../pages/MasterFreightSecondary/FreightMasterSecondary";
import CustomersMasterSecondary from "../pages/MasterCustomersSecondary/CustomersMasterSecondary";
import SecondaryTrips from "../pages/Secondary Trips/SecondaryTrips";
import SecondaryEPODIndex from "../pages/Secondary Trips/SecondaryEPOD/SecondaryEPODIndex";
import SecondaryTracking from "../pages/SecondaryTracking";
import SecondaryVehiclePlacementDetail from "../pages/SecondaryVehiclePlacementDetailAnalysisReport/SecondaryVehiclePlacementDetail";
import SecondaryTransporterDashboard from "../pages/SecondaryDashboard/SecondaryTransporterDashboard/transporterDashboard";
import SecondaryDashboard from "../pages/SecondaryDashboard/SecondaryOperationsDashboard";
import SecondaryGeneratedInvoices from "../pages/SecondaryBilling/InvoiceNew/SecondaryGeneratedInvoices";




const authProtectedRoutes = [
  //baxter
  { path: "/customers", component: Customer },
  { path: "/locations", component: Location },
  { path: "/products", component: Product },
  { path: "/viewOrders", component: OrderDetails },
  { path: "/viewProductByOrder", component: ViewProductByOrder },
  { path: "/orderHistory", component: OrderHistory },
  { path: "/trackOrder", component: OrderTrackModel },
  { path: "/ordersList", component: Orders },
  { path: "/tripList", component: TripList },
  { path: "/tripHistory", component: TripHistory },
  { path: "/viewTripDetails", component: ViewTripDetails },
  { path: "/InvoiceList", component: InvoiceBaxList },
  { path: "/viewInvoiceDetail", component: InvoiceDetail },
  { path: "/order/products", component: OrderProduct },
  { path: "/optimization", component: LoadOptimizationBaxter },
  { path: "/viewOrderProductDetail", component: ProductDetail },
  { path: "/invoiceOrderProduct", component: ProductInvoiceDetail },
  { path: "/trackOrders", component: OrderTrackModel },
  { path: "/qualityCheck", component: Questionnaire },
  { path: "/qualityCheckDetail", component: QualityDetail },
  { path: "/operationalDashboard", component: Dashboard },
  { path: "/DispatchLeadReports", component: DispatchLeadTime },
  { path: "/BifuricationRevenueReports", component: BifuricationRevenue },
  { path: "/BifuricationVolumeReports", component: BifuricationVolume },
  { path: "/truckUtilizationReports", component: TruckUtilization },
  { path: "/logbook", component: LogBook },

  // Profile
  { path: "/profileSettings", component: ProfileSettings },

  // Dashboard
  { path: "/operationsDashboard", component: OperationsDashboard },
  { path: "/secondary-OperationsDashboard", component: SecondaryDashboard },
  { path: "/transporterDashboard", component: TransporterDashboard },
  {
    path: "/secodary-TransporterDashboard",
    component: SecondaryTransporterDashboard,
  },
  { path: "/invoice-dashboard", component: InvoiceDashboardCard },
  // Indents
  { path: "/indent-planning", component: Indent_new },
  { path: "/secondary-indent-planning", component: SecondaryIndent },
  { path: "/load-optimization", component: LoadOptimization },
  { path: "/Indent_new", component: Indent_new },
  { path: "/Route-Optimization", component: RouteOptimization },
  { path: "/assigned-indent", component: AssignedTransporterIndent },
  {
    path: "/secondary-assigned-indent",
    component: AssignedSecondaryTransporterIndent,
  },
  { path: "/add-indent", component: IndentForm },
  { path: "/secondary-add-indent", component: SecondaryIndentForm },

  // Trips
  { path: "/trips", component: Trip_new },
  { path: "/secondary-trips", component: SecondaryTrips },
  { path: "/tripDetails", component: TripDetails_new },
  { path: "/tripAllDetail", component: TripAllDetails_new },
  { path: "/tripAllDetails", component: TripAllDetails_newUI },
  { path: "/secondary-tripAllDetails", component: SecondaryTripDetailNewUi },
  { path: "/Tracking", component: Tracking },
  { path: "/secondary-Tracking", component: SecondaryTracking },
  { path: "/EPODIndex", component: EPODIndex },
  { path: "/secondary-EPODIndex", component: SecondaryEPODIndex },

  // Masters
  { path: "/drivers", component: DriverNew },
  { path: "/vehicles", component: VehicleNew },
  { path: "/vehicle-type", component: VehicleType },
  { path: "/roles", component: Roles_New },
  { path: "/user", component: Users_New },
  { path: "/routes", component: Routes },
  { path: "/indents", component: IndentsForMasters },
  { path: "/hops", component: HopMaster },
  { path: "/freight", component: FreightMaster },
  { path: "/secondary-freight", component: FreightMasterSecondary },
  { path: "/secondary-customers", component: CustomersMasterSecondary },
  { path: "/shipments", component: Shipments },
  { path: "/zoneToState", component: ZonteToStateMap },

  // Reports
  { path: "/Reports", component: Reports_New },
  { path: "/vehicle-placement", component: VehiclePlacement },
  { path: "/vehicle-placement-detail", component: vehiclePlacementDetail },
  {
    path: "/secondary-vehicle-placement-detail",
    component: SecondaryVehiclePlacementDetail,
  },

  { path: "/ontime-delivery-report", component: onTimeDeliveryReport },
  { path: "/indentReport-analysis", component: LaneIndent },
  { path: "/statusIndent", component: StatusIndent },
  { path: "/transporterIndent", component: TransportIndent },
  { path: "/share-of-business", component: ShareOfBusiness },

  // Invoice
  { path: "/tripBills", component: TripBills },
  { path: "/BillingIndex", component: AgreementIndex_new },
  { path: "/secondary-BillingIndex" , component:SecondaryAgreementIndex},
  { path: "/invoiceGeneration", component: InvoiceGeneration },
  {path: "/Secondary-invoiceGeneration" , component : SecondaryInvoiceGeneration},
  { path: "/generateInvoice", component: GeneratedInvoice },
  { path: "/secondaryGenerateInvoice", component: SecondaryGeneratedInvoices },
  { path: "/Billing/AddAgreement", component: addAgreementIndex },
  { path: "/SecondaryBilling/AddAgreement" , component : SecondaryAddAgreementIndex },
  { path: "/Billing/InvoiceList", component: InvoiceList },
  {path:"/Billing/Secondary-InvoiceList" , component : SecondaryInvoiceList},
  { path: "/InvoiceEdit", component: InvoiceEdit },
  { path: "/Secondary-InvoiceEdit", component: SecondaryInvoiceEdit },

  // Control Tower
  { path: "/ControlTower", component: ControlTower },

  // this route should be at the end of all other routes

  {
    path: "/",
    exact: true,
    component: () =>
      process.env.REACT_APP_CLIENT !== "3_BAXTER" ? (
        localStorage.getItem("typeSelection") === "Secondary" ? (
          <Redirect to="/secondary-operationsDashboard" />
        ) : (
          <Redirect to="/operationsDashboard" />
        )
      ) : sessionStorage.getItem("viewOrderPath") ? (
        (window.location.href = sessionStorage.getItem("viewOrderPath"))
      ) : (
        <Redirect to="/ordersList" />
      ),
  },

  // { path: "/edit-driver-:id", component: EditDriverForm },

  // { path: "/add-vehicle", component: VehicleForm },
  // { path: "/edit-vehicle-:id", component: EditVehicleForm },

  // { path: "/hops", component: Hop },
  // { path: "/add-hop", component: HopForm },
  // { path: "/edit-role-:id", component: EditRoleForm },

  // // { path: "/roles", component: Role },
  // { path: "/add-role", component: RoleForm },
  // { path: "/edit-hop-:id", component: EditHopForm },

  // { path: "/transporter", component: Transporter },
  // { path: "/add-transporter", component: TransporterForm },

  // // { path: "/user", component: User },
  // // { path: "/add-user", component: UserForm },
  // // { path: "/add-user", component: UserForm_New},

  // { path: "/employees", component: Employee },
  // { path: "/add-employee", component: EmpForm },

  // { path: "/dealers", component: Dealer },
  // { path: "/add-dealer", component: DealerForm },
  // { path: "/edit-dealer-:id", component: EditDealerForm },

  // // { path: "/indent-planning", component: CreatorIndent },
  // // { path: "/assigned-indent", component: AssignedTransporterIndent },

  // { path: "/orders", component: Order },
  // { path: "/add-order", component: Upload },
  // { path: "/upload", component: Upload },
  // { path: "/upload-hop", component: Upload },
  // { path: "/upload-order", component: Upload },
  // { path: "/upload-dealer", component: Upload },
  // { path: "/upload-driver", component: Upload },
  // { path: "/upload-location", component: Upload },
  // { path: "/upload-vehicle", component: Upload },
  // { path: "/upload-employee", component: Upload },
  // // { path: "/upload", component: Upload },
  // // { path: "/upload", component: Upload },
  // // { path: "/add-order", component: Upload },
  // // { path: "/add-order", component: Upload },

  // // Forms

  // { path: "/form-advanced", component: FormAdvanced },
  // //Reports
  // // { path: "/Reports", component: Reports },

  // // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/Tracking", component: Tracking },
  // //kurlon project:
  // { path: "/operationsDashboard", component: OperationsDashboard },
  // { path: "/transporterDashboard", component: TransporterDashboard },
  // // { path: "/InvoiceDashboard", component: InvoiceDashboard },
  // { path: "/invoice-dashboard", component: InvoiceDashboardCard },

  // //forms
  // { path: "/add-trip", component: TripForm },
  // // { path: "/add-indent", component: IndentForm },
  // { path: "/edit-trip-:id", component: EditTripForm },
  // { path: "/track-trip-:id", component: TrackTripForm },
  // { path: "/UploadForm", component: UploadForm },

  // // { path: "/BillingIndex", component: agreementIndex },
  // { path: "/BillingIndex", component: AgreementIndex_new },
  // { path: "/invoiceGeneration", component: InvoiceGeneration },
  // { path: "/generateInvoice", component: GeneratedInvoice },
  // { path: "/Billing/AddAgreement", component: addAgreementIndex },
  // { path: "/Billing/InvoiceList", component: InvoiceList },
  // { path: "/InvoiceEdit", component: InvoiceEdit },
  // { path: "/ControlTower", component: ControlTower },

  // //mobile -> web features
  // { path: "/EPODIndex", component: EPODIndex },
  // { path: "/profileSettings", component: ProfileSettings },

  // { path: "/indent-planning", component: CreatorIndent },
  // { path: "/tables-editable", component: EditableTables },
  // { path: "/trips", component: Trip },
  // { path: "/Trip_new", component: Trip_new },
  // { path: "/tripDetails", component: TripDetails },
  // { path: "/add-driver", component: DriverForm },
];
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },
  { path: "/pages-401", component: Error401 },
  // { path: "/indent-planning2", component: CreatorIndent },
  // { path: "/assigned-indent2", component: AssignedTransporterIndent },

  // { path: "/confirm-delivery/:tripId/:dealerId", component: ConfirmDelivery },
  // { path: "/orders-list/:extordId", component: OrderDetails },
  // { path: "/order-detail/:extordId", component: OrderDetail },

  //test

  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "/auth-lock-screen", component: AuthLockScreen },

  // Authentication Inner
  // { path: "/auth-login", component: Login1 },
  // { path: "/auth-register", component: Register1 },
  // { path: "/auth-recoverpw", component: ForgetPwd1 },

  // { path: "/pages-maintenance", component: Maintenance },
  // { path: "/pages-comingsoon", component: CommingSoon },

  //Billing Modules
  // { path: "/Billing/AddAgreement", component: addAgreementIndex },
];

export { authProtectedRoutes, publicRoutes };
