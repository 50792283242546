let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.masters?.FREIGHT_CODE ?? "Freight Code",
        field: "freightCode",
        sort: "asc",
        width: 100,
      },
  
      {
        label: userLang?.masters?.COST_MAIN ?? "Cost Main",
        field: "costMain",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.SOURCE ?? "Source",
        field: "source",
        sort: "asc",
        width: 180,
      },
      {
        label: userLang?.masters?.DESTINATION ??  "Destination",
        field: "destination",
        sort: "asc",
        width: 180,
      },
      {
        label: userLang?.masters?.ROUTES ??  "Route",
        field: "route",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.TRANSPORTER ?? "Transporter",
        field: "transporter",
        sort: "asc",
        width: 200,
      },
      {
        label: userLang?.masters?.VEHICLE_TYPE ?? "VehicleType",
        field: "vehicleType",
        sort: "asc",
        width: 100,
      },
      {
        label: userLang?.masters?.VALIDITY ?? "Validity",
        field: "validityFromTo",
        sort: "asc",
        width: 200,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.masters?.FREIGHT_CODE ?? "Freight Code",
      field: "freightCode",
      sort: "asc",
      width: 100,
    },

    {
      label: userLang?.masters?.COST_MAIN ?? "Cost Main",
      field: "costMain",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.SOURCE ?? "Source",
      field: "source",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.DESTINATION ??  "Destination",
      field: "destination",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.ROUTES ??  "Route",
      field: "route",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.TRANSPORTER ?? "Transporter",
      field: "transporter",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.VEHICLE_TYPE ?? "VehicleType",
      field: "vehicleType",
      sort: "asc",
      width: 100,
    },
    {
      label: userLang?.masters?.VALIDITY ?? "Validity",
      field: "validityFromTo",
      sort: "asc",
      width: 100,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.masters?.FREIGHT_CODE ?? "Freight Code",
      field: "freightCode",
      sort: "asc",
     type: "text",
    },

    {
      label: userLang?.masters?.COST_MAIN ?? "Cost Main",
      field: "costMain",
      sort: "asc",
     type: "text",
    },
    {
      label: userLang?.masters?.SOURCE ?? "Source",
      field: "source",
      sort: "asc",
     type: "select",
    },
    {
      label: userLang?.masters?.DESTINATION ??  "Destination",
      field: "destination",
      sort: "asc",
     type: "select",
    },
    {
      label: userLang?.masters?.ROUTES ??  "Route",
      field: "route",
      sort: "asc",
     type: "select",
    },
    {
      label: userLang?.masters?.TRANSPORTER ?? "Transporter",
      field: "transporter",
      sort: "asc",
     type: "select",
    },
    {
      label: userLang?.masters?.VEHICLE_TYPE ?? "VehicleType",
      field: "vehicleType",
      sort: "asc",
     type: "select",
    },
    // {
    //   label: userLang?.masters?.VALIDITY ?? "Validity",
    //   field: "validityFromTo",
    
    //  sort: "asc",
    // },
    {
      label: userLang?.trip?.VALIDITY,
      field: "validityFromTo",
      type: "date",
    },
    {
      label: userLang?.masters?.VALIDITY ?? "action",
      field: "action",
      sort: "asc",
     type: "text",
    },
  ],
};
