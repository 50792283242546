import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  types,
} from "./actionTypes";

export const agreementDate = (values) => ({
  type: types.DATE_CAHNGE,
  values,
});

export const fetchViewAgreement = (id) => ({
  type: types.VIEW_AGREEMENT,
  id,
});

export const fetchLastAgreement = (id) => ({
  type: types.FETCH_LAST_AGREEMENT_VALUES,
  id,
});

export const storeAgreement = (values) => ({
  type: types.STORE_AGREEMENT,
  payload: values,
});

export const createAgreement = (values, callback) => ({
  type: types.CREATE_AGREEMENT,
  values,
  callback,
});

export const fetchAgreements = (values, cb, duration) => {
  return {
    type: types.FETCH_AGREEMENTS,
    values,
    cb,
    duration,
  };
};

export const clearAgreementstate = () => ({
  type: types.CLEAR_AGREEMENT_VALUES,
});

export const fetchSignedURL = (values, callback) => ({
  type: types.FETCH_SIGNED_URL,
  values,
  callback,
});

export const uploadAgreement = (uri, data, headers) => ({
  type: types.UPLOAD_AGREEMENT,
  uri,
  data,
  headers,
});
export const uploadImagesAws = (uri, data, headers, callback) => ({
  type: types.UPLOAD_IMAGES_AWS,
  uri,
  data,
  headers,
  callback,
});
export const fetchInvoices = (values, callback, fromDate, endDate, state) => ({
  type: types.FETCH_INVOICES,
  values,
  fromDate,
  endDate,
  state,
  callback,
});

export const fetchInvoicesExcel = (
  values,
  callback,
  fromDate,
  endDate,
  state
) => ({
  type: types.FETCH_INVOICES_EXCEL,
  values,
  fromDate,
  endDate,
  state,
  callback,
});

export const fetchBills = (
  values,
  startDate,
  endDate,
  stateCode,
  callback
) => ({
  type: types.FETCH_BILLS,
  values,
  startDate,
  endDate,
  stateCode,
  callback,
});


export const createInvoice = (values, callback) => ({
  type: types.CREATE_INVOICE,
  values,
  callback,
});
export const fetchInvoiceById = (invId, callback) => ({
  type: types.FETCH_INVOICE_BYID,
  invId,
  callback,
});

export const addNewBills = (values, callback) => ({
  type: types.ADD_NEW_BILL,
  values,
  callback,
});
export const clearLoadedValues = () => ({
  type: types.CLEAR_LOADED_VALUES,
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width, layoutType) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: { width, layoutType },
});

export const changeSidebarTheme = (theme, layoutType) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: { theme, layoutType },
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});

export const acceptInvoice = (values, callback) => ({
  type: types.ACCEPT_INVOICE,
  values,
  callback,
});

export const approveInvoice = (invoiceID, callback) => ({
  type: types.APPROVE_INVOICE,
  invoiceID,
  callback,
});

export const rejectInvoice = (values, callback) => ({
  type: types.REJECT_INVOICE,
  values,
  callback,
});

export const updateBill = (values, callback) => ({
  type: types.UPDATE_BILL,
  values,
  callback,
});

export const fetchAllAgreements = (offset, limit, callback) => ({
  type: types.COPY_AGREEMENT_LIST,
  offset,
  limit,
  callback,
});

export const clearAllBills = () => ({
  type: types.CLEAR_BILLS,
});

export const updateInvoice = (values, callback) => ({
  type: types.UPDATE_INVOICE,
  values,
  callback,
});

export const invoiceBillAddOrDelete = (payload, callback) => ({
  type: types.INVOICE_BILL_DELETE,
  payload,
  callback,
});

export const createInvoiceForBills = (payload, callback) => ({
  type: types.CREATE_INVOICE_BILLS,
  payload,
  callback,
});
export const submitToRaisedInvoice = (payload, callback) => ({
  type: types.SUBMIT_TO_RAISE_INVOICE,
  payload,
  callback,
});
