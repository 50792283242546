import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import dotGroup from "./assets/dot.png";
import Vector from "./assets/Vector.png";
import dot3 from "./assets/dot3.png";
import dotLast from "./assets/dotLast.png";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import TrackingIcon from "./assets/tracking.png";
import {
  closeOrderTrip,
  orderTrackingByEwayBill,
} from "../../store/baxter-order/actions";
import { NotificationManager } from "react-notifications";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Button } from "reactstrap";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class OrderDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ewayBillNumber: "",
      status: "",
      openModel: false,
      confirmed: false,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }
  handleClose = () => {
    this.setState({ openModel: false });
    window.close();
  };
  onClicked(e, EWaybillnumber, status) {
    e.preventDefault();
    if (EWaybillnumber) {
      this.setState({ ewayBillNumber: EWaybillnumber }, () => {
        if (status === "close") {
          this.setState({
            openModel: true,
          });
        } else {
          this.props.orderTrackingByEwayBill(EWaybillnumber, (res) => {
            this.props.history.push({
              pathname: "/trackOrders",
              state: res?.data?.trackingData?.[0]?.baxterTripId,
              data: res,
              pathKey: status,
            });
          });
        }
      });
    }
  }

  render() {
    const { fullScreen } = this.props;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let user = JSON.parse(window.localStorage.getItem("authUser"));
    let darkState = this.props.darkTheme;
    if (this.state.confirmed) {

      let date1 = new Date().toISOString();
      let currentTime = moment(new Date()).format("HH:mm:ss");

      let formattedDate = date1.replace(date1.substring(11, 19), currentTime);
      this.props.closeOrderTrip(
        {
          eWayBillNo: this.state.ewayBillNumber,
          tripCompletedDate: formattedDate,
        },
        (res) => {
          // console.log(res,'resss')
          if (res?.success) {
            NotificationManager.success(res["message"], "Success");
          } else {
            NotificationManager.error(res?.["message"], "Error");
          }
        }
      );
      this.setState({ confirmed: false });
      this.handleClose();
    }
    return (
      <>
        <div>
          <Grid
            container
            style={{
              marginBottom: "-24px ",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_PUNCHED" ? (
                        <>
                          <div>{items[0]?.updatedDate}</div>
                          <div>{items[0]?.updateTime}</div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle  dark__span">Order Punch</span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            style={{
              marginBottom: "-24px ",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_RELEASED" ? (
                        <>
                          <div>{items[0]?.updatedDate}</div>
                          <div>{items[0]?.updateTime}</div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle  dark__span">
                  Order Release
                </span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            style={{
              marginBottom: "-24px ",
              height: "auto",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props.groupedItems).map(
                    ([status, items]) =>
                      status === "INVOICE_GENERATED" ? (
                        <>
                          <div>
                            {items?.length > 0 ? (
                              items[0]?.acknowledgementDateTimeObject ? (
                                <span>
                                  {moment(
                                    items[0]?.acknowledgementDateTimeObject
                                  ).format("DD-MMM-YYYY")}
                                </span>
                              ) : items[0]?.acknowledgementDate ? (
                                <span>
                                  {moment(items[0]?.acknowledgementDate).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              ) : items[0]?.updatedDate ? (
                                <span>{items[0]?.updatedDate}</span>
                              ) : (
                                <span></span>
                              )
                            ) : (
                              <span></span>
                            )}
                          </div>
                          <div>
                            {items?.length > 0 ? (
                              <span>
                                {items[0]?.acknowledgementDateTimeObject
                                  ?.length > 0
                                  ? items[0]?.acknowledgementDateTimeObject.substr(
                                      11,
                                      8
                                    )
                                  : items[0]?.updatedDateTimeObject?.length > 0
                                  ? items[0]?.updatedDateTimeObject.substr(
                                      11,
                                      8
                                    )
                                  : items[0]?.updateTime}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dot3}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle  dark__span">
                  <span className="">
                    {" "}
                    Invoice Generated
                    {`(${
                      this.props?.countInvoiceItem
                        ? this.props?.countInvoiceItem
                        : 0
                    }/${this.props.productLength})`}
                  </span>

                  {this.props?.finalDocumentNumber1[0] &&
                    this.props?.finalDocumentNumber1[0].map((item) => (
                      <>
                        <div style={{ padding: "5px" }}>
                          <span className="OrderLineHead">
                            <span>
                              <img src={Vector} />
                            </span>{" "}
                            Invoice No
                          </span>{" "}
                          :{" "}
                          <span
                            style={{
                              color: "GrayText",
                            }}
                          >
                            {item?.id}
                          </span>
                        </div>
                        <div>
                          {item?.values?.map((item) => (
                            <>
                              <div
                                style={{
                                  color: "GrayText",
                                  padding: "1%",
                                  marginLeft: "40px",
                                  fontSize: "12px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  Order Line No
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  {item?.orderLineNo}
                                </span>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ))}
                </span>
              </div>

              <br></br>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            style={{
              marginBottom: "-24px ",
              marginTop: "40px",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_DISPATCHED" ? (
                        <>
                          <div>
                            {items?.length > 0 ? (
                              items[0]?.eWayBillDateTimeObject ? (
                                <span>
                                  {moment(
                                    items[0]?.eWayBillDateTimeObject
                                  ).format("DD-MMM-YYYY")}
                                </span>
                              ) : items[0]?.eWayBillDate ? (
                                <span>
                                  {moment(items[0]?.eWayBillDate).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              ) : items[0]?.updatedDate ? (
                                <span>{items[0]?.updatedDate}</span>
                              ) : (
                                <span></span>
                              )
                            ) : (
                              <span></span>
                            )}
                          </div>
                          <div>
                            {items?.length > 0 ? (
                              <span>
                                {items[0]?.eWayBillDateTimeObject?.length > 0
                                  ? items[0]?.eWayBillDateTimeObject.substr(
                                      11,
                                      8
                                    )
                                  : items[0]?.updatedDateTimeObject?.length > 0
                                  ? items[0]?.updatedDateTimeObject.substr(
                                      11,
                                      8
                                    )
                                  : items[0]?.updateTime}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dot3}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{}}>
                <span className="orderStageStyle  dark__span mt-4">
                  <span>
                    Order Dispatch
                    {`(${this.props?.countEway ? this.props?.countEway : 0}/ ${
                      this.props?.productLength
                    })`}
                  </span>

                  {this.props.finalEwayBillNo2[0] &&
                    this.props.finalEwayBillNo2[0]?.map((item) => (
                      <>
                        <div style={{ padding: "5px" }}>
                          <span className="OrderLineHead">
                            <span>
                              <img src={Vector} />
                            </span>{" "}
                            EWay Bill No
                          </span>{" "}
                          :{" "}
                          <span
                            style={{
                              color: "GrayText",
                            }}
                          >
                            {item?.id}
                          </span>
                          <div style={{ display: "flex" }}>
                            {user?.data?.role?.label === "customer" ? (
                              <div>
                                <div className="tooltipIcon">
                                  <input
                                    type="checkbox"
                                    checked
                                    style={{
                                      accentColor: "green",
                                      width: "20px",
                                      height: "20px",
                                      marginTop: "5px",
                                      marginLeft: "40px",
                                      backgroundColor: "#f1f5f7",
                                    }}
                                    onClick={(e) => {
                                      this.onClicked(e, item?.id, "close");
                                    }}
                                  />
                                  {/* <img src={Confirm} className="img-fluid" /> */}
                                  {/* </input> */}
                                  <span
                                    className="tooltipIcontext"
                                    style={{ width: "80px" }}
                                  >
                                    &nbsp; Complete Trip &nbsp;
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div>
                              <div className="tooltipIcon">
                                <button
                                  style={{
                                    width: "50px",
                                    marginLeft: "10px",
                                    backgroundColor: "#f1f5f7",
                                  }}
                                  onClick={(e) => {
                                    this.onClicked(
                                      e,
                                      item?.id,
                                      "Ordertracking"
                                    );
                                  }}
                                >
                                  <img
                                    src={TrackingIcon}
                                    style={{ height: "20px", marginTop: "3px" }}
                                    className="img-fluid"
                                  />
                                </button>
                                <span
                                  className="tooltipIcontext"
                                  style={{ width: "80px" }}
                                >
                                  &nbsp; Tracking &nbsp;
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {item?.values?.map((item) => (
                            <>
                              <div
                                style={{
                                  color: "GrayText",
                                  padding: "1%",
                                  marginLeft: "40px",
                                  fontSize: "12px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  Order Line No
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  {item?.orderLineNo}
                                </span>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ))}
                </span>
              </div>
              {/* <Button
                className="track-button"
                outline
                size="xs"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/trackOrders",
                    state:
                      this.props.finalEwayBillNo2[0] &&
                      this.props.finalEwayBillNo2[0]?.map((item) => {
                        return item?.id;
                      }),
                  });
                }}
              >
                Track Order
              </Button> */}

              <br></br>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            style={{
              marginBottom: "-24px ",
              marginTop: "10px",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_DELIVERED" ? (
                        <>
                          <div>
                            {moment(items[0]?.createdAt).format("DD-MMM-YYYY")}
                          </div>
                          <div>
                            {moment(items[0]?.createdAt).format("HH:mm:ss")}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
              }}
              className="mb-4"
            >
              <div>
                <img
                  src={dotLast}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <span className="orderStageStyle  dark__span">
                  <span
                    className={
                      this.props.curOrderStatus === "ORDER_DELIVERED"
                        ? ""
                        : "inctiveStatus"
                    }
                  >
                    Order Delivered
                  </span>
                </span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.openModel}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              Are you sure you want to complete the trip?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ confirmed: true })}
              color="success"
              autoFocus
            >
              Complete
            </Button>
            <Button onClick={this.handleClose} color="danger" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(null, {
  closeOrderTrip,
  orderTrackingByEwayBill,
})(withRouter(OrderDispatch));
