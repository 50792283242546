import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Container } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import InvoiceDetail from "./InvoiceDetail";
import InvoiceCancelledModal from "./InvoiceCancelledModal";
import AddChargesModal from "./AddCharges";

//actions
import {
  getInvoiceList,
  checkTripCreation,
  createTrip,
  getInvoiceListForExcel,
  cancelInvoice,
  AddCharges,
} from "../../store/baxter-orderInvoice/actions";
// component
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
  TripCreatioModalHeaders,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quckFilters";
import { Button } from "reactstrap";
import TripCreationModal from "./TripCreationModal";
import { CheckBoxItem } from "./CheckBoxComponent";
import { NotificationManager } from "react-notifications";
import { exportToCSV } from "../common/exportToCsv";
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;
var searchValues = {};
let pathName;
let key;
let keyOffset;
const sortInvoiceOption = [
  { label: "All Invoice", value: "" },
  { label: "Pending Invoice", value: true },
];
const cancelledInvoice = [
  { label: "true", value: true },
  { label: "false", value: false },
];
class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      currentInvoice: "",
      searchFilters: {},
      modalView: false,
      // topFilters: true,
      selectedInvoices: new Map(),
      selectedInvoicesData: [],
      tripCreationCompletedModal: false,
      tripCreationSuccess: false,
      sortInvoiceVal: "",
      cancelModal: false,
      remark: "",
      legalInvoice:"",
      modal: false,
      form: {},
      errors: {},
      ttselectValue:"ptl",
    };
  }

  componentDidMount() {
    offset = 0;
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }

  getAllData = () => {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let role = authUser?.data ? authUser?.data?.role?.label : "";
    pathName = window.location.pathname;
    key = `mySearchFilter_${pathName}`;
    keyOffset = `myOffsetFilter_${pathName}`;
    let supervisorCode;
    let passSupervisor;
    if (role === "supervisor") {
      supervisorCode =
        authUser?.data?.cfaCode &&
        authUser?.data?.cfaCode?.map((ele) => {
          return ele;
        });
      passSupervisor = supervisorCode ? supervisorCode.join(",") : "";
    }
    let storedData = localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : "";
    searchValues = storedData || {};
    let offsetData = localStorage.getItem(keyOffset)
      ? localStorage.getItem(keyOffset)
      : window.localStorage.setItem(keyOffset, offset);
    if (searchValues["pendingInvoice"] === true) {
      this.setState({
        sortInvoiceVal: { label: "Pending Invoice", value: true },
      });
    } else if (searchValues["pendingInvoice"] === "") {
      this.setState({ sortInvoiceVal: { label: "All Invoice", value: "" } });
    } else {
      searchValues = { pendingInvoice: true };
      this.setState({
        sortInvoiceVal: { label: "Pending Invoice", value: true },
      });
    }
    this.setState({ dataLoading: true });
    this.setState({ searchFilters: searchValues }, () => {
      let searchFilters = this.state.searchFilters;
      // console.log(Object.keys(this.state.searchFilters).length > 0 ,"90")
      this.props.getInvoiceList(
        {
          offset: offset || offsetData,
          limit: 10,
        },
        passSupervisor,
        "",
        Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
        () => {
          this.setState({ dataLoading: false });
        }
      );
    });
    if (searchBar)
      searchBar.searchBarData.forEach((obj) => {
        if (obj.field === "Cancelled Invoice") {
          obj.options = cancelledInvoice;
        }
      });
  };
  getAllDataReset = () => {
    offset = 0;
    window.localStorage.setItem(keyOffset, offset);
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    window.localStorage.setItem(keyOffset, offset);
    this.setState({ loading: true });
    this.getAllData();
  };
  handleChange = (dateValues) => {
    // console.log("date values", dateValues);
    searchValues["invoiceStartDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("YYYY-MM-DD")
          : moment(dateValues).format("YYYY-MM-DD")
        : null;
    searchValues["invoiceEndsDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("YYYY-MM-DD")
        : null;
    pathName = window.location.pathname;
    key = `mySearchFilter_${pathName}`;
    keyOffset = `myOffsetFilter_${pathName}`;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      window.localStorage.setItem(
        key,
        JSON.stringify(this.state.searchFilters)
      );
      window.localStorage.setItem(keyOffset, offset);
      this.getAllData();
    });
  };

  viewInvoiceDetail = (data) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>View DEtaisl", data);
    this.props.history.push({
      pathname: "/viewInvoiceDetail",
      state: { currentInvoice: data },
    });
  };

  actions = (actionString) => {
    // console.log(actionString);
    var darkMode = document.body.classList.contains("dark__mode");
    return (
      <div className="dropdown ">
        <Button className="iconButtons dropbtn actionButtonsDark">
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3C9.55228 3 10 2.55228 10 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3C16.5523 3 17 2.55228 17 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <div className="dropdown-content contentDark">
          <div
            className="action-button actionDspan"
            onClick={() => {
              this.viewInvoiceDetail(actionString);
            }}
          >
            View Details
          </div>
          <div
            className="action-button actionDspan"
            onClick={() => {
              this.addChargesPopUp(actionString);
            }}
          >
            Add Charges
          </div>
          <div
            className="action-button-cancel actionDspan"
            onClick={() => {
              this.invoiceCancell(actionString);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    );
  };

  searchValueAssignerFunction = (name, value, type) => {
    if (typeof value === "text" || type === "text") {
      searchValues[name] = value?.replace(/^\s+/, "");
      this.setState({ searchFilters: searchValues }, () => {
        window.localStorage.setItem(
          key,
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem(keyOffset, offset);
      });
    }
    if (type === "date" || type === "select") {
      offset = 0;
      searchValues[name] = value;
      this.setState({ searchFilters: searchValues }, () => {
        offset = 0;
        window.localStorage.setItem(
          key,
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem(keyOffset, offset);
        this.getAllData();
      });
    }
  };

  reset = (invoiceTopFilter) => {
    const { invoiceStartDate, invoiceEndsDate, pendingInvoice } = searchValues;
    offset = 0;
    if (invoiceTopFilter) {
      searchValues = {
        ...searchValues,
        invoiceStartDate: "",
        invoiceEndsDate: "",
        pendingInvoice: true,
      };
      // const keysToRemove = ["invoiceStartDate", "invoiceEndsDate"];
      // const myLocalObject = JSON.parse(localStorage.getItem(key)) || {};
      // keysToRemove.forEach((key) => delete myLocalObject[key]);
      localStorage.setItem(key, JSON.stringify(searchValues));
      window.localStorage.setItem(keyOffset, offset);
      this.setState({
        // topFilters: "true",
        sortInvoiceVal: sortInvoiceOption[1],
      });
    } else {
      searchValues = {
        invoiceStartDate: invoiceStartDate || "",
        invoiceEndsDate: invoiceEndsDate || "",
        pendingInvoice: pendingInvoice,
      };
      localStorage.setItem(key, JSON.stringify(searchValues));
      window.localStorage.setItem(keyOffset, offset);
    }
    let selectedInvoices = this.state.selectedInvoices;
    selectedInvoices.clear();
    this.setState(
      {
        searchFilters: searchValues,
        selectedInvoices: selectedInvoices,

        selectedInvoicesData: [],
        sortInvoiceVal: sortInvoiceOption[1],
      },
      () => {
        this.getAllData();
      }
    );
  };

  resetPopUp = (e) => {
    this.setState(
      {
        form: {
          basicFreightCharges: "",
          loadingCharges: "",
          unLoadingCharges: "",
          detentionCharges: "",
          name:"",
        },
        ttselectValue:"",
        errors: {},
      },
     
      
    );
  };

  modalViewToggle = () => {
    this.setState({ modalView: !this.state.modalView });
  };

  SelectedInvoices = (ele, e) => {
    let selectedInvoices = this.state.selectedInvoices;
    if (e.target.checked) {
      selectedInvoices.set(ele._id, ele);
    } else if (!e.target.checked && this.state.selectedInvoices.has(ele._id)) {
      selectedInvoices.delete(ele._id);
    }
    this.setState({ selectedInvoices: selectedInvoices });
  };
  removeSelectedInvoices = (id) => {
    let selectedInvoicesData = this.state.selectedInvoicesData;
    let selectedInvoices = this.state.selectedInvoices;
    if (id) {
      selectedInvoices.delete(id);
      selectedInvoicesData = selectedInvoicesData.filter(
        (item) => item._id !== id
      );
    }
    this.setState(
      {
        selectedInvoices: selectedInvoices,
        selectedInvoicesData: selectedInvoicesData,
      },
      () => {
        this.getAllData();
      }
    );
  };
  tripCreationCheck = () => {
    let selectedInvoicesData = [];
    for (let [key, value] of this.state.selectedInvoices) {
      value.action = (
        <svg
          onClick={() => this.removeSelectedInvoices(key)}
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9092 1.82141L1.90918 13.8214"
            stroke="#E53D2C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.90918 1.82141L13.9092 13.8214"
            stroke="#E53D2C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      selectedInvoicesData.push(value);
    }
    this.setState({ selectedInvoicesData: selectedInvoicesData }, () => {
      this.setState({ modalView: true });
    });
    //   } else {
    //     this.setState(
    //       {
    //         tripCreationCompletedModal: !this.state.tripCreationCompletedModal,
    //       },
    //       () => {
    //         this.setState({
    //           tripCreationSuccess: false,
    //         });
    //       }
    //     );
    //   }
    // });
  };

  createTrip = () => {
    const invoices = Array.from(this.state.selectedInvoices.keys());
    this.setState({ modalView: false }, () => {
      this.props.createTrip(invoices, (res) => {
        if (res?.success) {
          this.setState({ tripCreationSuccess: res.success }, () => {
            this.setState({ tripCreationCompletedModal: true });
            this.reset();
          });
        } else {
          NotificationManager.error(res?.message, "error");
        }
      });
    });
  };

  tripCreationCompletedModalToggle = () => {
    this.setState({
      tripCreationCompletedModal: !this.state.tripCreationCompletedModal,
    });
  };
  sortColumn = (item) => {
    if (item) {
      searchValues["sortBy"] = item;
      this.setState({ searchFilters: searchValues }, () => {
        this.getAllData();
      });
    }
  };
  handleSortInvoice = (sortData) => {
    // this.setState(
    //   (prevState) => ({
    //     sortInvoiceVal: sortData,
    //     topFilters: sortData?.value,
    //   }),
    //   () => {
    //     window.localStorage.setItem(
    //       "myFilterData",
    //       JSON.stringify(this.state.searchFilters)
    //     );
    //     window.localStorage.setItem("offset", offset);
    //     this.getAllData();
    //   }
    // );
    const { value, label } = sortData;
    searchValues = {
      ...searchValues,
      pendingInvoice: value,
    };
    this.setState(
      {
        searchFilters: searchValues,
        sortInvoiceVal: { label: label, value: value },
      },
      () => {
        window.localStorage.setItem(
          key,
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem(keyOffset, offset);
        this.getAllData();
      }
    );
  };
  exportToData = () => {
    this.setState({ dataLoading: true });
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let role = authUser?.data ? authUser?.data?.role?.label : "";
    let supervisorCode;
    let passSupervisor;
    if (role === "supervisor") {
      supervisorCode =
        authUser?.data?.cfaCode &&
        authUser?.data?.cfaCode?.map((ele) => {
          return ele;
        });
      passSupervisor = supervisorCode ? supervisorCode.join(",") : "";
    }
    let storedData = localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : "";
    let searchFilters = this.state.searchFilters;

    this.props.getInvoiceListForExcel(
      {
        offset: offset,
        limit: 100000,
      },
      passSupervisor,
      "",
      Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
      (res) => {
        this.setState({ dataLoading: false });
        let exportData = [];
        if (res?.success) {
          if (res?.data?.docs?.length > 0) {
            res.data.docs.map((ele) => {
              exportData.push({
                "Order Number": ele?.order?.orderNumber
                  ? ele?.order?.orderNumber
                  : "N/A",
                "Order Type": ele?.order?.orderType
                  ? ele?.order?.orderType
                  : "N/A",
                "Invoice No": ele?.legalInvoiceNo ? ele?.legalInvoiceNo : "N/A",
                "Billed To Name": ele?.orders?.billToDetails?.billedToName
                  ? ele?.orders?.billToDetails?.billedToName
                  : "N/A",
                "Billed To City": ele?.orders?.billToDetails?.billedToCity
                  ? ele?.orders?.billToDetails?.billedToCity
                  : "N/A",
                Warehouse: ele?.order?.businessUnit
                  ? ele?.order?.businessUnit
                  : "N/A",
                Transporter: ele?.transporter ? ele?.transporter : "N/A",

                MoT: ele?.modeofTransport ? ele?.modeofTransport : "N/A",
                "Vehicle No": ele?.vehicleNo ? ele?.vehicleNo : "N/A",
                "Invoice Date": ele?.InvoiceDate
                  ? moment(ele?.InvoiceDate).format("DD-MMM-YYYY")
                  : "N/A",
              });
            });
          }
          if (res?.data?.docs?.length > 0) {
            exportToCSV(exportData, "Invoice list");
          } else {
            exportData.push({
              "Order Number": "",
              "Order Type": "",
              "Invoice No": "",
              "Billed To Name": "",
              "Billed To City": "",
              Warehouse: "",
              Transporter: "",
              MoT: "",
              "Vehicle No": "",
              "Invoice Date": "",
            });
            exportToCSV([...exportData], "Orders");
          }
        } else {
          NotificationManager.error(res?.message, "error");
        }
      }
    );
  };
  cancellTogglemodal = () => {
    this.setState(
      (prevState) => ({
        cancelModal: !prevState.cancelModal,
      }),
      () => {}
    );
  };
  invoiceCancell = (data) => {
    // console.log("CANCEL INVOICE DATA", data);
    this.setState(
      { cancelModal: true, currentInvoice: data, remark: "" },
      () => {}
    );
  };
  onChangeInputData = (name, value) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>TRUCTYPE",name, value);
    this.setState({ [name]: value }, () => {});
  };
  handleCancellInvoiceSubmit = () => {
    const { currentInvoice, remark } = this.state;
    this.setState({ dataLoading: true });
    let payload = {
      legalInvoiceNo: currentInvoice?.legalInvoiceNo
        ? currentInvoice?.legalInvoiceNo
        : "",
      comment: remark ? remark : "",
    };
    this.props.cancelInvoice(payload, (res) => {
      if (
        (res["message"] &&
          res["message"] === "Invoice Cancelled  Successfully!") ||
        res["success"] === true
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/InvoiceList");
        this.setState({ dataLoading: false, cancelModal: false }, () => {
          this.resetCancell();
          offset = 0;
          this.getAllData();
        });
      } else {
        this.setState({ dataLoading: false });
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  resetCancell = () => {
    this.setState({ remark: "" });
  };

  //Add charges
  validateValAdd = () => {
    const errorsAdd = {};
    let { basicFreightCharges, loadingCharges ,unLoadingCharges ,detentionCharges,name} =
      this.state.form;
    // let image = this.state.urlArrayValues;

    if (!basicFreightCharges || !Number(basicFreightCharges)) {
      errorsAdd.basicFreightCharges = "Basic Freight Charges is required and it should be Number !";
    }
    if (!loadingCharges|| !Number(loadingCharges)) {
      errorsAdd.loadingCharges = "Loading is required and it should be Number !";
    }
    if (!unLoadingCharges || !Number(unLoadingCharges)) {
      errorsAdd.unLoadingCharges = "Unloading Charges is required and it should be Number !";
    }
    // if (!detentionCharges|| !Number(detentionCharges)) {
    //   errorsAdd.detentionCharges = "Detention Charges is required and it should be Number !";
    // }
    if (!name) {
      errorsAdd.name = "Truck Type is required!";
    }
    return errorsAdd;
  };
  handleSubmit = () => {
    this.setState({
      onSubmitDisable: true,
      dataLoading: true,
      errors: this.validateValAdd(),
    });
    const { form, formErrors } = this.state;
    const updatedForm = {
      ...form,
      truckType: form.name
    };
    delete updatedForm.name
    let formVal = { ...updatedForm,legalInvoiceNo:this.state.legalInvoice };
    console.log(formVal, "form");
    this.props.AddCharges(formVal, (res) => {
      // console.log(res, "90");
      if (
        (res["message"] &&
          res["message"] === "Charges and truck type updated  successfully!") ||
        res["success"] === true
      ) {
        NotificationManager.success(res["message"], "Success");
        
        this.setState(
          { modal: false },
          () => {
            this.reset();
            // this.getAllData();
          }
        );
      } else {
        this.setState({ dataLoading: false });
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  onChangeInputDataCharges = (name, value) => {
    const { form } = this.state;
    let formObj = {};
    
  
    if (name === 'truckType') {
      formObj = {
        ...form,
        name: value,
      };
    } else {

      formObj = {
        ...form,
        [name]: Number(value),
      };
    }
  
    this.setState({ form: formObj }, () => {});
  };
  
  
  togglemodal = () => {
    this.setState(
      (prevState) => ({
        modal: !prevState.modal,
      }),
      () => {}
    );
  };
  
  addChargesPopUp = (data) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>Data: " , data);
    this.setState({ modal: true, errors: {},legalInvoice:data?.legalInvoiceNo }, () => {
    });
  };

  // handleTruckTypeValue=(value) => {
  //   console.log(">>>>>>>>>>>>>>>>>TRUCK TYPE VALUE",value);
  //   const { value, label } = name;
  //   const { form } = this.state;
  //   let formObj = {};
    
  
  //   if (name === 'truckType') {
  //     formObj = {
  //       ...form,
  //       name: value,
  //     };
  //   }
  
  //   this.setState({ ttselectValue:value }, () => {});
  // };

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    const { getInvoiceData } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("InvoiceData"));
    const invoiceOffset = localStorage.getItem(keyOffset)
      ? JSON.parse(localStorage.getItem(keyOffset))
      : "";
    const invoiceData = navigator.onLine
      ? getInvoiceData?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(invoiceData)) {
      offset = invoiceOffset ? invoiceOffset : offset;
      totCnt = navigator.onLine
        ? getInvoiceData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? getInvoiceData?.data?.offset + getInvoiceData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

      invoiceData.forEach((ele) => {
        rows.push({
          Select: (
            <CheckBoxItem
              key={ele._id}
              item={ele}
              selectedInvoices={this.state.selectedInvoices}
              SelectedInvoices={this.SelectedInvoices}
              isDisabled={
                ele?.isAvailableForTripCreation == false ||
                ele?.isAvailableForTripCreation == ""
                  ? true
                  : false
              }
            />
          ),
          transporter: ele?.transporter ? (
            <div className="tooltipIcon">
              <span
                className="d-inline-block text-truncate"
                style={{ maxWidth: "90px" }}
              >
                {ele?.transporter}
              </span>
              <span
                className="tooltipIcontext"
                // style={{ width: "85px", marginLeft: "-14px" }}
              >
                &nbsp;{ele?.transporter}&nbsp;
              </span>
            </div>
          ) : (
            "N/A"
          ),
          legalInvoiceNo: ele?.legalInvoiceNo ? ele?.legalInvoiceNo : "N/A",
          moT: ele?.modeofTransport ? ele?.modeofTransport : "N/A",
          vehicleNo: ele?.vehicleNo ? ele?.vehicleNo : "N/A",
          LR_No: ele?.lrNo ? ele?.lrNo : "N/A",
          LRDate: ele?.lrDate ? ele?.lrDate : "N/A",
          billedToName: ele?.orders?.billToDetails?.billedToName
            ? ele?.orders?.billToDetails?.billedToName
            : "N/A",
          billedToCity: ele?.orders?.billToDetails?.billedToCity
            ? ele?.orders?.billToDetails?.billedToCity
            : "N/A",
          PreparationDate: ele?.preparationDateTime
            ? ele?.preparationDateTime
            : "N/A",
          RemovalDate: ele?.removalDateTime ? ele?.removalDateTime : "N/A",
          dueDate: ele?.dueDate ? ele?.dueDate : "N/A",
          InvoiceDate: ele?.InvoiceDate
            ? moment(ele?.InvoiceDate).format("DD-MMM-YYYY")
            : "N/A",
          IRN: ele?.irn ? (
            <div className="tooltipIcon">
              <span
                className="d-inline-block text-truncate"
                style={{ maxWidth: "70px" }}
              >
                {ele?.irn}
              </span>
              <span
                className="tooltipIcontext"
                style={{ width: "55px", marginLeft: "-14px" }}
              >
                &nbsp;{ele?.irn}&nbsp;
              </span>
            </div>
          ) : (
            "N/A"
          ),
          // eWayBillNo: ele?.eWayBillNo ? ele?.eWayBillNo : "N/A",
          orderNumber: ele?.order?.orderNumber
            ? ele?.order?.orderNumber
            : "N/A",
          orderCo: ele?.order?.orderCo ? ele?.order?.orderCo : "N/A",
          orderType: ele?.order?.orderType ? ele?.order?.orderType : "N/A",
          businessUnit: ele?.order?.businessUnit
            ? ele?.order?.businessUnit
            : "N/A",
          action: this.actions(ele),
        });
      });
    }

    return (
      <>
        <TripCreationModal
          selectedDataHead={TripCreatioModalHeaders}
          SelectedDataFinal={this.state.selectedInvoicesData}
          modalView={this.state.modalView}
          modalViewToggle={this.modalViewToggle}
          createTrip={this.createTrip}
        />
        <Modal
          isOpen={this.state.tripCreationCompletedModal}
          toggle={this.tripCreationCompletedModalToggle}
          size="sm"
          centered
        >
          <ModalBody style={{ alignSelf: "center" }}>
            <div className="tick-cross-container">
              {this.state.tripCreationSuccess ? (
                <div style={{ textAlign: "center" }}>
                  <svg viewBox="0 0 20 20" className="tick-cross success">
                    <path d="M4.8,10.2L2.6,8C2.2,7.6,2.2,7,2.6,6.6c0.4-0.4,1-0.4,1.4,0l1.6,1.6L10,2.6c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-5.6,5.6C5.8,10.6,5.2,10.6,4.8,10.2z" />
                  </svg>
                  <span>Trip Created Successfully.</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.closeModal}
          size="xl"
          className="CustomerModal"
        >
          <ModalHeader>
            <div className="Heading">
              <h3>Invoice Detail</h3>
              <svg
                onClick={this.closeModal}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody>
            <InvoiceDetail currentInvoice={this.state.currentInvoice} />
          </ModalBody>
        </Modal>
        <InvoiceCancelledModal
          cancelModal={this.state.cancelModal}
          cancelTogglemodal={this.cancellTogglemodal}
          onChangeInput={this.onChangeInputData}
          handleSubmit={this.handleCancellInvoiceSubmit}
          reset={this.resetCancell}
          remark={this.state.remark}
        />
        {getLocalStorage()}
        <Container
          aria-hidden="true"
          fluid
          className={`${
            darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
          }`}
        >
          <QuickFilters
            handleChange={this.handleChange}
            reset={this.reset}
            handleSortInvoice={this.handleSortInvoice}
            sortInvoiceOption={sortInvoiceOption}
            sortInvoiceVal={this.state.sortInvoiceVal}
            toggleExcel={this.exportToData}
          />
          <div className="invoiceList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={true}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"68px"}
              dataLoading={this.state.dataLoading}
              sortColumn={this.sortColumn}
              classList="3"
              textInput="inputIndex"
              resetCol="12"
            />
          </div>

          {totCnt !== 0 && !this.state.dataLoading ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 mt-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
                <span className="float-right">
                  <span className="mr-2">
                    {" "}
                    <Button className="button_height" onClick={this.reset}>
                      Reset
                    </Button>
                  </span>
                  <span className="ml-2">
                    <Button
                      className={
                        this.state.selectedInvoices?.size > 0
                          ? "button_height_Active "
                          : "button_height_InActive"
                      }
                      onClick={this.tripCreationCheck}
                      disabled={
                        this.state.selectedInvoices?.size > 0 ? false : true
                      }
                    >
                      Create Trip
                    </Button>
                  </span>
                </span>
              </Col>
            </Row>
          ) : null}
          <AddChargesModal
            modal={this.state.modal}
            // handleTruckTypeValue={this.handleTruckTypeValue}
            toggle={this.togglemodal}
            onChangeInput={this.onChangeInputDataCharges}
            reset={this.resetPopUp}
            form={this.state.form}
            handleSubmit={this.handleSubmit}
            // ttselectValue={this.state.ttselectValue}
            errors={this.state.errors}
          />
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    getInvoiceData: state?.invoiceListReducer?.getInvoiceListData,
    fetchId: state.invoiceListReducer?.fetchId,
  };
};

export default connect(mapStateToProps, {
  getInvoiceList,
  checkTripCreation,
  createTrip,
  getInvoiceListForExcel,
  cancelInvoice,
  AddCharges
})(InvoiceList);
