let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage=()=>{
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: userLang?.report?.LANE,
        field: "lane",
        sort: "asc",
      width: 100,
      },
      {
        label: userLang?.report?.NO_OF_INDENT,
        field: "indentNo",
        sort: "asc",
      width: 100,
      },
    ],
  };
}
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    {
      label: userLang?.report?.LANE,
      field: "lane",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.report?.NO_OF_INDENT,
      field: "indentNo",
      sort: "asc",
      width: 1,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.report?.LANE,
      field: "lane",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.report?.NO_OF_INDENT,
      field: "indentNo",
      sort: "asc",
      width: 2,
    },
  ],
};
