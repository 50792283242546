import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Container } from "reactstrap";
import { StatusIndicator, SubStatusIndicator } from "./StatusIndicator";
import { toPascalCase } from "../../components/Common/utils";
import { actions } from "./actionButtons";
import { NotificationManager } from "react-notifications";
//actions
import {
  getTripList,
  changeMarkedAsArrivedStatus,
  startLoadingStatus,
  loadingCompleted,
  completeTrip,
  getTripListForExcel,
} from "../../store/baxter-trip/actions";
import {
  tripCompletedFileUrl,
  uploadImagesAws,
} from "../../store/baxter-uploadModule/actions";
import CompleteTipModel from "./CompleteTipModel";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quckFilters";
import { Button } from "reactstrap";
import moment from "moment";
import SingleValueOrArraysValue from "../../components/Common/SingleValueOrArraysValue";
import { tripTypeEnum } from "../../components/Common/Status";
import { exportToCSV } from "../common/exportToCsv";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var limit = 1000;
var searchValues = {};
var darkTheme;

const tripSubStatusMaster = [
  { label: "Planned", value: "planned" },
  { label: "Arrived At Source", value: "arrived_at_source" },
  { label: "Quality Check Completed", value: "quality_check_completed" },
  { label: "Loading In Progress", value: "loading_in_progress" },
  { label: "In Transit", value: "in_transit" },
  // { label: "Arrived At Via Point", value: "arrived_at_via" },
  // { label: "EPOD In Progress", value: "epod_in_progress" },
  { label: "Completed", value: "completed" },
  // { label: "Rejected", value: "rejected" },
];
const tripStatusMaster = [
  { label: "Planned", value: "planned" },
  { label: "In Progress", value: "in_progress" },
  { label: "Completed", value: "completed" },
];

const tripsWithOutEwayBillNumber = [
  { label: "true", value: true },
  { label: "false", value: false },
];
class Trip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      offset: 0,
      fromDate: "",
      toDate: "",
      isCompleteTripOpen: false,
      currentTripData: "",
      searchFilters: {},
      tripCompletedDate: "",
      remark: "",
      selectedFile: "",
      attachUrl: "",
      invoiceLength: 0,
    };
  }

  maintainFilterValueInTextBox = (searchValuesObj) => {
    const pathName = "/tripList"; // Set your desired pathname here
    const keys = `myStatusFilter_${pathName}`;
    const updatedstatusFilterObj = {};

    for (const key in searchValuesObj) {
      if (
        key === "SubStatus" ||
        key === "tripType" ||
        key === "Is Ewaybill Not Exist"
      ) {
        const innerKey = `${key}_${pathName}`;
        const filterStatusLabel = toPascalCase(searchValuesObj[key]);

        updatedstatusFilterObj[innerKey] = {
          label: filterStatusLabel,
          value: searchValuesObj[key],
        };
      } else {
        // let updatedsearchFilterObj = { ...searchValues, [key]: searchValuesObj[key] };
        // console.log(updatedsearchFilterObj,"top")
      }

      window.localStorage.setItem(keys, JSON.stringify(updatedstatusFilterObj));
    }
  };

  componentDidMount() {
    let mountFilterData = window.localStorage.getItem("tripsFilterData")
      ? JSON.parse(window.localStorage.getItem("tripsFilterData"))
      : "";
    searchValues = mountFilterData || {};

    if (
      this.props.location?.state?.filter !== undefined ||
      this.props.location?.state?.topFilters !== undefined
    ) {
      let filters = this.props.location.state.filter;
      let topFilters = this.props.location.state.topFilters;
      let tripType = topFilters?.tripType;
      let transporter = topFilters?.transporter;
      let billedToCity = topFilters?.city;
      let state = topFilters?.state;
      let businessUnit = topFilters?.warehouseNumber
        ? topFilters?.warehouseNumber[0]
        : "";
      let startTimeStart = topFilters?.startDate ? topFilters?.startDate : "";
      let startTimeEnd = topFilters?.endDate ? topFilters?.endDate : "";

      if (filters === "COURIER" || filters === "TRUCK") {
        searchValues = {
          tripType: filters ? filters : tripType ?? "",
          SubStatus: "in_transit",
          Warehouse: businessUnit ? businessUnit : "",
          transporter: transporter ? transporter : "",
          billedToCity: billedToCity ? billedToCity : "",
          state: state ? state : "",
          startTimeStart,
          startTimeEnd,
        };
        this.maintainFilterValueInTextBox(searchValues);
      } else if (filters === "tracking" || filters === "non_tracking") {
        searchValues = {
          tracking: filters === "tracking" ? true : false,
          SubStatus: "in_transit",
          tripType: tripType ? tripType : "TRUCK",
          Warehouse: businessUnit ? businessUnit : "",
          transporter: transporter ? transporter : "",
          billedToCity: billedToCity ? billedToCity : "",
          state: state ? state : "",
          startTimeStart,
          startTimeEnd,
        };
        this.maintainFilterValueInTextBox(searchValues);
      } else if (filters === "tripsWithOutEwayBillNumber") {
        if (tripType) {
          searchValues = {
            "Is Ewaybill Not Exist":
              filters === "tripsWithOutEwayBillNumber" ? "true" : "false",
            tripType: tripType ? tripType : "",
            Warehouse: businessUnit ? businessUnit : "",
            startTimeStart,
            startTimeEnd,
          };
          this.maintainFilterValueInTextBox(searchValues);
        } else {
          searchValues = {
            "Is Ewaybill Not Exist":
              filters === "tripsWithOutEwayBillNumber" ? "true" : "false",
            Warehouse: businessUnit ? businessUnit : "",
            startTimeStart,
            startTimeEnd,
          };
          this.maintainFilterValueInTextBox(searchValues);
        }
      } else {
        searchValues = {
          SubStatus: filters ? filters : "",
          Warehouse: businessUnit ? businessUnit : "",
          transporter: transporter ? transporter : "",
          billedToCity: billedToCity ? billedToCity : "",
          state: state ? state : "",
          startTimeStart,
          startTimeEnd,
        };
        // Add 'tripType' key only if 'topFilters.tripType' is non-empty
        if (tripType) {
          searchValues.tripType = tripType;
        }
        this.maintainFilterValueInTextBox(searchValues);
        // searchValues["SubStatus"] = filters ? filters : "";
      }
      this.setState({ searchFilters: searchValues }, () => {
        offset = 0;
        window.localStorage.setItem(
          "tripsFilterData",
          JSON.stringify(searchValues)
        );

        window.localStorage.setItem("tripOffset", offset);
        window.history.replaceState(null, "");

        this.getAllData();
      });
    } else {
      offset = 0;
      this.getAllData();
    }

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }
  getAllData = () => {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let role = authUser?.data ? authUser?.data?.role?.label : "";
    let supervisorCode;
    let passSupervisor;
    if (role === "supervisor") {
      supervisorCode =
        authUser?.data?.cfaCode &&
        authUser?.data?.cfaCode?.map((ele) => {
          return ele;
        });
      passSupervisor = supervisorCode ? supervisorCode.join(",") : "";
    }
    this.setState({ dataLoading: true }, () => {});
    let storedData = localStorage.getItem("tripsFilterData")
      ? JSON.parse(localStorage.getItem("tripsFilterData"))
      : "";
    let offsetData = localStorage.getItem("tripOffset")
      ? localStorage.getItem("tripOffset")
      : "";
    let searchFilters = this.state.searchFilters;
    this.props.getTripList(
      {
        offset: offset || offsetData,
        limit: 10,
        cfaCode: passSupervisor,
      },
      Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );

    if (searchBar)
      searchBar.searchBarData.forEach((obj) => {
        if (obj.field === "SubStatus") {
          obj.options = tripSubStatusMaster;
        } else if (obj.field === "tripType") {
          obj.options = tripTypeEnum;
        } else if (obj.field === "Is Ewaybill Not Exist") {
          obj.options = tripsWithOutEwayBillNumber;
        }
      });
  };
  // componentWillUnmount = () => {
  //   offset = 0;
  // };
  getAllDataReset = () => {
    offset = 0;
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    window.localStorage.setItem("tripOffset", offset);
    this.setState({ loading: true }, () => {
      this.getAllData();
    });
  };
  handleChange = (dateValues) => {
    searchValues["startTimeStart"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).toISOString()
          : moment(dateValues).toISOString()
        : null;
    searchValues["startTimeEnd"] =
      dateValues[1] !== null ? moment(dateValues[1])?.toISOString() : null;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      window.localStorage.setItem(
        "tripsFilterData",
        JSON.stringify(this.state.searchFilters)
      );
      window.localStorage.setItem("tripOffset", offset);
      this.getAllData();
    });
  };
  handleDispatchChange = (dateValues) => {
    searchValues["dispatchStartDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).toISOString()
          : moment(dateValues).toISOString()
        : null;
    searchValues["dispatchEndDate"] =
      dateValues[1] !== null ? moment(dateValues[1])?.toISOString() : null;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      window.localStorage.setItem(
        "tripsFilterData",
        JSON.stringify(this.state.searchFilters)
      );
      window.localStorage.setItem("tripOffset", offset);
      this.getAllData();
    });
  };
  //complte trip
  fileUpload = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
      },
      () => {
        this.S3Upload();
      }
    );
  };
  S3Upload = async () => {
    const fileType = this.state.selectedFile?.type?.substring(
      this.state.selectedFile?.type?.lastIndexOf("/") + 1
    );
    // console.log(fileType, this.state.selectedFile?.type, "fileType");
    this.props.tripCompletedFileUrl(fileType, (res) => {
      // console.log("res", res.presignedS3Url);
      let softCopy = "";
      softCopy = res.presignedS3Url.split("?")[0];
      // console.log("SoftCopy", softCopy);
      this.setState(
        {
          attachUrl: softCopy,
        },
        () => {
          // console.log("images", this.state.images);
          const formData = new FormData();
          formData.append(
            this.state.selectedFile,
            this.state.selectedFile.name
          );
          // console.log("--------", formData);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", this.state.selectedFile.type);
          this.props.uploadImagesAws(
            res?.presignedS3Url,
            this.state.selectedFile,
            myHeaders
          );
        }
      );
    });
  };
  onChangeInput = (name, val) => {
    this.setState({
      [name]: val,
    });
  };
  CompleteTripChangeStatus = (ele) => {
    this.setState({ currentTripData: ele });
    this.resetCompleteTrip();
    this.completeViewToggle(ele);
  };
  completeViewToggle = (ele) => {
    this.setState({
      isCompleteTripOpen: !this.state.isCompleteTripOpen,
      currentTripData: ele,
    });
  };
  resetCompleteTrip = () => {
    this.setState({ tripCompletedDate: "", remark: "", selectedFile: "" });
  };
  handleCompleteTripSubmit = () => {
    const { currentTripData, remark, tripCompletedDate, attachUrl } =
      this.state;
    // console.log(new Date(tripCompletedDate).toISOString(), "op");
    let completeTripPayload = {
      tripId: currentTripData ? currentTripData?._id : "",
      remark: remark ? remark : "",
      tripCompletedDate: tripCompletedDate
        ? new Date(tripCompletedDate).toISOString()
        : "",
      url: attachUrl ? attachUrl : "",
    };
    this.props.completeTrip(completeTripPayload, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
        this.completeViewToggle();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  //complte trip
  searchValueAssignerFunction = (name, value, type) => {
    if (typeof value === "text" || type === "text") {
      offset = 0;
      window.localStorage.setItem("tripOffset", offset);

      searchValues[name] = value?.toUpperCase();
      this.setState({ searchFilters: searchValues }, () => {
        window.localStorage.setItem(
          "tripsFilterData",
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem("tripOffset", offset);
      });
    }
    if (type === "date" || type === "select") {
      offset = 0;
      window.localStorage.setItem("tripOffset", offset);
      if (name === "tripType" && value === "All") {
        delete searchValues.tripType;
        this.setState({ searchFilters: searchValues }, () => {
          window.localStorage.setItem(
            "tripsFilterData",
            JSON.stringify(this.state.searchFilters)
          );
          window.localStorage.setItem("tripOffset", offset);
          this.getAllData();
        });
      } else {
        searchValues[name] = value;
        this.setState({ searchFilters: searchValues }, () => {
          window.localStorage.setItem(
            "tripsFilterData",
            JSON.stringify(this.state.searchFilters)
          );
          window.localStorage.setItem("tripOffset", offset);
          this.getAllData();
        });
      }
    }
  };
  reset = (topFilter) => {
    const { startTimeStart, startTimeEnd, dispatchStartDate, dispatchEndDate } =
      searchValues;

    offset = 0;
    if (topFilter) {
      searchValues = {
        ...searchValues,
        startTimeStart: "",
        startTimeEnd: "",
        dispatchStartDate: "",
        dispatchEndDate: "",
        // Add any other specific keys you want to reset here
      };
      window.localStorage.setItem("tripOffset", offset);
    } else {
      searchValues = {
        startTimeStart: startTimeStart || "",
        startTimeEnd: startTimeEnd || "",
        dispatchStartDate: dispatchStartDate || "",
        dispatchEndDate: dispatchEndDate || "",
        // Add any other specific keys you want to reset here
      };
      window.localStorage.setItem("tripOffset", offset);
    }

    this.setState({ searchFilters: searchValues }, () => {
      localStorage.setItem("tripsFilterData", JSON.stringify(searchValues));
      this.getAllData();
      window.history.replaceState(null, "");
      // localStorage.removeItem("tripsFilterData");
      // localStorage.removeItem("tripOffset");
    });
  };

  routingTOTripDetails = (tripDetails) => {
    this.props.history.push({
      pathname: "/tripHistory",
      state: { tripDetails: tripDetails },
    });
  };
  routingToViewTripDetails = (tripDetails) => {
    this.props.history.push({
      pathname: "/viewTripDetails",
      state: { viewDetails: tripDetails },
    });
  };

  markerAsArrivedChangeStatus = (ele) => {
    this.props.changeMarkedAsArrivedStatus(ele?._id, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  //tracking here
  CreateTripChangeStatus = (ele) => {
    this.props.history.push({ pathname: "/trackOrders", state: ele?._id });
  };
  qualityDetailButton = (ele) => {
    this.props.history.push({
      pathname: "/qualityCheck",
      state: { tripDetail: ele },
    });
  };
  startLoading = (ele) => {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let userId = authUser ? authUser?.data?._id : "";
    // console.log(ele,"payload")
    let payload = {
      tripId: ele ? ele : "",
      newStatus: "loading_in_progress",
      userID: userId,
    };
    // console.log(payload,"payload")
    this.props.startLoadingStatus(payload, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  completeLoading = (ele) => {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let userId = authUser ? authUser?.data?._id : "";
    // console.log(userId,"payload")
    let payload = {
      tripId: ele ? ele?._id : "",
      newStatus: "loading_completed",
      userID: userId,
    };
    // console.log(payload,"payload")
    this.props.loadingCompleted(payload, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  ellipse = (transporter) => {
    return (
      <div className="tooltipIcon">
        <span
          className="d-inline-block text-truncate"
          style={{ maxWidth: "90px" }}
        >
          {transporter}
        </span>
        <span
          className="tooltipIcontext"
          // style={{ width: "85px", marginLeft: "-14px" }}
        >
          &nbsp;{transporter}&nbsp;
        </span>
      </div>
    );
  };
  exportToData = () => {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let role = authUser?.data ? authUser?.data?.role?.label : "";
    let supervisorCode;
    let passSupervisor;
    if (role === "supervisor") {
      supervisorCode =
        authUser?.data?.cfaCode &&
        authUser?.data?.cfaCode?.map((ele) => {
          return ele;
        });
      passSupervisor = supervisorCode ? supervisorCode.join(",") : "";
    }
    this.setState({ dataLoading: true }, () => {});
    let storedData = localStorage.getItem("tripsFilterData")
      ? JSON.parse(localStorage.getItem("tripsFilterData"))
      : "";
    let searchFilters = this.state.searchFilters;
    this.props.getTripListForExcel(
      {
        offset: 0,
        limit: 1000000,
        cfaCode: passSupervisor,
      },
      Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
      (res) => {
        this.setState({ dataLoading: false });

        let exportData = [];
        if (res?.success) {
          if (res?.data?.docs?.length > 0) {
            res.data.docs.map((ele) => {
              let billedToCity = ele.destination?.shipToCity;
              let billedToName = ele.destination?.shipToName;
              let businessUnit = ele?.order?.businessUnit;
              let orderType = ele?.order?.orderType;
              let transporterName = ele?.transporter;
              let orderNumberArry = [];
              let eWayBillNoArry = [];
              if (Array.isArray(ele?.destination)) {
                billedToCity = ele.destination?.[0]?.shipToCity;
                billedToName = ele.destination?.[0]?.shipToName;
              }
              if (Array.isArray(ele?.order)) {
                orderType = ele?.order?.[0]?.orderType;
                businessUnit = ele?.order?.[0]?.businessUnit;
              }
              if (Array.isArray(ele?.transporter))
                transporterName = ele?.transporter?.[0];
              if (ele?.legalInvoiceNo?.length > this.state.invoiceLength)
                this.setState({ invoiceLength: ele?.legalInvoiceNo });
              if (Array.isArray(ele?.order)) {
                ele.order.map((e) => {
                  orderNumberArry.push(e?.orderNumber);
                });
              } else {
                orderNumberArry.push(ele?.order?.orderNumber);
              }
              if (Array.isArray(ele?.eWayBillNo)) {
                ele.eWayBillNo.map((e) => {
                  eWayBillNoArry.push(e);
                });
              } else {
                eWayBillNoArry.push(ele.eWayBillNo);
              }
              exportData.push({
                "Trip Id": ele?.tripId ? ele?.tripId : "N/A",
                "Order Number":
                  orderNumberArry?.length > 0
                    ? orderNumberArry.join(",")
                    : "N/A",
                Warehouse: businessUnit ? businessUnit : "N/A",
                "Invoice No":
                  ele?.legalInvoiceNo?.length > 0
                    ? ele?.legalInvoiceNo.join(",")
                    : "N/A",
                Transporter: transporterName ? transporterName : "N/A",

                "Order Type": ele?.order?.length
                  ? ele?.order[0]?.orderType
                  : ele?.order?.orderType
                  ? ele?.order?.orderType
                  : "N/A",
                "Billed To Name": billedToName ? billedToName : "N/A",
                "Billed To City": billedToCity ? billedToCity : "N/A",
                "Vehicle No": ele?.vehicle?.regNo ? ele?.vehicle?.regNo : "N/A",
                Status: ele?.subStatus ? ele?.subStatus : "N/A",
                "EWay Bill No": eWayBillNoArry?.length
                  ? eWayBillNoArry.join(",")
                  : "N/A",
                "Dispatch Date":
                  ele?.eWayBillDt && ele?.eWayBillDt?.length > 0
                    ? ele?.eWayBillDt && ele?.eWayBillDt[0]
                      ? this.convertDate(ele?.eWayBillDt[0])
                      : "N/A"
                    : "N/A",
              });
            });
          }
          if (res?.data?.docs?.length > 0) {
            exportToCSV([...exportData], "Trips");
          } else {
            exportData.push({
              "Trip Id": "",
              "Order Number": "",
              "Invoice No": "",
              Transporter: "",
              "Order Type": "",
              "Billed To Name": "",
              "Billed To City": "",
              "Vehicle No": "",
              status: "",
              "eWay Bill No": "",
              "Dispatch Date": "",
            });
            exportToCSV([...exportData], "Orders");
          }
        } else {
          NotificationManager.error(res?.message, "error");
        }
      }
    );
  };

  convertDate = (isoDate) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateObject = new Date(isoDate);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();

    // const today = new Date(e);
    // const month = today.getMonth() + 1;
    // const year = today.getFullYear();
    // const date = today.getDate() - 1;
    return `${day}-${month}-${year}`;
  };

  render() {
    // let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { getTripData } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("TripBaxData"));
    const tripOffset = localStorage.getItem("tripOffset")
      ? JSON.parse(localStorage.getItem("tripOffset"))
      : "";
    const tripData = navigator.onLine
      ? getTripData?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(tripData)) {
      // console.log("tripData : ", tripData);
      offset = tripOffset ? tripOffset : offset;
      totCnt = navigator.onLine
        ? getTripData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? getTripData?.data?.offset + getTripData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      tripData.forEach((ele) => {
        let billedToCity = ele.destination?.shipToCity;
        let billedToName = ele.destination?.shipToName;
        let businessUnit = ele?.order?.businessUnit;
        let orderType = ele?.order?.orderType;
        let transporterName = ele?.transporter;
        if (Array.isArray(ele?.destination)) {
          billedToCity = ele.destination?.[0]?.shipToCity;
          billedToName = ele.destination?.[0]?.shipToName;
        }
        if (Array.isArray(ele?.order)) {
          orderType = ele?.order?.[0]?.orderType;
          businessUnit = ele?.order?.[0]?.businessUnit;
        }
        if (Array.isArray(ele?.transporter))
          transporterName = ele?.transporter?.[0];
        if (ele?.legalInvoiceNo?.length > this.state.invoiceLength)
          this.setState({ invoiceLength: ele?.legalInvoiceNo });
        rows.push({
          tripId: ele?.tripId ? ele?.tripId : "N/A",
          orderType: ele?.order?.length ? (
            <>
              <div style={{ maxWidth: "58px" }} className="text-truncate">
                <div className="tooltipIcon">
                  <span className="text-truncate" style={{ maxWidth: "58px" }}>
                    {orderType}
                  </span>
                  <span className="tooltipIcontext">{orderType}</span>
                </div>
              </div>
            </>
          ) : ele?.order?.orderType ? (
            ele?.order?.orderType
          ) : (
            "N/A"
          ),
          // orderCo: ele?.order?.orderCo ? ele?.order?.orderCo : "N/A",
          orderNumber: ele?.order?.length ? (
            <SingleValueOrArraysValue ele={ele?.order} name="Order Number" />
          ) : ele?.order?.orderNumber ? (
            ele?.order?.orderNumber
          ) : (
            "N/A"
          ),
          transporter: transporterName ? (
            <div className="tooltipIcon">
              <div className="text-truncate" style={{ maxWidth: "90px" }}>
                <span>{transporterName}</span>
              </div>
              <span className="tooltipIcontext">{transporterName}</span>
            </div>
          ) : (
            "N/A"
          ),
          legalInvoiceNo: ele?.legalInvoiceNo?.length ? (
            <>
              <SingleValueOrArraysValue
                ele={ele?.legalInvoiceNo}
                name="legal invoices"
              />
            </>
          ) : (
            "N/A"
          ),
          vehicleNo: ele?.vehicle?.regNo ? ele?.vehicle?.regNo : "N/A",
          driverNo: ele?.driver?.name ? (
            <div className="tooltipIcon">
              <span className="text-truncate" style={{ maxWidth: "58px" }}>
                {ele?.driver?.name.toLowerCase()}
              </span>
              <span className="tooltipIcontext">
                {ele?.driver?.name.toLowerCase()}
              </span>
            </div>
          ) : (
            "N/A"
          ),
          billedToName: billedToName ? (
            <div className="tooltipIcon">
              <div style={{ maxWidth: "58px" }} className="text-truncate">
                <span>{billedToName?.toLowerCase()}</span>
              </div>
              <span className="tooltipIcontext">
                &nbsp;{billedToName?.toLowerCase()}&nbsp;
              </span>
            </div>
          ) : (
            "N/A"
          ),
          billedToCity: billedToCity ? (
            <div className="tooltipIcon">
              <div style={{ maxWidth: "90px" }} className="text-truncate">
                <span>{billedToCity?.toLowerCase()}</span>
              </div>
              <span className="tooltipIcontext">
                &nbsp;{billedToCity?.toLowerCase()}&nbsp;
              </span>
            </div>
          ) : (
            "N/A"
          ),
          status: ele?.status ? (
            <StatusIndicator status={ele?.status} />
          ) : (
            "N/A"
          ),
          substatus: ele?.subStatus ? (
            <SubStatusIndicator subStatus={ele?.subStatus} />
          ) : (
            "N/A"
          ),
          eWayBillNo: ele?.eWayBillNo?.length ? (
            <>
              <SingleValueOrArraysValue
                ele={ele?.eWayBillNo}
                name="EWaybill"
                maxWidth="90px"
              />
            </>
          ) : (
            "N/A"
          ),
          dispatchDate:
            ele?.eWayBillDt && ele?.eWayBillDt?.length > 0
              ? ele?.eWayBillDt && ele?.eWayBillDt[0]
                ? this.convertDate(ele?.eWayBillDt[0].substring(0, 10))
                : "N/A"
              : "N/A",
          businessUnit: businessUnit ? businessUnit : "N/A",
          actions: actions(ele, {
            markerAsArrivedChangeStatus: this.markerAsArrivedChangeStatus,
            qualityDetailButton: this.qualityDetailButton,
            CreateTripChangeStatus: this.CreateTripChangeStatus,
            startLoading: this.startLoading,
            completeLoading: this.completeLoading,
            routingTOTripDetails: this.routingTOTripDetails,
            CompleteTripChangeStatus: this.CompleteTripChangeStatus,
            routingToViewTripDetails: this.routingToViewTripDetails,
          }),
        });
      });
    }

    return (
      <>
        {getLocalStorage()}
        <Container
          aria-hidden="true"
          fluid
          className={`${
            darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
          }`}
        >
          <QuickFilters
            reset={this.reset}
            handleChange={this.handleChange}
            handleDispatchChange={this.handleDispatchChange}
            dashTopFilter={this.props.location?.state?.topFilters}
            toggleExcel={this.exportToData}
          />
          <div className="tripList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={true}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"80px"}
              dataLoading={this.state.dataLoading}
              classList="3"
              textInput="inputIndex"
              resetCol="6"
            />
          </div>

          {totCnt !== 0 && !this.state.dataLoading && rows?.length > 0 ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 mt-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
              </Col>
            </Row>
          ) : null}
        </Container>
        <CompleteTipModel
          isOpen={this.state.isCompleteTripOpen}
          toggleModel={this.completeViewToggle}
          tripData={this.state.currentTripData}
          onChangeInput={this.onChangeInput}
          tripCompletedDate={this.state.tripCompletedDate}
          remark={this.state.remark}
          fileUpload={this.fileUpload}
          selectedFile={this.state.selectedFile}
          resetCompleteTrip={this.resetCompleteTrip}
          handleSubmit={this.handleCompleteTripSubmit}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    getTripData: state?.getBaxTripListReducer?.getBaxTripListData,
    fetchId: state.getBaxTripListReducer?.fetchId,
  };
};

export default connect(mapStateToProps, {
  getTripList,
  startLoadingStatus,
  loadingCompleted,
  changeMarkedAsArrivedStatus,
  completeTrip,
  tripCompletedFileUrl,
  uploadImagesAws,
  getTripListForExcel,
})(Trip);
