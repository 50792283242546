import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { types } from "./actionTypes";
import {
    getApi,
    postApi,
    // postUploadApi,
    putUploadApi,
    patchApi,
    // putApi,
  } from "../../helpers/auth_helper";







  function* workFetchSecondaryAgreements({ values, cb, duration }) {
   
    let { offset, q } = values;
    try {
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/agreement/searchSf?duration=${duration || ""}&offset=${offset || 0}&q=${
          q || ""
        }`;
      const response = yield call(getApi, uri);
   
      
      //   Dispatch the action to the reducers
      yield put({
        type: types.FETCH_SECONDARY_AGREEMENTS_SUCCESS,
        payload: response.data,
      });
      if (cb) cb(response);
      localStorage.setItem("agreementData", JSON.stringify(response));
    } catch (error) {
      console.log("Fetch Agreements Failed.", error);
    }
  }


  function* workfetchViewAgreement({ id }) {
    try {
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/agreement/viewAgreementByAgreementId?agreementId=` +
        id;
      const response = yield call(getApi, uri);
      yield put({
        type: types.VIEW_AGREEMENT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.log("Request failed! Could not fetch values.", error);
    }
  }


  function* workPostSecondaryAgreement({ values, callback }) {
    try {
      const uri = process.env.REACT_APP_SERVERURL + `/agreement/save_secondary_freight`;
      const response = yield call(postApi, uri, values);
      callback(response);
      yield put({
        type: types.SECONDARY_CREATE_AGREEMENT_SUCCESS,
        payload: response,
      });
     
    } catch (error) {
      // Act on the error
      console.log("Request failed! Could not create indent.", error);
    }
  }

  function* workfetchLastAgreement({ id }) {
    try {
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/agreement/fetchLastAgreementSf?transporterId=`+
        id;
      const response = yield call(getApi, uri);
      
      yield put({
        type: types.SECONDARY_FETCH_LAST_AGREEMENT_VALUES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.log("Request failed! Could not fetch values.", error);
    }
  }


  function* workFetchAllAgreements({ offset, limit, callback }) {
    try {
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/agreement/agreementSf/?offset=` +
        offset +
        `&limit=` + 
        limit;
      const response = yield call(getApi, uri);
      callback(response);
    } catch (error) {
      console.log("Failed to fetch.", error);
    }
  }


  function* workFetchSignedURL({ values, callback }) {
    // console.log("fileType", values);
    try {
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/upload/agreementSoftCopy/60d035d89392430f16acecfa/${values?.fileType}`;
      const response = yield call(getApi, uri);
      ////consolelog("response---------", response);
      callback(response);
      yield put({
        type: types.FETCH_SIGNED_URL_SUCCESS,
        payload: response,
      });
    } catch (error) {
      ////consolelog("Fetch signed URL failed.", error);
    }
  }

  function* workUploadAgreement({ uri, data, headers }) {
    try {
      // Try to call the API
      const response = yield call(putUploadApi, uri, data, headers);
      //   Dispatch the action to the reducers
      yield put({
        type: types.UPLOAD_AGREEMENT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Act on the error
      console.log(error);
    }
  }

  function* workUploadImagesAws({ uri, data, headers, callback }) {
    try {
      // Try to call the API
      const response = yield call(putUploadApi, uri, data, headers);
      //   Dispatch the action to the reducers
  
      yield put({
        type: types.UPLOAD_IMAGES_AWS_SUCCESS,
        payload: response,
      });
      callback(response);
    } catch (error) {
      // Act on the error
      console.log(error);
    }
  }

  
  function* workSecondaryFetchInvoices({ values, fromDate, endDate, state, callback }) {
 
    let {
      offset,
      limit,
      transporterId,
      indentId,
      invoiceId,
      gst,
      status,
      comment,
      lrNumber,
      isExport,
    } = values;
    
    try {
     
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/invoice/invoicesSf?offset=${offset || 0}&limit=${limit || 10}&startDate=${
          fromDate || ""
        }&endDate=${endDate || ""}&stateCode=${state || ""}&transporter=${
          transporterId || ""
        }&status=${status?.value || ""}&comment=${comment || ""}&gst=${
          gst || ""
        }&invoiceId=${invoiceId || ""}&indentId=${indentId || ""}&lrNo=${
          lrNumber || ""
        }&isExport=${isExport || ""}`;
      const response = yield call(getApi, uri);
  
      //   Dispatch the action to the reducers
      yield put({
        type: types.FETCH_SECONDARY_INVOICES_SUCCESS,
        payload: response.data,
      });
      callback(response);
      localStorage.setItem("invoiceData", JSON.stringify(response));
    } catch (error) {
     console.log("Fetching Invoices Failed.", error);
    }
  }


  function* workAcceptInvoice({ values, callback }) {
    try {
  
      const uri = process.env.REACT_APP_SERVERURL + `/invoice/statusChange`;
      const response = yield call(postApi, uri, values);
  
      callback(response);
  
      yield put({
        type: types.ACCEPT_SECONDARY_INVOICE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.log("Request failed! Could Not Accept Invoice.", error);
    }
  }


  function* workFetchSecondaryInvoicesExcel({
    values,
    fromDate,
    endDate,
    state,
    callback,
  }) {
    
    let {
      offset,
      limit,
      transporterId,
      indentId,
      invoiceId,
      gst,
      status,
      comment,
      lrNumber,
      isExport,
    } = values;
    
    try {
      
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/invoice/invoicesSf?offset=${offset || 0}&limit=${100000}&startDate=${
          fromDate || ""
        }&endDate=${endDate || ""}&stateCode=${state || ""}&transporter=${
          transporterId || ""
        }&status=${status || ""}&comment=${comment || ""}&gst=${
          gst || ""
        }&invoiceId=${invoiceId || ""}&indentId=${indentId || ""}&lrNo=${
          lrNumber || ""
        }&isExport=${isExport || ""}`;
      const response = yield call(getApi, uri);
  
      //   Dispatch the action to the reducers
      yield put({
        type: types.FETCH_SECONDARY_INVOICES_EXCEL_SUCCESS,
        payload: response.data,
      });
      callback(response);
    
    } catch (error) {
    
      console.log("Fetch secondary invoice excel Failed.", error);
    }
  }
  

  function* workApproveSecondaryInvoice({ invoiceID, callback }) {
    try {
      // Try to call the API
      const uri = process.env.REACT_APP_SERVERURL + `/invoice/statusChange`;
      const response = yield call(postApi, uri, {
        invoiceId: invoiceID,
        status: "approved",
      });

      callback(response);
      //   Dispatch the action to the reducers
      yield put({
        type: types.APPROVE_SECONDARY_INVOICE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.log("Request failed! Could Not Approve secondary Invoice.", error);
    }
  }


  function* workRejectSecondaryInvoice({ values, callback }) {
    try {
      // Try to call the API
      const uri = process.env.REACT_APP_SERVERURL + `/invoice/statusChange`;
      const response = yield call(postApi, uri, values);
      callback(response);
      //   Dispatch the action to the reducers
      yield put({
        type: types.REJECT_SECONDARY_INVOICE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Act on the error
      console.log("Request failed! Could not Reject Invoice.", error);
    }
  }



  function* workFetchInvoiceByid({ invId, callback }) {
    try {
  
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/invoice/viewInvoice?invoiceId=` +
        invId;
  
      const response = yield call(getApi, uri);
      //   Dispatch the action to the reducers
      yield put({
        type: types.FETCH_SECONDARY_INVOICE_BYID_SUCCESS,
        payload: response,
      });
      callback(response);
    } catch (error) {
      // Act on the error
      console.log("viewing invoice function Failed.", error);
    }
  }


  function* workSecondryInvoiceBillsDelete({ payload, callback }) {
    try {
      const uri =
        process.env.REACT_APP_SERVERURL + `/invoice/addOrDeleteBillInInvoiceSf`;
      const response = yield call(patchApi, uri, payload);
      callback(response);
      yield put({
        type: types.INVOICE_BILL_DELETE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.log("Request failed! Could not delete invoice.", error);
    }
  }



  
  function* workUpdateInvoice({ values, callback }) {
    try {
      // Try to call the API
      const uri = process.env.REACT_APP_SERVERURL + `/invoice/updateInvoice`;
  
      const response = yield call(postApi, uri, values);
  
      callback(response);
      //   Dispatch the action to the reducers
      yield put({
        type: types.UPDATE_SECONDARY_INVOICE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Act on the error
      // console.log("Update invoice Failed.", error);
    }
  }

  
  function* workFetchBills({ values, startDate, endDate, stateCode, callback }) {
    // console.log("values",values)
    let { offset, limit } = values;
    let transporterId = values?.transporterId ? values?.transporterId : "";
    let type = values?.type ? values?.type : "";
    let lrNumber = values?.lrNumber ? values?.lrNumber : "";
    let vehicleNumber = values?.vehicleNumber ? values?.vehicleNumber : "";
    let indentId = values?.indentId ? values?.indentId : "";
    let tripId = values?.tripId ? values?.tripId : "";
    let source = values?.source ? values?.source : "";
    let destination = values?.destination ? values?.destination : "";
    let vehicleType = values?.vehicleType ? values?.vehicleType : "";
    let customers = values?.customers ? values?.customers : "";
    let objectIds = values?.objectIds || []; 
    let objectIdsQuery = objectIds.length > 0 ? `&objectIds=${objectIds.join(",")}` : ""; 
    try {
      // Try to call the API
      const uri =
        process.env.REACT_APP_SERVERURL +
        `/billing/bills_Sf?offset=${offset || 0}&limit=${limit || ""}&startDate=` +
        startDate +
        `&endDate=` +
        endDate +
        `&stateCode=` +
        stateCode +
        "&type=" +
        type +
        "&transporter=" +
        transporterId +
        "&vehicleNumber=" +
        vehicleNumber +
        "&indentId=" +
        indentId +
        "&lrNumber=" +
        lrNumber +
        "&source=" +
        source +
        //removed destination and updated with [] of customers id's 
        // "&destination=" +
        // destination +
        "&vehicleType=" +
        vehicleType +
        "&customers=" +
        customers +
        "&tripId=" +
        tripId +
        objectIdsQuery; 
      const response = yield call(getApi, uri);
      callback(response);
      //   Dispatch the action to the reducers
      yield put({
        type: types.FETCH_SECONDARY_BILLS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log(" Error fetching bills", error);
    }
  }
  

  function* workUpdateBill({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/billing/updateBill`;

    const response = yield call(postApi, uri, values);

    callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.UPDATE_SECONDARY_BILL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("update bill function Failed.", error);
  }
  }




function* workSubmitToRaisedInvoice({ payload, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/invoice/submitInvoiceSf`;
    const response = yield call(postApi, uri, payload);
    callback(response);
    yield put({
      type: types.SUBMIT_TO_RAISE_SECONDARY_INVOICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log(" Request failed! Could not fetch values.", error);
  }
}




function* workCreateInvoiceBills({ payload, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/invoice/createInvoiceDraftSf`;
    const response = yield call(postApi, uri, payload);
    callback(response);    
    yield put({
      type: types.CREATE_SECONDARY_INVOICE_BILLS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log(" Request failed! Could not fetch values.", error);
  }
}





  export function* watchFetchSecondaryAgreements() {
    yield takeEvery(types.FETCH_SECONDARY_AGREEMENTS, workFetchSecondaryAgreements);
  }

  export function* watchFetchViewAgreement() {
    yield takeEvery(types.VIEW_AGREEMENT, workfetchViewAgreement);
  }

  export function* watchPostSecondaryAgreement() {
    yield takeEvery(types.SECONDARY_CREATE_AGREEMENT, workPostSecondaryAgreement);
  }

  export function* watchFetchLastAgreement() {
    yield takeEvery(types.SECONDARY_FETCH_LAST_AGREEMENT_VALUES, workfetchLastAgreement);
  }


  export function* watchFetchAllAgreements() {
    yield takeEvery(types.SECONDARY_COPY_AGREEMENT_LIST, workFetchAllAgreements);
  }

  export function* watchFetchSignedURL() {
    yield takeEvery(types.FETCH_SIGNED_URL, workFetchSignedURL);
  }

  export function* watchUploadAgreement() {
    yield takeEvery(types.UPLOAD_AGREEMENT, workUploadAgreement);
  }

  export function* watchUploadImagesAws() {
    yield takeEvery(types.UPLOAD_IMAGES_AWS, workUploadImagesAws);
  }


  export function* watchSecondaryFetchInvoices() {
    yield takeEvery(types.FETCH_SECONDARY_INVOICES, workSecondaryFetchInvoices);
  }

  export function* watchAcceptInvoice() {
    yield takeEvery(types.ACCEPT_SECONDARY_INVOICE, workAcceptInvoice);
  }


  export function* watchFetchInvoicesExcel() {
    yield takeEvery(types.FETCH_SECONDARY_INVOICES_EXCEL, workFetchSecondaryInvoicesExcel);
  }


  export function* watchApproveInvoice() {
    yield takeEvery(types.APPROVE_SECONDARY_INVOICE, workApproveSecondaryInvoice);
  }


  export function* watchRejectSecondaryInvoice() {
    yield takeEvery(types.REJECT_SECONDARY_INVOICE, workRejectSecondaryInvoice);
  }


  export function* watchFetchInvoiceByid() {
    yield takeEvery(types.FETCH_SECONDARY_INVOICE_BYID, workFetchInvoiceByid);
  }

  export function* watchInvoiceDeleteBills() {
    yield takeEvery(types.SECONDARY_INVOICE_BILL_DELETE, workSecondryInvoiceBillsDelete);
  }


export function* watchUpdateInvoice() {
  yield takeEvery(types.UPDATE_SECONDARY_INVOICE, workUpdateInvoice);
}


export function* watchFetchBills() {
  yield takeEvery(types.FETCH_SECONDARY_BILLS, workFetchBills);
}



export function* watchUpdateBill() {
  yield takeEvery(types.UPDATE_SECONDARY_BILL, workUpdateBill);
}


export function* watchSubmitToRaisedInvoice() {
  yield takeEvery(types.SUBMIT_TO_RAISE_SECONDARY_INVOICE, workSubmitToRaisedInvoice);
}


export function* watchCreateInvoiceForBills() {
  yield takeEvery(types.CREATE_SECONDARY_INVOICE_BILLS, workCreateInvoiceBills);
}


function* SecondaryBillingSaga(){
    yield all([
        fork(watchFetchSignedURL),
        fork(watchFetchAllAgreements),
        fork(watchFetchLastAgreement),
        fork(watchPostSecondaryAgreement),
        fork(watchFetchViewAgreement),
        fork(watchFetchSecondaryAgreements),
        fork(watchUploadAgreement),
        fork(watchUploadImagesAws),
        fork(watchSecondaryFetchInvoices),
        fork(watchFetchInvoicesExcel),
        fork(watchApproveInvoice),
        fork(watchRejectSecondaryInvoice),
        fork(watchFetchInvoiceByid),
        fork(watchInvoiceDeleteBills),
        fork(watchUpdateInvoice),
        fork(watchFetchBills),
        fork(watchUpdateBill),
        fork(watchSubmitToRaisedInvoice),
        fork(watchCreateInvoiceForBills)

    ])
}

export default SecondaryBillingSaga;