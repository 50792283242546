import React, { Component } from "react";
import { stateList } from "../../../../components/Common/StateList";


//todo create secondary api' for this

import {
  clearAllBills,
} from '../../../../store/billing/actions'


import { createInvoiceForBills} from '../../../../store/secondaryBilling/actions'


import { fetchBills } from "../../../../store/secondaryBilling/actions";


import Index from "../../../../components/Common/DataTableNeo/Index";
import actionVerticleDots from "./assets/more-vertical.png";
import actionVerticleDotsDark from "./assets/more-vertical-dark.png";
import { connect } from "react-redux";
import { restrict } from "../../../restrict";
import { PERMS } from "../../../../enum/perms.enum";
import Pagination from "@material-ui/lab/Pagination";
import RaiseInvoceModal from ".././RaiseInvoiceModal";
import Checkbox from "@mui/material/Checkbox";
import InvoiceModal from '../InvoiceModal'
import "./invoiceListTheme.scss";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { MoonLoader } from "react-spinners";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  searchBar,
  getLocalStorage,
  AdminHeaderData
} from "./RowHeaders/RowHeaders.js";
import QuickFilters from "./quckFilters";
import DataTable from '../../../Utility/dataTable'
import { Button, ButtonGroup, Row, Col, Input } from "reactstrap";
import { fetchHops } from "../../../../store/hop/actions";
import { fetchVehicles } from "../../../../store/vehicle/actions";
import { fetchAllTransporterSearch } from "../../../../store/transporter/actions";
import AddInvoiceBillsToDraft from "./AddInvoiceBillsToDraft";
import {
  fetchCustomerOptionsSecondary
} from "../../../../store/secondaryIndentPlanning/actions";
import { ThrottlingUtils } from "@azure/msal-common";
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
let selectedData = [];
let selectedDataHead;
var defaultCheckedBills = [];
let totalAmount = 0;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;




//     columns: [
//       {
//         label: " ",
//         field: "select",
//         sort: "disabled",
//         width: 200,
//       },
//       {
//         label: userLang?.invoice?.BILL_TYPE,
//         field: "bill",
//         sort: "disabled",
//         width: 200,
//       },
//       {
//         label: userLang?.planning?.INDENT_ID,
//         field: "indentId",
//         sort: "disabled",
//         width: 200,
//       },
//       {
//         label: userLang?.trip?.TRIP_ID,
//         field: "tripId",
//         sort: "disabled",
//         width: 200,
//       },
//       {
//         label: userLang?.invoice?.LR_NO ?? " LR_NO",
//         field: "lrNo",
//         sort: "disabled",
//         width: 200,
//       },
//       {
//         label: userLang?.common?.SOURCE,
//         field: "source",
//         sort: "disabled",
//         width: 300,
//       },
//       {
//         label: "Customer",
//         field: "customer",
//         sort: "disabled",
//         width: 300,
//       },
//       {
//         label: userLang?.invoice?.DATE,
//         field: "date",
//         sort: "disabled",
//         width: 200,
//       },
//       {
//         label: userLang?.masters?.REG_NO,
//         field: "vehicleRegNo",
//         sort: "disabled",
//         width: 400,
//       },
//       {
//         label: userLang?.invoice?.TOTAL_COST,
//         field: "totalCost",
//         sort: "disabled",
//         width: 300,
//       },

//       {
//         label: userLang?.invoice?.ACTION,
//         field: "actions",
//         sort: "disabled",
//         width: 200,
//       },
//     ],
//   };



class SecondaryInvoiceGeneration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      stateFilter: "",
      stateValue: "",
      searchValue: "",
      searchOn: false,
      FromDate: "",
      ToDate: "",
      raiseinvoiceModal: false,
      isOpen: false,
      invoiceDet: {},
      dateFlag: false,
      allFlag: false,
      bills: [],
      documentArray: [],
      raiseInvoice: {
        gstNo: "",
        FromDate: "",
      },
      resetDate: true,
      errorMessage: "",
      billInfo: { bill: [], response: [] },
      raiseInvoiceDisable: true,
      billFreightCheck: [],
      defaultCheckedBills: [],
      selectedItemData: [],
      selectedItemTotalcost: 0,
      modalPreview: false,
      fetchId: "",
      billData: [],
      selectedHopValues: {
        selectedSource: "",
        selecteddestination: "",
      },
      hopValues: "",
      vehicleTypes: [],
      selectedVehicleType: "",
      vehicleTypeValue: "",
      transporterId: "",
      filterData: {
        type: "",
        indentId: "",
        tripId: "",
        vehicleNumber: "",
        lrNumber: "",
      },
      allTransporter: [],
      selectedTrans: "",
      addBillOpen: false,
      selectedCustomer: "",
      CustomerValue: "",
      allCustomers: [],

    };

    (async () => {
      let redirect = await restrict(PERMS.INDENT_TRANSPORTER_READ);

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount() {
    let authUserData = JSON.parse(window.localStorage.getItem("authUser"));
    let filter_data = JSON.parse(localStorage.getItem("invoiceGenerationFiliter"));
   
    if (filter_data !== null) {

      let values = {
        limit: filter_data?.limit,
        offset: filter_data?.offset,
        source: filter_data?.source?.value,
        destination: filter_data?.destination?.value,
        transporterId: filter_data?.transporterId,
        vehicleType: filter_data?.vehicleType?.value,
        type: filter_data?.type,
        indentId: filter_data?.indentId,
        tripId: filter_data?.tripId,
        vehicleNumber: filter_data?.vehicleNumber,
        lrNumber: filter_data?.lrNumber,
      };
      
      this.setState(
        {
          FromDate: filter_data["FromDate"],
          ToDate: filter_data["ToDate"],
          stateFilter: filter_data["state"],
          stateValue: filter_data["state"]?.value,
          filterData: {
            type: filter_data?.type,
            indentId: filter_data?.indentId,
            tripId: filter_data?.tripId,
            vehicleNumber: filter_data?.vehicleNumber,
            lrNumber: filter_data?.lrNumber,
          },
          selectedHopValues: {
            selectedSource: filter_data?.source,
            selecteddestination: filter_data?.destination,
          },
          selectedTrans: "",
          selectedVehicleType: filter_data?.vehicleType,
          vehicleTypeValue: filter_data?.vehicleType?.value,
        },
        () => {
        
        }
      );

      this.setState({ dataLoading: true });
     
      
      this.props.fetchBills(
        values,
        filter_data["FromDate"],
        filter_data["ToDate"],
        this.state.stateValue,
        (res) => {
          this.setState({
            dataLoading: false,
            defaultCheckedBills: [],
            billData: [],
          });
          defaultCheckedBills = [];
        }
      );
    }
    

    if (authUserData?.data?.role?.label == "transporter") {
      this.setState({
        transporterId: authUserData?.data?._id,
      });
      if (window.localStorage.getItem("invoiceGenerationFiliter") === null) {
        this.reset();
      }
    } else {
      this.getTAllranspoterData();
    }
    if (AdminHeaderData.columns.length > 0) {
      if (AdminHeaderData.columns[0].label === " ") {
        AdminHeaderData.columns[0].label = (
          <Checkbox
            id="table-check"
            disabled
            inputProps={{ "aria-label": "disabled checkbox" }}
          />
        );
      }
    }
   
    this.setState({ fetchId: this.props?.fetchId });
    this.getAllVehicleData();
    this.getAllLocationsData();
    this.fetchAllCustomersSearch();


  }


  handleAddBillOpen = () => {
    this.setState({
      addBillOpen: true,
    });
  };


  
  handleAddBillclose = () => {
    this.setState({
      addBillOpen: false,
    });
  };



  getAllDataInvoice = async () => {
    const { 
      selectedHopValues, 
      FromDate, 
      ToDate, 
      stateValue, 
      stateFilter, 
      transporterId, 
      vehicleTypeValue, 
      filterData, 
      CustomerValue 
    } = this.state;
  
    const values = {
      limit: 10,
      offset: 0,
      source: selectedHopValues?.selectedSource?.value,
      destination: selectedHopValues?.selecteddestination?.value,
      transporterId,
      vehicleType: vehicleTypeValue,
      ...filterData,
      customers: CustomerValue,
    };
  
    this.setState({ dataLoading: true });
  
    try {
      const res = await this.props.fetchBills(values, FromDate, ToDate, stateValue);
  
      this.setState({
        dataLoading: false,
        defaultCheckedBills: [],
        billData: [],
      });
  
    } catch (error) {
      // console.error("API Error:", error);
      this.setState({ dataLoading: false });
    }
  };
  


  resetGetAllDataInvoice = () => {
    let source = this.state.selectedHopValues?.selectedSource?.value;
    let destination = this.state.selectedHopValues?.selecteddestination?.value;
    let { FromDate, ToDate, stateFilter, transporterId } = this.state;
    let values = {
      limit: 10,
      offset,
      source,
      destination,
      transporterId,
      vehicleType: this.state.vehicleTypeValue,

      ...this.state.filterData,
    };
  
    this.setState({ dataLoading: true });

    this.props.fetchBills(values, FromDate, ToDate, "", () => {
      this.setState({
        dataLoading: false,
        defaultCheckedBills: [],
        billData: [],
      });
      defaultCheckedBills = [];
    });
  };


  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  handleStateChange = (e) => {
    // console.log(e, "eval");
    // let offset = 0;
    // let values = { offset: offset, limit: 10 };
    this.setState(
      {
        stateFilter: e,
        searchOn: !this.state.searchOn,
        stateFlag: true,
        stateValue: e.value,
        allFlag: true,
        // sourceLocation:this.state.hopValues
      },
      () => {
        this.setState({ allFlag: true });
      }
    );
  };

  handleChange = (dateValues) => {
    // console.log("date values", dateValues);
    this.setState(
      {
        FromDate:
          dateValues[0] !== ""
            ? moment(dateValues[0]).format("MM-DD-YYYY")
            : "",
        ToDate:
          dateValues[1] !== ""
            ? moment(dateValues[1]).format("MM-DD-YYYY")
            : "",
        dateFlag: true,
      },
      () => {
        if (
          this.state.stateFilter?.value &&
          this.state.FromDate &&
          this.state.ToDate
        )
          this.setState({ allFlag: true });
        // this.searchFilter();
      }
    );
  };


  searchFilter = () => {
    //vehicle type remaining
    let limit = 10;
    let type = this.state.filters?.type;
    let source = this.state.selectedHopValues?.selectedSource;
    let destination = this.state.selectedHopValues?.selecteddestination;
    let values = {
      limit: limit,
      offset: offset,
      type: type,
      source,
      destination,
      transporterId: this.state.transporterId,
      ...this.state.filterData,
      customers: this.state.CustomerValue,
    };
    console.log("values**-----",values)
    let { FromDate, ToDate, dateFlag, stateFlag, stateFilter, allFlag } =
      this.state;
    let stfilter_values = {
      ...values,
      source,
      destination,
      FromDate,
      ToDate,
      state: stateFilter,
      vehicleType: this.state.selectedVehicleType,
    };
    localStorage.setItem(
      "invoiceGenerationFiliter",
      JSON.stringify(stfilter_values)
    );
    if (FromDate && ToDate && dateFlag && !allFlag) {
      this.setState({ dateFlag: false, dataLoading: true }, () => {
       

        this.props.fetchBills(
          values,
          FromDate,
          ToDate,
          this.state.stateValue,
          () => {
            this.setState({ dataLoading: false });
          }
        );
      });
    } else if (allFlag) {
      this.setState(
        {
          dateFlag: false,
          stateFlag: false,
          allFlag: false,
          dataLoading: true,
        },
        () => {
          

          this.props.fetchBills(
            values,
            FromDate,
            ToDate,
            this.state.stateValue,
            () => {
              this.setState({ dataLoading: false });
            }
          );
        }
      );
    } else if (!dateFlag && !allFlag && stateFlag) {
      this.setState({ stateFlag: false, dataLoading: true }, () => {
      

        this.props.fetchBills(values, "", "", this.state.stateValue, () => {
          this.setState({ dataLoading: false });
        });
      });
    } else {
      this.getAllDataInvoice();
    }
  };


  typeDisplay = (item) => {
    switch (item) {
      case "indent_rejection":
        return "Indent Rejection";
      case "canacelation_on_arrival":
        return "Cancellation On Arrival";
      case "freight":
        return "Freight";
      case "vehicle_non_placement":
        return "Vehicle Non Placement";
      default:
        return item;
    }
  };


  openRaiseInvoiceModal = () => {
    if (this.state.billInfo.bill.length > 0 || defaultCheckedBills.length > 0) {
      this.setState(
        { raiseinvoiceModal: !this.state.raiseinvoiceModal },
        () => {
          this.setState({ defaultCheckedBills: defaultCheckedBills });
        }
      );
    }
  };


  documentAssigner = (value) => {
    this.setState({ documentArray: value }, () => {
      // this.invoiceSubmit();
    });
  };

  defaultBillConcat = async () => {
    if (defaultCheckedBills.length > 0) {
      this.setState({
        bills: this.state.billInfo.bill.concat(defaultCheckedBills),
      });
      // console.log(this.state.bills);
    } else {
      this.setState({
        bills: this.state.billInfo.bill,
      });
    }
  };


  validateVal = (values) => {
    const errors = {};
    if (!values.gstNo) {
      errors.gstNo = "Gst No is required!";
    }
    if (!values.fromDate) {
      errors.fromDate = "Date is required!";
    }
    if (values.documents.length === 0) {
      errors.documents = "File is required!";
    }
    return errors;
  };


  createInvoiceForBillApi = () => {

    let values = {
      bills: this.state.billInfo.bill,
    };
    
    if (this.state.billInfo.bill?.length > 0) {
      this.setState({ dataLoading: true });

      this.props.createInvoiceForBills(values, (res) => {
        if ( res["result"] && res["message"] === "Invoice draft created successfully") {
          NotificationManager.success(res["message"], "Success");
          this.setState({ dataLoading: false, defaultCheckedBills: [] }, () => {
            // this.reset();
          });
          this.props.history.push({ pathname: "/secondaryGenerateInvoice", state: { invoiceId: res["result"]._id },});
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({ dataLoading: false });
          this.props.history.push("/invoiceGeneration");
        }
      });
    }
  };


  closeInvoiceModal = () => {
    let limit = 10;
    let source = this.state.selectedHopValues?.selectedSource?.value;
    let destination = this.state.selectedHopValues?.selecteddestination?.value;
    let values = {
      limit: limit,
      offset: offset,
      source,
      destination,
      transporterId: this.state.transporterId,
      ...this.state.filterData,
    };
    let { FromDate, ToDate } = this.state;
    this.setState({ isOpen: !this.state.isOpen, dataLoading: true }, () => {

      this.searchFilter();
    });
  };


  invoiceModal = (r) => {
    // debugger
    let destination = r.destination;
    let id = r.id;
    let type = r.type;
    let indentId = r.indentId;
    let invoice = r.invoice;
    let isOpen = r.isOpen;
    let select = r.select;
    let source = r.select;
    let totalCost = r.totalCost;
    let tripId = r.tripId;
    let charges = r.charges;
    let vehicle = r.vehicle;
    let status = r.status;
    let startTime = r.startTime;
    let leadTime = r.leadTime;
    let distance = r.distance;
    let calculations = r.calculations;
    let revisedChargesStatus = r.revisedChargesStatus;
    let shipments = r.shipments;

    this.setState(
      {
        invoiceDet: {
          charges,
          destination,
          id,
          type,
          indentId,
          invoice,
          isOpen,
          select,
          source,
          totalCost,
          tripId,
          vehicle,
          status,
          startTime,
          leadTime,
          distance,
          calculations,
          revisedChargesStatus,
          shipments,
        },
      },
      () => {
        // console.log(this.state.invoiceDet, "my state");
      }
    );
    this.closeInvoiceModal();
  };


  reset = () => {
    this.setState(
      {
        FromDate: "",
        ToDate: "",
        stateFilter: "",
        billInfo: { bill: [] },
        billFreightCheck: [],
        raiseInvoiceDisable: true,
        stateValue: "",
        documentArray: [],
        defaultCheckedBills: [],
        billData: [],
        selectedItemData: [],
        filterData: {
          type: "",
          indentId: "",
          tripId: "",
          vehicleNumber: "",
          lrNumber: "",
        },
        selectedHopValues: {
          selectedSource: "",
          selecteddestination: "",
        },
        selectedTrans: "",
        selectedVehicleType: "",
        vehicleTypeValue: "",
        
      },
      () => {
        offset = 0;
        totCnt = 0;
        totPages = 0;
        fromVal = 0;
        toVal = 0;
        defaultCheckedBills = [];

        this.props.clearAllBills();
        totalAmount = 0;
        this.resetGetAllDataInvoice();
      }
    );
    window.localStorage.removeItem("invoiceGenerationFiliter");
  };


  search = () => {
    // console.log("search called");
    // console.log(this.state.stateFilter, this.state.FromDate, this.state.ToDate);
    // if(this.state.FromDate !== null && this.state.ToDate !== null )
    this.searchFilter();
  };


  imageReset = () => {
    this.setState({ documentArray: [] });
  };


  checkbox = (item) => {
    // console.log("called =>", item._id);
    // console.log("freight", item);
    // console.log("bill info=>", this.state.billInfo.bill);

    // if(defaultCheckedBills.length>0 || this.state.billInfo.bill.length>0){
    //   this.setState({raiseInvoiceDisable:false})
    // }else{
    //   this.setState({raiseInvoiceDisable:true})
    // }
    if (!defaultCheckedBills.includes(item._id) && item.type !== "freight") {
      defaultCheckedBills.push(item._id);
      // console.log(defaultCheckedBills, "bill");
      if (defaultCheckedBills.length > 0) {
        this.setState({ raiseInvoiceDisable: false });
      } else {
        this.setState({ raiseInvoiceDisable: true });
      }
    }

    if (
      item.type === "freight" &&
      this.state.billInfo.bill.includes(item._id)
    ) {
      // console.log("includes =>", item._id);
      return (
        <Checkbox
          name="bill3"
          id="table-check"
          value={item._id}
          defaultChecked
          onClick={(e) => {
            // console.log("freight", e.target.value);

            this.addBills(e, item);
          }}
          inputProps={{ "aria-label": "Checkbox demo" }}
        />
      );
    } else if (
      item.type === "freight" &&
      !this.state.billInfo.bill.includes(item._id)
    ) {
      return (
        <Checkbox
          name="bill"
          inputProps={{ "aria-label": "Checkbox demo" }}
          value={item._id}
          onClick={(e) => {
            this.addBills(e, item);
          }}
        />
      );
    } else if (item.type !== "freight") {
      return (
        <form>
          <Checkbox
            inputProps={{ "aria-label": "Checkbox demo" }}
            value={item._id}
            disabled
            checked
          />
          {/* </div> */}
        </form>
      );
    }
  };



  addBills = (e, item) => {
    const { value, checked } = e.target;
    // console.log(e.target.value, "=E value=", item);
    const { bill } = this.state.billInfo;
    // console.log(value, checked, bill, item, "con");
    // Case 1 : The user checks the box

    if (checked) {
      if (!bill.includes(value.toString())) {
        this.setState(
          {
            billInfo: {
              bill: [...bill, value.toString()],
              response: [...bill, value.toString()],
            },
            selectedItemData: [...this.state.selectedItemData, item],
          },
          () => {
            if (bill?.length < 0) {
              this.setState({ raiseInvoiceDisable: true });
            } else {
              this.setState({ raiseInvoiceDisable: false }, () => {});
            }
            selectedData = [];
            if (this.state.selectedItemData) {
              this.state.selectedItemData.map((e) => {
                selectedData.push({
                  id: e._id,
                  // bill: e.type ? this.typeDisplay(e.type) : "----",
                  indentId: e.indentId ? e.indentId : "-----",
                  tripId: e.trip?.tripId || "N/A",
                  source:
                    e.type === "indent_rejection" ||
                    e.source?.description !== undefined
                      ? e.source?.description
                      : e.trip?.source?.label || "-----",
                  destination:
                    e.type === "indent_rejection" ||
                    e.destination?.description !== undefined
                      ? e.destination?.description
                      : e.trip?.destination?.label || "-----",
                  date: moment(new Date(e?.indentStartOrStartTripDate)).format(
                    "DD/MM/YYYY"
                  ),
                  vehicleRegNo: e?.vehicle?.regNo ? e?.vehicle?.regNo : "N/A",
                  totalCost: e.calculations ? e.calculations.netPayble : "----",
                });
              });
            }
            selectedDataHead = Object.keys(selectedData[0]);
          }
        );
      }
    }
    // Case 2  : The user unchecks the box
    else if (!checked) {
      this.setState(
        {
          billInfo: {
            bill: bill.filter((e) => e !== value),
            response: bill.filter((e) => e !== value),
          },
          selectedItemData: this.state.selectedItemData.filter(
            (e) => e != item
          ),
        },
        () => {
          if (bill?.length <= 1) {
            this.setState({ raiseInvoiceDisable: true });
          } else {
            this.setState({
              raiseInvoiceDisable: false,
            });
          }
          selectedData = [];
          if (this.state.selectedItemData) {
            this.state.selectedItemData.map((e) => {
              selectedData.push({
                id: e._id,
                // bill: e.type ? this.typeDisplay(e.type) : "----",
                indentId: e.indentId ? e.indentId : "-----",
                tripId: e.trip?.tripId || "N/A",
                source:
                  e.type === "indent_rejection" ||
                  e.source?.description !== undefined
                    ? e.source?.description
                    : e.trip?.source?.label || "-----",
                destination:
                  e.type === "indent_rejection" ||
                  e.destination?.description !== undefined
                    ? e.destination?.description
                    : e.trip?.destination?.label || "-----",
                date: moment(new Date(e?.indentStartOrStartTripDate)).format(
                  "DD/MM/YYYY"
                ),
                vehicleRegNo: e?.vehicle?.regNo ? e?.vehicle?.regNo : "N/A",
                totalCost: e.calculations ? e.calculations.netPayble : "----",
              });
            });
          }
          if (selectedData?.length) {
            selectedDataHead = Object.keys(selectedData[0]);
          }
        }
      );
    }
  };



  raiseChange = (e) => {
    let { name, value } = e.target;
    let raise = this.state.raiseInvoice;
    raise[name] = value;
    this.setState({ raiseInvoice: raise });
  };



  handlePdf = () => {
    this.setState({ showpdf: !this.state.showpdf });
  };



  getAllData = () => {
    this.handleStateChange(this.state.stateFilter);
  };


  handleChangePage = async (event, val) => {
    const { 
      selectedHopValues, 
      FromDate, 
      ToDate, 
      stateFilter, 
      stateValue, 
      transporterId, 
      filterData 
    } = this.state;
  
    const offset = (val - 1) * Number(process.env.REACT_APP_PERPAGE); // Ensure it's a number
  
    const values = {
      limit: 10,
      offset,
      source: selectedHopValues?.selectedSource?.value,
      destination: selectedHopValues?.selecteddestination?.value,
      transporterId,
      ...filterData,
    };
  
    this.setState({ dataLoading: true });
  
    try {
      const res = await this.props.fetchBills(values, FromDate, ToDate, stateValue);
      
  
      this.setState({
        dataLoading: false,
        defaultCheckedBills: [],
        billData: [],
      });
  
    } catch (error) {
      // console.error("API Error:", error);
      this.setState({ dataLoading: false });
    }
  };

  
  handleResetInvoiceInput = () => {
    this.setState(
      {
        resetDate: false,
        documentArray: [],
        errorMessage: {},
        raiseInvoice: {
          gstNo: "",
          FromDate: "",
        },
      },
      () => {
        this.setState({
          resetDate: true,
        });
      }
    );
  };


  modalPreviewToggle = () => {
    this.setState({
      modalPreview: !this.state.modalPreview,
    });
  };


  //vehicle
  getAllVehicleData = () => {
    let values = {
      q: "",
    };
    this.getFetchVehicles(values);
  };


  getFetchVehicles = (values) => {
    this.props.fetchVehicles(offset, values, (res) => {
      let vehicleTypes = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          vehicleTypes.push({
            label: ele?.type?.label
              ? ele?.type?.label + " - " + ele?.registration?.number
              : "",
            value: ele?._id ? ele?._id : "",
          });
        });
      }
      this.setState(
        {
          vehicleTypes,
        },
        () => {
          // console.log("vehicle",this.state.vehicleTypes,vehicleTypes)
        }
      );
    });
  };



   fetchAllCustomersSearch = () => {
      this.props.fetchCustomerOptionsSecondary((res) => {
  
      
        let customers = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            customers.push({
              value: ele._id ? ele._id : "",
              label:
                (ele.customerName ? ele.customerName : "NA") +
                " - " +
                (ele.customerId ? ele.customerId : "NA") +
                (ele.shipToCode ? " - " + ele.shipToCode : " - NA"),
            });
          });
        }
 
        this.setState(
          { allCustomers: customers }
        );
      });
    };



  onChangeVehicleType = (e) => {
    this.setState(
      {
        selectedVehicleType: e,
        vehicleTypeValue: e.value,
      },
      () => {
        // this.getAllDataInvoice()
      }
    );
  };


  onChangeCustomer = (e) => {
    this.setState(
      {
        selectedCustomer: e,
        CustomerValue: e.value,
      },
      () => {
        // this.getAllDataInvoice()
      }
    );
  };

  
  filtersVehicles = (e) => {
    if (e?.length > 0) {
      let filtersVehicleData = {
        q: e,
      };
      this.getFetchVehicles(filtersVehicleData);
    }
    if (e.length === 0) {
      let filtersVehicleData = {
        q: "",
      };
      this.getFetchVehicles(filtersVehicleData);
    }
  };
  //hop


  filtersCustomers = (e) => {
    if (e?.length > 2) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchAllCustomersSearch(filterData);
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      if (this.state.allCustomers?.length === 0)
        this.fetchAllCustomersSearch(filterData);
    }
  };


  getAllLocationsData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchHopsData(filtersHopsData);
  };

  fetchHopsData = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        let hops = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            hops.push({
              label: ele?.label ? ele?.label : "",
              value: ele?._id ? ele?._id : "",
            });
          });
        }
        this.setState(
          {
            hops: hops,
          },
          () => {}
        );
      }
    );
  };


  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHopsData(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHopsData(filtersHopsData);
    }
  };


  onChangeHop = (type) => {
    if (type?.target == "selecteddestination") {
      this.setState(
        {
          selectedHopValues: {
            ...this.state.selectedHopValues,
            selecteddestination: type?.value,
          },
        },
        () => {
          // this.getAllDataInvoice();
        }
      );
    } else {
      this.setState(
        {
          selectedHopValues: {
            ...this.state.selectedHopValues,
            selectedSource: type?.value,
          },
        },
        () => {
          // this.getAllDataInvoice();
        }
      );
    }
  };


  handleOnchangeFilters = (type) => {
    this.setState(
      {
        filterData: { ...this.state.filterData, [type.target]: type?.value },
      },
      () => {}
    );

    console.log(">>>>>>>>>>>>>>>", type);
  };


  enterCheck = (event) => {
    if (event.key === "Enter") {
      let limit = 10;
      let type = this.state.filters?.type;
      let source = this.state.selectedHopValues?.selectedSource;
      let destination = this.state.selectedHopValues?.selecteddestination;
      let values = {
        limit: limit,
        offset: offset,
        type: type,
        source,
        destination,
        transporterId: this.state.transporterId,
        ...this.state.filterData,
      };
      let { FromDate, ToDate, dateFlag, stateFlag, stateFilter, allFlag } =
        this.state;
      let stfilter_values = {
        ...values,
        source,
        destination,
        FromDate,
        ToDate,
        state: stateFilter,
        vehicleType: this.state.selectedVehicleType,
      };
      localStorage.setItem(
        "invoiceGenerationFiliter",
        JSON.stringify(stfilter_values)
      );

      this.getAllDataInvoice();
    }
  };


  filterList = () => {
    this.getAllDataInvoice();
  };


  // transpoter search
  getTAllranspoterData = () => {
    this.props.fetchAllTransporterSearch(
      this.state.offset,
      10,
      this.state.filterTranspoter,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState({ allTransporter: transporter }, () => {});
      }
    );
  };


  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState(
          { allTransporter: transporter },

          () => {}
        );
      }
    );
  };


  handleStateTransChange = (e) => {
    this.setState(
      {
        transporterId: e.value,
        selectedTrans: e,
      },
      () => {
        // this.getAllDataInvoice();
      }
    );
  };


  filtersTranspoter = (e) => {
    if (e?.length > 2) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchAllTransporterSearch(filterData);
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      if (this.state.allTransporter?.length === 0)
        this.fetchAllTransporterSearch(filterData);
    }
  };


  render() {


    if (this.state.billInfo?.bill?.length == 0) {
      this.state.selectedItemData = [];
    }
  
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;

    const { stateFilter, loading } = this.state;
    const { bills } = this.props;
    offset = bills?.offset;
    totCnt = bills?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = bills?.offset + bills?.docs.length;
    totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);


    let bill = bills.docs.map((e) => ({
      id: e._id,
      bill: e.type ? this.typeDisplay(e.type) : "----",
      indentId: e.indentId ? e.indentId : "-----",
      tripId: e.trip?.tripId || "N/A",
      lrNo: e?.shipments?.length
        ? e?.shipments?.map((ele) => {
            return <div>{ele?.lrNo}</div>;
          })
        : "N/A",
      shipments: e?.shipments,
      source:
        e.type === "indent_rejection" || e.source?.description !== undefined
          ? e.source?.description
          : e.trip?.source?.label || "-----",
      destination:
        e.type === "indent_rejection" ||
        e.destination?.description !== undefined
          ? e.destination?.description
          : e.trip?.destination?.label || "-----",
      date: moment(new Date(e?.indentStartOrTripStartDate)).format(
        "DD/MM/YYYY"
      ),
      vehicleRegNo: e?.vehicle?.regNo ? e?.vehicle?.regNo : "N/A",
      totalCost: e.calculations ? e.calculations.netPayble : "----",
      charges: e.charges,
      vehicle: e.trip?.vehicle,
      status: e.trip?.subStatus,
      startTime: e.trip?.startTime,
      leadTime: e.trip?.leadTime,
      distance: e.trip?.distance,
      calculations: e.calculations,
      select: e.type ? this.checkbox(e) : "No Type",
      revisedChargesStatus: e.revisedChargesStatus,
      customers: e.customers,
    }));

    if (Array.isArray(bill)) {
      bill.map((r, i) => {
        if (r.bill === "Freight") {
          r.actions = (
            <div className="dropdown">
              <Button className="iconButtons dropbtn actionButtonsDark">
                <img
                  style={{ margin: "-4px" }}
                  src={darkMode ? actionVerticleDotsDark : actionVerticleDots}
                  alt="actions"
                />
              </Button>
              <div className="dropdown-content contentDark">
                <div
                  className="action-button actionDspan"
                  onClick={() => {
                    this.invoiceModal(r);
                  }}
                >
                  Edit Details
                </div>
              </div>
            </div>
          );
        } else {
          r.actions = (
            <div className="dropdown">
              <Button
                className="iconButtons dropbtn actionButtonsDark"
                disabled
              >
                <img
                  style={{ margin: "-4px" }}
                  src={darkMode ? actionVerticleDotsDark : actionVerticleDots}
                  alt="actions"
                />
              </Button>
            </div>
          );
          r.clickEvent = (params) => this.handleChangeRow(params);
        }
        return true;
      });
    }

    let Aggreement = bill;
    if (this.state.fetchId !== this.props.fetchId) {
      this.setState({ billData: Aggreement, fetchId: this.props.fetchId });
    }

    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    let resultSelectedData = [];

    if (
      this.state.billInfo.bill?.length > 0 &&
      this.state.selectedItemData?.length > 0
    ) {
      this.state.billInfo.bill.filter((array22) =>
        this.state.selectedItemData.some((array11) => {
          if (array11._id === array22) {
            resultSelectedData.push(array11);
          }
        })
      );
    }
    let SelectedDataFinal = [];
    resultSelectedData.map((e) => {
      SelectedDataFinal.push({
        id: e._id,
        // bill: e.type ? this.typeDisplay(e.type) : "----",
        indentId: e.indentId ? e.indentId : "-----",
        tripId: e.trip?.tripId || "N/A",
        source:
          e.type === "indent_rejection" || e.source?.description !== undefined
            ? e.source?.description
            : e.trip?.source?.label || "-----",
        destination:
          e.type === "indent_rejection" ||
          e.destination?.description !== undefined
            ? e.destination?.description
            : e.trip?.destination?.label || "-----",
        date: moment(new Date(e?.indentStartOrStartTripDate)).format(
          "DD/MM/YYYY"
        ),
        vehicleRegNo: e?.vehicle?.regNo ? e?.vehicle?.regNo : "N/A",
        totalCost: e.calculations ? e.calculations.netPayble : "----",
      });
    });
    totalAmount = 0;
    if (resultSelectedData?.length > 0) {
      resultSelectedData.map((ele) => {
        totalAmount = totalAmount + ele?.calculations?.netPayble;
        // console.log("total cost", totalAmount);
      });
    }

    return (
      <div style={{ overflow: "visible"}}>
      {/* <div style={{ overflow: "auto",maxHeight:"730px" }}> */}

        <AddInvoiceBillsToDraft
          handleAddBillOpen={this.handleAddBillOpen}
          handleAddBillclose={this.handleAddBillclose}
          addBillOpen={this.state.addBillOpen}
          billsArrayForAdd={this.state.billInfo.bill}
          getAllDataInvoiceGen={this.getAllDataInvoice}
        />


        <Modal
          isOpen={this.state.modalPreview}
          toggle={this.modalPreviewToggle}
          size="xl"
          centered
        >
          <Row className="mt-2">
            <Col>
              <ModalHeader> Selected Freight Cost Bills </ModalHeader>
            </Col>
            <Col className="text-right">
              <i
                className="fas fa-times closemodal1 pr-2 text-right close"
                onClick={() => this.modalPreviewToggle()}
              ></i>
            </Col>
          </Row>

          <ModalBody>
            <DataTable
              heads={selectedDataHead}
              data={SelectedDataFinal}
              hidden={["id"]}
            />
          </ModalBody>
        </Modal>


        {getLocalStorage()}



        <InvoiceModal
          isOpen={this.state.isOpen}
          toggle={this.closeInvoiceModal}
          invoiceDet={this.state.invoiceDet}
          getAllData={this.getAllData}
          mode={darkMode}
          bills={bills}
        />


  


        <QuickFilters
          name="stateFilter"
          handleStateChange={this.handleStateChange}
          stateListOption={stateList}
          stateFilter={stateFilter}
          handleChange={this.handleChange}
          reset={this.reset}
          mode={darkMode}
          search={this.search}
          selectedHopValues={this.state.selectedHopValues}
          hopOptions={this.state.hops}
          onChangeHop={this.onChangeHop}
          filtersHops={this.filtersHops}
          onChangeVehicleType={this.onChangeVehicleType}
          filtersVehicles={this.filtersVehicles}
          selectedVehicleType={this.state.selectedVehicleType}
          vehicleTypes={this.state.vehicleTypes}
          filtersCustomers={this.filtersCustomers}
          selectedCustomer={this.state.selectedCustomer}
          allCustomers={this.state.allCustomers}
          onChangeCustomer={this.onChangeCustomer}
          handleOnchangeFilters={this.handleOnchangeFilters}
          filters={this.state.filterData}
          enterCheck={this.enterCheck}
          filterList={this.filterList}
          getTAllranspoterData={this.getTAllranspoterData}
          filtersTranspoter={this.filtersTranspoter}
          allTransporter={this.state.allTransporter}
          selectedTrans={this.state.selectedTrans}
          handleStateTransChange={this.handleStateTransChange}
          FromDate={this.state.FromDate}
          ToDate={this.state.ToDate}
        />
       
        {this.state.billInfo.bill?.length > 0 ? (
          <div className="totalAmount" >
            {" "}
            {selectedData?.length ? (
              <div>
                {this.state.selectedItemData?.length > 0 ? (
                  <h5 className="d-flex fontRoboto177 ">
                    Total Amount:
                    {totalAmount <= 0 ? (
                      <h3
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                        }}
                      >
                        {" ₹ "} {totalAmount}
                      </h3>
                    ) : (
                      <h3
                        style={{
                          color: "blue",
                          paddingLeft: "10px",
                        }}
                      >
                        {" ₹ "}
                        {totalAmount}
                      </h3>
                    )}
                  </h5>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        
        {Aggreement?.length > 0 ? (
          <>
           
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={this.state.billData}
              searchBar={searchBar}
              rowHeight={"64px"}
              getAllData={this.getAllData}
              dataLength={this.props.bills?.docs?.length}
              fetchId={this.props?.fetchId}
              dataLoading={this.state.dataLoading}
            />
           
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{
                  color: "#0C0C0C",
                  backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
                }}
                className="mb-2 mt-2 paginationRow "
              >
                <Col
                  className="mt-0"
                  xs={12}
                  style={{ fontSize: "12px", fontFamily: "Roboto" }}
                >
                  <div className="d-flex flex-row pt-2 pb-2 btnWrap" style={{gap:"7px"}}>
                    <div>
                      <Button
                        color="primary"
                        size="sm"
                        style={{
                          backgroundColor: "#05284E",
                        }}
                        onClick={() => this.handleAddBillOpen()}
                        disabled={
                          this.state.stateFilter.label === "All"
                            ? true
                            : false || this.state.raiseInvoiceDisable
                        }
                      >
                        Add bills to draft
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        size="sm"
                        style={{
                          height: "30px ",
                        }}
                        className="fontRoboto127"
                        disabled={resultSelectedData?.length > 0 ? false : true}
                        onClick={() => {
                          this.setState({
                            modalPreview: true,
                          });
                        }}
                      >
                        Preview
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        size="sm"
                        style={{
                          backgroundColor: "#05284E",
                        }}
                        onClick={() => this.createInvoiceForBillApi()}
                        disabled={
                          this.state.stateFilter.label === "All" ||
                          this.state.raiseInvoiceDisable
                        }
                      >
                        {userLang?.invoice?.RAISE_INVOICE_DRAFT
                          ? userLang?.invoice?.RAISE_INVOICE_DRAFT
                          : "Raise Invoice Draft"}
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="success"
                        size="sm"
                        onClick={() => this.reset()}
                      >
                        {" "}
                        {userLang?.common?.RESET}
                      </Button>
                    </div>

               
                  </div>
                </Col>
                <Col xs={6} sm={5} md={3} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2"
                  xs={6}
                  md={5}
                  style={{ fontSize: "13px" }}
                >
                  <span className="light__span">
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
                
              </Row>
            ) : null}
          </>
        ) : (
          <>
            
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={this.state.billData}
              searchBar={searchBar}
              rowHeight={"64px"}
              getAllData={this.getAllData}
              dataLength={this.props.bills?.docs?.length}
              fetchId={this.props?.fetchId}
              dataLoading={this.state.dataLoading}
            />
           
            <div
              style={{
                color: "red",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {/* <span className="alertStyleGenerator light__span">
                *{userLang?.invoice?.SELECT_STATE_FUNCTIONALITY}
              </span> */}
            </div>
          </>
        )}
       



        {(totCnt === 0 && !this.state.dataLoading) || totCnt === undefined ? (
          <>
            <Row
              xs={12}
              style={{ color: "#0C0C0C" }}
              className="mb-2 mt-2 paginationRow"
            >
              <Col
                className="mt-0 mobileDisplay"
                xs={12}
                style={{ fontSize: "12px", fontFamily: "Roboto" }}
              >
                <div
                  className="float-right d-flex flex-row pt-2 pb-2"
                  // style={{ paddingLeft: "15px" }}
                >
                  <div>
                    <Button
                      color="primary"
                      size="sm"
                      style={{
                        marginRight: "25px",
                        backgroundColor: "#05284E",
                      }}
                      onClick={() => this.handleAddBillOpen()}
                      disabled={
                        this.state.stateFilter.label === "All"
                          ? true
                          : false || this.state.raiseInvoiceDisable
                      }
                    >
                      Add bills to draft
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      size="sm"
                      style={{
                        marginRight: "25px",
                        backgroundColor: "#05284E",
                      }}
                      onClick={() => this.createInvoiceForBillApi()}
                      disabled={
                        this.state.stateFilter.label === "All"
                          ? true
                          : false || this.state.raiseInvoiceDisable
                      }
                    >
                      {userLang?.invoice?.RAISE_INVOICE_DRAFT
                        ? userLang?.invoice?.RAISE_INVOICE_DRAFT
                        : "Raise Invoice Draft"}
                    </Button>
                  </div>
                  <div>
                    <Button color="success" size="sm" style={{ width: "87px" }}>
                      {" "}
                      {userLang?.common?.RESET}
                    </Button>{" "}
                  </div>
                </div>
              </Col>
              <Col xs={6} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col className="mt-2" xs={6} md={4} style={{ fontSize: "13px" }}>
                <span className="light__span">Showing 0 to 0 of 0 entries</span>
              </Col>
              <Col
                className="mt-0 desktopDisplay"
                xs={10}
                md={4}
                style={{ fontSize: "13px" }}
              >
                <div
                  className="float-left d-flex flex-row"
                  style={{ paddingLeft: "15px" }}
                >
                  <div>
                    <Button
                      color="primary"
                      size="sm"
                      style={{
                        marginRight: "25px",
                        backgroundColor: "#05284E",
                      }}
                      disabled={
                        this.state.stateFilter.label === "All"
                          ? true
                          : false || this.state.raiseInvoiceDisable
                      }
                      onClick={() => this.handleAddBillOpen()}
                    >
                      Add bills to draft
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      size="sm"
                      style={{
                        marginRight: "25px",
                        backgroundColor: "#05284E",
                      }}
                      onClick={() => this.createInvoiceForBillApi()}
                      disabled={
                        this.state.stateFilter.label === "All"
                          ? true
                          : false || this.state.raiseInvoiceDisable
                      }
                    >
                      {userLang?.invoice?.RAISE_INVOICE_DRAFT
                        ? userLang?.invoice?.RAISE_INVOICE_DRAFT
                        : "Raise Invoice Draft"}
                    </Button>
                  </div>
                  <div>
                    <Button color="success" size="sm" style={{ width: "87px" }}>
                      {" "}
                      {userLang?.common?.RESET}
                    </Button>{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : null}
       
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bills: state?.SecondaryBilling?.SecondaryBilling?.bills,
  fetchId: state?.SecondaryBilling?.SecondaryBilling?.fetchId,
});

export default connect(mapStateToProps, {
  fetchBills,
  // createInvoice,
  createInvoiceForBills,
  clearAllBills,
  fetchHops,
  fetchVehicles,
  fetchAllTransporterSearch,
  fetchCustomerOptionsSecondary,
})(SecondaryInvoiceGeneration);
