import React, { Component } from "react";
//import from libraries
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
//import files
import { HeaderData, searchBar, getLocalStorage } from "./RowHeader/RowHeader";
import QuickFilters from "./QuickFilters";
import Index from "../../components/Common/DataTableNeo/Index";
// import from store
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import {
  FetchVehiclePlacementReports,
  FetchVehiclePlacementReportsExport,
} from "../../store/vehiclePlacement/actions";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkMode;

class vehiclePlacement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transpoterId: "",
      FromDate: "",
      ToDate: "",
      offset: 0,
      limit: 10,
      dataLoading: false,
      selectedTranspoterValues: "",
      transpoter: [],
      transporterValues: "",
      isExport: false,
    };
  }
  componentDidMount() {
    this.getAllData();
    this.getAllTransporterData();
    this.getAllExportData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }
  getAllData = () => {
    this.setState({
      dataLoading: true,
    });
    let values = {
      offset: offset,
      transpoterId: this.state.transporterValues,
      startDate: this.state.FromDate,
      endDate: this.state.ToDate,
    };
    this.props.FetchVehiclePlacementReports(values, () => {
      this.setState({
        dataLoading: false,
      });
    });
  };

  getAllExportData = () => {
    this.setState({
      dataLoading: true,
    });
    let values = {
      offset: offset,
      transpoterId: this.state.transporterValues,
      startDate: this.state.FromDate,
      endDate: this.state.ToDate,
      isExport: true,
    };
    this.props.FetchVehiclePlacementReportsExport(values, () => {
      this.setState({
        dataLoading: false,
      });
    });
  };
  getAllTransporterData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchAllTransporterSearch(filtersHopsData);
  };
  // calling transpoter api
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
          },
          () => {}
        );
      }
    );
  };
  enterCheck = (event) => {
    if (event.key === "Enter") this.searchFilter();
  };
  // get transpoter name by filters
  filtersTranspoter = (e) => {
    if (e?.length > 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: e,
      };
      this.fetchAllTransporterSearch(filtersData);
    }
    if (e?.length === 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: "",
      };
      this.fetchAllTransporterSearch(filtersData);
    }
  };
  handleChange = (dateValues) => {
    // console.log(dateValues)
    this.setState(
      {
        FromDate:
          dateValues[0] !== null
            ? moment(dateValues[0]).format("MM-DD-YYYY")
            : null,
        ToDate:
          dateValues[1] !== null
            ? moment(dateValues[1]).format("MM-DD-YYYY")
            : null,
      },
      () => {
        offset = 0;
        this.getAllData();
        this.getAllExportData();
      }
    );
  };
  onChangeTranspoter = (selectedTranspoterValues) => {
    // console.log("selectedTranspoterValues",selectedTranspoterValues)
    this.setState(
      {
        selectedTranspoterValues: selectedTranspoterValues,
        transporterValues: selectedTranspoterValues.value,
      },
      () => {
        this.setState({
          dataLoading: true,
        });
        let values = {
          offset: 0,
          transpoterId: this.state.transporterValues,
          startDate: this.state.FromDate,
          endDate: this.state.ToDate,
        };
        this.props.FetchVehiclePlacementReports(values, () => {
          this.setState({
            dataLoading: false,
          });
        });
        this.getAllExportData();
      }
    );
  };
  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true });
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };
  reset = () => {
    this.setState(
      {
        FromDate: "",
        ToDate: "",
        transporterValues: [],
        selectedTranspoterValues: [],
        dataLoading: true,
        Loading: true,
      },
      () => {
        let values = {
          transporterId: this.state.transporterValues,
          startDate: this.state.FromDate,
          endDate: this.state.ToDate,
        };
        this.props.FetchVehiclePlacementReports(values, () => {
          this.setState({
            dataLoading: false,
          });
        });
        let valuesExport = {
          offset: 0,
          transporterId: this.state.transporterValues,
          startDate: this.state.FromDate,
          endDate: this.state.ToDate,
          isExport: true,
        };
        this.props.FetchVehiclePlacementReportsExport(valuesExport, () => {
          this.setState({
            Loading: false,
          });
        });
      }
    );
  };
  exportToCSV = (csvData, fileName) => {
    // console.log("Calling excel")
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
      }
      return item;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  render() {
    const localStorageData = JSON.parse(
      localStorage.getItem("VehicleplacementData")
    );
    const vehiicleReportData = navigator.onLine
      ? this.props.vehiclePlacementReportData?.data?.docs
      : localStorageData?.data?.docs;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    let vehiclesReports = [];
    if (Array.isArray(vehiicleReportData)) {
      offset = navigator.onLine
        ? this.props.vehiclePlacementReportData?.data?.offset
        : localStorageData?.data?.offset;
      totCnt = navigator.onLine
        ? this.props.vehiclePlacementReportData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? this.props.vehiclePlacementReportData?.data?.offset +
          this.props.vehiclePlacementReportData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      // console.log("vehiicleReportData" , vehiicleReportData)

      vehiicleReportData.forEach((ele, i) => {
        vehiclesReports.push({
          // transporterId: ele?.transporterId ? ele?.transporterId : "",
          transporterName: ele?.transporterName ? ele?.transporterName : "N/A",
          truckPlanDate: ele?.truckPlanDate
            ? ele?.truckPlanDate
                ?.substring(0, ele?.truckPlanDate?.length - 5)
                ?.replace("T", " ")
            : "N/A",
          planGivenDate: ele?.planGivenDate
            ? ele?.planGivenDate
                ?.substring(0, ele?.planGivenDate?.length - 5)
                ?.replace("T", " ")
            : "N/A",
          locationFrom: ele?.locationFrom ? ele?.locationFrom : "N/A",
          destinationCode: ele?.destinationCode ? ele?.destinationCode : "N/A",
          shipToLocation: ele?.shipToLocation ? ele?.shipToLocation : "N/A",
          twoPointLoading: ele?.twoPointLoading ? ele?.twoPointLoading : "N/A",
          truckType: ele?.truckType ? ele?.truckType : "N/A",
          truckAsked: ele?.truckAsked ? ele?.truckAsked : 0,
          truckPlaced: ele?.truckPlaced ? (
            <div className="tile-text1 text-capitalize p dark__span">
              {ele?.truckPlaced}
            </div>
          ) : (
            0
          ),
          remark: ele?.remark ? (
            <div className="tooltipIconComment">
              <div className="ellipsis-text-remark ">{ele?.remark}</div>
              <div className="tooltipIcontext">{ele?.remark}</div>
            </div>
          ) : (
            "N/A"
          ),
        });
      });

      // console.log("final data vehiclesReports ", vehiclesReports)
    }
    // console.log("vehiclePlacementExportData",this.props.vehiclePlacementExportData?.data?.docs)

    let exprotDataReport = [];
    let exportData = this.props.vehiclePlacementExportData?.data?.docs;
    if (this.props.vehiclePlacementExportData !== undefined) {
      if (Array.isArray(exportData)) {
        exportData.forEach((ele, i) => {
          exprotDataReport.push({
            "Sl no.": i + 1,
            "Transporter Name": ele?.transporterName
              ? ele?.transporterName
              : "N/A",
            "Truck Plan Date": ele?.truckPlanDate
              ? ele?.truckPlanDate
                  ?.substring(0, ele?.truckPlanDate?.length - 5)
                  ?.replace("T", " ")
              : "",
            "Plan Given Date": ele?.planGivenDate
              ? ele?.planGivenDate
                  ?.substring(0, ele?.planGivenDate?.length - 5)
                  ?.replace("T", " ")
              : "",
            "Location From": ele?.locationFrom ? ele?.locationFrom : "",
            "STL Code": ele?.destinationCode ? ele?.destinationCode : "",
            "Ship To Location": ele?.shipToLocation ? ele?.shipToLocation : "",
            "Two Point Loading": ele?.twoPointLoading
              ? ele?.twoPointLoading
              : "",
            "Truck Type": ele?.truckType ? ele?.truckType : "",
            "Truck Asked": ele?.truckAsked ? ele?.truckAsked : "",
            "Truck Placed": ele?.truckPlaced ? ele?.truckPlaced : "",
            remark: ele?.remark ? ele?.remark : "",
          });
        });
      }
    }
    return (
      <React.Fragment>
        {/* {getLocalStorage()} */}
        <div>
          <QuickFilters
            searchFilter={this.searchFilter}
            transporterValues={this.state.transporterValues}
            reset={this.reset}
            selectedTranspoterValues={this.state.selectedTranspoterValues}
            transpoterOptions={this.state.transpoter}
            onChangeTranspoter={this.onChangeTranspoter}
            enterCheck={this.enterCheck}
            vehiclesReports={exprotDataReport}
            exportToCSV={this.exportToCSV}
            onChangeExport={this.onChangeExport}
            handleChange={this.handleChange}
            mode={darkMode}
            filtersTranspoter={this.filtersTranspoter}
          />
          <Container fluid>
            <Index
              {...this.props}
              headers={HeaderData}
              data={vehiclesReports}
              searchBar={searchBar}
              searchable={false}
              getAllData={this.getAllData}
              fetchId={this.props.fetchId}
              // rowHeight={"70px"}
              dataLoading={this.state.dataLoading}
            />

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkMode ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 mt-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("state",state)
  return {
    ...state,
    fetchId: state.VehiclePlacement.VehiclePlacement.fetchId,
    vehiclePlacementReportData:
      state.VehiclePlacement.VehiclePlacement.vehiclePlacementData,
    vehiclePlacementExportData:
      state.VehiclePlacement.VehiclePlacement.vehiclePlacementExport,
  };
};
export default connect(mapStateToProps, {
  FetchVehiclePlacementReports,
  fetchAllTransporterSearch,
  FetchVehiclePlacementReportsExport,
})(vehiclePlacement);
