import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from "../restrict";
import { Row, Col } from "reactstrap";
import { PERMS } from "../../enum/perms.enum";
//components
import { shipmentData } from "./dummyData";
import QuickFilters from "./QuickFilter";
import Index from "../../components/Common/DataTableNeo/Index";
// files
import { HeaderData, searchBar, getLocalStorage } from "./RowHeader.js";
// actions
import { ShipmentMasterList } from "../../store/shipmentMaster/actions";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;
var searchValues = {};
class Shipments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offset: 0,
      searchFilters: {},
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_READ);

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }
  componentDidMount = () => {
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 1 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 1;
  }
  getAllData = () => {
    this.setState({ loading: true }, () => { });


    this.props.ShipmentMasterList(
      {
        offset: 0,
      },
      this.state.searchFilters,
      () => {
        this.setState({ loading: false });
      }
    );
  };
  handleChangePage = (event, val) => {
    // console.log(event, val, "handlepage");
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };

  handleChangeFilters = (type) => {
    searchValues[type?.name] = type?.value?.replace(/^\s+/, "");
    this.setState({ searchFilters: searchValues }, () => { });
  };
  searchValueAssignerFunction = (name, value, type) => {
    this.setState({ dataLoading: true });
    let searchValues = { [name]: value };
  
    this.setState({ searchFilters: searchValues }, () => {
      // localStorage.setItem("filters", JSON.stringify(this.state.searchFilters));
      // console.log(this.state.searchFilters, "-----Filters-");
      
      this.getAllData();  
    });
  };
  reset = () => {
    offset = 0;
    searchValues = {};
    this.setState({ searchFilters: {} }, () => {
      this.getAllData();
    });
  };
  enterCheck = (event) => {
    offset = 0;
    if (event.key === "Enter") {
      this.getAllData();
    }
  };
  filterList = () => {
    offset = 0;
    this.getAllData();
  };
  render() {
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { shipmentDataForMaster } = this.props;
    // console.log(shipmentDataForMaster, "shipmentDataForMaster");
    const localStorageData = JSON.parse(
      localStorage.getItem("shipmentFreighMaster")
    );
    const shipmentDataForList = navigator.onLine
      ? shipmentDataForMaster?.data?.shipmentAndTrips
      : localStorageData?.data?.shipmentAndTrips;
    let rows = [];
    if (Array.isArray(shipmentDataForList)) {
      // console.log(shipmentDataForMaster, "shipmentDataForMaster");
      totCnt = navigator.onLine
        ? shipmentDataForMaster?.data?.totalCount
        : localStorageData?.data?.totalCount;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? shipmentDataForMaster?.data?.offset +
        shipmentDataForMaster?.data?.shipmentAndTrips?.length
        : localStorageData?.data?.offset + localStorageData?.data?.totalCount;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      // console.log(shipmentDataForList, "shipmentDataForMaster");
      shipmentDataForList.forEach((e) => {
        rows.push({
          shipmentId: e?.shipmentId ?? "-----",
          tripId: e?.tripId?.tripId ?? "-----",
          
          epodDetails: e.epodDetails ? "Yes" : "No",
        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    return (
      <React.Fragment>
        {getLocalStorage()}
        {/* <QuickFilters
          reset={this.reset}
          filterList={this.filterList}
          enterCheck={this.enterCheck}
          mode={darkTheme}
          handleChangeFilters={this.handleChangeFilters}
          filterNames={this.state.searchFilters}
        /> */}
        <Index
          {...this.props}
          headers={HeaderData}
          data={rows}
          hidesearchBar={true}
          searchable={true}
          searchBar={searchBar}
          fetchId={this.props.fetchId}
          reset={this.reset}
          searchValueAssignerFunction={this.searchValueAssignerFunction}
          getAllData={this.getAllDataReset}
          dataLength={90}
          rowHeight={"58px"}
          dataLoading={this.state.loading}
          dashboard="true"
        />
        {totCnt !== 0 && rows?.length > 0 && !this.state.loading ? (
          <Row
            xs={12}
            style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
            className="mb-2 mt-2 paginationRow"
          >
             <Col
              className="mt-2 spanPagenationentries span-col-pagenation"
              xs={5}
              sm={5}
              md={5}
            >
              {" "}
              <span>
                Showing {fromVal} to {toVal} of {totCnt} entries
              </span>
            </Col>
            <Col xs={7} sm={7} md={7} className="span-col-pagenation">
              <div className="float-right">
                <Pagination
                  className="desktop-pagination"
                  size="medium"
                  shape="rounded"
                  page={offset / 30 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
                <Pagination
                  className="mobile-pagination"
                  size="small"
                  shape="rounded"
                  page={offset / 30 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
              </div>
            </Col>
           
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    shipmentDataForMaster:
      state?.ShipmentMaster?.ShipmentMaster?.ShipmentMasterData,
    fetchId: state.ShipmentMaster?.ShipmentMaster?.fetchId,
  };
};

export default connect(mapStateToProps, { ShipmentMasterList })(Shipments);
