let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        // width: 3,
        width: 500,
      },
      {
        label: userLang?.common?.START_TIME,
        field: "startTime",
        sort: "asc",
        // width: 2,
        width: 100,
      },
      {
        label: userLang?.common?.EXPIRY_TIME,
        field: "expiryTime",
        sort: "asc",
        // width: 2,
        width: 100,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        // width: 1,
        width: 100,
      },
    ],
  };
  AdminHeaderData = {
    columns: [
      {
        label: "Transporter",
        field: "transporter",
        sort: "asc",
        width: 3,
      },
      {
        label: "Start Time",
        field: "startTime",
        sort: "asc",
        width: 2,
      },
      {
        label: "Expiry Time",
        field: "expiryTime",
        sort: "asc",
        width: 2,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.START_TIME,
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.EXPIRY_TIME,
      field: "expiryTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 1,
    },
  ],
};

export var AdminHeaderData = {
  columns: [
    {
      label: "Transporter",
      field: "transporter",
      sort: "asc",
      width: 3,
    },
    {
      label: "Start Time",
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: "Expiry Time",
      field: "expiryTime",
      sort: "asc",
      width: 2,
    },
    {
      label: "Actions",
      field: "actions",
      sort: "asc",
      width: 1,
    },
  ],
};

export const searchBar = {
  searchBarData: [
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      type: "text",
    },
    {
      label: userLang?.common?.START_TIME,
      field: "startTime",
      type: "text",
    },
    {
      label: userLang?.common?.EXPIRY_TIME,
      field: "expiryTime",
      type: "text",
    },
  ],
};
