import React, { Component } from "react";
import { FormGroup, Row, Col, Button, Card } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { NotificationManager } from "react-notifications";
import {
    NonVehiclePlacementChargesHeaders,
    // MultiPointPickChargesHeaders,
    DetentionChargesAtSourceHeaders,
    getLocalStorage,
    searchBar,
  } from "./AgreementForms/DataTableHeaders";
  import Index from "../../../components/Common/DataTableNeo/Index";
  // import {
  //   storeAgreement,
  //   createAgreement,
  //   fetchAgreements,
  //   fetchSignedURL,
  //   uploadAgreement,
  // } from "../../../store/billing/actions";
import {storeAgreement , createAgreement , fetchSecondaryAgreements , fetchSignedURL , uploadAgreement} from '../../../store/secondaryBilling/actions';

import NonVehiclePlacementCharges from "./AgreementForms/NonVehiclePlacementCharges";
//import MultiPointPickCharges from "./AgreementForms/MultiPointPickCharges";
//import MultiPointDropCharges from "./AgreementForms/MultiPointDropCharges";
import DetentionChargesAtSource from "./AgreementForms/DetentionChargesAtSource";
//import DetentionChargesAtDestination from "./AgreementForms/DetentionChargesAtDestination";
//import LateDeliveryCharges from "./AgreementForms/LateDeliveryCharges";
import CancellationUponArrivalCompany from "./AgreementForms/CancellationUponArrivalCompany";


import SecondaryAgreementPdf from "../SecondaryAgreementPdf";
import plusIcon from "../assets/plus.png";
import plusDarkIcon from "../assets/plusDark.png";
import attachmentIcon from "../assets/AttachmentIcon.png";

var vehicleNonPlacementChargesData = {};
//var MultiPointPickChargesData = {};
//var MultiPointDropChargesData = {};
var detentionChargesAtSourceData = {};
//var detentionChargesAtDestinationData = {};
//var lateDeliveryChargesData = {};
var cancellationUponArrivalCompanyData = {};


let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));



class AddAgreementIndexSubScreen extends Component{


    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          showpdf: false,
          startDate: "",
          endDate: "",
          //vehicleNonPlacementCharges
          vehicleNonPlacementChargesModal: false,
          vehicleNonPlacementChargesListShow: false,
          vehicleNonPlacementChargesList: [],
          vehicleNonPlacementChargesDataListID: [],
          //MultiPointPickCharges
          //MultiPointPickChargesModal: false,
          // MultiPointPickChargesListShow: false,
          // MultiPointPickChargesList: [],
          // MultiPointPickChargesDataListID: [],
          // MultiPointDropCharges
          // MultiPointDropChargesModal: false,
          // MultiPointDropChargesListShow: false,
          // MultiPointDropChargesList: [],
          // MultiPointDropChargesDataListID: [],
          // detentionChargesAtSource
          detentionChargesAtSourceModal: false,
          detentionChargesAtSourceListShow: false,
          detentionChargesAtSourceList: [],
          detentionChargesAtSourceDataListID: [],
          // detentionChargesAtDestination
          // detentionChargesAtDestinationModal: false,
          // detentionChargesAtDestinationListShow: false,
          // detentionChargesAtDestinationList: [],
          // detentionChargesAtDestinationDataListID: [],
          //lateDeliveryCharges
          // lateDeliveryChargesModal: false,
          // lateDeliveryChargesListShow: false,
          // lateDeliveryChargesList: [],
          // lateDeliveryChargesDataListID: [],
          //cancellationUponArrivalCompany
          cancellationUponArrivalCompanyModal: false,
          cancellationUponArrivalCompanyListShow: false,
          cancellationUponArrivalCompanyList: [],
          cancellationUponArrivalCompanyDataListID: [],
          //uploaded File
          signedURL: "",
          selectedFile: "",
          uploadData: "",
          fileFlag: false,
          fileUrl: "",
        };
        this.fileInput = React.createRef();
      }


      toggle_vehicleNonPlacementChargesModal = () => {
        this.setState({
          vehicleNonPlacementChargesModal:
            !this.state.vehicleNonPlacementChargesModal,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        });
      };
    
      toggle_vehicleNonPlacementChargesListShow = () => {
        if (this.state.vehicleNonPlacementChargesListShow === false)
          this.setState({ vehicleNonPlacementChargesListShow: true });
      };
    
      vehicleNonPlacementChargesData = (data, IDdata) => {
        // console.log("vehiclelist", data, IDdata);
        this.setState(
          {
            vehicleNonPlacementChargesList: data,
            vehicleNonPlacementChargesDataListID: IDdata,
          },
          () => {
            // console.log("UPDATED LIST", this.state.vehicleNonPlacementChargesList);
            // console.log(
            //   "UPDATED ID LIST",
            //   this.state.vehicleNonPlacementChargesDataListID
            // );
          }
        );
      };
    
      //MPPC
    
      // toggle_MultiPointPickChargesModal = () => {
      //   this.setState({
      //     MultiPointPickChargesModal: !this.state.MultiPointPickChargesModal,
      //   });
      // };
    
      // toggle_MultiPointPickChargesListShow = () => {
      //   if (this.state.MultiPointPickChargesListShow === false)
      //     this.setState({ MultiPointPickChargesListShow: true });
      // };
    
      // MultiPointPickChargesData = (data, IDdata) => {
      //   this.setState({
      //     MultiPointPickChargesList: data,
      //     MultiPointPickChargesDataListID: IDdata,
      //   });
      // };
    
      //MPDC
    
      // toggle_MultiPointDropChargesModal = () => {
      //   this.setState({
      //     MultiPointDropChargesModal: !this.state.MultiPointDropChargesModal,
      //   });
      // };
    
      // toggle_MultiPointDropChargesListShow = () => {
      //   if (this.state.MultiPointDropChargesListShow === false)
      //     this.setState({ MultiPointDropChargesListShow: true });
      // };
    
      // MultiPointDropChargesData = (data, IDdata) => {
      //   this.setState({
      //     MultiPointDropChargesList: data,
      //     MultiPointDropChargesDataListID: IDdata,
      //   });
      // };
    
      //DCS
    
      toggle_detentionChargesAtSourceModal = () => {
        this.setState({
          detentionChargesAtSourceModal: !this.state.detentionChargesAtSourceModal,
        });
      };
    
      toggle_detentionChargesAtSourceListShow = () => {
        if (this.state.detentionChargesAtSourceListShow === false)
          this.setState({ detentionChargesAtSourceListShow: true });
      };
    
      detentionChargesAtSourceData = (data, IDdata) => {
        this.setState({
          detentionChargesAtSourceList: data,
          detentionChargesAtSourceDataListID: IDdata,
        });
      };
    
      //DCD
      // toggle_detentionChargesAtDestinationModal = () => {
      //   this.setState({
      //     detentionChargesAtDestinationModal:
      //       !this.state.detentionChargesAtDestinationModal,
      //   });
      // };
    
      // toggle_detentionChargesAtDestinationListShow = () => {
      //   if (this.state.detentionChargesAtDestinationListShow === false)
      //     this.setState({ detentionChargesAtDestinationListShow: true });
      // };
    
      // detentionChargesAtDestinationData = (data, IDdata) => {
      //   this.setState({
      //     detentionChargesAtDestinationList: data,
      //     detentionChargesAtDestinationDataListID: IDdata,
      //   });
      // };
    
      //LDC
      // toggle_lateDeliveryChargesModal = () => {
      //   this.setState({
      //     lateDeliveryChargesModal: !this.state.lateDeliveryChargesModal,
      //   });
      // };
    
      // toggle_lateDeliveryChargesListShow = () => {
      //   if (this.state.lateDeliveryChargesListShow === false)
      //     this.setState({ lateDeliveryChargesListShow: true });
      // };
    
      // lateDeliveryChargesData = (data, IDdata) => {
      //   this.setState({
      //     lateDeliveryChargesList: data,
      //     lateDeliveryChargesDataListID: IDdata,
      //   });
      // };
    
      
      toggle_cancellationUponArrivalCompanyModal = () => {
        this.setState({
          cancellationUponArrivalCompanyModal:
            !this.state.cancellationUponArrivalCompanyModal,
        });
      };
    
      toggle_cancellationUponArrivalCompanyListShow = () => {
        if (this.state.cancellationUponArrivalCompanyListShow === false)
          this.setState({ cancellationUponArrivalCompanyListShow: true });
      };
    
      cancellationUponArrivalCompanyData = (data, IDdata) => {
        this.setState({
          cancellationUponArrivalCompanyList: data,
          cancellationUponArrivalCompanyDataListID: IDdata,
        });
      };



      handleSubmit = async () => {
        this.S3Upload();
        let Agreement = {
          agreement:
            this.props.agreementHistoryDetails?.page === "view details"
              ? this.props.billing.copiedAgreementValues.id
                ? this.props.billing.copiedAgreementValues.id
                : ""
              : "",
          transporter: this.props.transporter.id,
          startDate: this.props.startDate,
          expiryDate: this.props.endDate,
          softCopy: this.state.fileUrl,
          charges: {
            vehicleNonPlacement: this.state.vehicleNonPlacementChargesDataListID,
            sourceDetention: this.state.detentionChargesAtSourceDataListID,
            cancellationUponArrivalByCompany:
              this.state.cancellationUponArrivalCompanyDataListID,
          },
        };
       // this.props.storeAgreement(Agreement);
        this.props.createAgreement(Agreement, (res) => {
          if (
            res["Success"] === "true" ||
            res["message"] === "Agreement has been saved/updated successfully"
          ) {
            NotificationManager.success("Agreement has been saved successfully", "Success");
            this.props.fetchSecondaryAgreements({}, (res) => {
            this.props.history.push("/SecondaryBillingIndex");
            });
          } else {
            if (res["message"] === "Invalid Request") {
              res?.data &&
                res.data.forEach((element) => {
                  let textError = (
                    <p style={{ textTransform: "capitalize" }}>
                      {element?.constraints?.isNotEmpty}
                    </p>
                  );
                  NotificationManager.error(
                    textError ? textError : "error",
                    "Error"
                  );
                });
            } else {
              NotificationManager.error(res["message"], "Error");
            }
          }
        });
       
      };
    
      fileUploadTrigger = () => {
        // console.log(this.fileInput);
        this.fileInput.current.click();
      };
    
      S3Upload = () => {
        let softCopy = "";
        // console.log("----UPLOAD----", this.state.selectedFile.name);
        // let fileType;
        //   if(this.state.selectedFile.name.includes("pdf")){
        //     fileType = "pdf"
        //   }
    
        this.props.fetchSignedURL({ fileType: "pdf" }, (res) => {
          // console.log("signedURL", res.presignedS3Url, typeof res.presignedS3Url);
          softCopy = res.presignedS3Url.split("?")[0];
          this.setState({ fileUrl: softCopy }, () => {
            // console.log(this.state.fileUrl);
          });
          // console.log("-------------", softCopy);
          const formData = new FormData();
          formData.append(
            "Agreement",
            this.state.selectedFile,
            this.state.selectedFile.name
          );
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", this.state.selectedFile.type);
          this.props.uploadAgreement(res.presignedS3Url, formData, myHeaders);
        });
      };
    
      fileUpload = (event) => {
        // console.log(event.target.files, "op");
        this.setState(
          { selectedFile: event.target.files[0], fileFlag: true },
          () => {
            this.S3Upload();
          }
        );
      };
    
      handlePdf = () => {
        //console.log(AggreementPdf)
        this.setState({ showpdf: !this.state.showpdf });
      };


      render(){


      
        userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
        let mode = localStorage.getItem("Theme")
          ? localStorage.getItem("Theme")
          : null;
        var darkMode = mode === "dark__mode" ? true : false;
        const {
          vehicleNonPlacementChargesList,
          //MultiPointPickChargesList,
          //MultiPointDropChargesList,
          detentionChargesAtSourceList,
          //detentionChargesAtDestinationList,
          cancellationUponArrivalCompanyList,
        } = this.state;
    
        vehicleNonPlacementChargesData = {
          columns: NonVehiclePlacementChargesHeaders,
          rows: this.state.vehicleNonPlacementChargesList,
        };
        // MultiPointPickChargesData = {
        //   columns: MultiPointPickChargesHeaders,
        //   rows: this.state.MultiPointPickChargesList,
        // };
        // MultiPointDropChargesData = {
        //   columns: MultiPointPickChargesHeaders,
        //   rows: this.state.MultiPointDropChargesList,
        // };
        detentionChargesAtSourceData = {
          columns: DetentionChargesAtSourceHeaders,
          rows: this.state.detentionChargesAtSourceList,
        };
        // detentionChargesAtDestinationData = {
        //   columns: DetentionChargesAtSourceHeaders,
        //   rows: this.state.detentionChargesAtDestinationList,
        // };
        // lateDeliveryChargesData = {
        //   columns: DetentionChargesAtSourceHeaders,
        //   rows: this.state.lateDeliveryChargesList,
        // };
        cancellationUponArrivalCompanyData = {
          columns: NonVehiclePlacementChargesHeaders,
          rows: this.state.cancellationUponArrivalCompanyList,
        };

        return (

            <React.Fragment>
            {getLocalStorage()}
            
            <SecondaryAgreementPdf
              SDate={this.props.STARTDATE}
              EDate={this.props.EXPIRYDATE}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              valid={this.valid}
              validExpiry={this.validExpiry}
              isOpen={this.state.showpdf}
              closePdf={this.handlePdf}
              transporter={this.props.transporter}
              vehicleNonPlacementChargesData={vehicleNonPlacementChargesData}
             
              detentionChargesAtSourceData={detentionChargesAtSourceData}
            
              cancellationUponArrivalCompanyData={
                cancellationUponArrivalCompanyData
              }
            />
            <NonVehiclePlacementCharges
              valid={this.props.valid}
              validExpiry={this.props.validExpiry}
              startDate={this.props.billing.startDate.Date}
              endDate={this.props.billing.endDate.Date}
              modal={this.state.vehicleNonPlacementChargesModal}
              toggle={this.toggle_vehicleNonPlacementChargesModal}
              listFiller={this.vehicleNonPlacementChargesData}
              listShow={this.toggle_vehicleNonPlacementChargesListShow}
              mode={darkMode}
            />
            {/* <MultiPointPickCharges
              valid={this.props.valid}
              validExpiry={this.props.validExpiry}
              startDate={this.props.billing.startDate.Date}
              endDate={this.props.billing.endDate.Date}
              modal={this.state.MultiPointPickChargesModal}
              toggle={this.toggle_MultiPointPickChargesModal}
              listFiller={this.MultiPointPickChargesData}
              listShow={this.toggle_MultiPointPickChargesListShow}
              mode={darkMode}
            /> */}
            {/* <MultiPointDropCharges
              valid={this.props.valid}
              validExpiry={this.props.validExpiry}
              startDate={this.props.billing.startDate.Date}
              endDate={this.props.billing.endDate.Date}
              modal={this.state.MultiPointDropChargesModal}
              toggle={this.toggle_MultiPointDropChargesModal}
              listFiller={this.MultiPointDropChargesData}
              listShow={this.toggle_MultiPointDropChargesListShow}
              mode={darkMode}
            /> */}
            <DetentionChargesAtSource
              valid={this.props.valid}
              validExpiry={this.props.validExpiry}
              startDate={this.props.billing.startDate.Date}
              endDate={this.props.billing.endDate.Date}
              modal={this.state.detentionChargesAtSourceModal}
              toggle={this.toggle_detentionChargesAtSourceModal}
              listFiller={this.detentionChargesAtSourceData}
              listShow={this.toggle_detentionChargesAtSourceListShow}
              mode={darkMode}
            />
            {/* <DetentionChargesAtDestination
              valid={this.props.valid}
              validExpiry={this.props.validExpiry}
              startDate={this.props.billing.startDate.Date}
              endDate={this.props.billing.endDate.Date}
              modal={this.state.detentionChargesAtDestinationModal}
              toggle={this.toggle_detentionChargesAtDestinationModal}
              listFiller={this.detentionChargesAtDestinationData}
              listShow={this.toggle_detentionChargesAtDestinationListShow}
              mode={darkMode}
            /> */}
            {/* <LateDeliveryCharges
              valid={this.props.valid}
              validExpiry={this.props.validExpiry}
              startDate={this.props.billing.startDate.Date}
              endDate={this.props.billing.endDate.Date}
              modal={this.state.lateDeliveryChargesModal}
              toggle={this.toggle_lateDeliveryChargesModal}
              listFiller={this.lateDeliveryChargesData}
              listShow={this.toggle_lateDeliveryChargesListShow}
              mode={darkMode}
            /> */}
            <CancellationUponArrivalCompany
              valid={this.props.valid}
              validExpiry={this.props.validExpiry}
              startDate={this.props.billing.startDate.Date}
              endDate={this.props.billing.endDate.Date}
              modal={this.state.cancellationUponArrivalCompanyModal}
              toggle={this.toggle_cancellationUponArrivalCompanyModal}
              listFiller={this.cancellationUponArrivalCompanyData}
              listShow={this.toggle_cancellationUponArrivalCompanyListShow}
              mode={darkMode}
            />
            <div>
              <Row>
                <Col xs={12}>
                  <Card className="addAgreementCard darkMultipleCharges">
                    <Row>
                      <Col xs={10}>
                        <span className="fontRoboto15 span-names-agreement light__span">
                          {userLang?.invoice?.VEHICLE_NON_PLACEMENT_CHARGES}
                        </span>
                      </Col>
                      <Col xs={2}>
                        <div className="tooltipIcon float-right pr-4 pt-2">
                          <Button
                            className="iconButtons indentExpiry"
                            onClick={this.toggle_vehicleNonPlacementChargesModal}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? plusDarkIcon : plusIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "115px", left: "-8px" }}
                          >
                            Add Charge
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.vehicleNonPlacementChargesListShow ? (
                  <>
                    <Col
                      xs={12}
                      className={
                        this.state.vehicleNonPlacementChargesList?.length > 10
                          ? "DataTableSub"
                          : ""
                      }
                    >
                      <Card
                        style={{
                          height:
                            this.state.vehicleNonPlacementChargesList?.length > 10
                              ? "700px"
                              : "auto",
                        }}
                      >
                        <Index
                          headers={NonVehiclePlacementChargesHeaders}
                          data={this.state.vehicleNonPlacementChargesList}
                          searchBar={searchBar}
                          rowHeight={"56px"}
                          limit={this.state.vehicleNonPlacementChargesList?.length}
                          fetchId={this.props.fetchId}
                        />
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row>
              {/* <Row>
                <Col xs={12}>
                  <Card className="addAgreementCard darkMultipleCharges">
                    <Row>
                      <Col xs={10}>
                        <span className="fontRoboto15 span-names-agreement pt-3 light__span">
                          {userLang?.invoice?.MULTI_POINT_PICK_CHARGES}
                        </span>
                      </Col>
                      <Col xs={2}>
                        <div className="tooltipIcon float-right pr-4 pt-2">
                          <Button
                            className="iconButtons indentExpiry"
                            onClick={this.toggle_MultiPointPickChargesModal}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? plusDarkIcon : plusIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "115px", left: "-8px" }}
                          >
                            Add charge
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.MultiPointPickChargesListShow ? (
                  <>
                    <Col
                      xs={12}
                      className={
                        this.state.MultiPointPickChargesList?.length > 10
                          ? "DataTableSub"
                          : ""
                      }
                    >
                      <Card
                        style={{
                          height:
                            this.state.MultiPointPickChargesList?.length > 10
                              ? "700px"
                              : "auto",
                        }}
                      >
                        <Index
                          headers={MultiPointPickChargesHeaders}
                          data={this.state.MultiPointPickChargesList}
                          searchBar={searchBar}
                          rowHeight={"56px"}
                          limit={this.state.MultiPointPickChargesList?.length}
                          fetchId={this.props.fetchId}
                        />
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row> */}
              {/* <Row>
                <Col xs={12}>
                  <Card className="addAgreementCard darkMultipleCharges">
                    <Row>
                      <Col xs={10}>
                        <span className="fontRoboto15 span-names-agreement pt-3 light__span">
                          {userLang?.invoice?.MULT_POINT_DROP_CHARGES}
                        </span>
                      </Col>
                      <Col xs={2}>
                        <div className="tooltipIcon float-right pr-4 pt-2">
                          <Button
                            className="iconButtons indentExpiry"
                            onClick={this.toggle_MultiPointDropChargesModal}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? plusDarkIcon : plusIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "115px", left: "-8px" }}
                          >
                            Add charge
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.MultiPointDropChargesListShow ? (
                  <>
                    <Col
                      xs={12}
                      className={
                        this.state.MultiPointDropChargesList?.length > 10
                          ? "DataTableSub"
                          : ""
                      }
                    >
                      <Card
                        style={{
                          height:
                            this.state.MultiPointDropChargesList?.length > 10
                              ? "700px"
                              : "auto",
                        }}
                      >
                        <Index
                          headers={MultiPointPickChargesHeaders}
                          data={this.state.MultiPointDropChargesList}
                          searchBar={searchBar}
                          rowHeight={"56px"}
                          limit={this.state.MultiPointDropChargesList?.length}
                          fetchId={this.props.fetchId}
                        />
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row> */}
              <Row>
                <Col xs={12}>
                  <Card className="addAgreementCard darkMultipleCharges">
                    <Row>
                      <Col xs={10}>
                        <span className="fontRoboto15 span-names-agreement pt-3 light__span">
                          {userLang?.invoice?.DETENTION_CHARGES_AT_SOURCE}
                        </span>
                      </Col>
                      <Col xs={2}>
                        <div className="tooltipIcon float-right pr-4 pt-2">
                          <Button
                            className="iconButtons indentExpiry"
                            onClick={this.toggle_detentionChargesAtSourceModal}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? plusDarkIcon : plusIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "115px", left: "-8px" }}
                          >
                            Add charge
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
    
                {this.state.detentionChargesAtSourceListShow ? (
                  <>
                    <Col
                      xs={12}
                      className={
                        this.state.detentionChargesAtSourceList?.length > 10
                          ? "DataTableSub"
                          : ""
                      }
                    >
                      <Card
                        style={{
                          height:
                            this.state.detentionChargesAtSourceList?.length > 10
                              ? "700px"
                              : "auto",
                        }}
                      >
                        <Index
                          headers={DetentionChargesAtSourceHeaders}
                          data={this.state.detentionChargesAtSourceList}
                          searchBar={searchBar}
                          rowHeight={"56px"}
                          limit={this.state.detentionChargesAtSourceList?.length}
                          fetchId={this.props.fetchId}
                        />
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row>
              {/* <Row>
                <Col xs={12}>
                  <Card className="addAgreementCard darkMultipleCharges">
                    <Row>
                      <Col xs={10}>
                        <span className="fontRoboto15 span-names-agreement pt-3 light__span">
                          {userLang?.invoice?.DETENTION_CHARGES_AT_DESTINATON}
                        </span>
                      </Col>
                      <Col xs={2}>
                        <div className="tooltipIcon float-right pr-4 pt-2">
                          <Button
                            className="iconButtons indentExpiry"
                            onClick={this.toggle_detentionChargesAtDestinationModal}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? plusDarkIcon : plusIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "115px", left: "-8px" }}
                          >
                            Add charge
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.detentionChargesAtDestinationListShow ? (
                  <>
                    <Col
                      xs={12}
                      className={
                        this.state.detentionChargesAtDestinationList?.length > 10
                          ? "DataTableSub"
                          : ""
                      }
                    >
                      <Card
                        style={{
                          height:
                            this.state.detentionChargesAtDestinationList?.length >
                            10
                              ? "700px"
                              : "auto",
                        }}
                      >
                        <Index
                          headers={DetentionChargesAtSourceHeaders}
                          data={this.state.detentionChargesAtDestinationList}
                          searchBar={searchBar}
                          rowHeight={"56px"}
                          limit={
                            this.state.detentionChargesAtDestinationList?.length
                          }
                          fetchId={this.props.fetchId}
                        />
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row> */}
              {/* <Row>
                <Col xs={12}>
                  <Card className="addAgreementCard darkMultipleCharges">
                    <Row>
                      <Col xs={10}>
                        <span className="fontRoboto15 span-names-agreement pt-3 light__span">
                          {userLang?.invoice?.LATE_DELIVERY_CHARGES}
                        </span>
                      </Col>
                      <Col xs={2}>
                        <div className="tooltipIcon float-right pr-4 pt-2">
                          <Button
                            className="iconButtons indentExpiry"
                            onClick={this.toggle_lateDeliveryChargesModal}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? plusDarkIcon : plusIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "115px", left: "-8px" }}
                          >
                            Add charge
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.lateDeliveryChargesListShow ? (
                  <>
                    <Col
                      xs={12}
                      className={
                        this.state.lateDeliveryChargesList?.length > 10
                          ? "DataTableSub"
                          : ""
                      }
                    >
                      <Card
                        style={{
                          height:
                            this.state.lateDeliveryChargesList?.length > 10
                              ? "700px"
                              : "auto",
                        }}
                      >
                        <Index
                          headers={DetentionChargesAtSourceHeaders}
                          data={this.state.lateDeliveryChargesList}
                          searchBar={searchBar}
                          rowHeight={"56px"}
                          limit={this.state.lateDeliveryChargesList?.length}
                          fetchId={this.props.fetchId}
                        />
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row> */}
              <Row>
                <Col xs={12}>
                  <Card className="addAgreementCard darkMultipleCharges">
                    <Row>
                      <Col xs={10}>
                        <span className="fontRoboto15 span-names-agreement light__span">
                          {userLang?.invoice?.CANCELLATION_UPON_ARRIVAL_COMPANY}
                        </span>
                      </Col>
                      <Col xs={2}>
                        <div className="tooltipIcon float-right pr-4 pt-2">
                          <Button
                            className="iconButtons indentExpiry"
                            onClick={
                              this.toggle_cancellationUponArrivalCompanyModal
                            }
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? plusDarkIcon : plusIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "115px", left: "-8px" }}
                          >
                            Add charge
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.cancellationUponArrivalCompanyListShow ? (
                  <>
                    <Col
                      xs={12}
                      className={
                        this.state.cancellationUponArrivalCompanyList?.length > 10
                          ? "DataTableSub"
                          : ""
                      }
                    >
                      <Card
                        style={{
                          height:
                            this.state.cancellationUponArrivalCompanyList?.length >
                            10
                              ? "700px"
                              : "auto",
                        }}
                      >
                        <Index
                          headers={NonVehiclePlacementChargesHeaders}
                          data={this.state.cancellationUponArrivalCompanyList}
                          searchBar={searchBar}
                          rowHeight={"56px"}
                          limit={
                            this.state.cancellationUponArrivalCompanyList?.length
                          }
                          fetchId={this.props.fetchId}
                        />
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row
                className="float-bottom"
                style={{
                  paddingTop: "335px",
                }}
              >
                <Col xs={12}>
                  <Card className="addAgreementCardBottom indentExpiry">
                    <Row
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Col xs={4} md={9}>
                        <div className="d-flex ml-4">
                          <div className="m-2">
                            <Button
                              color="primary"
                              className="darkAgreementAddToList button-wide buttonClrS "
                              onClick={this.handleSubmit}
                              disabled={
                                vehicleNonPlacementChargesList.length &&
                               // MultiPointPickChargesList.length &&
                               // MultiPointDropChargesList.length &&
                                detentionChargesAtSourceList.length &&
                                //detentionChargesAtDestinationList.length &&
                                cancellationUponArrivalCompanyList.length
                                  ? false
                                  : true
                              }
                            >
                              {userLang?.common?.SUBMIT}
                            </Button>
                          </div>
                          <div
                            className="m-2"
                            style={{
                              width: "85px",
                            }}
                          >
                            <Button
                              type="button"
                              // color="secondary"
                              className="small align-middle mb-1 rounded-black  buttomClr"
                              onClick={this.handlePdf}
                              disabled={
                                vehicleNonPlacementChargesList.length &&
                              //  MultiPointPickChargesList.length &&
                              //  MultiPointDropChargesList.length &&
                                detentionChargesAtSourceList.length &&
                              //  detentionChargesAtDestinationList.length &&
                                cancellationUponArrivalCompanyList.length
                                  ? false
                                  : true
                              }
                            >
                              {userLang?.invoice?.PRINT}
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} md={2}>
                        <div className="float-right">
                          {" "}
                          {this.state.fileFlag ? (
                            <div className="container" style={{ display: "flex" }}>
                              <div style={{ width: "90%" }}>
                                <i className="fas fa-file-alt fa-2x m-1"></i>
                                {this.state.selectedFile.name}
                              </div>
                              <div style={{ flexGrow: "1" }}>
                                <i
                                  className="fas fa-times"
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() =>
                                    this.setState(
                                      {
                                        fileFlag: false,
                                        signedURL: "",
                                        selectedFile: "",
                                        uploadData: "",
                                      },
                                      () => {
                                        this.fileInput.current.value = "";
                                      }
                                    )
                                  }
                                ></i>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={2} md={1}>
                        <div className="d-flex justify-content-end">
                          <div className="">
                            <FormGroup className="select2-container required mt-1 float-right">
                              <input
                                type="file"
                                name="file"
                                accept=".pdf"
                                style={{ display: "none" }}
                                ref={this.fileInput}
                                onChange={this.fileUpload}
                              />
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "#5664D2",
                                }}
                                // onClick={this.fileUploadTrigger}
                              >
                                <div className="tooltipIcon float-right pr-4 pt-2">
                                  <Button
                                    className="iconButtons darkCalenderCharges"
                                    onClick={this.fileUploadTrigger}
                                  >
                                    <img
                                      style={{ margin: "-4px" }}
                                      src={attachmentIcon}
                                      alt="exp"
                                    />
                                  </Button>
                                  <span
                                    className="tooltipIcontext"
                                    style={{ width: "115px", left: "-8px" }}
                                  >
                                    attachment Icon
                                  </span>
                                </div>
                              </span>
                            </FormGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </React.Fragment>

        )
      }

}


const mapStateToProps = (state) => ({
    fetchId: state.SecondaryBilling.SecondaryBilling.fetchId,
    billing: state.SecondaryBilling.SecondaryBilling,
  });
  
  export default connect(mapStateToProps, {
    storeAgreement,
    createAgreement,
    fetchSecondaryAgreements,
    fetchSignedURL,
    uploadAgreement,
  })(withRouter(AddAgreementIndexSubScreen));