import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Button } from "reactstrap";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getLocalStorage } from "../RowHeaders/RowHeaders";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import ExportButton from "./ExportButton";
import { formattingData, headersData, headerBodyData } from "./ExcelHeader";
import DynamicTable from "../../../components/Common/DynamicTable";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
let finalComments = [];
var date = moment(new Date()).format("DD-MMM-YYYY");


class InvoicePdf extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      transporterName: "",
      invoiceId: "",
      date: "",
      gst: "",
      raiseDate: "",
      state: "",
      billCount: "",
      comment: "",
    };
  }


  pdfDownload = () => {
    // let pdf =new jsPDF('p', 'pt', 'letter');
    // pdf.setFontSize(8);
    // setTimeout(function(quality = 1){
    //   pdf.html(document.getElementById("printagreement"),{pagesplit:true,margins:[10,10,10,10],callback:function  (pdf) {
    //       ////console.log(document.getElementById("printagreement"))
    //     // pdf.save()
    //     window.open(pdf.output('bloburl'))

    //   }});
    setTimeout(function () {
      let quotes = document.getElementById("printinvoice");
      html2canvas(quotes).then((canvas) => {
        //! MAKE YOUR PDF
        let pdf = new jsPDF("l", "pt", "letter", true);
        //     let pdf= new jsPDF({
        //       orientation: 'l', // landscape
        //       unit: 'pt', // points, pixels won't work properly
        // })

        for (let i = 0; i <= quotes.clientHeight / 1210; i++) {
          //! This is all just html2canvas stuff
          let srcImg = canvas;
          let sX = 0;
          let sY = 1210 * i; // start 980 pixels down for every new page
          let sWidth = 1800;
          let sHeight = 1210;
          let dX = 0;
          let dY = 0;
          let dWidth = 1800;
          let dHeight = 1210;
          window.onePageCanvas = document.createElement("canvas");
          window.onePageCanvas.setAttribute("width", 1800);
          window.onePageCanvas.setAttribute("height", 1210);
          let ctx = window.onePageCanvas.getContext("2d");
          // details on this usage of this function:
          // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
          ctx.drawImage(
            srcImg,
            sX,
            sY,
            sWidth,
            sHeight,
            dX,
            dY,
            dWidth,
            dHeight
          );

          // document.body.appendChild(canvas);
          let canvasDataURL = window.onePageCanvas.toDataURL("image/png", 1.0);

          let width = window.onePageCanvas.width;
          let height = window.onePageCanvas.clientHeight;

          //! If we're on anything other than the first page,
          // add another page
          if (i > 0) {
            pdf.addPage(); //8.5" x 11" in pts (in*72)
          }
          //! now we declare that we're working on that page
          pdf.setPage(i + 1);
          //! now we add content to that page!
          pdf.addImage(
            canvasDataURL,
            "PNG",
            10,
            10,
            width * 0.42,
            height * 0.5
          );
        }

        //  pdf.save('agreement.pdf')
        //  window.open(pdf.output('bloburl'))

        pdf.output("save", "invoice.pdf"); //Try to save PDF as a file (not works on ie before 10, and some mobile devices)
        pdf.output("datauristring");
        pdf.output("dataurlnewwindow");
      });
    }, 2000);
  };

  componentDidUpdate = () => {
   
    if (this.state.id === "" || this.state.id !== this.props.invoice?._id)
      this.setState({
        id: this.props.invoice?._id,
        transporterName: this.props.invoice?.transporter?.name,
        invoiceId: this.props.invoice?.invoiceId,
        date: moment(this.props.invoice?.createdAt).format("DD-MM-YYYY"),
        gst: this.props.invoice?.gstNo,
        raiseDate: moment(this.props.invoice?.fromDate).format("DD-MM-YYYY"),
        state: this.props.invoice?.state?.stateCode,
        billCount: this.props.invoice?.bills?.length,
        comment: this.props.invoice?.comment,
      });
  };

  headingPopulator = () => {
    let head = [
      {
        "Transporter Name": this.state.transporterName,
        "Supplier Tax Invoice No":
          this.state.gst + " ,dated." + this.state.raiseDate,
        "TMS Invoice No": this.state.invoiceId + " ,dated." + this.state.date,
        State: this.state.state,
        "No Of Bills": this.state.billCount,
        Comment: this.state.comment,
      },
    ];
    return head;
  };

  exportToCSV = (csvData, fileName) => {
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
        if (key === "type") {
          upper = "Bill Type";
        }
        if (key === "source") {
          upper = "Source";
        }
        if (key === "destination") {
          upper = "Destination";
        }
        if (key === "lrNumber") {
          upper = "LR NUMBER";
        }
        if (key === "vehicleNo") {
          upper = "Vehicle No";
        }
        if (key === "pfNo") {
          upper = "Pf No./Indent Id";
        }
        if (key === "createdAt") {
          upper = "Created At";
        }
        if (key === "freightAmount") {
          upper = "Freight Amount";
        }
        if (key === "diff") {
          upper = "Diff";
        }
        if (key === "asPerOracle") {
          upper = "As Per Oracle";
        }
        if (key === "loadingCharge") {
          upper = "Loading Charge";
        }
        if (key === "UnLoadingCharges") {
          upper = "UnLoading Charge";
        }
        if (key === "damageCharges") {
          upper = "Damage Charges";
        }
        if (key === "revisedDamageCharges") {
          upper = "Revised Damage Charges";
        }
        if (key === "shortageCharges") {
          upper = "Shortage Charges";
        }
        if (key === "sourceDetentionCharges") {
          upper = "LR Number";
        }
        if (key === "destinationDetentionCharges") {
          upper = "Destination Detention Charges";
        }
        if (key === "lateDeliveryCharges") {
          upper = "Late Delivery Charges";
        }
        if (key === "multipointPickupCharges") {
          upper = "Multipoint Pick Charges";
        }
        if (key === "multipointDropCharges") {
          upper = "Multipoint Drop Charges";
        }
        if (key === "revisedOtherDeductionsCharges") {
          upper = "Other Add/Ded";
        }
        if (key === "cancellationUponArrivalByCompanyCharges") {
          upper = "Cancellation Upon Arrival";
        }
        if (key === "indentRejectionCharges") {
          upper = "Indent Rejection Charges";
        }
        if (key === "netCharges") {
          upper = "Net Charges";
        }
        if (key === "netDeduction") {
          upper = "Net Deductions";
        }
        if (key === "total") {
          upper = "Total";
        }
        if (upper !== key) {
          item[upper] = item[key];
          delete item[key];
        }
      }
      return item;
    });
    // console.log(csvData, "data log test");
    let headingData = this.headingPopulator();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const ws1 = XLSX.utils.json_to_sheet(headingData);
    // const wb = XLSX.utils.book_new();
    var tbl = document.getElementById("pdfTable");
    const wb = XLSX.utils.table_to_book(tbl);
    XLSX.writeFile(wb, "HTMLTable.xlsx");
    // XLSX.utils.book_append_sheet(wb, ws1, "Invoice Details");
    // XLSX.utils.book_append_sheet(wb, ws, "Invoice Calculations");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };
  typeConvert = (type) => {
    switch (type) {
      case "unknown":
        return "Unknown";
      case "indent_rejection":
        return "Indent Rejection";

      case "vehicle_non_placement":
        return "Vehicle Non-Placement";
      case "canacelation_on_arrival":
        return "Cancellation Upon Arrival";
      case "freight":
        return "Freight Cost";
      case "new_by_old":
        return "New By Old";

      default:
        break;
    }
  };
  lrNo = (shipMent) => {
    let lr = shipMent?.map((s) => {
      return s.lrNo;
    });
    return lr?.join(",");
  };
  shipId = (shipMent) => {
    let shipId = shipMent?.map((s) => {
      return s.shipmentId;
    });
    return shipId?.join(",");
  };

  render() {


    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    var invoiceHeaders = [
      {
        label: <b>{userLang?.invoice?.BILL_TYPE}</b>,
        field: "type",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.TMS_TRIP_ID}</b>,
        field: "tripID",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.common?.SOURCE}</b>,
        field: "source",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.common?.DESTINATION} </b>,
        field: "destination",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.common?.ROUTE} </b>,
        field: "route",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.LR_NUMBER}</b>,
        field: "lrNumber",
        sort: "disabled",
        width: 220,
      },

      {
        label: <b>{userLang?.controlTower?.VEHICLE_NO}</b>,
        field: "vehicleNo",
        sort: "disabled",
        width: 220,
      },
      {
        label: (
          <b>
            {userLang?.invoice?.PF_NO}/{userLang?.planning?.INDENT_ID}
          </b>
        ),
        field: "pfNo",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.CREATED_AT}</b>,
        field: "createdAt",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.FREIGHT_AMOUNT}</b>,
        field: "freightAmount",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.LOADING_CHARGES}</b>,
        field: "loadingCharge",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.UNLOADING_CHARGES}</b>,
        field: "UnLoadingCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.DAMAGE_CHARGES}</b>,
        field: "damageCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.REVISED_DAMAGE_CHARGES}</b>,
        field: "revisedDamageCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.SHORTAGE_CHARGES}</b>,
        field: "shortageCharges",
        sort: "disabled",
        width: 320,
      },
      {
        label: <b>{userLang?.invoice?.SOURCE_DETENTION_CHARGES}</b>,
        field: "sourceDetentionCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.DESTINATION_DETENTION_CHARGES}</b>,
        field: "destinationDetentionCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.LATE_DELIVERY_CHARGES}</b>,
        field: "lateDeliveryCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.MULTI_POINT_PICK_CHARGES}</b>,
        field: "multipointPickupCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.MULT_POINT_DROP_CHARGES}</b>,
        field: "multipointDropCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.OTHER_ADD_DED}</b>,
        field: "revisedOtherDeductionsCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.CANCELLATION_UPON_ARRIVAL} </b>,
        field: "cancellationUponArrivalByCompanyCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.INDENT_REJECTION_CAHRGES} </b>,
        field: "indentRejectionCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.NET_CHARGES} </b>,
        field: "netCharges",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.invoice?.NET_DEDUCTIONS} </b>,
        field: "netDeduction",
        sort: "disabled",
        width: 220,
      },
      {
        label: <b>{userLang?.dashboard?.TOTAL}</b>,
        field: "total",
        sort: "disabled",
        width: 220,
      },
    ];

    let transporter = this.props.invoice?.transporter?.name;
    const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role?.label || "";
    
    if (role !== "transporter") {
      if (invoiceHeaders.length === 23 && invoiceHeaders.length < 26) {
        invoiceHeaders.splice(
          7,
          0,

          {
            label: <b>{userLang?.invoice?.AS_PER_ORACLE}</b>,
            field: "asPerOracle",
            sort: "disabled",
            width: 220,
          },
          {
            label: <b>{userLang?.invoice?.DIFF}</b>,
            field: "diff",
            sort: "disabled",
            width: 220,
          }
        );
      }
    }

    let finalRowGst = {
      netDeduction: "Gst(12%",
      total: this.props.invoice?.calculations?.netPayble
        ? this.props.invoice?.calculations.netPayble * 0.12
        : 0,
    };
    let finalTotal = {
      netDeduction: "Final Total",
      total: Math.round(
        (this.props.invoice?.calculations?.netPayble
          ? this.props.invoice?.calculations.netPayble
          : 0) +
          (this.props.invoice?.calculations?.netPayble
            ? this.props.invoice?.calculations.netPayble * 0.12
            : 0),
        2
      ),
    };
    let data = {
      columns: invoiceHeaders,
      rows: this.props.invoice?.bills
        ? this.props.invoice?.bills.map((e) => {
            let shortageComments = [];
            let damagedComments = [];
            if (Array.isArray(e.epods)) {
              e.epods.map((eleEpod) => {
                if (Array.isArray(eleEpod.shipments)) {
                  eleEpod.shipments.map((eleShip) => {
                    if (Array.isArray(eleShip.items)) {
                      eleShip.items.map((eleItems) => {
                        if (eleItems?.epod?.shortageComment) {
                          shortageComments.push({
                            shipmentId: eleShip?.shipmentId,
                            id: eleItems?.itemId,
                            comment: eleItems?.epod?.shortageComment,
                          });
                        }
                        if (eleItems?.epod?.damageComment) {
                          damagedComments.push({
                            shipmentId: eleShip?.shipmentId,
                            id: eleItems?.itemId,
                            comment: eleItems?.epod?.damageComment,
                          });
                        }
                        // }
                      });
                    }
                  });
                }
              });
            }
            finalComments = [...shortageComments, ...damagedComments];
            let dispatchedDate = null;
            if (e.transitionLogs) {
              e.transitionLogs.map((eLog) => {
                if (
                  eLog?.curStatus === "loading_completed" &&
                  eLog?.hop === e?.trip?.source?._id
                ) {
                  dispatchedDate = moment(new Date(e?.trip?.createdAt)).format(
                    "DD-MMM-YYYY"
                  );
                  // console.log("createdAt", dispatchedDate);
                }
              });
            }

            let obj = {};
            if (role === "transporter") {
              obj = {
                tripID: e?.trip?.tripId ? e?.trip?.tripId : "",
                route: e?.routeDetails[0]?.name ? e?.routeDetails[0]?.name : "",
                dispatchedDate: dispatchedDate,
                asPerOracleAmount: e?.freightDetails
                  ? e?.freightDetails[0]?.freightDetails?.cost?.main
                  : 0,
                type: this.typeConvert(e.type),
                source: e.trip?.source?.label,
                destination: e.trip?.destination?.label,
                lrNumber:
                  e.type === "freight" && e.shipments !== undefined
                    ? this.lrNo(e.shipments)
                    : "",
                vehicleNo: e.type === "freight" ? e.vehicle?.regNo : "",
                truckType: e.vehicle?.type?.label
                  ? e.vehicle?.type?.label
                  : "NA",
                pfNo:
                  e.type === "freight"
                    ? this.shipId(e.shipments)
                    : e.type === "indent_rejection"
                    ? e.indentId
                    : "",
                createdAt: e.createdAt
                  ? moment(new Date(e.createdAt)).format("DD-MMM-YYYY")
                  : "",
                freightAmount: e?.revisedChargesStatus
                  ?.isFreightCostChargesRevised
                  ? e.calculations?.revisedFreightCostCharges
                  : e.calculations?.freightCharges,
                billNumber: e.billId,
                // type:e.type?e.type.split('_').join(" "):'',
                loadingCharge: e?.revisedChargesStatus?.isLoadingChargesRevised
                  ? e.calculations?.revisedLoadingCharges
                  : e.calculations?.loadingCharges,
                UnLoadingCharges: e?.revisedChargesStatus
                  ?.isUnloadingChargesRevised
                  ? e.calculations?.revisedUnloadingCharges || 0
                  : e.calculations?.unloadingCharges,
                damageCharges: e?.revisedChargesStatus?.isDamageChargesRevised
                  ? e.calculations?.revisedDamageCharges
                  : e.calculations?.damageCharges,
                revisedDamageCharges: e.calculations?.revisedDamageCharges
                  ? -e.calculations.revisedDamageCharges
                  : 0,
                shortageCharges: e?.revisedChargesStatus
                  ?.isShortageChargesRevised
                  ? e.calculations?.revisedShortageCharges
                  : e.calculations?.shortageCharges,
                sourceDetentionCharges: e?.revisedChargesStatus
                  ?.isSourceDetentionChargesRevised
                  ? e.calculations?.revisedSourceDetentionCharges
                  : e.calculations?.sourceDetentionCharges,
                destinationDetentionCharges: e?.revisedChargesStatus
                  ?.isDestinationDetentionChargesRevised
                  ? e.calculations?.revisedDestinationDetentionCharges
                  : e.calculations.destinationDetentionCharges,
                lateDeliveryCharges: e?.revisedChargesStatus
                  ?.isLateDeliveryChargeChargesRevised
                  ? e.calculations?.revisedLateDeliveryChargeCharges
                  : e.calculations.lateDeliveryCharges,
                multipointPickupCharges: e?.revisedChargesStatus
                  ?.isMultipointPickupChargesRevised
                  ? e.calculations?.revisedMultipointPickupCharges
                  : e.calculations.multipointPickupCharges,
                multipointDropCharges: e?.revisedChargesStatus
                  ?.isMultipointDropChargesRevised
                  ? e.calculations?.revisedMultipointDropCharges || 0
                  : e.calculations.multipointDropCharge || 0,
                revisedOtherDeductionsCharges: e.calculations
                  ?.revisedOtherDeductionsCharges
                  ? e.calculations.revisedOtherDeductionsCharges
                  : 0,
                cancellationUponArrivalByCompanyCharges: e?.revisedChargesStatus
                  ?.isCancellationUponArrivalChargesRevised
                  ? e.calculations
                      ?.revisedcancellationUponArrivalByCompanyCharges
                  : e.calculations.cancellationUponArrivalByCompanyCharges,
                indentRejectionCharges: e?.revisedChargesStatus
                  ?.isIndentRejectionChargesRevised
                  ? e.calculations?.revisedIndentRejectionCharges
                  : e.calculations.indentRejectionCharges,
                netCharges: e.calculations?.netCharges
                  ? e.calculations.netCharges
                  : 0,
                netDeduction: e.calculations?.netDeduction
                  ? -e.calculations.netDeduction
                  : 0,
                // khf: 0,
                total: e.calculations?.netPayble ? (
                  e.calculations.netPayble < 0 ? (
                    <p style={{ color: "red" }}>{e.calculations.netPayble}</p>
                  ) : (
                    <p>{e.calculations.netPayble}</p>
                  )
                ) : (
                  0
                ),
                otherCharges: e?.calculations?.otherCharges
                  ? e?.calculations?.otherCharges
                  : 0,
                comment: finalComments,
                qualityCheck: e?.trip?.qualityCheck?.comment,
              };
            } else {
              obj = {
                route: e?.routeDetails?.[0]?.name ? e?.routeDetails[0]?.name : "",
                tripID: e?.trip?.tripId ? e?.trip?.tripId : "",
                dispatchedDate: dispatchedDate,
                asPerOracleAmount: e?.freightDetails
                  ? e?.freightDetails[0]?.freightDetails?.cost?.main
                  : 0,
                type: this.typeConvert(e.type),
                source: e.trip?.source?.label,
                destination: e.trip?.destination?.label,
                lrNumber:
                  e.type === "freight" && e.shipments !== undefined
                    ? this.lrNo(e.shipments)
                    : "",
                vehicleNo: e.type === "freight" ? e.vehicle?.regNo : "",
                truckType: e.vehicle?.type?.label
                  ? e.vehicle?.type?.label
                  : "NA",
                pfNo:
                  e.type === "freight"
                    ? this.shipId(e.shipments)
                    : e.type === "indent_rejection"
                    ? e.indentId
                    : "",
                createdAt: e.createdAt
                  ? moment(new Date(e.createdAt)).format("DD-MMM-YYYY")
                  : "",
                freightAmount: e?.revisedChargesStatus
                  ?.isFreightCostChargesRevised
                  ? e.calculations?.revisedFreightCostCharges
                  : e.calculations?.freightCharges,
                billNumber: e.billId,
                // type:e.type?e.type.split('_').join(" "):'',
                loadingCharge: e?.revisedChargesStatus?.isLoadingChargesRevised
                  ? e.calculations?.revisedLoadingCharges
                  : e.calculations?.loadingCharges,
                UnLoadingCharges: e?.revisedChargesStatus
                  ?.isUnloadingChargesRevised
                  ? e.calculations?.revisedUnloadingCharges
                  : e.calculations?.unloadingCharges,
                damageCharges: e?.revisedChargesStatus?.isDamageChargesRevised
                  ? e.calculations?.revisedDamageCharges
                  : e.calculations?.damageCharges,
                revisedDamageCharges: e.calculations?.revisedDamageCharges
                  ? -e.calculations.revisedDamageCharges
                  : 0,
                shortageCharges: e?.revisedChargesStatus
                  ?.isShortageChargesRevised
                  ? e.calculations?.revisedShortageCharges
                  : e.calculations?.shortageCharges,
                sourceDetentionCharges: e?.revisedChargesStatus
                  ?.isSourceDetentionChargesRevised
                  ? e.calculations?.revisedSourceDetentionCharges
                  : e.calculations?.sourceDetentionCharges,
                destinationDetentionCharges: e?.revisedChargesStatus
                  ?.isDestinationDetentionChargesRevised
                  ? e.calculations?.revisedDestinationDetentionCharges
                  : e.calculations.destinationDetentionCharges,
                lateDeliveryCharges: e?.revisedChargesStatus
                  ?.isLateDeliveryChargeChargesRevised
                  ? e.calculations?.revisedLateDeliveryChargeCharges
                  : e.calculations.lateDeliveryCharges,
                multipointPickupCharges: e?.revisedChargesStatus
                  ?.isMultipointPickupChargesRevised
                  ? e.calculations?.revisedMultipointPickupCharges
                  : e.calculations.multipointPickupCharges,
                multipointDropCharges: e?.revisedChargesStatus
                  ?.isMultipointDropChargesRevised
                  ? e.calculations?.revisedMultipointDropCharges
                  : e.calculations.multipointDropCharge,
                revisedOtherDeductionsCharges: e.calculations
                  ?.revisedOtherDeductionsCharges
                  ? e.calculations.revisedOtherDeductionsCharges
                  : 0,
                cancellationUponArrivalByCompanyCharges: e?.revisedChargesStatus
                  ?.isCancellationUponArrivalChargesRevised
                  ? e.calculations
                      ?.revisedcancellationUponArrivalByCompanyCharges
                  : e.calculations.cancellationUponArrivalByCompanyCharges,
                indentRejectionCharges: e?.revisedChargesStatus
                  ?.isIndentRejectionChargesRevised
                  ? e.calculations?.revisedIndentRejectionCharges
                  : e.calculations.indentRejectionCharges,
                netCharges: e.calculations?.netCharges
                  ? e.calculations.netCharges
                  : 0,
                netDeduction: e.calculations?.netDeduction
                  ? -e.calculations.netDeduction
                  : 0,
                // khf: 0,
                total: e.calculations?.netPayble ? (
                  e.calculations.netPayble < 0 ? (
                    <p style={{ color: "red" }}>{e.calculations.netPayble}</p>
                  ) : (
                    <p>{e.calculations.netPayble}</p>
                  )
                ) : (
                  0
                ),
                otherCharges: e?.calculations?.otherCharges
                  ? e?.calculations?.otherCharges
                  : 0,
                comment: finalComments,
                qualityCheck: e?.trip?.qualityCheck?.comment,
              };
            }

            return obj;
          })
        : [],
      finalRow: {},
      // finalRow: role === "transporter" ? final : final2,

      // finalRow:{
      //   locationFrom: '',
      //   locationTo: '',
      //   lrNumber: '',
      //   billNumber: '',
      //   qty: '',
      //   pfNumber: "",
      //   freightAmount: cal?.freight?cal.freight:40920,
      //   asPerOracle: 41920,
      //   diff: '('+1000+')',
      //   loadingCharge:  cal?.loadingCharge?cal.loadingCharge:'---',
      //   UnLoadingCharges: cal?.UnLoadingCharges?cal.UnLoadingCharges:'---',
      //   lateDeliveryRecovery: cal?.lateDeliveryRecovery?cal.lateDeliveryRecovery:'---',
      //   others: cal?.otherCharges?cal.otherCharges:'---',
      //   deduction: cal?.otherDeductionCharges?cal.otherDeductionCharges:'---',
      //   khf: 0,
      //   total:  cal?.netPayble?cal.netPayble:'---',
      // },
      titleHeader: transporter,
      page: "invoice",
      invoice: this.props.invoice,
    };

    if (data.rows?.length > 0) {
      let dataRows = data.rows;
      data.finalRow = data.rows.reduce(
        (accumulator, currentObject) => {
          accumulator.freightAmount += currentObject.freightAmount;
          accumulator.loadingCharge += currentObject.loadingCharge;
          accumulator.UnLoadingCharges += currentObject.UnLoadingCharges;
          accumulator.damageCharges += currentObject.damageCharges
            ? -currentObject.damageCharges
            : 0;
          accumulator.revisedDamageCharges +=
            currentObject.revisedDamageCharges;
          accumulator.shortageCharges += currentObject.shortageCharges
            ? -currentObject.shortageCharges
            : 0;
          accumulator.sourceDetentionCharges +=
            currentObject.sourceDetentionCharges
              ? currentObject.sourceDetentionCharges
              : 0;
          accumulator.destinationDetentionCharges +=
            currentObject.destinationDetentionCharges
              ? currentObject.destinationDetentionCharges
              : 0;
          accumulator.lateDeliveryCharges += currentObject.lateDeliveryCharges
            ? currentObject.lateDeliveryCharges
            : 0;
          accumulator.multipointPickupCharges +=
            currentObject.multipointPickupCharges
              ? currentObject.multipointPickupCharges
              : 0;
          accumulator.multipointDropCharges +=
            currentObject.multipointDropCharges
              ? currentObject.multipointDropCharges
              : 0;
          accumulator.revisedOtherDeductionsCharges +=
            currentObject.revisedOtherDeductionsCharges
              ? currentObject.revisedOtherDeductionsCharges
              : 0;
          accumulator.cancellationUponArrivalByCompanyCharges +=
            currentObject.cancellationUponArrivalByCompanyCharges
              ? currentObject.cancellationUponArrivalByCompanyCharges
              : 0;
          accumulator.indentRejectionCharges +=
            currentObject.indentRejectionCharges
              ? currentObject.indentRejectionCharges
              : 0;
          accumulator.netCharges += currentObject.netCharges
            ? currentObject.netCharges
            : 0;
          accumulator.netDeduction += currentObject.netDeduction
            ? -currentObject.netDeduction
            : 0;
          // khf: 0,
          accumulator.total += currentObject.total?.props?.children || 0;
          accumulator.otherCharges += currentObject.otherCharges
            ? currentObject.otherCharges
            : 0;
          return accumulator;
        },
        {
          type: "Total",
          source: "",
          destination: "",
          lrNumber: "",
          vehicleNo: "",
          pfNo: "",
          createdAt: "",
          freightAmount: 0,
          loadingCharge: 0,
          UnLoadingCharges: 0,
          damageCharges: 0,
          revisedDamageCharges: 0,
          shortageCharges: 0,
          sourceDetentionCharges: 0,
          destinationDetentionCharges: 0,
          lateDeliveryCharges: 0,
          multipointPickupCharges: 0,
          multipointDropCharges: 0,
          revisedOtherDeductionsCharges: 0,
          cancellationUponArrivalByCompanyCharges: 0,
          indentRejectionCharges: 0,
          netCharges: 0,
          netDeduction: 0,
          total: 0,
          otherCharges: 0,
        }
      );
    }

    const customStylesTable = {
      title: (style) => {
        return {
          ...style,
          fontFamily: "Calibri",
          color: "#505d69",
          fontWeight: "bold",
          fontSize: "12px",
        };
      },
      table: (style) => {
        return {
          ...style,
          textAlign: "center",
          fontFamily: "Calibri",
          borderCollapse: "collapse",
          border: "3px solid #ddd",
          width: "100%",
          marginBottom: "1rem",
          display: "block",
          overFlow: "scroll",
        };
      },
      td: (style) => {
        return {
          ...style,
          padding: "1px",
          color: "#505d69",
          fontSize: "0.55rem",
          textTransform: "capitalize",
        };
      },
      tr: (style) => {
        return { ...style, border: "1px solid #ddd" };
      },
      th: (style) => {
        return {
          ...style,
          border: "1px solid #ddd",
          padding: "2px",
          paddingTop: "6px",
          paddingBottom: "6px",
          textAlign: "center",
          backgroundColor: "#505d69",
          color: "white",
          fontSize: "0.55rem",
        };
      },
      tHeader: (style) => {
        return {
          ...style,
          border: "1px solid #ddd",
          padding: "2px",
          paddingTop: "6px",
          paddingBottom: "6px",
          textAlign: "left",
          color: "black",
          fontSize: "12px",
          width: "1100%",
          marginBottom: "0px",
        };
      },
    };


    return (
      <React.Fragment>
        <div>
          {getLocalStorage()}
          <MDBContainer>
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={this.props.closePdf}
              size={"lg"}
              id="invoice-pdf-modal"
            >
              <MDBModalHeader toggle={this.props.closePdf}></MDBModalHeader>
              <MDBModalBody>
                <div>
                  <Col lg="12" style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        this.pdfDownload();
                      }}
                      color="primary"
                      className="mr-1"
                    >
                      {userLang?.common?.DOWNLOAD_PDF || "Download PDF"}
                    </Button>
                
                    <ExportButton data={data} />
                 
                  </Col>
                  <div id="printinvoice">
                    <table className="pdfTable" style={{ marginLeft: "1.5%" }}>
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td></td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={date}
                              data={data}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    invoice: state.Billing?.billing?.invoice,
  };
};

export default connect(mapStateToProps, {})(InvoicePdf);
