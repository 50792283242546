// import React, { Component } from "react";

// import { DatePicker } from "rsuite";
// import "rsuite/dist/rsuite.min.css";
// import calendarDarkIcon from "../../../pages/Trips/assets/calendarDark.png";
// import calendarIcon from "../../../pages/Trips/assets/calendar.png";
// import "../../../pages/Dashboard/OperationsDashboard/Styles/dashboard.css";

// var classname, darkMode, isDashboard;

// const MyIcon = React.forwardRef((props, ref) => {
//   if (isDashboard) {
//     return (
//       // <span style={{ position: "absolute", top: "2px", right: "0px" }}>
//       <span >
//         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//           <path d="M15.8333 3.33301H4.16667C3.24619 3.33301 2.5 4.0792 2.5 4.99967V16.6663C2.5 17.5868 3.24619 18.333 4.16667 18.333H15.8333C16.7538 18.333 17.5 17.5868 17.5 16.6663V4.99967C17.5 4.0792 16.7538 3.33301 15.8333 3.33301Z" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
//           <path d="M13.3335 1.66699V5.00033" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
//           <path d="M6.6665 1.66699V5.00033" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
//           <path d="M2.5 8.33301H17.5" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
//         </svg>
//       </span>
//     );
//   } else {
//     return (
//       <img
//         className={classname}
//         src={darkMode ? calendarDarkIcon : calendarIcon}
//         alt=""
//       />
//     );
//   }
// });

// class Datepicker extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   render() {
//     darkMode = this.props?.mode;
//     classname = this.props?.classname ? this.props?.classname : null;
//     isDashboard = this.props?.dashboard ? this.props?.dashboard : false;
//     let placeholder = isDashboard
//       ? this.props?.placeholder
//       : this.props?.myPlaceholder;

//     return (
//       <React.Fragment>
//         <DatePicker
//           size="md"
//           placeholder={placeholder}
//           block
//           format="dd MM yy"
//           showOneCalendar
//           placement="auto"
//           //  preventOverflow
//           value={this.props.value || null}
//           onChange={(newValue) => {
//             // console.log(newValue);
//             this.props.fromDate
//               ? this.props.onChangeDatePicker(newValue, "fromDate")
//               : this.props.onChangeDatePicker(newValue, "toDate");
//           }}
//           caretAs={MyIcon}
//         />
//       </React.Fragment>
//     );
//   }
// }

// export default Datepicker;

import React, { Component } from "react";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import calendarDarkIcon from "../../../pages/Trips/assets/calendarDark.png";
import calendarIcon from "../../../pages/Trips/assets/calendar.png";
import "../../../pages/Dashboard/OperationsDashboard/Styles/dashboard.css";

// Global variables (avoid using these if possible; pass as props instead)
let classname, darkMode, isDashboard;

// Custom icon component
const MyIcon = React.forwardRef((props, ref) => {
  if (isDashboard) {
    return (
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M15.8333 3.33301H4.16667C3.24619 3.33301 2.5 4.0792 2.5 4.99967V16.6663C2.5 17.5868 3.24619 18.333 4.16667 18.333H15.8333C16.7538 18.333 17.5 17.5868 17.5 16.6663V4.99967C17.5 4.0792 16.7538 3.33301 15.8333 3.33301Z"
            stroke="#6D7885"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.3335 1.66699V5.00033"
            stroke="#6D7885"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.6665 1.66699V5.00033"
            stroke="#6D7885"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 8.33301H17.5"
            stroke="#6D7885"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    );
  } else {
    return (
      <img
        className={classname}
        src={darkMode ? calendarDarkIcon : calendarIcon}
        alt=""
      />
    );
  }
});

// Utility function to normalize date input
const normalizeDate = (value) => {
  if (!value) return null; // Handle null or undefined
  const date = new Date(value);
  return isNaN(date.getTime()) ? null : date; // Return null if invalid, else Date object
};

class Datepicker2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: normalizeDate(props.value), // Initialize with normalized value
    };
  }

  // Sync with props if value changes
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const normalizedValue = normalizeDate(this.props.value);
      console.log(
        "Datepicker - Prev Value:",
        prevProps.value,
        "New Value:",
        this.props.value,
        "Normalized:",
        normalizedValue
      );
      this.setState({ selectedDate: normalizedValue });
    }
  }

  render() {
    darkMode = this.props?.mode;
    classname = this.props?.classname ? this.props?.classname : null;
    isDashboard = this.props?.dashboard ? this.props?.dashboard : false;
    const placeholder = isDashboard
      ? this.props?.placeholder
      : this.props?.myPlaceholder;

    console.log("Datepicker Render - selectedDate:", this.state.selectedDate);

    return (
      <React.Fragment>
        <DatePicker
          size="md"
          placeholder={placeholder}
          block
          format="dd MM yy"
          showOneCalendar
          placement="auto"
          value={this.state.selectedDate} // Use normalized state value
          onChange={(newValue) => {
            const normalizedValue = normalizeDate(newValue);
            this.setState({ selectedDate: normalizedValue }, () => {
              this.props.onChangeDatePicker(normalizedValue); // Pass normalized value to parent
            });
          }}
          caretAs={MyIcon}
        />
      </React.Fragment>
    );
  }
}

export default Datepicker2;