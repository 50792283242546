import React, { Component } from "react";
import {
  InputField,
  RenderNewDate,
  RenderNewDate2,
} from "./inputFields/Fields";
import {
  Row, Col, Card, Button, CardTitle, Input,


  Collapse, CardBody, CardHeader,
  FormGroup,
  Label
} from "reactstrap";
import sorting_icon from "./assets/sorting_icon.png";
import sortingDark_icon from "./assets/sortDark.png";
import moment from "moment";
import { WaveLoader } from "../../Common/Loader/LoaderIndex";
import { NotificationManager } from "react-notifications";
import { searchBar } from "../../../pages/MastersDriver/RowHeaders/RowHeaders";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";

import exportIcon from "./assets/exporticon.svg";
import exportDarkIcon from "./assets/external-linkDark.png";
import filterIcon from "./assets/sliders.svg";
import rotateIcon from "./assets/rotate_cw.svg";
import { CSSTransition } from "react-transition-group";
import { select } from "redux-saga/effects";
import _ from "lodash";
let dataSet = [];
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
let AllFilterObj = {};
let statusFilter = {};
let locName = window.location.pathname;



class Index extends Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exloading: false,
      emptyFlag: false,
      //data
      headers: [],
      columnWidthArray: [],
      data: [],
      dataLength: 0,
      processedData: [],
      //search
      searchToggle: true,
      searchBar: [],
      //date
      date: [null, null],
      startDate: [null, null],
      endDate: [null, null],
      // reset
      resetBar: true,
      fetchId: "",
      specialCharError: "",
      checkChanged: false,
      showFilter: false,
      toggleQuestion: '',
      dateFilters: {},
    };
    // Debounce the function to delay API calls
    this.debouncedSearchValueAssignerFunction = _.debounce(this.searchValueAssignerFunction, 1200);
    this._handleKeyDown = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    let isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
    if (isBaxter) {
      let pathName = window.location.pathname;
      let keys = `myStatusFilter_${pathName}`;
      let keys1 = `mySearchFilter_${pathName}`;
      let mountFilterData = window.localStorage.getItem(keys)
        ? JSON.parse(window.localStorage.getItem(keys))
        : "";
      let mountFilterData1 = window.localStorage.getItem(keys1)
        ? JSON.parse(window.localStorage.getItem(keys1))
        : "";
      // console.log(mountFilterData1,"99")
      statusFilter = mountFilterData || {};
      AllFilterObj = mountFilterData1 || {};
    }
    let headers = this.props?.headers?.columns;
    let searchBar = this.props?.searchBar?.searchBarData;
    // console.log(searchBar," searchBar----===-----");  

    let columnWidthArray = [];

    this.setState({ headers: headers, searchBar: searchBar }, () => {
      if (this.state.headers)
        this.state.headers.forEach((item) => {
          columnWidthArray.push(item.width);
        });
      this.setState({ columnWidthArray: columnWidthArray });
    });
    document.addEventListener("keydown", this._handleKeyDown);
  };
  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }
  componentDidUpdate = () => {
    let data = this.props.data;

    let fetchId = this.props.fetchId;
    // console.log("componentDIdupdate", data);
    if (!data?.length && this.state?.emptyFlag === false)
      this.setState({
        processedData: [],
        emptyFlag: true,
      });
    else if (
      this.state?.data[0]?.id !== data[0]?.id ||
      this.state?.data?.length !== data?.length ||
      this.props?.dataLength !== this.state?.dataLength ||
      this.state?.fetchId !== fetchId ||
      this.props?.checkChanged != this.state?.checkChanged
    ) {
      this.setState(
        {
          data,
          emptyFlag: false,
          dataLength: this.props?.dataLength,
          fetchId: fetchId,
          checkChanged: this.props?.checkChanged,
        },
        () => {
          this.formatter(this.state.data);
        }
      );
      //console.log("HERE INSIDE INDEX");
    }
  };

  formatter = (data) => {
    dataSet = [];
    data.map((dataItem, index) => {
      this.binder(dataItem, index);
      return true;
    });
  };



  //this function extract data from the api response and attached it to corresponding header titles
  binder = (dataItem, index) => {
    let dataSetItem = [];
    let headers = this.props?.headers?.columns;
    let currentRoute = window.location.pathname; // Get current route

    if (currentRoute === "/Secondary-invoiceGeneration") {
      // 🔹 Use the second logic for this specific route
      headers.forEach((headItem, headIndex) => {
        if (headItem.field === "customers") {
          // Check if `customers` exists and is an array
          dataSetItem[headIndex] = Array.isArray(dataItem.customers)
            ? dataItem.customers.map((customer) => customer.shipToCode).join(", ")
            : "N/A"; // Fallback if `customers` is missing or not an array
        } else if (headItem.field in dataItem) {
          dataSetItem[headIndex] = dataItem[headItem.field];
        }
      });
    } else {
      // 🔹 Use the first (previous) binder logic for other pages
      Object.keys(dataItem).forEach((key) => {
        if (dataSet?.length <= 1000 || this.props?.limit > 0) {
          if (headers) {
            headers.forEach((headItem, headIndex) => {
              if (headItem.field === key) {
                dataSetItem[headIndex] = dataItem[key];
              }
            });
          }
        }
      });
    }

    dataSet[index] = dataSetItem;

    this.setState({ processedData: dataSet }, () => {

    });
  };



  // Sorting the trip and time for table----------
  sorting = () => {
    this.setState((prevState) => {
      // const lastValue = sessionStorage.getItem("processedData") ? parseInt(sessionStorage.getItem("processedData")) : 1;
      const lastValue = sessionStorage.getItem("processedData");
      console.log("lastValue: +++" + lastValue)
      const newValue = lastValue == 1 ? -1 : 1;
      console.log("newValue: +++" + newValue)
      sessionStorage.setItem("processedData", newValue);

      return { processedData: newValue };
    }, () => {
      const eventData = {
        target: {
          name: "sortKey",
          value: sessionStorage.getItem("processedData"),
        },
      };

      // console.log("Stored Value:", eventData.target.value);

      this.props.searchValueAssignerFunction(
        eventData.target.name,
        eventData.target.value,
      );
    });
  };

  // ensure state initializes from sessionStorage when component mounts or loads
  componentDidMount() {
    const storedValue = sessionStorage.getItem("processedData") || 1;
    this.setState({ processedData: parseInt(storedValue) });
  }


  handleChange = (event, type) => {
    // console.log("--event", event);
    // console.log("..type", type);
    const inputText = event.target.value;
    const containsSpecialChars = /[!@#$%^&*+_+\[\]{};':"\\|<>\/?]+/.test(inputText);

    if (!containsSpecialChars || type === "select" || type === "email") {
      this.setState(
        {
          [event.target.name]:
            type === "select"
              ? { label: event.target.label, value: event.target.value }
              : event.target.value,
          specialCharError: "",
        },
        () => {

          if (type === "select") {
            const pathName = window.location.pathname;
            const key = `myStatusFilter_${pathName}`;
            const innerKey = `${event.target.name}_${pathName}`;

            statusFilter[`${innerKey}`] = {
              label: event.target.label,
              value: event.target.value,
            };
            const updatedstatusFilterObj = {
              ...statusFilter,
              [`${innerKey}`]: {
                label: event.target.label,
                value: event.target.value,
              },
            };

            if (!this.props?.hasOwnProperty("filterMaintain"))
              window.localStorage.setItem(
                key,
                JSON.stringify(updatedstatusFilterObj)
              );
          } else if (type === "text") {
            let pathName = window.location.pathname;
            let key = `mySearchFilter_${pathName}`;
            if (pathName != "/tripList" && pathName != "/ordersList") {
              AllFilterObj[event.target.name] = event.target.value;
              // console.log(AllFilterObj,"99")
              const updatedAllFilterObj = {
                ...AllFilterObj,
                [event.target.name]: event.target.value,
              };
              if (!this.props?.hasOwnProperty("filterMaintain"))
                window.localStorage.setItem(
                  key,
                  JSON.stringify(updatedAllFilterObj)
                );
            }
          }


          // filter data passing to parent{select , text both}=----------------------------------------------------------------
          this.debouncedSearchValueAssignerFunction(
            event.target.name,
            event.target.value,
            type
          );

        }
      );
    } else {
      this.setState({
        specialCharError: "Special characters are not allowed.",
      });
      NotificationManager.error("Special Character Not allowed", "Input Error");
    }
    // //console.log("STATE:", this.state);
  };
  // passes the data to the parent for all filter --------------------------------
  searchValueAssignerFunction = (name, value, type) => {
    this.props.searchValueAssignerFunction(name, value, type);
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitSelections();
        this.setState({ showFilter: false });
    }
  };
  
  // keyPress = (e) => {
  //   // if (e.key === "Enter")  
  //   // this.props.getAllData();
  // };

  // handleSubmitSelections = () => {
  //   const pageKey = window.location.pathname.replace(/^\//, "");
  //   const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};

  //   // Loop through all stored data and send it using the parent function
  //   Object.entries(storedData).forEach(([key, values]) => {
  //     console.log("key, values000000000000",key,"and", values);
  //     let formattedValues = values;

  //     // If values is an array (from Selector), extract the 'value' property and join

  //     // ""subStatus=planned&subStatus=arrived_at_source""" pattern-----
  //     //  if (Array.isArray(values)) {
  //     //   formattedValues = values
  //     //     .map((item) => (typeof item === "object" && item.value ? item.value : item))
  //     //     .join(",");
  //     // }

  //     // ""planned&subStatus=arrived_at_source""" pattern----
  //     if (Array.isArray(values)) {
  //       // Convert array into `key=value` parameters but omit the key for the first value
  //       formattedValues = values
  //       .map((item, index) => {
  //           const value = typeof item === "object" && item.value ? item.value : item;
  //           return index === 0 ? value : `${key}=${value}`;
  //         })
  //         .join("&");
  //         // console.log("1=======and", formattedValues);
  //     }

  //     else {
  //       let date = [];

  //       if (key === "startTime") {
  //         this.setState({ startDate: values}, () => {
  //           date[0] = moment(this.state.startDate).format("MM-DD-YYYY");
    
  //           this.props.searchValueAssignerFunction(key, date, "date");
  //         });
  //       } else if (key === "expiryTime") {
  //         this.setState({ endDate: values }, () => {
  //           date[0] = moment(this.state.endDate).format("MM-DD-YYYY");
  //           this.props.searchValueAssignerFunction(key, date, "date");
  //         });
  //       }
  //       // else {
  //       //   this.setState({ endDate: values }, () => {
  //       //     date = moment(this.state.endDate).format("MM-DD-YYYY");
  //       //     this.props.searchValueAssignerFunction(key, date, "date");
  //       //   });
  //       // }
        
  //     }

  //     // console.log(`Submitting ${key}:`, formattedValues);

  //     this.props.searchValueAssignerFunction(
  //       key,            // input name like 'hop' or 'status'
  //       formattedValues, // comma-separated values
  //       "select"        // type for Selector component data
  //     );
  //   });
  // };
  handleSubmitSelections = () => {
    const pageKey = window.location.pathname.replace(/^\//, "");
    const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};
  
    Object.entries(storedData).forEach(([key, values]) => {
      console.log("Processing key:", key, "values:", values);
      let formattedValues = values;
  
      // If values is an array, format accordingly
      if (Array.isArray(values)) {
        formattedValues = values
          .map((item, index) => {
            const value = typeof item === "object" && item.value ? item.value : item;
            return index === 0 ? value : `${key}=${value}`;
          })
          .join("&");
  
        this.props.searchValueAssignerFunction(key, formattedValues, "select");
      } else if (key === "startTime" || key === "expiryTime") {
        this.setState(
          key === "startTime" ? { startDate: values } : { endDate: values },
          () => {
            let date = moment(values).format("MM-DD-YYYY");
            this.props.searchValueAssignerFunction(key, [date], "date");
          }
        );
      } 
      else if (key === "fromDate" || key === "toDate") {
        if (!values) return; // Ensure value exists
    
        // console.log("Raw Date Value from Storage:", values);
    
        let formattedDate = moment(values).local().format("MM-DD-YYYY");
    
        // console.log("Formatted Date:", formattedDate);
    
        if (formattedDate) {
            // Assign proper key name in API call
            const dateKey = key === "fromDate" ? "validityStartDate" : "validityEndDate";
            this.props.searchValueAssignerFunction(dateKey, formattedDate, "date");
        }
    }
    else {
      // Handle normal key-value pairs
      this.props.searchValueAssignerFunction(key, formattedValues, "text");
    }
    
    });
  };
  
  handleDate = (e, name) => {
    let date = [];

    if (name === "startTime") {
      this.setState({ startDate: e }, () => {
        date[0] = moment(this.state.startDate).format("MM-DD-YYYY");

        this.props.searchValueAssignerFunction(name, date, "date");
      });
    } else if (name === "expiryTime") {
      this.setState({ endDate: e }, () => {
        date[0] = moment(this.state.endDate).format("MM-DD-YYYY");
        this.props.searchValueAssignerFunction(name, date, "date");
      });
    }
    else {
      this.setState({ endDate: e }, () => {
        date = moment(this.state.endDate).format("MM-DD-YYYY");
        this.props.searchValueAssignerFunction(name, date, "date");
      });
    }
  };

  handleDate2 = (dateValues) => {
    if (!dateValues || dateValues.length < 2) return; // Ensure both dates exist

    let formattedStartDate = dateValues[0] ? moment(dateValues[0]).format("MM-DD-YYYY") : null;
    let formattedEndDate = dateValues[1] ? moment(dateValues[1]).format("MM-DD-YYYY") : null;
    
    if (formattedStartDate && formattedEndDate) {
        const formattedDateString = `validityStartDate=${formattedStartDate}&validityEndDate=${formattedEndDate}`;
    
        this.props.searchValueAssignerFunction( formattedDateString, "date");
    }
     };


  // use this for multiselector for all cahnge only in apis call--------------------------------
  // handleSubmitSelections = () => {
  //   const pageKey = window.location.pathname.replace(/^\//, "");
  //   const storedData = JSON.parse(localStorage.getItem(pageKey)) || {};

  //   // Loop through all stored data and send it using the existing parent function
  //   Object.entries(storedData).forEach(([key, values]) => {
  //     let formattedValues;

  //     if (Array.isArray(values)) {
  //       // If values is an array, format it as key=value1&key=value2
  //       formattedValues = values.map(value => `${key}=${value}`).join("&");
  //     } else {
  //       // If values is a single value, format it as key=value
  //       formattedValues = `${key}=${values}`;
  //     }

  //     // console.log("formattedValues", formattedValues);
  //     this.props.searchValueAssignerFunction(
  //       key,             // input name like 'hop' or 'status'
  //       formattedValues, // formatted as key=value or key=value1&key=value2
  //       "select"         // assuming data from Selector should be treated as select
  //     );
  //   });
  // };




  // handleDate = (e, name) => {
  //   let date = [];

  //   if (name === "startTime") {
  //     this.setState({ startDate: e }, () => {
  //       // if (Array.isArray(this.state.startDate)) {
  //       //   date[0] = moment(this.state.startDate[0]).format("MM-DD-YYYY");
  //       //   date[1] = moment(this.state.startDate[1]).format("MM-DD-YYYY");
  //       // } else {
  //       date[0] = moment(this.state.startDate).format("MM-DD-YYYY");
  //       // }

  //       this.props.searchValueAssignerFunction(name, date, "date");
  //     });
  //   } else if (name === "expiryTime") {
  //     this.setState({ endDate: e }, () => {
  //       // if (Array.isArray(this.state.endDate)) {
  //       //   date[0] = moment(this.state.endDate[0]).format("MM-DD-YYYY");
  //       //   date[1] = moment(this.state.endDate[1]).format("MM-DD-YYYY");
  //       // } else {
  //       date[0] = moment(this.state.endDate).format("MM-DD-YYYY");
  //       // }


  //       this.props.searchValueAssignerFunction(name, date, "date");
  //     });
  //   }
  //   else {
  //     this.setState({ endDate: e }, () => {
  //       // if (Array.isArray(this.state.endDate)) {
  //       //   date[0] = moment(this.state.endDate[0]).format("MM-DD-YYYY");
  //       //   date[1] = moment(this.state.endDate[1]).format("MM-DD-YYYY");
  //       // } else {
  //       date = moment(this.state.endDate).format("MM-DD-YYYY");
  //       // }
  //       // console.log("date------------",date);

  //       this.props.searchValueAssignerFunction(name, date, "date");
  //     });
  //   }

  //   // console.log("Updated Date:", date);
  // };
  // handleDate2 = (dateValues) => {
  //   if (!dateValues || dateValues.length < 2) return; // Ensure both dates exist

  //   let formattedStartDate = dateValues[0] ? moment(dateValues[0]).format("MM-DD-YYYY") : "";
  //   let formattedEndDate = dateValues[1] ? moment(dateValues[1]).format("MM-DD-YYYY") : "";

  //   this.props.searchValueAssignerFunction("validityStartDate", formattedStartDate, "date");
  //   this.props.searchValueAssignerFunction("validityEndDate", formattedEndDate, "date");
  // };

  // for new one saga-----







  // reset = () => {
  //   this.setState(
  //     { resetBar: false, startDate: [null, null], endDate: [null, null], emptyValue: "" },
  //     () => {
  //       this.setState({ resetBar: true });
  //     }
  //   );
  //   this.state.searchBar.forEach((item) => {
  //     // //console.log(this[item.field]?.value);
  //     if (this[item.field]?.value) this[item.field].value = "";
  //     // //console.log(item);
  //     this.setState({ [item.field]: "" }, () => {
  //       AllFilterObj = {};
  //       statusFilter = {};
  //       // //console.log(this.state[item.field]);
  //     });
  //   });
  //   let pathName = window.location.pathname;
  //   const pageKey = window.location.pathname.replace(/^\//, "");
  //   let key = `myStatusFilter_${pathName}`;
  //   let keyOffset = `myOffsetFilter_${pathName}`;
  //   let key1 = `mySearchFilter_${pathName}`;
  //   localStorage.removeItem(pageKey);
  //   localStorage.removeItem(key);
  //   localStorage.removeItem(keyOffset);
  //   localStorage.removeItem(key1);
  //   sessionStorage.removeItem("processedData")
  //   this.props.reset();
  // };

  reset = () => {
    this.setState(
      {
        resetBar: false,
        startDate: [null, null],
        endDate: [null, null],
        emptyValue: "",
      },
      () => {
        this.setState({ resetBar: true });
      }
    );
  
    let updatedState = {};
  
    this.state.searchBar.forEach((item) => {
      if (this[item.field]?.value) {
        this[item.field].value = "";
      }
      updatedState[item.field] = "";
    });
  
    // Batch updating state to prevent multiple renders
    this.setState(updatedState, () => {
      AllFilterObj = {};
      statusFilter = {};
    });
  
    // Remove filters from localStorage and sessionStorage
    let pathName = window.location.pathname;
    let pageKey = pathName.replace(/^\//, "");
    localStorage.removeItem(pageKey);
    localStorage.removeItem(`myStatusFilter_${pathName}`);
    localStorage.removeItem(`myOffsetFilter_${pathName}`);
    localStorage.removeItem(`mySearchFilter_${pathName}`);
    sessionStorage.removeItem("processedData");
  
    // Call reset function
    this.props.reset();
  };
  
  handleDoubleClick = (dataItem, Item) => {
    // //console.log("double click captured", this.props, this.state, dataItem , Item)
    if (this.props?.location?.pathname === "/indent-planning") {
      const data = this.state.data.find((e) => e.indentId === Item[0]);
      this.props.toggle(data);
    }
  };

  filterModal = (ele) => {
    this.setState({
      showFilter: !this.state.showFilter
    });
  };

  handleToggle = (index) => {
    this.setState((prevState) => ({
      toggleQuestion: prevState.toggleQuestion === index ? true : index,
    }));
  };
  resetToggle = () => {
    this.setState({ toggleQuestion: null });
  };



  render() {
    let pathName = window.location.pathname;
    let key = `myStatusFilter_${pathName}`;
    let key1 = `subStatus_${pathName}`;
    const storedStatusObjects = JSON.parse(localStorage.getItem(key)) || {};

    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let { headers, processedData, columnWidthArray } = this.state;


    // console.log("processedData----------------",processedData)


    const { REACT_APP_CLIENT } = process.env;
    const isNoBaxter = REACT_APP_CLIENT !== "3_BAXTER";
    let initStorage = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : "";
    if (initStorage === "dark__mode") {
      var darkTheme = true;
      var dataHeaderBgClr = "#222238";
      var dataHeaderTextClr = "#FFFFFF";
      var dataTableTextClr = "#FFFFFF";
      var dataCardBgClr = "#1B1C31";
      var dataProcessingClr = "#24243C";
      var dateBgClr = "#24243C";
      var dateClr = "";
    } else if (initStorage === "light__mode") {
      darkTheme = false;
      dataHeaderBgClr = "";
      dataHeaderTextClr = "#05284E";
      dataTableTextClr = "#000000";
      dataCardBgClr = "#eff2f7 ";
      dataProcessingClr = "";
      dateBgClr = "";
      dateClr = "";
    } else {
      darkTheme = false;
      dataHeaderBgClr = "";
      dataHeaderTextClr = "#05284E";
      dataTableTextClr = "#0C0C0C";
      dataCardBgClr = "#eff2f7 ";
      dataProcessingClr = "";
      dateBgClr = "";
      dateClr = "";
    }
    let darkMode = this.props?.mode;


    // console.log("this.props.exportLoading",this.props.exportLoading)
    return (
      <React.Fragment>
        {this.props.dataLoading && (
          <div className="ListingPages">
            <WaveLoader />
          </div>
        )}

        <div
          // className={
          //   this.props?.headers?.columns?.length > 12
          //     ? "IndexDataTableNeoParentScroll"
          //     : "IndexDataTableNeoParent"
          // }
          style={{
            // textAlign: "center",
            // justifyContent: "center",
            marginTop: darkTheme ? "0px" : "0px",
            backgroundColor: `${dataHeaderBgClr}`,
            paddingBottom: "0px",
            marginBottom: "0px !important",
            textTransform: "capitalize",
          }}
        >
          <div
          // className={
          //   this.props?.headers?.columns?.length > 13
          //     ? "scroollDataTableNeoScroll"
          //     : "scroollDataTableNeo"
          // }
          >
            {/* <div className="container-fluid"> */}
            <div className="table-scroll">
              <Card
                style={{
                  minHeight: "190px",
                  backgroundColor: 'white',
                  // maxHeight: "95vh",
                  // overflowY: "auto",
                  position: 'relative',
                  minWidth: "100%",
                  width: "fit-content",

                  marginBottom: '10px',
                  height: "100%"
                }}
                // className="CardDateTable"
                className={

                  this.props?.headers?.columns?.length > 13
                    ? "CardDateTableScroll"
                    : "CardDateTable"
                }
              >
                {/* Search and filter navigator---------------------------------------------------------------- */}
                <Row
                  style={{ alignItems: "center" }}
                  // style={{ flex: "1", alignItems: "center" }}
                  // className={`IndexDatatableNeoRows search-padding ${this.props. seachclassName }`}
                  className="IndexDatatableNeoRows search-padding searchBar-table"

                >
                  <Col xs="6" className="px-0">
                    {this.props.searchable && this.props.hidesearchBar !== true && (
                      <div className="search-main">
                        <div className="search-div">
                          <div className="search-main-view">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M9.3 15.6C12.7794 15.6 15.6 12.7794 15.6 9.3C15.6 5.82061 12.7794 3 9.3 3C5.82061 3 3 5.82061 3 9.3C3 12.7794 5.82061 15.6 9.3 15.6Z" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M16.9999 17.0004L14.8999 14.9004" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </div>

                          <input
                            className="form-control search-input-view2 input"
                            type="text"
                            value={this.state.emptyValue}
                            placeholder="Search"
                            onChange={(event) => {
                              const { value } = event.target;
                              this.setState({ emptyValue: value });
                              this.handleChange({ target: { name: "generalQuery", value } });
                            }}
                          // onKeyPress={this.keyPress}


                          />


                        </div>

                        <div className="tooltip-container">
                          <button className="n search-filter2 shadow1" onClick={this.reset}>
                            {/* <i class="fas fa-undo"></i> */}
                            <img
                              style={{ margin: "-4px" }}
                              className="filter-img"
                              src={rotateIcon}
                              alt="exp"
                            />
                          </button>
                          <span className="tooltip">Reset Data</span>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col xs="6" className="text-right"
                    style={{ display: 'flex', justifyContent: 'flex-end' }} >

                    {/* add indent btn------------------------------- */}
                    {this.props?.onClickAddIndent?.handler && (
                      <button
                        className="shadow2 mr-12 addbtn"
                        onClick={this.props.onClickAddIndent.handler}
                      >
                        <span>Create {this.props.onClickAddIndent.label}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M8 1V15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1 8H15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                    )}



                    {/* Filter Button---------------------- */}
                    {this.props.searchable && (
                      <div className="tooltip-container ">
                        <button className="filter-bdr search-filter2 shadow1"
                          onClick={() => {
                            this.filterModal();
                            this.resetToggle();
                          }}
                        >
                          {/* <i className="fas fa-filter"></i> */}
                          <img
                            style={{ margin: "-4px" }}
                            className="filter-img"
                            src={filterIcon}
                            alt="exp"
                          />
                        </button>

                        <span className="tooltip">Filter Data</span>
                      </div>
                    )
                    }


                    {/* Export Button ----------------------*/}
                    {this.props.toggleExcel && (
                      <div className="tooltip-container ">
                        <button
                          color=""
                          disabled={!navigator.onLine || this.props.exportLoading}
                          onClick={() => this.props.toggleExcel()}
                          className={`iconButtons shadow1 ${darkMode ? "iconDarkButtons" : ""} search-filter2 filter-bdr ml-12`}
                        >
                          {this.props.exportLoading ? (
                            <div className="loader">
                              <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "-4.5px" }} width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M13.4165 2.33301V5.83301H9.9165" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.9526 8.74985C11.5734 9.82313 10.8557 10.7441 9.90752 11.374C8.95937 12.0038 7.83219 12.3085 6.69584 12.242C5.55948 12.1755 4.47552 11.7415 3.60728 11.0054C2.73905 10.2693 2.13359 9.27089 1.88214 8.16071C1.63069 7.05053 1.74687 5.8887 2.21318 4.8503C2.67949 3.8119 3.47066 2.95318 4.46747 2.40355C5.46427 1.85392 6.61272 1.64315 7.73974 1.80301C8.86675 1.96287 9.91128 2.48469 10.7159 3.28985L13.4168 5.83318" stroke="#6D7885" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </div> // Show loader during export
                          ) : (
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? exportDarkIcon : exportIcon}
                              alt="exp"
                            />
                          )}
                        </button>
                        <span className="tooltip">Export Data</span>
                      </div>
                    )}

                  </Col>
                </Row>
                {/* Search and filter navigator END----------------------------------------------------------- */}


                {/* temp table-------------------Start------------------++++ */}

                <Card className="m-0 table-card">

                  {/* <div className="  tw-text-green-400 ">THis is Temp data for testing the tailwindcss</div> */}

                  <div className="">
                    <div className="tw-relative">
                      <table className="custom-table ">
                        {/* table header========================== */}
                        <thead>
                          <tr>
                            {headers.map((headItem, index) => {
                              // console.log("headItem", headItem);

                              return (
                                <th key={index} style={{ width: headItem.label === "Actions" ? "0%" : "1%" }}>
                                  <span className="d-flex justify-content-between ">
                                    {headItem.label}

                                    {(
                                      (this.props.tableType === "triptable" &&
                                        ["trip", "time"].includes(headItem.label?.toLowerCase())) ||
                                      (this.props.tableType === "indenttable" &&
                                        ["start time", "indent", "expiry time"].includes(headItem.label?.toLowerCase()))
                                    ) && (
                                        <span className="sortbtn" onClick={this.sorting}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                          >
                                            <path d="M4.5 3V10.9997" stroke="#AEAEAE" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6 9.28613L4.5 11.0004L3 9.28613" stroke="#AEAEAE" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.5 11L9.5 3.00032" stroke="#AEAEAE" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8 4.71387L9.5 2.99965L11 4.71387" stroke="#AEAEAE" strokeLinecap="round" strokeLinejoin="round" />
                                          </svg>
                                        </span>
                                      )}
                                  </span>
                                </th>
                              );
                            })}
                          </tr>

                        </thead>

                        {/* table body======================== */}
                        <tbody >
                          {this.props.dataLoading ? (
                            <tr>
                              <td colSpan={headers.length} className="text-center">
                                <span className="loading-text">
                                  Loading<span className="dots">..</span>
                                </span>

                              </td>
                            </tr>
                          ) :
                            processedData.length > 0 ? (
                              processedData.map((item, rowIndex) => (
                                <tr
                                  key={rowIndex}
                                  className="table-row"
                                  style={{
                                    backgroundColor: `${dataProcessingClr}`,
                                    animationDelay: `${rowIndex * 0.02}s`,
                                    zIndex:`${30 - rowIndex}` 
                                  }}
                                >
                                  {item.map((dataItem, cellIndex) => (
                                    <td
                                      key={cellIndex}
                                      className="table-cell-row"
                                      onDoubleClick={() => this.handleDoubleClick(dataItem, item)}
                                    >
                                      {dataItem}
                                    </td>
                                  ))}
                                </tr>
                              ))

                            ) : (
                              <tr>
                                <td colSpan={headers.length} className="no-data text-center mx-auto">
                                  No Records Found
                                </td>
                              </tr>
                            )}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </Card>

                {/* temp table-------------------END------------------++++ */}

              </Card>
            </div>
          </div>
        </div>

        <MDBContainer className="text-capitalize filterModal">
          <MDBModal

            size={"md"}
            isOpen={this.state.showFilter}
            toggle={this.filterModal}

            tabIndex='-1'
            position="top-right"
            side
          >

            <MDBModalHeader toggle={this.filterModal}>
              <p className="fontRoboto">Filter</p>
            </MDBModalHeader>
            <MDBModalBody>
              <div>



                {this.props?.searchable ? (
                  this.state?.searchToggle ? (
                    <div
                      className="searchToggleDiv"
                      style={{ backgroundColor: darkTheme ? "" : "white" }}

                    >



                      {this.state?.resetBar
                        ? this.state?.searchBar?.map((item, index) => {
                          if (index !== this.state?.searchBar.length - 1) {
                            if (item.type !== "date") {
                              // console.log("item.field+++++++++", this.state.searchBar);
                              return (
                                // responsible for the search filter bars--------------------------------------------------------
                                <Card>

                                  <CardHeader onClick={() => this.handleToggle(index)} className="toggle-header">
                                    <span className="font-weight-bold">
                                      {/* Changing the name or subStatus to Status */}

                                      {item.field === "subStatus"
                                        ? "Status"
                                        : item.field.replace(/([a-z])([A-Z])/g, "$1 $2")}


                                    </span>
                                    {this.state.toggleQuestion === index ? (
                                      <i className="fas fa-angle-up"></i>
                                    ) : (
                                      <i className="fas fa-angle-down"></i>
                                    )}
                                  </CardHeader>
                                  {/* <CSSTransition
                                    in={this.state.toggleQuestion === index}
                                    timeout={600}
                                    classNames="collapse"
                                    unmountOnExit
                                  >


                                    <InputField
                                      className="card-body"
                                      innerRef={(val) =>
                                        (this[item.field] = val)
                                      }
                                      inputName={item.field}
                                      controlType={item.type}
                                      value={storedStatusObjects[key1]}
                                      placeholderValue={
                                        item.field
                                      }
                                      onChangeMethod={(e) => {
                                        this.handleChange(e, item.type);
                                      }}
                                      selectval={this.state[`${item?.field}`]}

                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                    />

                                  </CSSTransition> */}
                                  <div
                                    className={`collapse-transition ${this.state.toggleQuestion === index ? "open" : "closed"
                                      }`}
                                  >
                                    <InputField
                                      className="card-body"
                                      innerRef={(val) => (this[item.field] = val)}
                                      inputName={item.field}
                                      controlType={item.type}
                                      value={storedStatusObjects[key1]}
                                      placeholderValue={item.field}
                                      onChangeMethod={(e) => {
                                        this.handleChange(e, item.type);
                                      }}
                                      selectval={this.state[`${item?.field}`]}
                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                    />
                                  </div>

                                </Card>


                              );
                            } else if (
                              item.type === "date"
                              && item.field === "startTime"
                            ) {
                              return (


                                <Card>
                                  {/* <CardHeader onClick={() => this.setState({
                                    toggleQuestion: index
                                  })}>
                                    <span className="font-weight-bold">{item.field}</span>
                                    {this.state.toggleQuestion === index ?
                                      <i class="fas fa-angle-up"></i>
                                      :
                                      <i class="fas fa-angle-down"></i>
                                    }

                                  </CardHeader> */}
                                  <CardHeader onClick={() => this.handleToggle(index)} className="toggle-header">
                                    <span className="font-weight-bold">
                                      {/* {item.field} */}
                                      {item.field === "startTime" ? "Start Time" : item.field}
                                    </span>
                                    {this.state.toggleQuestion === index ? (
                                      <i className="fas fa-angle-up"></i>
                                    ) : (
                                      <i className="fas fa-angle-down"></i>
                                    )}
                                  </CardHeader>
                                  <div
                                    className={`collapse-transition ${this.state.toggleQuestion === index ? "open" : "closed"
                                      }`}
                                  >
                                    {/* <CardBody> */}
                                    <div
                                      xs="12"
                                      className="dateSearch IndexDatatableNeoCol1"
                                      key={index}
                                      style={
                                        {
                                          height: "58px",
                                        }
                                      }
                                    >
                                      <RenderNewDate
                                        inputName={item.field}
                                        controlType={item.type}
                                        inputValue={this.state.startDate}
                                        placeholderValue={item.field}
                                        onChangeMethod={(e) =>
                                          this.handleDate(e, item.field)
                                        }
                                        // onInputChange={this.change}
                                        optionList={item.options}
                                        handleKeyPress={this.keyPress}
                                        dateBg={dateBgClr}
                                        dateClr={dateClr}
                                      />
                                    </div>

                                    {/* </CardBody> */}
                                  </div>
                                </Card>

                              );
                            } else if (
                              (item.type === "date" &&
                                item.field === "expiryTime") ||
                              item.field === "createTime"
                            ) {
                              return (


                                <Card>
                                  <CardHeader onClick={() => this.handleToggle(index)} className="toggle-header">
                                    <span className="font-weight-bold">
                                      {/* {item.field} */}
                                      {item.field === "expiryTime" ? "Expiry Time" : item.field}
                                    </span>
                                    {this.state.toggleQuestion === index ? (
                                      <i className="fas fa-angle-up"></i>
                                    ) : (
                                      <i className="fas fa-angle-down"></i>
                                    )}
                                  </CardHeader>
                                  <div
                                    className={`collapse-transition ${this.state.toggleQuestion === index ? "open" : "closed"
                                      }`}
                                  >
                                    {/* <Collapse isOpen={this.state.toggleQuestion === index ? true : false}> */}
                                    {/* <CardBody> */}
                                    <div
                                      xs="12"
                                      className="dateSearch IndexDatatableNeoCol1"
                                      key={index}
                                      style={{

                                        height: "auto",
                                      }}

                                    >
                                      <RenderNewDate
                                        inputName={item.field}
                                        controlType={item.type}
                                        inputValue={this.state.endDate}
                                        placeholderValue={item.field}
                                        onChangeMethod={(e) =>
                                          this.handleDate(e, item.field)
                                        }
                                        // onInputChange={this.change}
                                        optionList={item.options}
                                        handleKeyPress={this.keyPress}
                                        dateBg={dateBgClr}
                                        dateClr={dateClr}
                                      />
                                    </div>

                                    {/* </CardBody> */}
                                  </div>
                                </Card>

                              );
                            }
                            else if (
                              item.type === "date"
                              && item.field === "validityFromTo"
                            ) {
                              return (


                                <Card>
                                  <CardHeader onClick={() => this.handleToggle(index)} className="toggle-header">
                                    <span className="font-weight-bold">
                                      {/* {item.field} */}
                                      {item.field === "validityFromTo" ? "Validity From To" : item.field}
                                    </span>
                                    {this.state.toggleQuestion === index ? (
                                      <i className="fas fa-angle-up"></i>
                                    ) : (
                                      <i className="fas fa-angle-down"></i>
                                    )}
                                  </CardHeader>
                                  <div
                                    className={`collapse-transition ${this.state.toggleQuestion === index ? "open" : "closed"
                                      }`}
                                  >

                                    <div
                                      xs="12"
                                      className="dateSearch IndexDatatableNeoCol1"
                                      key={index}
                                      style={{

                                        height: "auto",
                                      }}

                                    >

                                      <RenderNewDate2
                                        handleChange={(e) => this.handleDate2(e, item.field)}
                                        darkMode={this.state.darkMode} // Assuming dark mode state is managed in the parent
                                        dateBg={"#fff"} // Example prop
                                        dateClr={"#000"} // Example prop
                                      />

                                    </div>

                                  </div>
                                </Card>

                              );
                            }
                          }
                          else
                            return (
                              <>
                                {!this.props.classList ? (
                                  <div xs="12"></div>
                                ) : (
                                  ""
                                )}
                                {this.props?.computedMatch?.path ===
                                  "/indent-planning" ? (
                                  <div xs="12"></div>
                                ) : (
                                  " "
                                )}
                                {this.props?.computedMatch?.path ===
                                  "/assigned-indent" ? (
                                  <>
                                    <div xs="12"></div>
                                    <div xs="12"></div>
                                  </>
                                ) : (
                                  " "
                                )}

                                <div
                                  xs={
                                    this.props.resetCol
                                      ? this.props.resetCol
                                      : "12"
                                  }
                                  className="IndexDatatableNeoCol1"
                                  key={index}
                                  style={{
                                    // flex: columnWidthArray[index],
                                    alignSelf: "center",
                                  }}
                                >
                                  <div
                                    className="filter-button-view"
                                  >
                                    <Button
                                      className={
                                        "resetSearchBar apply-reset"
                                      }
                                      onClick={this.reset}
                                    >
                                      {userLang?.common?.RESET}
                                    </Button>
                                    {/* <Button
                                      className={
                                        "resetSearchBar apply-filter"
                                      }
                                      onClick={() => {
                                        this.setState({ showFilter: false });
                                      }}
                                    >
                                      Apply Filter
                                    </Button> */}
                                    <Button
                                      className="resetSearchBar apply-filter"
                                      onClick={() => {
                                        this.handleSubmitSelections();
                                        this.setState({ showFilter: false });
                                      }}
                                      onKeyDown={(e) => this._handleKeyDown(e)} // Correctly pass the event
                                      // onKeyDown={this._handleKeyDown()}
                                    >
                                      Apply Filter
                                    </Button>


                                  </div>
                                </div>
                              </>
                            );
                          return true;
                        })
                        : null}


                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#2800FC",
                        color: "#FFFFFF",
                        width: "24px",
                        height: "20px",
                        marginTop: "-12px",
                        position: "absolute",
                        borderRadius: "4px",
                      }}
                      onClick={() => {
                        // this.setState({ searchToggle: true });
                      }}
                    >
                      <i className="fas fa-search"></i>
                    </div>
                  )
                ) : null}
              </div>
            </MDBModalBody>
            {/* <MDBModalFooter>
            <Button color="secondary" onClick={this.props.cancelTripModal}>
              {userLang?.common?.CLOSE}
            </Button>
          </MDBModalFooter> */}

          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

export default Index;
