import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { clearAgreementstate } from "../../store/actions";
import { InputGroup } from "reactstrap";
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
import plusIcon from "./assets/plus.png";
import refreshIcon from "./assets/refresh-cw.png";
import plusDarkIcon from "./assets/plusDark.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));


class QuickFilters extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          hops: [],
          vehicleTypes: [],
          // searchBar: [],
          searchFilters: {},
          dateValue: [null, null],
          showCalender: false,
          userLangs: undefined,
        };
      }


      componentDidUpdate() {
        if (
          this.state.userLangs === undefined &&
          (this.state.userLangs !== userLang || userLang === null)
        ) {
          this.setState({
            userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
          });
        }
      }




      render(){

        userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
        let mode = localStorage.getItem("Theme")
        ? localStorage.getItem("Theme")
        : null;
        var darkMode = mode === "dark__mode" ? true : false;


        return(

          <React.Fragment>
            <Card className="darkBgQuickFilter" style={{marginBottom:"-30px"}}>
              <div className="quickStylesBar agreement-filters">
                <Row>
                  <Col xs={0} md={6} lg={7}></Col>
                 
                  <Col xs={12} className="mobileDisplay">
                    <div className="d-flex justify-content-end">
                      <div>
                        <Button
                        color=""
                          style={{
                            minWidth: "70%",
                          }}
                          className="mobile-reset reset"
                          onClick={() => this.props?.reset()}
                        >
                          <img className="mobile-rest-img" src={refreshIcon} alt=""></img>
                        </Button>
                      </div>

                      <div className="">
                        <Link to="/SecondaryBilling/AddAgreement"> 
                          <div className="tooltipIcon">
                            <Button className="iconButtons" color="">
                              <img
                                style={{ margin: "-4px" }}
                                src={plusIcon}
                                alt="exp"
                              />
                            </Button>
                            <span
                              className="tooltipIcontext"
                              style={{ width: "170px", left: "-4px" }}
                            >
                              &nbsp; {this.state.userLangs?.invoice?.ADD_AGREEMENT}
                              &nbsp;
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={5} lg={3}>
                    <div className="input-border mt-2">
                      <InputGroup>
                        <input
                          style={{ height: "38px" }}
                          className="form-control form-control-sm w-100 sharp-corners-right rounded-left"
                          type="text"
                          name="SearchValue"
                          value={this.props.SearchValue}
                          onChange={(e) => this.props?.searchValueSetter(e)}
                          placeholder="Search By Transporter..."
                          aria-label="Search"
                          onKeyDown={(e) => this.props?.handleKeyDown(e)}
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.searchFilter()}
                          // onClick={(e) => this.props?.handleKeyDown(e)}
                        >
                          <img
                            style={{
                              flex: "no-wrap",
                            }}
                            className="pl-1"
                            src={darkMode?searchDark:searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </Col>

                  <Col xs={2} md={4} lg={2} className="desktopDisplay">
                    <div className="d-flex flex-row">
                      {/* <div>
                        <Button
                          onClick={() => {
                            this.setState({
                              showCalender: !this.state.showCalender,
                            });
                          }}
                          className="iconButtons mr-2"
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={calendarIcon}
                            alt="cal"
                          />
                        </Button>
                        {this.state.showCalender ? (
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <StaticDateRangePicker
                                displayStaticWrapperAs="desktop"
                                calendars={1}
                                format={"MM-DD-YYYY"}
                                value={this.state.dateValue}
                                onChange={(newValue) => {
                                  this.setState({ dateValue: newValue }, () => {
                                    if (this.state.dateValue[1] !== null) {
                                      this.setState({
                                        showCalender: !this.state.showCalender,
                                      });
                                      // this.props.handleChange(this.state.dateValue);
                                    }
                                  });
                                }}
                                renderInput={(startProps, endProps) => (
                                  <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                  </React.Fragment>
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        ) : null}
                      </div> */}
                      <div
                        className="agreementDesktopRefresh"
                        // style={{ width: "70%", float: "left" }}
                      >
                        <Button
                          onClick={() => this.props?.reset()}
                          className="reset"
                        >
                          {this.state.userLangs?.common?.RESET}
                        </Button>
                      </div>
                      <div className="">
                        <Link
                          to="/SecondaryBilling/AddAgreement"
                          onClick={this.props.clearAgreementstate}
                        >
                          <div className="tooltipIcon">
                            <Button className="iconButtons iconDarkButtons" color="">
                              <img
                                style={{ margin: "-4px" }}
                                src={darkMode?plusDarkIcon:plusIcon}
                                alt="exp"
                              />
                            </Button>
                            <span
                              className="tooltipIcontext"
                              style={{ width: "170px", left: "-4px" }}
                            >
                              &nbsp; {this.state.userLangs?.invoice?.ADD_AGREEMENT}
                              &nbsp;
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Col>

                </Row>
              </div>
            </Card>
          </React.Fragment>

        )
      }

}



const mapStateToProps = (state) => ({
    billing: state.SecondaryBilling.SecondaryBilling,
});
  
export default connect(mapStateToProps, { clearAgreementstate })(QuickFilters);
