import React, { Component } from "react";
import CancelIndentModal from "../../../CancelIndentModal";
import {
  Row,
  Col,
  Button,
  Label,
  Input,
  FormGroup,
  Container,
} from "reactstrap";
import Select from "react-select";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { restrict } from "../../../restrict";
import { PERMS } from "../../../../enum/perms.enum";
import {
  TranspoterHeaderDataSecondary,
  searchBarTransporterSecondary,
} from "./TranspoterRowHeadersSecondary";
import { CustomersHeaderData, searchBarSecondary } from "./CustomerRowHeaders";
import Index from "../../../../components/Common/DataTableNeo/Index";
import { fetchSourceSecondary } from "../../../../store/secondaryIndentPlanning/actions";
import { fetchVehicleTypeSecondary } from "../../../../store/secondaryIndentPlanning/actions";
import { fetchTransporterSecondary } from "../../../../store/secondaryIndentPlanning/actions";
import { fetchCustomersSecondary } from "../../../../store/secondaryIndentPlanning/actions";
import {
  createIndentSecondary,
  costDisplayFormSecondary,
} from "../../../../store/secondaryIndentPlanning/actions";
import { fetchCustomerOptionsSecondary } from "../../../../store/secondaryIndentPlanning/actions";

import "./indentDetails.css";
import { relative } from "path";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var yesterday = moment().subtract(1, "day");

const formSideImg = require("../../../../assets/images/" +
  process.env.REACT_APP_INDENT_IMAGE);
const checkedBox = require("../../../../../src/assets/images/checkedBox.svg");
const unCheckedBox = require("../../../../../src/assets/images/unCheckedBox.svg");
const downArrow = require("../../../../../src/assets/images/downArrow.svg");
const cross = require("../../../../../src/assets/images/cross.svg");

const initialState = () => ({
  customText: "down",
  modal: false,
  customerModal: false,
  heads: [],
  source: [],
  customers: [],
  hops: [],
  vehicleType: [],
  transporter: [],
  rejectionHistory: [],
  form: {
    source: "",
    customers: [],
    date: moment(new Date()).add(1, "day").startOf("day").add(15, "hours"),
    expirydate: moment(new Date())
      .add(1, "day")
      .startOf("day")
      .add(11, "hours"),
    vehicleType: "",
    transporter: "",
    pickUpLocation: "",
    comments: "",
  },
  offset: 0,
  disableHop: true,
  onSubmitDisable: false,
  actualDate: "",
  rejectModal: false,
  userLangs: undefined,
  customerDropdown: false,
  checkChanged: false,
  searchKey: "",
  searchValue: "",
  searchFilters: {},
  disabled: true,
  keySelected: false,
  searchOn: false,
  radioFilter: "",
  dataLoading: false,
  freightCostDisplay: "",
  transporterError: false,
  customerError: false,
});
var darkMode;
var searchValues = {};

class SecondaryIndentDetails extends Component {
  constructor(props) {
    super(props);

    this.state = initialState();
    this.hops = [];
    this.customers = [];
    this.vehicleType = [];
    this.transporter = [];
    this.startdate = React.createRef();
    this.expirydate = React.createRef();
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.INDENT_CREATE);
        //console.log(redirect, "redirect");

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside);

    this.fetchSource();

    if (this.props.location.indent) {
      let indData = this.formSet();
      let { rejectionHistory = [] } = this.props.location.indent;
      // console.log(this.props.location.indent);
      this.setState(
        {
          form: { ...indData, vehicleType: indData.vehicleType },
          rejectionHistory,
        },
        () => this.fetchTransporter(indData)
      );
    }
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  formSet = () => {
    let formData = {};
    if (this.props.location.indent) {
      const {
        source,
        customers,
        vehicle,
        transporter,
        comments,
        startTime,
        expiryTime,
        pickUpLocation,
      } = this.props.location.indent;

      formData = {
        source,
        customers,
        vehicleType: vehicle,
        expirydate: expiryTime
          ? moment(expiryTime).format("YYYY-MM-DD HH:mm:ss")
          : "---",
        date: startTime
          ? moment(startTime).format("YYYY-MM-DD HH:mm:ss")
          : "---",
        transporter,
        comments,
        pickUpLocation,
      };

      // this.setState({ form: formData });
    }
    return formData;
  };

  fetchVehicleType = (data) => {
    data = data || {};
    this.props.fetchVehicleTypeSecondary({
      callback: (res) => {
        this.vehicleType = [];
        if (res?.data?.length > 0) {
          res.data.docs.forEach((ele) => {
            this.vehicleType.push({
              label: ele.label + " - " + ele.vehicleTypeCode,
              value: {
                id: ele._id,
                // latitude: ele.cordinates ? ele.cordinates[0] : null,
                // longitude: ele.cordinates ? ele.cordinates[1] : null,
              },
            });
          });
          this.setState({ vehicleType: this.vehicleType });
        }
      },
    });
  };

  fetchTransporter = (data) => {
    data = data || {};
    let source = data.source?.value?.id || this.state.form.source?.value?.id;
    let vehicleType =
      data.vehicleType?.type?.value?.id ||
      this.state.form.vehicleType?.value?.id;
    this.props.fetchTransporterSecondary(source, vehicleType, (res) => {
      this.transporter = [];
      // console.log(data.vehicleType?.type?.value?.id);
      // console.log(this.state.form.vehicleType?.value?.id);
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          let {
            _id,
            name,
            userId,
            role,
            userType,
            updatedAt,
            cost,
            freightCode,
            validityStatus,
          } = ele;
          // console.log("LANE LOT", ele.laneLot);
          this.transporter.push({
            _id: _id,
            freightCode: freightCode,
            Name: name,
            cost: cost ? "Rs. " + cost.main : "unavailable",
            status: (
              <div>
                {this.state.rejectionHistory.some(
                  (e) => e.transporter === _id
                ) ? (
                  <span>
                    <i className="fas fa-circle text-danger"></i>Rejected
                  </span>
                ) : (
                  <span>-------</span>
                )}
              </div>
            ),

            Action: (
              <>
                <Button
                  style={{
                    backgroundColor: darkMode ? "#24243C" : "#FFF",
                    border: "none",
                  }}
                  disabled={validityStatus === "Invalid" ? true : false}
                >
                  <svg
                    onClick={() => this.selectTransporter(ele)}
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                      fill={darkMode ? "#222238" : "#F1F5F7"}
                    />
                    <path
                      d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V22C0.25 24.0711 1.92893 25.75 4 25.75H22C24.0711 25.75 25.75 24.0711 25.75 22V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                      stroke="#2800FC"
                      strokeWidth="0.5"
                    />
                    <path
                      d="M13 8V18"
                      stroke="#2800FC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 13H18"
                      stroke="#2800FC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </>
            ),
          });
        });
      }
      this.setState(
        {
          transporter: this.transporter,
        },
        () => {
          this.getAllData();
          // console.log(this.state.transporter);
          // console.log(this.state.head);
        }
      );
    });
  };

  getCustomers = () => {
    return this.state.customers.map((e, index) => {
      const isChecked = this.state.form?.customers?.find(
        (e) =>
          (e?.value?.id ? e.value.id : e._id) == this.state.customers[index]._id
      )
        ? true
        : false;

      const cust = {
        ...e,
        isChecked,
        Action: (
          <>
            <div onClick={() => this.selectCustomers(e)}>
              {isChecked ? (
                <img src={checkedBox} />
              ) : (
                <img src={unCheckedBox} />
              )}
            </div>
          </>
        ),
      };
      return cust;
    });
  };

  fetchCustomers = () => {
    let data = this.state.searchFilters;
    let filters = data || {};
    // console.log("fetchCustomers",filters);
    data = data || {};
    let source = data.source?.value?.id || this.state.form.source?.value?.id;
    let transporter =
      data.transporter?.value?.id || this.state.form.transporter?.value?.id;
    let freightCode =
      data.transporter?.freightCode || this.state.form.transporter?.freightCode;
    let vehicleType = "";
    // data.vehicleType?.type?.value?.id ||
    this.state?.form?.vehicleType?.type?.value?.id
      ? (vehicleType = this.state.form?.vehicleType?.type?.value.id)
      : (vehicleType = this.state.form?.vehicleType?.value?.id);
    this.props.fetchCustomersSecondary(
      source,
      transporter,
      freightCode,
      vehicleType,
      (res) => {
        this.customers = [];
        if (res.data?.length > 0) {
          res.data.forEach((ele) => {
            let {
              _id,
              customerNumber,
              address,
              customerId,
              customerName,
              shipToCode,
            } = ele;

            this.customers.push({
              _id: _id,
              customerName: customerName,
              Number: customerNumber ? customerNumber : "N/A",
              ShipToCode: shipToCode ? shipToCode : "N/A",
              Address: address
                ? `${address?.address1}, ${address?.address2}`
                : "N/A",
              City: address?.city ? address?.city : "N/A",
              PostalCode: address?.postalCode ? address?.postalCode : "N/A",
              State: address?.state ? address?.state : "N/A",
              status: (
                <div>
                  {this.state.rejectionHistory.some(
                    (e) => e.transporter === _id
                  ) ? (
                    <span>
                      <i className="fas fa-circle text-danger"></i>Rejected
                    </span>
                  ) : (
                    <span>-------</span>
                  )}
                </div>
              ),
            });
          });
        }
        this.setState(
          {
            customers: this.customers,
          },
          () => {}
        );
      },
      filters,
    );
  };

  fetchRoutes = () => {
    this.setState({ route: "DIRECT" });
  };

  fetchSource = () => {
    this.props.fetchSourceSecondary((res) => {
      this.hops = [];
      if (res?.data?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
        this.setState({ source: this.hops });
      }
    });
  };

  disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  customText = () => {
    if (this.state.customText === "up") {
      this.setState({ customText: "down" });
    } else {
      this.setState({ customText: "up" });
    }
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleCustomers = () => {
    localStorage.removeItem("mySearchFilter_/secondary-add-indent");
    localStorage.removeItem("SecondaryCustomersFilters");
    this.setState({ customerModal: !this.state.customerModal });
  };

  customerDropdown = () => {
    this.setState({ customerDropdown: !this.state.customerDropdown });
  };

  handleClickOutside = (event) => {
    if (event.target) {
      if (
        this.wrapperRef &&
        !this.wrapperRef?.current?.contains(event.target)
      ) {
        this.setState({ customerDropdown: false });
      }
    }
  };

  selectTransporter = (data) => {
    let formData = this.state.form;
    formData.transporter = {
      name: data.name,
      value: { id: data._id },
      freightCode: data.freightCode,
    };
    this.setState({ form: formData, modal: !this.state.modal }, () => {
      this.fetchCustomers();
    });
  };

  selectCustomers = (data) => {
    // console.log(this.state.form);
    // console.log(data);
    let formData = this.state.form;
    if (
      formData.customers.find(
        (e) => (e?.value?.id ? e.value.id : e._id) == data._id
      )
    ) {
      formData.customers = formData.customers.filter(
        (e) => (e?.value?.id ? e.value.id : e._id) !== data._id
      );
    } else {
      formData.customers.push({
        customerName: data.customerName,
        value: { id: data._id },
        isChecked: !data.isChecked,
        customerNumber: data.Number,
        shipToCode: data.ShipToCode,
        city: data.City,
      });
    }
    this.setState({ form: formData, checkChanged: !this.state.checkChanged });
    let {
      source,
      customers,
      vehicleType,
      transporter,
      date,
      expirydate,
      pickUpLocation,
      comments,
    } = this.state.form;

    let form = {
      source: source?.value?.id,
      customers: customers?.map((item) =>
        item?.value?.id ? item?.value?.id : item?._id
      ),
      vehicleType: vehicleType?.type?.value?.id || vehicleType?.value?.id,
      transporter: transporter?.value.id,
      startTime: date,
      expiryTime: expirydate,
      pickUpLocation: pickUpLocation || "",
      comments: comments || "",
    };
    // console.log("have to add logic ");
    this.props.costDisplayFormSecondary(form, (res) => {
      // console.log(res.data[0]);
      this.setState({ freightCostDisplay: res.data[0].FREIGHT_COST });
    });
    // console.log(this.state.freightCostDisplay);
  };

  handleSubmitChange = (e) => {
    // console.log("value selected is ...", e.target.value);
    const name = e.target.name;
    let value = e.target.value;
    let formData = this.state.form;
    if (name === "date" || name === "expirydate") {
      // let dt =new Date(e.target.value).getHours()
      // if(dt==0){
      //   yesterday.add(24,'h');
      // }
      if (name === "date") {
        let isBeforeExpiry = moment(value).isBefore(
          moment(formData["expirydate"])
        );
        //console.log("IS BEFORE EXPIRY: ", isBeforeExpiry);
        this.setState({
          actualDate: isBeforeExpiry ? "" : value,
        });

        //if (isBeforeExpiry)
        //  formData["expirydate"] = moment().add(2, "hours").format();
      }

      value = moment(value).format();
      //console.log("DATE_ ", value);
    }

    formData[`${name}`] = value;
    //console.log("\n==================\n");
    //console.log(formData["date"], ":", formData["expirydate"]);
    this.setState({ form: formData }, () => {
      if (name === "source") {
        this.fetchVehicleType();
        this.setState({ disableHop: !this.state.disableHop });
      }
      if (name === "vehicleType") {
        this.fetchRoutes();
        this.fetchTransporter();
      }
      if (name === "transporter") {
        this.fetchCustomers();
      }
    });
  };
  toggleRejectModal = () => {
    this.setState({ rejectModal: !this.state.rejectModal });
  };

  handleSubmitSave = (formValues) => {
    let {
      source,
      customers,
      vehicleType,
      transporter,
      date,
      expirydate,
      pickUpLocation,
      comments,
    } = this.state.form;

    if (!transporter) {
      this.setState({ transporterError: true });
      return;
    }
    if (transporter) {
      this.setState({ transporterError: false });
    }

    if (!customers || customers.length === 0) {
      this.setState({ customerError: true });
      return;
    }

    if (customers) {
      this.setState({ customerError: false });
    }

    let form = {
      source: source?.value?.id,
      customers: customers?.map((item) =>
        item?.value?.id ? item?.value?.id : item?._id
      ),
      vehicleType: vehicleType?.type?.value?.id || vehicleType?.value?.id,
      transporter: transporter?.value.id,
      startTime: date,
      expiryTime: expirydate,
      pickUpLocation: pickUpLocation || "",
      comments: comments || "",
    };
    this.setState({ onSubmitDisable: true });
    if (this.props.location.mode === "edit") {
      form.indentID = this.props.location.indentID;
    }

    this.props.createIndentSecondary(form, (res) => {
      this.setState({ onSubmitDisable: false });
      // // //console.log(this.props, "create res", this.state.form);
      if (res && res["message"] && res["message"] === "Successfully Added") {
        // this.props.history.push("/indent-planning");
        window.history.replaceState(null, null, "/");
        this.props.history.push("/secondary-indent-planning");
        NotificationManager.success(res["message"], "Success");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.props.history.push("/secondary-indent-planning");
      }
    });
  };

  reset = (cb) => {
    this.setState(initialState(), () => {
      this.startdate.current.state.value = "";
      this.startdate.current.state.inputValue = "";
      this.expirydate.current.state.value = "";
      this.expirydate.current.state.inputValue = "";
      cb();
      this.fetchSource();
      this.setState({ transporterError: false });
      this.setState({ customerError: false });
    });
  };

  resetCustomerModal = () => {
    localStorage.removeItem("SecondaryCustomersFilters");
    this.setState(
      { searchFilters: {}, active: false, dataLoading: true },
      () => {
        searchValues = {};
        this.fetchCustomers();
      }
    );
  };

  // searchValueAssignerFunction = (name, value, type) => {
  //   if (typeof value === "object") {
  //     if (type === "date") searchValues[name] = value;
  //     else searchValues[name] = value.id;
  //   } else {
  //     searchValues[name] = value;
  //   }
  //   this.setState({ searchFilters: searchValues }, () => {
  //     localStorage.setItem(
  //       "SecondaryCustomersFilters",
  //       JSON.stringify(this.state.searchFilters),
       
  //     );
  //     console.log(this.state.searchFilters, "-----Filters-")
  //     if (type === "select" || type === "date") 
  //       this.getAllData();
  //   });
  // };
  searchValueAssignerFunction = (name, value, type) => {
    this.setState({ dataLoading: true });
    let searchValues = { [name]: value };
  
    this.setState({ searchFilters: searchValues }, () => {
      // localStorage.setItem("filters", JSON.stringify(this.state.searchFilters));
      // console.log(this.state.searchFilters, "-----Filters-");
      
      // this.getAllData();  
      this.fetchCustomers();
    });
  };
  // filterDataTranspotersAndTrips = (e) => {
  //   this.filterCustomer(e);
  // };

  // filterCustomer = (e) => {
  //   if (e?.length > 0) {
  //     let filterData = {
  //       offset: 0,
  //       limit: 10,
  //       q: e,
  //     };
  //     this.fetchCustomersOption(filterData);
  //   }
  //   if (e?.length === 0) {
  //     let filterData = {
  //       offset: 0,
  //       limit: 10,
  //       q: "",
  //     };
  //     this.fetchCustomersOption(filterData);
  //   }
  // };

  // fetchCustomersOption = () => {
  //   this.props.fetchCustomerOptionsSecondary((res) => {
  //     let customerName = [];
  //     if (res?.data?.docs?.length > 0) {
  //       res.data.docs.forEach((ele) => {
  //         customerName.push({
  //           value: ele.customerName ? ele.customerName : "",
  //           label: `${ele.customerName ? ele.customerName : "N/A"} - ${
  //             ele.customerNumber ? ele.customerNumber : "N/A"
  //           } - ${ele.shipToCode ? ele.shipToCode : ele.shipToCode} `,
  //         });
  //       });
  //     }

  //     this.setState(
  //       { customerName: customerName },
  //       () => {
  //         searchBarSecondary.searchBarData.forEach((obj) => {
  //           if (obj.field === "customerName") {
  //             obj.options = this.state.customerName;
  //           }
  //         });
  //       },
  //       () => {}
  //     );
  //   });
  // };

  // getAllData = () => {
  //   // this.setState({
  //   //   dataLoading: true,
  //   // });
  //   // console.log(this.state.searchFilters, "-----Filters22-");

  //   this.props.fetchCustomerOptionsSecondary((res) => {
  //     let customerName = [];
  //     if (res?.data?.docs?.length > 0) {
  //       res.data.docs.forEach((ele) => {
  //         customerName.push({
  //           value: ele.customerName ? ele.customerName : "",
  //           label: `${ele.customerName ? ele.customerName : "N/A"} - ${
  //             ele.customerNumber ? ele.customerNumber : "N/A"
  //           } - ${ele.shipToCode ? ele.shipToCode : ele.shipToCode} `,
  //         });
  //       });
  //     }

  //     this.setState(
  //       { customerName: customerName },
  //       () => {
  //         searchBarSecondary.searchBarData.forEach((obj) => {
  //           if (obj.field === "customerName") {
  //             obj.options = this.state.customerName;
  //           }
  //         });
  //       },
  //       () => {}
  //     );
  //   });

  //   // if (localStorage.getItem("SecondaryCustomersFilters")) {
  //   //   this.fetchCustomers();
  //   // }
  // };
  getAllData = () => {
    this.props.fetchCustomerOptionsSecondary((res) => {
      let customerName = [];
  
      if (res?.data?.docs?.length > 0) {
        let uniqueCustomers = new Set(); // Set to track unique customer names
  
        res.data.docs.forEach((ele) => {
          let name = ele.customerName ? ele.customerName : "";
          let label = `${ele.customerName ? ele.customerName : "N/A"} - ${
            ele.customerNumber ? ele.customerNumber : "N/A"
          } - ${ele.shipToCode ? ele.shipToCode : "N/A"}`;
  
          // Check if customerName already exists in Set
          if (!uniqueCustomers.has(name)) {
            uniqueCustomers.add(name); // Add to Set to prevent duplicates
            customerName.push({ value: name, label: label });
          }
        });
      }
  
      this.setState({ customerName: customerName }, () => {
        searchBarSecondary.searchBarData.forEach((obj) => {
          if (obj.field === "customerName") {
            obj.options = this.state.customerName;
          }
        });
      });
    });
    // this.fetchCustomers();
  };
  


  valid = function (current) {
    var time = new Date(current).getHours();
    // yesterday.subtract(time)
    yesterday = moment(yesterday).subtract(time);
    // yesterday=moment(yesterday).subtract(11,"hours")
    return current.isAfter(yesterday);
  };

  validExpiry = (current) => {
    let day = new Date(this.state.form.date);
    let expiryEndDay = moment(day).add(1, "days");
    let expiryStartDay = moment(day).subtract(1, "days");
    if (day.getDate() === new Date().getDate()) {
      return current.isBefore(expiryEndDay) && current.isAfter(expiryStartDay);
    } else {
      // console.log("notequals", new Date().getDate(), day.getDate());
      return (
        current.isBefore(day) &&
        current.isAfter(moment(new Date()).subtract(1, "days"))
      );
    }
  };

  handleBlurFilter = (e) => {
    if (e.target.name === "source") {
      if (e.target.value.length >= 3) {
        if (this.state.source.length > 0) {
          let filter = this.state.source.filter((st, i) => {
            return st.label.includes(e.target.value.toUpperCase());
          });
          if (this.Timer) {
            clearTimeout(this.Timer);
          }
          if (Array.isArray(filter)) {
            if (filter.length <= 0) {
              this.Timer = setTimeout(() => {
                this.fetchSource("source", e.target.value);
              }, 500);
            }
          }
        }
      }
    }
  };
  checkChange = (e, i) => {
    let ch = this.state.check;
    let value = e.target.value;
    ch[i] = !this.state.check[i];
    this.setState({ check: ch }, () => {
      let vi = [...this.state.loadingPoints];
      if (ch[i] === false) {
        if (vi.length > 0) {
          let res = vi.filter((v) => v === value);
          this.setState({ loadingPoints: res });
        }
      } else {
        vi.push(value);
        this.setState({ loadingPoints: vi });
      }
    });
  };

  removeCustomer = (index) => {
    const formData = this.state.form;
    formData.customers.splice(index, 1);
    this.setState({ form: formData });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const validationSchema = Yup.object({
      source: Yup.object().required(
        this.state.userLangs?.planning?.SOURCE_IS_REQUIRED
      ),
      // customers: Yup.array().min(
      //   1,
      //   this.state.userLangs?.planning?.CUSTOMERS_IS_REQUIRED
      // ),
      vehicleType: Yup.object().required(
        this.state.userLangs?.planning?.VEHICLE_TYPE_IS_REQUIRED
      ),
      // transporter: Yup.object().required(
      //   this.state.userLangs?.planning?.TRANSPORTER_IS_REQUIRED
      // ),

      date: Yup.date()
        .required(this.state.userLangs?.planning?.START_DATE_IS_REQUIRED)
        .test(
          "StartDatetest",
          this.state.userLangs?.planning?.REPORT_DATE,
          function (startdate) {
            let expirydate = this.parent["expirydate"];
            return expirydate && moment(startdate).isSameOrAfter(expirydate);
          }
        ),
      expirydate: Yup.date()
        .required(this.state.userLangs?.planning?.EXPIRY_DATE_IS_REQUIRED)
        .test(
          "ExpiryDatetest",
          this.state.userLangs?.planning?.EXPIRY_DATE,
          function (expirydate) {
            let startdate = this.parent["date"];
            return startdate && moment(expirydate).isSameOrBefore(startdate);
          }
        ),
    });

    let initTogglestorage = localStorage.getItem("Theme");
    if (
      this.props.switchsTheme === "dark__mode" ||
      initTogglestorage === "dark__mode"
    ) {
      darkMode = true;
    } else if (
      this.props.switchsTheme === "light__mode" ||
      initTogglestorage === "light__mode"
    ) {
      darkMode = false;
    } else {
      darkMode = false;
    }
    const resetButtonStyle = {
      backgroundColor: "#0AB38C",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
      margin: "auto",
      marginRight: "9px",
    };

    const resetButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };
    const submitButtonStyle = {
      backgroundColor: darkMode ? "#5E40FF" : "#05284E",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
      margin: "9px",
      marginRight: "9px",
    };

    const submitButtonStyleIndent = {
      backgroundColor: darkMode ? "#5E40FF" : "#05284E",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
      margin: "auto 9px auto auto",
    };

    const submitButtonCustomerStyle = {
      position: "absolute",
      bottom: "16px",
      right: "40px",
      backgroundColor: darkMode ? "#5E40FF" : "#05284E",
      borderRadius: "3px",
      height: "50px",
      width: "120px",
    };

    const submitButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };
    const cancelIndentButtonStyle = {
      backgroundColor: "#dc3545",
      borderRadius: "3px",
      height: "50px",
      width: "150px",
      margin: "auto",
      marginRight: "9px",
    };

    const cancelIndentButtonSpanStyle = {
      margin: "auto",
      fontWeight: "normal",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Verdana, sans-serif",
      color: "#FFFFFF",
    };

    const { onSubmitDisable } = this.state;
    const form = this.state.form;

    const rejection = this.props.location?.indent?.rejectionHistory || [];
    const customStyles = {
      control: (style) => {
        return {
          ...style,
          border: "0.5px solid #AEAEAE",
          borderRadius: "5px",
          height: "50px",
          backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
        };
      },
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      indicatorsContainer: (style) => {
        return {
          ...style,
          //backgroundColor: "#FFFFFF",
          width: "50px",
          margin: "auto",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#B9B9B9",
          height: "24px",
          margin: "auto",
          //marginRight:"13px"
        };
      },
      dropdownIndicator: (style) => ({
        ...style,
        svg: {
          fill: "#2800FC",
        },
        margin: "auto",
      }),
    };
console.log(`searchBarSecondary`,searchBarTransporterSecondary);

    return (
      <React.Fragment>
        <CancelIndentModal
          isOpen={this.state.rejectModal}
          toggle={this.toggleRejectModal}
          indentIndex={this.props.location?.indent?._id}
          refreshData={() => {}}
        />

        <div className="mt-2">
          <Container
            style={{
              minWidth: "100%",
            }}
          >
            <div
              className={`containerWhiteDivider mt-1  mb-3 ${
                darkMode ? "darkBackgroundList" : ""
              }`}
            ></div>
            <Modal
              isOpen={this.state.modal}
              toggle={() => this.toggle()}
              size="xl"
              centered
              className="TranspoterModel"
            >
              <Row className="mt-2">
                <Col>
                  <ModalHeader>
                    {this.state.userLangs?.common?.TRANSPORTER}{" "}
                  </ModalHeader>
                </Col>
                <Col className="text-right">
                  <i
                    className="fas fa-times closemodal1 pr-2 text-right"
                    onClick={() => this.toggle()}
                  ></i>
                </Col>
              </Row>

              <ModalBody className="mb-4 tw-w-full tw-overflow-auto">
                <Index
                  {...this.props}
                  headers={TranspoterHeaderDataSecondary}
                  data={this.state.transporter}
                  searchable={false}
                  searchBar={searchBarTransporterSecondary}
                  hidesearchBar={true}
                />
                {/* <DataTable
                  heads={this.state.heads}
                  data={this.state.transporter}
                  hidden={["_id", "laneLot"]}
                  selected={this.selectTransporter}
                /> */}
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.customerModal}
              toggle={() => this.toggleCustomers()}
              size="xl"
              centered
              className="CustomersModel"
            >
              <Row className="mt-2">
                <Col>
                  <ModalHeader>
                    {this.state.userLangs?.planning?.CUSTOMERS}{" "}
                  </ModalHeader>
                </Col>
                <Col className="text-right">
                  <i
                    className="fas fa-times closemodal1 pr-2 text-right"
                    onClick={() => this.toggleCustomers()}
                  ></i>
                </Col>
              </Row>

              <ModalBody className="mb-4 tw-w-full tw-overflow-auto">
                <Index
                  {...this.props}
                  headers={CustomersHeaderData}
                  data={this.getCustomers()}
                  searchable={true}
                  searchBar={searchBarSecondary}
                  checkChanged={this.state.checkChanged}
                  reset={this.resetCustomerModal}
                  searchValueAssignerFunction={this.searchValueAssignerFunction}
                  getAllData={this.getAllData}
                  // filterDataTranspotersAndTrips={
                  //   this.filterDataTranspotersAndTrips
                  // }
                  hidesearchBar={true}

                  // dataLoading={this.state.dataLoading}
                />
                {/* <DataTable
                  heads={this.state.heads}
                  data={this.state.transporter}
                  hidden={["_id", "laneLot"]}
                  selected={this.selectTransporter}
                /> */}
              </ModalBody>
              <Button
                style={submitButtonCustomerStyle}
                disabled={onSubmitDisable}
                type="submit"
                onClick={() => this.toggleCustomers()}
              >
                <span style={submitButtonSpanStyle}>Submit</span>
              </Button>
            </Modal>
          </Container>

          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            className={`gridContainer ${
              darkMode ? "darkBackgroundBorder" : ""
            }`}
            style={{
              width: "100%",
            }}
          >
            <Grid item xs={4} md={4} className="mobileIndentImg">
              <div className="">
                <img
                  src={formSideImg}
                  alt="placeholder"
                  style={{ width: "100%" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Formik
                initialValues={
                  this.props.location.mode === "edit" ? this.formSet() : form
                }
                validationSchema={() => validationSchema}
                onSubmit={(formValues) => this.handleSubmitSave(formValues)}
                validateOnChange={false}
              >
                {(formik) => {
                  const {
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                    resetForm,
                    setFieldValue,
                  } = formik;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid
                        spacing={2}
                        container
                        style={{ margin: "2%" }}
                        className="darkDropDownAddIndent"
                      >
                        {/* SOURCE */}
                        <Grid item xs={12} md={6}>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.SOURCE}{" "}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>

                          <Select
                            // value={this.state.source.find(
                            //   (x) => x.value === values.source
                            // )}
                            styles={customStyles}
                            name="source"
                            value={form?.source || null}
                            onChange={(e) => {
                              setFieldValue("source", e);
                              this.handleSubmitChange({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            isDisabled={rejection.length > 0 ? true : false}
                            options={this.state.source}
                            onInputChange={(e) => {
                              this.handleBlurFilter({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            classNamePrefix="select2-selection"
                            id="source"
                            className={
                              errors.source && touched.source
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="source"
                            component="span"
                            className="error"
                          />
                        </Grid>

                        {/* VEHICLE TYPE */}
                        <Grid item xs={12} md={6} className="indentFeildPd">
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.VEHICLE_TYPE}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <Select
                            value={
                              form.vehicleType.type
                                ? form.vehicleType.type
                                : form.vehicleType
                            }
                            name="vehicleType"
                            styles={customStyles}
                            isDisabled={rejection.length > 0 ? true : false}
                            onChange={(e) => {
                              setFieldValue("vehicleType", e);
                              this.handleSubmitChange({
                                target: {
                                  name: "vehicleType",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.vehicleType}
                            classNamePrefix="select2-selection"
                            id="vehicleType"
                            onBlur={handleBlur}
                            className={
                              errors.vehicleType && touched.vehicleType
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="vehicleType"
                            component="span"
                            className="error"
                          />
                        </Grid>

                        {/* TRANSPORTER */}
                        <Grid item xs={12} md={6}>
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.common?.TRANSPORTER}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <Input
                              name="transporter"
                              defaultValue={
                                form.transporter.name
                                  ? form.transporter.name
                                  : ""
                              }
                              id="transporter"
                              onBlur={handleBlur}
                              className={
                                errors.transporter && touched.transporter
                                  ? "input-error"
                                  : null
                              }
                              disabled
                              //onFocus="blur"
                              style={{
                                height: "45px",
                                border: "none",
                                backgroundColor: darkMode
                                  ? "#2E2E46"
                                  : "#ffffff",
                                //borderBottom: "0.5px solid #AEAEAE",
                              }}
                            />
                            <div className="transporterSeparator"></div>
                            <div
                              className={`d-flex align-items-center justify-content-end icon-cont ${
                                darkMode ? "darkInputHighlightDiv" : ""
                              }`}
                              style={{ float: "right" }}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "30%",
                                  borderRadius: "5px",
                                  backgroundColor: darkMode
                                    ? "#2E2E46"
                                    : "#F1F5F7",
                                  height: "25px",
                                  width: "25px",
                                }}
                                className=""
                                onClick={() => this.toggle()}
                              >
                                <i
                                  className="fas fa-plus fa-lg"
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          {/* <ErrorMessage
                            name="transporter"
                            component="span"
                            className="error"
                          /> */}

                          {this.state.transporterError && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              Transporter is required
                            </p>
                          )}
                        </Grid>

                        {/* CUSTOMERS */}
                        <Grid item xs={12} md={6}>
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.planning?.CUSTOMERS}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div
                            className="d-flex dateTimeInputParent"
                            style={{ position: "relative" }}
                          >
                            <Input
                              name="customers"
                              defaultValue={
                                form.customers.name ? form.customers.name : ""
                              }
                              id="customers"
                              onBlur={handleBlur}
                              className={
                                errors.customers && touched.customers
                                  ? "input-error"
                                  : null
                              }
                              disabled={this.props.location.mode === "edit"}
                              //onFocus="blur"
                              style={{
                                height: "45px",
                                border: "none",
                                backgroundColor: darkMode
                                  ? "#2E2E46"
                                  : "#ffffff",
                                //borderBottom: "0.5px solid #AEAEAE",
                              }}
                            />
                            <div className="chipWrapper">
                              {this.state.form.customers.length > 2 ? (
                                <>
                                  <div className="chip">
                                    <div className="chipTextDiv">
                                      {
                                        this.state.form.customers[0]
                                          .customerName
                                      }{" "}
                                      -{" "}
                                      {
                                        this.state.form.customers[0]
                                          .customerNumber
                                      }
                                    </div>
                                    <div>
                                      <img
                                        src={cross}
                                        className="chipImg"
                                        onClick={() => this.removeCustomer(0)}
                                      />
                                    </div>
                                  </div>
                                  <div className="chip">
                                    <div className="chipTextDiv">
                                      {
                                        this.state.form.customers[1]
                                          .customerName
                                      }{" "}
                                      -{" "}
                                      {
                                        this.state.form.customers[1]
                                          .customerNumber
                                      }
                                    </div>
                                    <div>
                                      <img
                                        src={cross}
                                        className="chipImg"
                                        onClick={() => this.removeCustomer(1)}
                                      />
                                    </div>
                                  </div>
                                  <div className="chipDot">...</div>
                                  <div className="chipNumber">
                                    {this.state.form.customers.length - 2}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.form.customers.map((e, index) => {
                                    return (
                                      <div className="chip" key={index}>
                                        <div className="chipTextDiv">
                                          {e.customerName} - {e.customerNumber}{" "}
                                          - {e.shipToCode} - {e.city}
                                        </div>
                                        <div>
                                          <img
                                            src={cross}
                                            className="chipImg"
                                            onClick={() =>
                                              this.removeCustomer(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                            {this.state.form.customers.length > 0 && (
                              <div
                                className="downArrowTransporter-div"
                                onClick={() => this.customerDropdown()}
                                hidden={this.props.location.mode === "edit"}
                              >
                                <img
                                  src={downArrow}
                                  className="downArrowTransporter"
                                />
                              </div>
                            )}
                            {this.state.customerDropdown && (
                              <div
                                className="customersDropDown"
                                ref={this.wrapperRef}
                              >
                                {this.state.form.customers.map((e, index) => {
                                  return (
                                    <div
                                      className="customerDropdownOption"
                                      key={index}
                                    >
                                      <div className="customerDropdownText">
                                        {e.customerName} -{e.shipToCode} -{" "}
                                        {e.customerNumber} - {e.city}
                                      </div>
                                      <img
                                        src={cross}
                                        className="customerDropdownCross"
                                        onClick={() =>
                                          this.removeCustomer(index)
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            <div className="transporterSeparator"></div>
                            <div
                              className={`d-flex align-items-center justify-content-end icon-cont ${
                                darkMode ? "darkInputHighlightDiv" : ""
                              }`}
                              style={{ float: "right" }}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "30%",
                                  borderRadius: "5px",
                                  backgroundColor: darkMode
                                    ? "#2E2E46"
                                    : "#F1F5F7",
                                  height: "25px",
                                  width: "25px",
                                }}
                                className=""
                                onClick={() => {
                                  this.toggleCustomers();
                                  this.fetchCustomers();
                                }}
                              >
                                <i
                                  className="fas fa-plus fa-lg"
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          {this.state.freightCostDisplay && (
                            <div
                              style={{
                                color: "blue",
                                fontWeight: "bolder",
                                fontSize: "medium",
                              }}
                            >
                              Freight cost:{this.state.freightCostDisplay}
                            </div>
                          )}
                          {/* <ErrorMessage
                            name="customers"
                            component="span"
                            className="error"
                          /> */}
                          {this.state.customerError && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              Customer is required
                            </p>
                          )}
                        </Grid>

                        {/* REPORTING DATE */}
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={`${darkMode ? "addIntentDarkModal" : ""}`}
                        >
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {
                              this.state.userLangs?.planning
                                ?.VEHICLE_REPORTING_DATE
                            }
                            / {this.state.userLangs?.planning?.TIME_AT_FACTORY}/
                            {this.state.userLangs?.planning?.DEPOT}
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent">
                            <div
                              className={`addIndentDateDiv ${
                                darkMode ? "darkReportTime" : ""
                              }`}
                            >
                              <Datetime
                                initialValue={
                                  rejection.length > 0
                                    ? moment(form.date)
                                    : form.date != null
                                    ? moment(form.date)
                                    : null
                                }
                                dateFormat={"DD/MM/YYYY"}
                                style={{
                                  height: "45px",
                                  border: "none",
                                  //borderBottom: "0.5px solid #AEAEAE",
                                }}
                                // className="d-flex flex-sm-grow-1 time-cont"
                                name="date"
                                isValidDate={this.valid}
                                onChange={(e) => {
                                  this.handleSubmitChange({
                                    target: {
                                      name: "date",
                                      value: e._d,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                id="date"
                                inputProps={{
                                  disabled: rejection.length > 0 ? true : false,
                                }}
                                className={
                                  (errors.date && touched.date
                                    ? "input-error"
                                    : null,
                                  "dateTime")
                                }
                                //className={`"dateTime ${errors?.date} && 'touched.date'? input-error:null"`}
                                ref={this.startdate}
                                onFocus={(e) => {}}
                              />
                            </div>
                            <div className="calendarSeparator"></div>
                            <div
                              className={`d-flex align-items-center justify-content-end icon-cont ${
                                darkMode ? "darkInputHighlightDiv" : ""
                              }`}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "25%",
                                  borderRadius: "5px",
                                  backgroundColor: darkMode
                                    ? "#2E2E46"
                                    : "#F1F5F7",
                                  height: "25px",
                                  width: "43px",
                                }}
                                onClick={() => {
                                  if (
                                    this.startdate.current.state.open === false
                                  ) {
                                    this.startdate.current._openCalendar();
                                  } else {
                                    this.startdate.current._closeCalendar();
                                  }
                                  // //console.log(
                                  //   this.startdate.current
                                  // );
                                }}
                                className={` ${
                                  rejection.length > 0 ? "disable" : ""
                                }`}
                              >
                                <i
                                  className="fas fa-calendar-week fa-lg "
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <ErrorMessage
                            name="date"
                            component="span"
                            className="error"
                          />
                        </Grid>

                        {/* EXPIRY DATE */}
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={`${darkMode ? "addIntentDarkModal" : ""}`}
                        >
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {
                              this.state.userLangs?.planning
                                ?.TRANSPORTER_ACCEPTANCE_EXPIRT_DATE
                            }
                            <span className="mandatoryFieldStyle">*</span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <div className="d-flex dateTimeInputParent expiry-date">
                            <div
                              className={`addIndentDateDiv ${
                                darkMode ? "darkReportTime" : ""
                              }`}
                            >
                              <Datetime
                                initialValue={
                                  rejection.length > 0
                                    ? moment(form.expirydate)
                                    : form.expirydate != null
                                    ? moment(form.expirydate)
                                    : null
                                }
                                name="expirydate"
                                isValidDate={this.validExpiry}
                                dateFormat={"DD/MM/YYYY"}
                                showTimeSelect
                                onChange={(e) => {
                                  this.handleSubmitChange({
                                    target: {
                                      name: "expirydate",
                                      value: e._d,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                input={true}
                                defaultValue={form.expirydate}
                                id="expirydate"
                                className={
                                  (errors.date && touched.date
                                    ? "input-error"
                                    : null,
                                  "dateTime")
                                }
                                ref={this.expirydate}
                              />
                            </div>
                            <div className="calendarSeparator"></div>
                            <div
                              className={`d-flex align-items-center justify-content-end icon-cont  ${
                                darkMode ? "darkInputHighlightDiv" : ""
                              }`}
                            >
                              <span
                                style={{
                                  border: "0.5px solid #2800FC",
                                  margin: "25%",
                                  borderRadius: "5px",
                                  backgroundColor: darkMode
                                    ? "#2E2E46"
                                    : "#F1F5F7",
                                  height: "25px",
                                  width: "43px",
                                }}
                                onClick={() => {
                                  if (
                                    this.expirydate.current.state.open === false
                                  ) {
                                    this.expirydate.current._openCalendar();
                                  } else {
                                    this.expirydate.current._closeCalendar();
                                  }
                                }}
                              >
                                <i
                                  className="fas fa-calendar-week fa-lg"
                                  style={{
                                    padding: "5px 5px",
                                    color: "#2800FC",
                                    fontSize: "small",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>

                          <ErrorMessage
                            name="expirydate"
                            component="span"
                            className="error"
                          />
                        </Grid>

                        {/* PICKUP LOCATION */}
                        <Grid item xs={12} md={6} className="indentFeildPd">
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.planning?.PICKUP_LOCATION}
                            <span className="mandatoryFieldStyle"></span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <input
                            id="pickUpLocation"
                            placeholder="Type"
                            name="pickUpLocation"
                            value={form.pickUpLocation || ""}
                            onChange={(e) => {
                              this.handleSubmitChange(e);
                            }}
                            onBlur={handleBlur}
                            options={[]}
                            className={
                              errors.pickUpLocation && touched.pickUpLocation
                                ? "input-error"
                                : null
                            }
                            style={{
                              height: "50px",
                              width: "202%",
                              border: "0.5px solid #AEAEAE",
                              borderRadius: "5px",
                              backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
                            }}
                          />
                        </Grid>

                        {/* COMMENTS */}
                        <Grid item xs={12} md={12} className="indentFeildPd">
                          <div style={{ height: "15px" }}></div>
                          <span
                            className={`formFieldHeaderStyle ${
                              darkMode ? "addIndentDarkColor" : ""
                            }`}
                          >
                            {this.state.userLangs?.planning?.COMMENTS}
                            <span className="mandatoryFieldStyle"></span>
                          </span>
                          <div style={{ height: "15px" }}></div>
                          <input
                            id="comments"
                            placeholder="Type"
                            name="comments"
                            value={form.comments || ""}
                            onChange={(e) => {
                              this.handleSubmitChange(e);
                            }}
                            onBlur={handleBlur}
                            options={[]}
                            className={
                              errors.comments && touched.comments
                                ? "input-error"
                                : null
                            }
                            style={{
                              height: "50px",
                              width: "97%",
                              border: "0.5px solid #AEAEAE",
                              borderRadius: "5px",
                              backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <div style={{ height: "25px" }}></div>
                          <div className="d-flex flex-row">
                            <div>
                              <Button
                                style={resetButtonStyle}
                                onClick={() => this.reset(resetForm)}
                              >
                                <span style={resetButtonSpanStyle}>
                                  {" "}
                                  {this.state.userLangs?.common?.RESET}
                                </span>
                              </Button>
                            </div>
                            <div>
                              <Button
                                style={submitButtonStyleIndent}
                                disabled={onSubmitDisable}
                                type="submit"
                              >
                                <span style={submitButtonSpanStyle}>
                                  {onSubmitDisable
                                    ? this.state.userLangs?.planning?.PROCESSING
                                    : this.state.userLangs?.common?.SUBMIT}
                                </span>
                              </Button>
                            </div>
                            <div>
                              {rejection.length > 0 ? (
                                <Button
                                  style={cancelIndentButtonStyle}
                                  onClick={() => {
                                    this.toggleRejectModal();
                                  }}
                                >
                                  <span style={cancelIndentButtonSpanStyle}>
                                    {
                                      this.state.userLangs?.planning
                                        ?.CANCEL_INDENT
                                    }
                                  </span>
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {
  fetchSourceSecondary,
  fetchVehicleTypeSecondary,
  fetchTransporterSecondary,
  fetchCustomersSecondary,
  createIndentSecondary,
  costDisplayFormSecondary,
  fetchCustomerOptionsSecondary,
})(SecondaryIndentDetails);
